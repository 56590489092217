import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Route } from "react-router-dom";
import { connect } from "react-redux";
import get from "lodash/get";
import isEqual from "lodash/isEqual";

import { PATHS } from "../../constants/url";
import withChildren from "components/WithChildren";
import { setSelectedChildId } from "../../actions/children";
import {
  selectSelectedChildId,
  selectIsFetchingChildren
} from "../../selectors/children";

class RouteWithValidChild extends Component {
  static propTypes = {
    children: PropTypes.array,
    isFetchingChild: PropTypes.bool
  };

  static defaultProps = {
    children: [],
    isFetchingChild: false
  };

  componentDidMount() {
    const { selectedChildId } = this.props;
    if (!selectedChildId && this.props.children.length) {
      this.selectValidChild();
    }
  }

  componentDidUpdate(prevProps) {
    const { children, selectedChildId, isFetchingChild, history } = this.props;
    if (
      !selectedChildId &&
      children &&
      !isEqual(children, prevProps.children)
    ) {
      this.selectValidChild();
    }
    if (!isFetchingChild && children.length < 1) {
      history.push(PATHS.ADD_CHILD);
    }
  }

  isValidChild = childId => {
    return !!(this.props.children || []).find(child => child.id === childId);
  };

  selectValidChild = () => {
    // todo was expecting props.match to work here instead of 'props.computedMatch'
    // todo but props.match always gives '/' as path - explore further
    const childIdParam = get(this, "props.computedMatch.params.childId", "");

    if (!childIdParam) {
      return;
    }

    if (this.isValidChild(childIdParam)) {
      if(this.props.saveChild !== false){
         this.props.selectChild(childIdParam);
      }
    } else {
      this.props.history.replace("/");
    }
  };

  render() {
    const { component: Component, isEdit, ...rest } = this.props;

    return <Route {...rest}>{({ props }) => <Component {...props} isEdit />}</Route>;
  }
}

const mapStateToProps = state => ({
  selectedChildId: selectSelectedChildId(state),
  isFetchingChild: selectIsFetchingChildren(state)
});

const mapDispatchToProps = dispatch => ({
  selectChild(id) {
    dispatch(setSelectedChildId(id));
  }
});

export default withRouter(
  withChildren(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(RouteWithValidChild)
  )
);
