import axios from "axios";
import Cookies from "js-cookie";
import { cookieParams } from "utils";

export default {
  setupInterceptors: (tokenName) => {
    axios.interceptors.request.use(request => requestHandler(request));

    let access_token = '';
    const requestHandler = request => {
      const token = Cookies.get(tokenName);
      // const token = Cookies.get("access_token");
      if (token) {
        access_token = token;
      }
      if (request.headers["Authorization"] && access_token) {
        request.headers["Authorization"] = `Bearer ${access_token}`;
      }
      return request;
    };

    axios.interceptors.response.use(
      response => {
        if (response.config.url.indexOf("/react/logout") !== -1) {
          access_token = '';
        }
        if (response.config.url.indexOf("manifests?deviceId") !== -1) {
          Cookies.set(
            "subscriptionStatus",
            response.data.data.subscriptionStatus,
            cookieParams
          );
          Cookies.set("subscriptionType", response.data.data.subscriptionType, cookieParams);
        }
        return response;
      },
      error => {
        if (error.response !== undefined) {
          error.message = "Something went wrong, Please check back later!";
        }
        return Promise.reject(error);
      }
    );
  }
};
