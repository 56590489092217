import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectBaseUrl } from "selectors";
import {
  selectCountry,
  selectCountryCode,
  selectDashboardApi
} from "selectors/dashboard";
import {
  selectSelectedChildId,
  selectSelectedChildAge
} from "selectors/children";
import {
  getCountry,
  getManifestRecommendation,
  getManifestData,
  getFavouriteList
} from "actions/dashboard";
import get from "lodash/get";
import { platform } from "helper.js";
import Cookies from "js-cookie";

export const mapStateToProps = state => ({
  baseUrl: selectBaseUrl(state),
  countryUrl: selectCountry(state),
  countryCode: selectCountryCode(state),
  dashboardApiData: selectDashboardApi(state),
  selectedChildId: selectSelectedChildId(state),
  selectedChildAge: selectSelectedChildAge(state),
  ApiVerson: get(state, "common.apiversiondata"),
  favListStatus: get(state, "dashboardList.favoriteList.status.code"),
  recommendedManifestDataStatus: get(state, "dashboardList.recommendedManifestData.status.code")
});

export const mapDispatchToProps = dispatch => ({
  getCountry(countryUrl) {
    dispatch(getCountry(countryUrl));
  },
  getManifestRecommendation(baseUrl, countryCode, deviceModel, age, apiVerson) {
    dispatch(
      getManifestRecommendation(
        baseUrl,
        countryCode,
        deviceModel,
        age,
        apiVerson
      )
    );
  },
  getManifestData(baseUrl, countryCode, deviceModel, itemType, apiVerson) {
    dispatch(
      getManifestData(baseUrl, countryCode, deviceModel, itemType, apiVerson)
    );
  },
  getFavouriteList(baseUrl, params) {
    dispatch(getFavouriteList(baseUrl, params));
  }
});

export default function fetchManifestData(WrappedComponent) {
  class FetchManifestData extends Component {
    static propTypes = {
      baseUrl: PropTypes.string,
      getCountry: PropTypes.func,
      countryUrl: PropTypes.string,
      countryCode: PropTypes.string,
      getManifestRecommendation: PropTypes.func,
      dashboardApiData: PropTypes.object,
      getManifestData: PropTypes.func,
      getFavouriteList: PropTypes.func,
      selectedChildId: PropTypes.string,
      selectedChildAge: PropTypes.number
    };

    static defaultProps = {
      baseUrl: "",
      getCountry() {},
      countryUrl: "",
      countryCode: "",
      getManifestRecommendation() {},
      dashboardApiData: {},
      getManifestData() {},
      getFavouriteList() {},
      selectedChildId: "",
      selectedChildAge: ""
    };

    componentDidMount() {
      const {
        getCountry,
        countryUrl,
        selectedChildId,
        baseUrl,
        getFavouriteList,
        countryCode,
        ApiVerson,
        selectedChildAge,
        dashboardApiData,
        getManifestData,
        getManifestRecommendation,
        favListStatus,
        recommendedManifestDataStatus
      } = this.props;
      const params = {
        cid: selectedChildId
      };

      if (baseUrl && countryCode && ApiVerson) {
        getManifestData(
          baseUrl,
          countryCode,
          platform,
          dashboardApiData["item_type"],
          ApiVerson
        );
      }
      let childId = Cookies.get("childId");
      if (baseUrl && countryCode && ApiVerson && selectedChildId && (typeof recommendedManifestDataStatus === "undefined" || (childId && selectedChildId !== childId))) {
        getManifestRecommendation(
          baseUrl,
          countryCode,
          platform,
          selectedChildAge,
          ApiVerson
        );
      }
      (selectedChildId && (typeof favListStatus === "undefined" || (childId && selectedChildId !== childId))) && getFavouriteList(baseUrl, params);
      if(!countryCode && countryUrl){
        getCountry(countryUrl);
      }
    }

    componentDidUpdate(prevProps) {
      const {
        baseUrl,
        countryCode,
        dashboardApiData,
        getManifestRecommendation,
        getManifestData,
        selectedChildAge,
        selectedChildId,
        getFavouriteList,
        ApiVerson,
        favListStatus,
        recommendedManifestDataStatus
      } = this.props;
      if (baseUrl && ApiVerson && countryCode !== prevProps.countryCode) {
        getManifestData(
          baseUrl,
          countryCode,
          platform,
          dashboardApiData["item_type"],
          ApiVerson
        );
      } else if (baseUrl && countryCode && ApiVerson !== prevProps.ApiVerson) {
        getManifestData(
          baseUrl,
          countryCode,
          platform,
          dashboardApiData["item_type"],
          ApiVerson
        );
      }
      if (
        baseUrl &&
        ApiVerson &&
        selectedChildId !== prevProps.selectedChildId &&
        countryCode
      ) {
        getManifestRecommendation(
          baseUrl,
          countryCode,
          platform,
          selectedChildAge,
          ApiVerson
        );

        const params = {
          cid: selectedChildId
        };

        selectedChildId && getFavouriteList(baseUrl, params);
      } else if (
        baseUrl &&
        ApiVerson &&
        countryCode !== prevProps.countryCode &&
        selectedChildId
      ) {
        (typeof recommendedManifestDataStatus === "undefined") && getManifestRecommendation(
          baseUrl,
          countryCode,
          platform,
          selectedChildAge,
          ApiVerson
        );

        const params = {
          cid: selectedChildId
        };

        (selectedChildId && typeof favListStatus === "undefined") && getFavouriteList(baseUrl, params);
      } else if (
        baseUrl &&
        countryCode &&
        ApiVerson !== prevProps.ApiVerson &&
        selectedChildId
      ) {
        (typeof recommendedManifestDataStatus === "undefined") && getManifestRecommendation(
          baseUrl,
          countryCode,
          platform,
          selectedChildAge,
          ApiVerson
        );

        const params = {
          cid: selectedChildId
        };

        (selectedChildId && typeof favListStatus === "undefined") && getFavouriteList(baseUrl, params);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(FetchManifestData);
}
