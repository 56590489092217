import axios from "axios";
import { EVENT_TOKEN } from "../constants";
import { keys } from "lodash";
import { platform } from "helper.js";

let commonHeaders = {
  "Content-Type": "application/json",
  Authorization: `Bearer`
};

const eventHeaders = {
  "Content-Type": "application/json",
  token: EVENT_TOKEN
};

export const generateUrl = (baseUrl, apiUrl = "") => `${baseUrl}${apiUrl}`;

export const apiGet = ({ url }) => axios.get(url, { headers: commonHeaders });

export const apiGetExternal = ({ url }) => axios.get(url);

export const apiPost = ({ url, params }) =>
  axios.post(
    url,
    { ...params, platform: platform },
    { headers: commonHeaders }
  );

export const apiPatch = ({ url, params }) =>
  axios.patch(
    url,
    { ...params, platform: platform },
    { headers: commonHeaders }
  );

export const apiPostEvent = ({ url, params }) =>
  axios.post(url, [...params], { headers: eventHeaders });

const loginHeader = {
  "Content-Type": "application/x-www-form-urlencoded"
};

export const apiPostLogin = ({ url, params }) => {
  const formDataObj = new FormData();
  keys(params).forEach(key => {
    formDataObj.append(key, params[key]);
  });
  formDataObj.append("platform", platform);
  return axios.post(url, formDataObj, { headers: loginHeader });
};
