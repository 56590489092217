import React from "react";
import styles from "./_loader.module.scss";
import classes from "../Loading/_styles.module.scss";
import Image from "../Global/Image";
import c from "classnames";

const Loader = favClass => {
  return (
    <div className={c(styles.loader, styles[favClass.className])}>
      {favClass.favClass === "true" ? (
        <Image
          className={styles.spinnerFav}
          folderName="ui_and_learning_area"
          fileName="spinner"
          alt="Loading"
        />
      ) : (
        <React.Fragment>
          {favClass.favClass === "lockicon" ? (
            <Image
              className={styles.lockicon}
              folderName="ui_and_learning_area"
              fileName="lock_icon"
              alt="icon"
            />
          ) : (
            <Image
              className={classes.spinner}
              folderName="ui_and_learning_area"
              fileName="spinner"
              alt="Loading"
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default Loader;
