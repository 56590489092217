export const READ_MANIFEST_REQUEST = "READ_MANIFEST_REQUEST";
export const READ_MANIFEST_SUCCESS = "READ_MANIFEST_SUCCESS";
export const READ_MANIFEST_FAILURE = "READ_MANIFEST_FAILURE";

export const GET_ALL_CHILDREN_REQUEST = "GET_ALL_CHILDREN_REQUEST";
export const GET_ALL_CHILDREN_SUCCESS = "GET_ALL_CHILDREN_SUCCESS";
export const GET_ALL_CHILDREN_FAILURE = "GET_ALL_CHILDREN_FAILURE";

export const ADD_CHILD_REQUEST = "ADD_CHILD_REQUEST";
export const ADD_CHILD_SUCCESS = "ADD_CHILD_SUCCESS";
export const ADD_CHILD_FAILURE = "ADD_CHILD_FAILURE";

export const EDIT_CHILD_REQUEST = "EDIT_CHILD_REQUEST";
export const EDIT_CHILD_SUCCESS = "EDIT_CHILD_SUCCESS";
export const EDIT_CHILD_FAILURE = "EDIT_CHILD_FAILURE";

export const DELETE_CHILD_REQUEST = "DELETE_CHILD_REQUEST";
export const DELETE_CHILD_SUCCESS = "DELETE_CHILD_SUCCESS";
export const DELETE_CHILD_FAILURE = "DELETE_CHILD_FAILURE";

export const SET_SELECTED_CHILD = "SET_SELECTED_CHILD";

export const GET_MY_PROFILE_REQUEST = "GET_MY_PROFILE_REQUEST";
export const GET_MY_PROFILE_SUCCESS = "GET_MY_PROFILE_SUCCESS";
export const GET_MY_PROFILE_FAILURE = "GET_MY_PROFILE_FAILURE";

export const GET_COUNTRY_REQUEST = "GET_COUNTRY_REQUEST";
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS";
export const GET_COUNTRY_FAILURE = "GET_COUNTRY_FAILURE";

export const GET_MANIFEST_RECOMMENDATION_REQUEST =
  "GET_MANIFEST_RECOMMENDATION_REQUEST";
export const GET_MANIFEST_RECOMMENDATION_SUCCESS =
  "GET_MANIFEST_RECOMMENDATION_SUCCESS";
export const GET_MANIFEST_RECOMMENDATION_FAILURE =
  "GET_MANIFEST_RECOMMENDATION_FAILURE";

export const GET_MANIFEST_DATA_REQUEST = "GET_MANIFEST_DATA_REQUEST";
export const GET_MANIFEST_DATA_SUCCESS = "GET_MANIFEST_DATA_SUCCESS";
export const GET_MANIFEST_DATA_FAILURE = "GET_MANIFEST_DATA_FAILURE";

export const GET_FAVOURITE_LIST_REQUEST = "GET_FAVOURITE_LIST_REQUEST";
export const GET_FAVOURITE_LIST_SUCCESS = "GET_FAVOURITE_LIST_SUCCESS";
export const GET_FAVOURITE_LIST_FAILURE = "GET_FAVOURITE_LIST_FAILURE";

export const LAUNCH_DIALOG = "LAUNCH_DIALOG";
export const CLOSE_DIALOG = "CLOSE_DIALOG";

export const GET_RELATED_CONTENT_REQUEST = "GET_RELATED_CONTENT_REQUEST";
export const GET_RELATED_CONTENT_SUCCESS = "GET_RELATED_CONTENT_SUCCESS";
export const GET_RELATED_CONTENT_FAILURE = "GET_RELATED_CONTENT_FAILURE";

export const ADD_TO_FAVOURITES_REQUEST = "ADD_TO_FAVOURITES_REQUEST";
export const ADD_TO_FAVOURITES_SUCCESS = "ADD_TO_FAVOURITES_SUCCESS";
export const ADD_TO_FAVOURITES_FAILURE = "ADD_TO_FAVOURITES_FAILURE";

export const REMOVE_TO_FAVOURITES_REQUEST = "REMOVE_TO_FAVOURITES_REQUEST";
export const REMOVE_TO_FAVOURITES_SUCCESS = "REMOVE_TO_FAVOURITES_SUCCESS";
export const REMOVE_TO_FAVOURITES_FAILURE = "REMOVE_TO_FAVOURITES_FAILURE";

export const WEB_APP_EVENT_REQUEST = "WEB_APP_EVENT_REQUEST";
export const WEB_APP_EVENT_SUCCESS = "WEB_APP_EVENT_SUCCESS";
export const WEB_APP_EVENT_FAILURE = "WEB_APP_EVENT_FAILURE";
export const NO_WEB_EVENT = "NO_WEB_EVENT";

export const RESOURCE_PROGRESSIONS_REQUEST = "RESOURCE_PROGRESSIONS_REQUEST";
export const RESOURCE_PROGRESSIONS_SUCCESS = "RESOURCE_PROGRESSIONS_SUCCESS";
export const RESOURCE_PROGRESSIONS_FAILURE = "RESOURCE_PROGRESSIONS_FAILURE";

export const USER_DASHBOARD_REQUEST = "USER_DASHBOARD_REQUEST";
export const USER_DASHBOARD_SUCCESS = "USER_DASHBOARD_SUCCESS";
export const USER_DASHBOARD_FAILURE = "USER_DASHBOARD_FAILURE";

export const LOGIN_EVENT_REQUEST = "LOGIN_EVENT_REQUEST";
export const LOGIN_EVENT_SUCCESS = "LOGIN_EVENT_SUCCESS";
export const LOGIN_EVENT_FAILURE = "LOGIN_EVENT_FAILURE";

export const AGECHCEK_DIALOG = "AGECHCEK_DIALOG";
export const ROUTE_VALUE = "ROUTE_VALUE";

export const AUTHENTICATE_URL_REQUEST = "AUTHENTICATE_URL_REQUEST";
export const AUTHENTICATE_URL_SUCCESS = "AUTHENTICATE_URL_SUCCESS";
export const AUTHENTICATE_URL_FAILURE = "AUTHENTICATE_URL_FAILURE";

export const REACT_LOGIN = "REACT_LOGIN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const GETSTARTED_CLICKED = "GETSTARTED_CLICKED";

export const READ_API_VERSION_FAILURE = "READ_API_VERSION_FAILURE";
export const READ_API_VERSION_REQUEST = "READ_API_VERSION_REQUEST";
export const READ_API_VERSION_SUCCESS = "READ_API_VERSION_SUCCESS";

export const LOGOUT_COOKIE_CLEAR = "LOGOUT_COOKIE_CLEAR";

export const EVENT_BASED_CONTENT_FAILURE = "EVENT_BASED_CONTENT_FAILURE";
export const EVENT_BASED_CONTENT_REQUEST = "EVENT_BASED_CONTENT_REQUEST";
export const EVENT_BASED_CONTENT_SUCCESS = "EVENT_BASED_CONTENT_SUCCESS";

export const STORE_EPUB_CONTENT = "STORE_EPUB_CONTENT";
export const STORE_EPUB_AUDIO_CONTENT = "STORE_EPUB_AUDIO_CONTENT";

export const GET_PRIMROSE_RESOURCE_FAILURE = "GET_PRIMROSE_RESOURCE_FAILURE";
export const GET_PRIMROSE_RESOURCE_REQUEST = "GET_PRIMROSE_RESOURCE_REQUEST";
export const GET_PRIMROSE_RESOURCE_SUCCESS = "GET_PRIMROSE_RESOURCE_SUCCESS";

export const LOADING_SCREEN_MESSAGE_REQUEST = "LOADING_SCREEN_MESSAGE_REQUEST";
export const LOADING_SCREEN_MESSAGE_SUCCESS = "LOADING_SCREEN_MESSAGE_SUCCESS";
export const LOADING_SCREEN_MESSAGE_FAILURE = "LOADING_SCREEN_MESSAGE_FAILURE";
