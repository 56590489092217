import get from "lodash/get";
import Cookies from "js-cookie";
import { BUILD } from "./constants";
import packageJson from "../package.json";
import { cookieParams } from "utils";
import { isMobileOnly, isTablet, isAndroid, isIOS } from "react-device-detect";
const queryString = require("query-string");

const parsed = queryString.parse(window.location.search);
// export const device = parsed.device;
// if (parsed.device && parsed.device.includes("android")) {
//   Cookies.set("device", "android", cookieParams);
// }
// export const platform = Cookies.get("device") ? "android" : "desktop";
export const platform = "desktop";
// const subscriptionStatus = Cookies.get("subscriptionStatus");
// let status = null;
// if (
//   subscriptionStatus === "Cancelled" ||
//   subscriptionStatus === "Free Promotion Expired" ||
//   subscriptionStatus === "Free"
// ) {
//   status = "Locked";
// } else {
//   status = "Unlocked";
// }
if (parsed.deviceId !== undefined && parsed.deviceId !== null) {
  Cookies.set("deviceIDnew", parsed.deviceId, cookieParams);
}

let deviceType = "Desktop";
if (isAndroid && isMobileOnly) {
  deviceType = "Android Mobile";
} else if (isAndroid && isTablet) {
  deviceType = "Android Tablet";
} else if (isIOS && isMobileOnly) {
  deviceType = "iPhone";
} else if (isIOS && isTablet) {
  deviceType = "iPad";
} 

export const deviceInfo = {
  mobi_build_version: packageJson.version,
  mobi_deviceId: Cookies.get("deviceIDnew") ? Cookies.get("deviceIDnew") : "web",
  mobi_deviceType: deviceType,
  mobi_build_bundle: BUILD.BUNDLE
};

// export const initialChildProfileCreated = (
//   data,
//   day,
//   month,
//   year,
//   profile
// ) => ({
//   buttonname: "LearningProfile_Next",
//   screenname: "LearningProfile",
//   event_name: "signUpChildDetail",
//   childDOB: day + "/" + month + "/" + year,
//   childName: data && data.name,
//   email: get(profile, "email"),
//   userId: get(profile, "id"),
//   usertype:
//     Cookies.get("subscriptionStatus") === "Subscriber"
//       ? "Subscribed"
//       : "Freemium",
//   subscriptionState: Cookies.get("subscriptionStatus"),
//   subscriptiontype: Cookies.get("subscriptionType")
// });

export const newChildAdded = (profile, data, countryCode) => ({
  event_name: "new_child_added",
  // username: get(profile, "email"),
  // profile: get(profile, "firstName"),
  mobi_userId: get(profile, "id"),
  operation: "Add",
  "Avatar": get(data, "metadata.avatar"),
  subscriptionStatus: Cookies.get("subscriptionStatus"),
  "Child Age": Cookies.get("child_age"),
  countryCode: countryCode,
  timestamp: Date.parse(new Date()),
  // email: get(profile, "email"),
  // usertype:
  //   Cookies.get("subscriptionStatus") === "Subscriber"
  //     ? "Subscribed"
  //     : "Freemium",
  // subscriptiontype: Cookies.get("subscriptionType")
});

export const titleEvent = (profile, dataElement, titletype, countryCode) => ({
  // email: get(profile, "email"),
  mobi_userId: get(profile, "id"),
  // usertype:
  //   Cookies.get("subscriptionStatus") === "Subscriber"
  //     ? "Subscribed"
  //     : "Freemium",
  "Title Name": get(dataElement, "title"),
  "Category Name": get(dataElement, "category"),
  "Content Type": titletype,
  // profile: get(profile, "firstName"),
  // publisher: get(dataElement, "publishers"),
  // username: get(profile, "email"),
  // screenname: "contentView",
  // status: status,
  "Child Age": Cookies.get("child_age"),
  // publishertype: get(dataElement, "isOriginal") ? "Original" : "Not Original",
  // titleId: get(dataElement, "id"),
  subscriptionStatus: Cookies.get("subscriptionStatus"),
  countryCode: countryCode,
  timestamp: Date.parse(new Date()),
  // subscriptiontype: Cookies.get("subscriptionType")
});

export const collectionEvent = (profile, dataElement, titletype, countryCode) => ({
  // email: get(profile, "email"),
  mobi_userId: get(profile, "id"),
  // usertype:
  //   Cookies.get("subscriptionStatus") === "Subscriber"
  //     ? "Subscribed"
  //     : "Freemium",
  "Collection Name": get(dataElement, "title"),
  // titletype: titletype,
  // profile: get(profile, "firstName"),
  // publisher: get(dataElement, "publishers"),
  // username: get(profile, "email"),
  // screenname: "contentView",
  // status: status,
  "Child Age": Cookies.get("child_age"),
  // publishertype: get(dataElement, "isOriginal") ? "Original" : "Not Original",
  // titleId: get(dataElement, "id"),
  subscriptionStatus: Cookies.get("subscriptionStatus"),
  countryCode: countryCode,
  timestamp: Date.parse(new Date()),
  // subscriptiontype: Cookies.get("subscriptionType")
});

export const signIn = (countryCode) => ({
  event_name: "sign_in",
  // usertype:
  //   Cookies.get("subscriptionStatus") === "Subscriber"
  //     ? "Subscribed"
  //     : "Freemium",
  subscriptionStatus: Cookies.get("subscriptionStatus"),
  countryCode: countryCode,
  timestamp: Date.parse(new Date()),
  // subscriptiontype: Cookies.get("subscriptionType")
});

export const successfulSearch = (profile, searchText, length, countryCode) => ({
  mobi_childage: Cookies.get("child_age"),
  mobi_screenname: "Search",
  mobi_userId: get(profile, "id"),
  // username: get(profile, "email"),
  // email: get(profile, "email"),
  // usertype:
  //   Cookies.get("subscriptionStatus") === "Subscriber"
  //     ? "Subscribed"
  //     : "Freemium",
  // profile: get(profile, "firstName"),
  mobi_searchkeyword: searchText,
  mobi_searchresult: length,
  subscriptionStatus: Cookies.get("subscriptionStatus"),
  event_name: "search_successful",
  countryCode: countryCode,
  timestamp: Date.parse(new Date()),
  // subscriptiontype: Cookies.get("subscriptionType")
});

export const unsuccessfulSearch = (profile, searchText, length, countryCode) => ({
  mobi_childage: Cookies.get("child_age"),
  // screenname: "Search",
  mobi_userId: get(profile, "id"),
  // username: get(profile, "email"),
  // email: get(profile, "email"),
  // usertype:
  //   Cookies.get("subscriptionStatus") === "Subscriber"
  //     ? "Subscribed"
  //     : "Freemium",
  // profile: get(profile, "firstName"),
  mobi_searchkeyword: searchText,
  mobi_searchresult: length,
  subscriptionStatus: Cookies.get("subscriptionStatus"),
  event_name: "search_unsuccessful",
  countryCode: countryCode,
  timestamp: Date.parse(new Date()),
  // subscriptiontype: Cookies.get("subscriptionType")
});

export const categoryTapped = (profile, category, countryCode) => ({
  event_name: "category_tapped",
  // username: get(profile, "email"),
  // profile: get(profile, "firstName"),
  // email: get(profile, "email"),
  subscriptionStatus: Cookies.get("subscriptionStatus"),
  mobi_userId: get(profile, "id"),
  // usertype:
  //   Cookies.get("subscriptionStatus") === "Subscriber"
  //     ? "Subscribed"
  //     : "Freemium",
  "Category Name": get(category, "title"),
  "Child Age": Cookies.get("child_age"),
  countryCode: countryCode,
  timestamp: Date.parse(new Date()),
  // subscriptiontype: Cookies.get("subscriptionType")
});

export const parentDashboardVisited = (profile, spentTime, countryCode) => ({
  event_name: "parent_dashboard_visited",
  subscriptionStatus: Cookies.get("subscriptionStatus"),
  // usertype:
  //   Cookies.get("subscriptionStatus") === "Subscriber"
  //     ? "Subscribed"
  //     : "Freemium",
  // timespent: Math.floor(spentTime),
  // email: get(profile, "email"),
  // profile: get(profile, "firstName"),
  // username: get(profile, "email"),
  mobi_userId: get(profile, "id"),
  "Child Age": Cookies.get("child_age"),
  countryCode: countryCode,
  timestamp: Date.parse(new Date()),
  // parentmanagermode: "View All",
  // subscriptiontype: Cookies.get("subscriptionType")
});

export const grownupMenu = (profile, countryCode) => ({
  ...deviceInfo,
  event_name: "grownups_menu_accessed",
  // profile: get(profile, "firstName"),
  // email: get(profile, "email"),
  mobi_userId: get(profile, "id"),
  // screenname: "grownupMenu",
  // parentmanagermode: "View All",
  // username: get(profile, "email"),
  "Child Age": Cookies.get("child_age"),
  subscriptionStatus: Cookies.get("subscriptionStatus"),
  countryCode: countryCode,
  timestamp: Date.parse(new Date()),
  // usertype:
  //   Cookies.get("subscriptionStatus") === "Subscriber"
  //     ? "Subscribed"
  //     : "Freemium",
  // subscriptiontype: Cookies.get("subscriptionType")
});

export const lockedTitleTapped = (profile, item, selectedChild, countryCode) => ({
  ...deviceInfo,
  event_name: "locked_title_tapped",
  // email: get(profile, "email"),
  mobi_userId: get(profile, "id"),
  // usertype:
  //   Cookies.get("subscriptionStatus") === "Subscriber"
  //     ? "Subscribed"
  //     : "Freemium",
  subscriptionStatus: Cookies.get("subscriptionStatus"),
  "Child Age": Cookies.get("child_age"),
  // childName: selectedChild.name,
  "Category Name": item.category,
  "Content Type": item.contentType,
  "Title Name": item.title,
  countryCode: countryCode,
  timestamp: Date.parse(new Date()),
  // subscriptiontype: Cookies.get("subscriptionType")
});

export const titleCompleted = (profile, itemEntity, timeOnTask, itemType, countryCode) => ({
  ...deviceInfo,
  event_name: "title_completed",
  // profile: get(profile, "firstName"),
  // email: get(profile, "email"),
  // username: get(profile, "email"),
  "Child Age": Cookies.get("child_age"),
  subscriptionStatus: Cookies.get("subscriptionStatus"),
  // usertype:
  //   Cookies.get("subscriptionStatus") === "Subscriber"
  //     ? "Subscribed"
  //     : "Freemium",
  "Title Name": get(itemEntity, "title"),
  "Time On Task": Math.floor(timeOnTask),
  mobi_userId: get(profile, "id"),
  "Category Name": get(itemEntity, "category"),
  "Content Type": itemType,
  countryCode: countryCode,
  timestamp: Date.parse(new Date()),
  // subscriptiontype: Cookies.get("subscriptionType")
});
