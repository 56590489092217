import deepFreeze from "deep-freeze-es6";
import {
  STORE_EPUB_CONTENT,
  STORE_EPUB_AUDIO_CONTENT,
  GET_PRIMROSE_RESOURCE_FAILURE,
  GET_PRIMROSE_RESOURCE_REQUEST,
  GET_PRIMROSE_RESOURCE_SUCCESS
} from "constants/actions";
import get from "lodash/get";
import { getOtpFileDetails } from "utils/epub"

const initialState = {
  epubData: [],
  primroseResource: {}
};

export const storeBookDetails = (state, action = {}) => {
  const prevEpubData = [...get(state, "epubData", [])];
  const bookId = get(action, "bookId");
  const epubbaseUrl = get(action, "epubbaseUrl");
  let bookDetials = { bookId, ...getOtpFileDetails(epubbaseUrl) }
  return {
    ...state,
    epubData: [...prevEpubData, bookDetials]
  };
};

export const storeAudioDetails = (state, action = {}) => {
  const prevEpubData = [...get(state, "epubData", [])];
  const bookId = get(action, "bookId");
  const page1AudioDetails = get(action, "page1AudioDetails");
  const page2AudioDetails = get(action, "page2AudioDetails");
  const pageNo = get(action, "pageNo");
  const noOfPages = get(action, "noOfPages");

  let audioDetails;
  if (pageNo === 1) {
    audioDetails = {
      ['page'+pageNo]: page2AudioDetails
    }
  }
  else if (noOfPages === pageNo) {
    audioDetails = {
      ['page'+pageNo]: page1AudioDetails
    }
  }
  else {
    audioDetails = {
      ['page'+pageNo]: page1AudioDetails,
      ['page'+(pageNo+1)]: page2AudioDetails
    }
  }

  let epubDataWithAudio = prevEpubData.map(item => {
    let prevAudios = item['audio'];
    if (item['bookId'] === bookId) {
      return {
        ...item,
        audio: {...prevAudios, ...audioDetails}
      }
    }
    else {
      return {
        ...item
      }
    }
  });

  return {
    ...state,
    epubData: epubDataWithAudio
  };
};

export const getPrimroseResourceRequest = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage
  };
};

export const getPrimroseResourceSuccess = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    primroseResource: action.primroseResource
  };
};

export const getPrimroseResourceFailure = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    primroseResource: action.primroseResource
  };
};


export default (state = initialState, action) => {
  deepFreeze(state);
  deepFreeze(action);
  switch (action.type) {
    case STORE_EPUB_CONTENT:
      return storeBookDetails(state, action);
    case STORE_EPUB_AUDIO_CONTENT:
      return storeAudioDetails(state, action);
    case GET_PRIMROSE_RESOURCE_FAILURE:
      return getPrimroseResourceFailure(state, action);
    case GET_PRIMROSE_RESOURCE_REQUEST:
      return getPrimroseResourceRequest(state, action);
    case GET_PRIMROSE_RESOURCE_SUCCESS:
      return getPrimroseResourceSuccess(state, action);
    default:
      return state;
  }
};
