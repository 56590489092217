import {
  WEB_APP_EVENT_REQUEST,
  WEB_APP_EVENT_SUCCESS,
  WEB_APP_EVENT_FAILURE,
  NO_WEB_EVENT,
  RESOURCE_PROGRESSIONS_REQUEST,
  RESOURCE_PROGRESSIONS_SUCCESS,
  RESOURCE_PROGRESSIONS_FAILURE,
  AUTHENTICATE_URL_REQUEST,
  AUTHENTICATE_URL_SUCCESS,
  AUTHENTICATE_URL_FAILURE,
  USER_LOGGED_OUT,
  READ_API_VERSION_FAILURE,
  READ_API_VERSION_REQUEST,
  READ_API_VERSION_SUCCESS,
  LOADING_SCREEN_MESSAGE_REQUEST,
  LOADING_SCREEN_MESSAGE_SUCCESS,
  LOADING_SCREEN_MESSAGE_FAILURE
} from "../constants/actions";
import get from "lodash/get";
import { apiPostEvent, apiPost, generateUrl, apiGet } from "./core";
import Cookies from "js-cookie";

export const webAppEventRequest = () => ({
  type: WEB_APP_EVENT_REQUEST,
  isFetching: true,
  errorMessage: ""
});

export const webAppEventSuccess = data => ({
  type: WEB_APP_EVENT_SUCCESS,
  isFetching: false,
  errorMessage: "",
  data
});

export const webAppEventFailure = errorMessage => ({
  type: WEB_APP_EVENT_FAILURE,
  isFetching: false,
  errorMessage
});

export const webAppEvent = (url, params) => {
  if(!Cookies.get("clever_user")) {
    return dispatch => {
      dispatch(webAppEventRequest());
      return apiPostEvent({
        url: url,
        params
      })
        .then(response => dispatch(webAppEventSuccess(get(response, "data", []))))
        .catch(err => dispatch(webAppEventFailure(get(err, "message", []))));
    };
  }
  else {
    return {
      type: NO_WEB_EVENT
    }
  }
};

export const resourceProgressionsRequest = () => ({
  type: RESOURCE_PROGRESSIONS_REQUEST,
  isFetching: true,
  errorMessage: ""
});

export const resourceProgressionsSuccess = data => ({
  type: RESOURCE_PROGRESSIONS_SUCCESS,
  isFetching: false,
  errorMessage: "",
  data
});

export const resourceProgressionsFailure = errorMessage => ({
  type: RESOURCE_PROGRESSIONS_FAILURE,
  isFetching: false,
  errorMessage
});

export const resourceProgressions = (baseUrl, params, userId) => {
  return dispatch => {
    dispatch(resourceProgressionsRequest());
    return apiPost({
      url: generateUrl(baseUrl, `/users/${userId}/resourceProgressions`),
      params
    })
      .then(response =>
        dispatch(resourceProgressionsSuccess(get(response, "data", [])))
      )
      .catch(err =>
        dispatch(resourceProgressionsFailure(get(err, "message", [])))
      );
  };
};

export const authenticateUrlRequest = () => ({
  type: AUTHENTICATE_URL_REQUEST,
  isFetching: true,
  errorMessage: ""
});

export const authenticateUrlSuccess = data => ({
  type: AUTHENTICATE_URL_SUCCESS,
  isFetching: false,
  errorMessage: "",
  data
});

export const authenticateUrlFailure = errorMessage => ({
  type: AUTHENTICATE_URL_FAILURE,
  isFetching: false,
  errorMessage
});

export const authenticateUrl = (baseUrl, params) => {
  return dispatch => {
    dispatch(authenticateUrlRequest());
    return apiGet({
      url: generateUrl(
        baseUrl,
        `/authenticate-url/${params.id}${(params.web_game && "?web_game=1") ||
          ""}`
      )
    })
      .then(response =>
        dispatch(authenticateUrlSuccess(get(response, "data", [])))
      )
      .catch(err => dispatch(authenticateUrlFailure(get(err, "message", []))));
  };
};

export const userLogout = () => ({
  type: USER_LOGGED_OUT
});

export const reasApiVersionRequest = () => ({
  type: READ_API_VERSION_REQUEST,
  isFetching: true
});

export const readApiVersionSuccess = data => ({
  type: READ_API_VERSION_SUCCESS,
  apiversiondata: data,
  isFetching: false
});

export const readApiVersionFailure = () => ({
  type: READ_API_VERSION_FAILURE,
  apiversiondata: {},
  isFetching: false
});

export const readApiVersion = (baseurl, deviceIDnew) => {
  return dispatch => {
    dispatch(reasApiVersionRequest());
    return apiGet({
      url: generateUrl(baseurl, `/manifests?deviceId=${deviceIDnew}`)
    })
      .then(response =>
        dispatch(readApiVersionSuccess(get(response, "data", [])))
      )
      .catch(err => dispatch(readApiVersionFailure(get(err, "message", []))));
  };
};

export const loadingScreenMessageRequest = () => ({
  type: LOADING_SCREEN_MESSAGE_REQUEST,
  isFetching: true
});

export const loadingScreenMessageSuccess = data => ({
  type: LOADING_SCREEN_MESSAGE_SUCCESS,
  messageList: data,
  isFetching: false
});

export const loadingScreenMessageFailure = () => ({
  type: LOADING_SCREEN_MESSAGE_FAILURE,
  messageList: {},
  isFetching: false
});

export const loadingScreenMessage = (baseurl) => {
  return dispatch => {
    dispatch(loadingScreenMessageRequest());
    return apiGet({
      url: generateUrl(baseurl, `/in-app-trial-message?_format=json`)
    })
      .then(response =>
        dispatch(loadingScreenMessageSuccess(get(response, "data", [])))
      )
      .catch(err => dispatch(loadingScreenMessageFailure(get(err, "message", []))));
  };
};
