import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import get from "lodash/get";
import c from "classnames";
import Cookies from "js-cookie";

import AssetsImage from "components/Global/AssetsImage";
import { selectSelectedChildId } from "selectors/children";
import { selectItemTypes } from "selectors/dashboard";
import Loader from "../Loader";
import Image from "../Global/Image";
import moment from "moment";

import styles from "./_RelatedContent.module.scss";
import { addToFavourites, removeToFavourites } from "actions/favourites";
import { getFavouriteList } from "actions/dashboard";
import { selectBaseUrl } from "selectors";

class RelatedTile extends Component {
  static propTypes = {
    dataElement: PropTypes.object,
    itemTypes: PropTypes.objectOf(PropTypes.string),
    selectedChildId: PropTypes.string,
    relatedDataFirstId: PropTypes.string,
    bumperScreenData: PropTypes.shape({
      next_text: PropTypes.string
    })
  };

  static defaultProps = {
    dataElement: {},
    itemTypes: {},
    selectedChildId: "",
    relatedDataFirstId: "",
    bumperScreenData: {
      next_text: ""
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      tileHeight: 0,
      imageStatus: "loading",
      isLoading:false
    };
  }

  componentDidMount() {
    let tiles = document.getElementsByClassName("related_tile_link")[0];
    if (tiles !== undefined && tiles !== null && tiles !== "") {
      this.setState({
        tileHeight: tiles.clientHeight
      });
      var self = this;
      window.addEventListener(
        "resize",
        function() {
          setTimeout(() => {
            let tiles = document.getElementsByClassName("related_tile_link")[0];
            if (tiles !== undefined && tiles !== null && tiles !== "") {
              self.setState({
                tileHeight: tiles.clientHeight
              });
            }
          }, 200);
        },
        false
      );
    }
  }

  getLinkUrl = () => {
    const { itemTypes, selectedChildId, dataElement = {} } = this.props;
    const { type, id } = dataElement;

    switch (type) {
      case itemTypes.videos:
        return `/${selectedChildId}/video/${id}`;
      case itemTypes.books:
        return `/${selectedChildId}/book/${id}`;
      case itemTypes.games:
        return `/${selectedChildId}/game/${id}`;
      default:
        return "";
    }
  };

  handleImageLoaded = () => {
    this.setState({ imageStatus: "loaded" });
  };

  handleImageErrored = () => {
    this.setState({ imageStatus: "failed to load" });
  };
  addToFavourites = (event, id, status) => {
    event.preventDefault();
    event.stopPropagation();
    const {
      selectedChildId,
      baseUrl,
      addToFavourites,
      removeToFavourites,
      getFavouriteList,
      updateTile
    } = this.props;
    const params = {
      rid: id,
      cid: selectedChildId
    };
    const listParams = {
      cid: selectedChildId
    };
    this.setState({
      isLoading: true
    });
    status && removeToFavourites(baseUrl, params);
    !status && addToFavourites(baseUrl, params);
    setTimeout(() => {
      selectedChildId && getFavouriteList(baseUrl, listParams);
      updateTile && updateTile(id, status);
      this.setState({
        isLoading: false
      });
    }, 3000);
  };
  
  render() {
    const {
      dataElement,
      relatedDataFirstId,
      onClose,
      seconds,
      bumperScreenData = {},
      pause,
      isFetchingDashboard,
      isId
    } = this.props;
    const { imageStatus,isLoading, tileHeight } = this.state;
    const subscriptionStatus = Cookies.get("subscriptionStatus");
    const { id } = dataElement;
    const { next_text: nextText } = bumperScreenData;

    const fontSizeTitle = (tileHeight * 15) / 230;
    const fontSizeText = (tileHeight * 12) / 230;

    const url = this.getLinkUrl();
    const content = (
      <div className={c(
        styles.tile,
        (get(dataElement, "category") !== "collection" && !get(dataElement, "offResponsiveBanner")) ? styles.show_border: ""
      )}>
        {relatedDataFirstId && relatedDataFirstId === id ? (
          <div className={styles.counter}>
            {nextText} <span>{seconds}</span>
          </div>
        ) : (
          ""
        )}
        {/*get(dataElement, "category") !== "collection" &&
          (dataElement.favorite === 1 ? (
            <Image
              folderName="ui_and_learning_area"
              fileName="favourites_thumbnail_icon"
              alt="Favourites Icon"
              className={styles.filled_heart}
              onClick={e => this.addToFavourites(e, dataElement.id, true)}
            />
          ) : (
            <Image
              folderName="ui_and_learning_area"
              fileName="favourites_icon_reversed"
              alt="Favourites Icon"
              className={styles.empty_heart}
              onClick={e => this.addToFavourites(e, dataElement.id, false)}
            />
          ))*/}
          {(isLoading || isFetchingDashboard) && isId === dataElement.id && (
          <Loader favClass="true" />
        )}
        {(relatedDataFirstId && relatedDataFirstId !== id) && (subscriptionStatus === "Cancelled" ||
          subscriptionStatus === "Free Promotion Expired" || subscriptionStatus === "Free") && !dataElement.isPublic && (
          <Loader favClass="lockicon" className="loader_lock" />
        )}
        {(moment().utc().diff(moment(parseInt(dataElement.resourceDate)).utc(), "days") < 21) && (
          <Image
            folderName="ui_and_learning_area"
            fileName="new_banner"
            alt="New"
            className={styles.new_banner}
          />
        )}
        <AssetsImage
          assetsImageName={get(dataElement, "thumbnails[0]", "")}
          className={styles.tile_thumbnail}
          onClick={this.goBack}
          onLoad={this.handleImageLoaded}
          onError={this.handleImageErrored}
        />
        {(get(dataElement, "category") !== "collection" && !get(dataElement, "offResponsiveBanner")) && (
          <div
            className={styles.tile_band}
            style={{ background: get(dataElement, "categoryColor") }}
          >
            <AssetsImage
              assetsImageName={get(dataElement, "categoryThumbnails[0]", "")}
              className={styles.category_image}
              categoryImage="true"
            />
            <div className={styles.tile_title}>
              {get(dataElement, "bannerData.header") !== "" && (
                <div className={c(
                    styles.tile_header,
                    get(dataElement, "bannerData.text") === "" ? styles.tile_header_double: ""
                  )}
                  style={{ fontSize: fontSizeTitle }}
                >{get(dataElement, "bannerData.header")}</div>
              )}
              {get(dataElement, "bannerData.text") !== "" && (
                <div className={styles.tile_text}
                  style={{ fontSize: fontSizeText }}
                >{get(dataElement, "bannerData.text")}</div>
              )}
            </div>
          </div>
        )}
        {imageStatus === "loading" && (
          <Loader favClass="true" className="image_loader" />
        )}
      </div>
    );

    return url ? (
      <React.Fragment>
        {pause ? (
          <Link to=""
            className="related_tile_link"
          >{content}</Link>
        ) : (
          <Link to={url} onClick={() => onClose()}
            className="related_tile_link"
          >
            {content}
          </Link>
        )}
      </React.Fragment>
    ) : (
      content
    );
  }
}


export const mapDispatchToProps = dispatch => ({
  addToFavourites(baseUrl, params) {
    dispatch(addToFavourites(baseUrl, params));
  },
  removeToFavourites(baseUrl, params) {
    dispatch(removeToFavourites(baseUrl, params));
  },
  getFavouriteList(baseUrl, params) {
    dispatch(getFavouriteList(baseUrl, params));
  }
});

export const mapStateToProps = state => ({
  itemTypes: selectItemTypes(state),
  selectedChildId: selectSelectedChildId(state),
  bumperScreenData: get(state, "appManifest.data.bumper_screen", {}),
  isFetchingDashboard: get(state, "dashboardList.isFetching"),
  isId: get(state, "favourites.isId"),
  baseUrl: selectBaseUrl(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
  )(RelatedTile);
