import {
  GET_ALL_CHILDREN_REQUEST,
  GET_ALL_CHILDREN_SUCCESS,
  GET_ALL_CHILDREN_FAILURE,
  ADD_CHILD_REQUEST,
  ADD_CHILD_SUCCESS,
  ADD_CHILD_FAILURE,
  EDIT_CHILD_REQUEST,
  EDIT_CHILD_SUCCESS,
  EDIT_CHILD_FAILURE,
  SET_SELECTED_CHILD,
  DELETE_CHILD_REQUEST,
  DELETE_CHILD_SUCCESS,
  DELETE_CHILD_FAILURE
} from "../constants/actions";
import get from "lodash/get";
import { platform } from "helper.js";
import { apiGet, apiGetExternal, apiPost, apiPatch, generateUrl } from "./core";

export const getChildrenRequest = () => ({
  type: GET_ALL_CHILDREN_REQUEST,
  errorMessage: "",
  getChildrenErrorMessage: "",
  isFetching: true
});

export const getChildrenSuccess = data => ({
  type: GET_ALL_CHILDREN_SUCCESS,
  data,
  errorMessage: "",
  getChildrenErrorMessage: "",
  isFetching: false
});

export const getChildrenFailure = errorMessage => ({
  type: GET_ALL_CHILDREN_FAILURE,
  data: {},
  errorMessage,
  getChildrenErrorMessage: errorMessage,
  isFetching: false
});

export const getChildren = (baseUrl, userId) => dispatch => {
  dispatch(getChildrenRequest());
  return apiGet({
    url: generateUrl(baseUrl, `/users/${userId}/children?platform=${platform}`)
  })
    .then(response => dispatch(getChildrenSuccess(get(response, "data", []))))
    .catch(err => dispatch(getChildrenFailure(get(err, "message", []))));
};

export const addChildRequest = () => ({
  type: ADD_CHILD_REQUEST,
  isFetching: true,
  errorMessage: ""
});

export const addChildSuccess = data => ({
  type: ADD_CHILD_SUCCESS,
  isFetching: false,
  errorMessage: "",
  data
});

export const addChildFailure = errorMessage => ({
  type: ADD_CHILD_FAILURE,
  isFetching: false,
  errorMessage
});

export const addChild = (baseUrl, userId, params) => {
  return dispatch => {
    dispatch(addChildRequest());
    return apiPost({
      url: generateUrl(baseUrl, `/users/${userId}/children`),
      params
    })
      .then(response => dispatch(addChildSuccess(get(response, "data", []))))
      .catch(err => dispatch(addChildFailure(get(err, "message", []))));
  };
};

export const editChildRequest = () => ({
  type: EDIT_CHILD_REQUEST,
  isFetching: true,
  errorMessage: ""
});

export const editChildSuccess = (data, childId) => ({
  type: EDIT_CHILD_SUCCESS,
  isFetching: false,
  errorMessage: "",
  data,
  childId
});

export const editChildFailure = errorMessage => ({
  type: EDIT_CHILD_FAILURE,
  isFetching: false,
  errorMessage
});

export const editChild = (baseUrl, userId, childId, params) => {
  return dispatch => {
    dispatch(editChildRequest());
    return apiPatch({
      url: generateUrl(baseUrl, `/users/${userId}/children/${childId}`),
      params
    })
      .then(response =>
        dispatch(editChildSuccess(get(response, "data", []), childId))
      )
      .catch(err => dispatch(editChildFailure(get(err, "message", []))));
  };
};

export const setSelectedChildId = id => ({
  type: SET_SELECTED_CHILD,
  data: id
});

export const deleteChildRequest = () => ({
  type: DELETE_CHILD_REQUEST,
  isFetching: true,
  errorMessage: ""
});

export const deleteChildSuccess = (data, childId) => ({
  type: DELETE_CHILD_SUCCESS,
  isFetching: false,
  errorMessage: "",
  data,
  childId
});

export const deleteChildFailure = errorMessage => ({
  type: DELETE_CHILD_FAILURE,
  isFetching: false,
  errorMessage
});

export const deleteChild = (baseUrl, userId, childId) => {
  return dispatch => {
    dispatch(deleteChildRequest());
    return apiPost({
      url: generateUrl(baseUrl, `/users/${userId}/children/${childId}`)
    })
      .then(response =>
        dispatch(deleteChildSuccess(get(response, "data", []), childId))
      )
      .catch(err => dispatch(deleteChildFailure(get(err, "message", []))));
  };
};

export const getCountryList = url => {
  return apiGetExternal({ url })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.log(err.message);
    });
};
