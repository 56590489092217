import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import get from "lodash/get";
import Loader from "../Loader";
import AssetsImage from "components/Global/AssetsImage";
import { selectItemTypes, selectCountryCode } from "selectors/dashboard";
import { selectSelectedChildId } from "selectors/children";
import { selectUser } from "selectors/profile";
import { selectBaseUrl, selectAssetsImagePath } from "selectors";
import Image from "../Global/Image";
import { addToFavourites, removeToFavourites } from "actions/favourites";
import { getFavouriteList } from "actions/dashboard";
import { webAppEvent } from "actions/common";
import moment from "moment";

import styles from "./_Dashboard.module.scss";
import { deviceInfo, titleEvent, collectionEvent } from "../../helper.js";
import c from "classnames";

import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";
import { isBedTime } from "utils";

class Tile extends Component {
  static propTypes = {
    dataElement: PropTypes.object,
    itemTypes: PropTypes.objectOf(PropTypes.string),
    selectedChildId: PropTypes.string,
    addToFavourites: PropTypes.func,
    removeToFavourites: PropTypes.func,
    getFavouriteList: PropTypes.func
  };

  static defaultProps = {
    dataElement: {},
    itemTypes: {},
    selectedChildId: "",
    addToFavourites() {},
    removeToFavourites() {},
    getFavouriteList() {}
  };

  constructor(props) {
    super(props);
    this.state = {
      tileHeight: 0,
      tileMarginRight: 0,
      isLoading: false,
      imageStatus: "loading"
    };
  }

  componentDidMount() {
    let tiles = document.getElementsByClassName("tile_link")[0];
    if (tiles !== undefined && tiles !== null && tiles !== "") {
      this.setState({
        tileHeight: tiles.clientHeight
      });
      var self = this;
      window.addEventListener(
        "resize",
        function() {
          setTimeout(() => {
            let tiles = document.getElementsByClassName("tile_link")[0];
            if (tiles !== undefined && tiles !== null && tiles !== "") {
              self.setState({
                tileHeight: tiles.clientHeight
              });
              self.handleLoad();
            }
          }, 200);
        },
        false
      );
    }
  }
  componentDidUpdate() {
    var self = this;
    if ("onorientationchange" in window) {
      window.addEventListener(
        "orientationchange",
        function() {
          setTimeout(() => {
            let tiles = document.getElementsByClassName("tile_link")[0];
            if (tiles !== undefined && tiles !== null && tiles !== "") {
              self.setState({
                tileHeight: tiles.clientHeight
              });
            }
          }, 200);
        },
        false
      );
    }
  }

  handleEvent = (dataElement, eventType) => {
    const { profile, itemTypes, countryCode } = this.props;
    const { type } = dataElement;
    let titletype = null;
    switch (type) {
      case itemTypes.videos:
        titletype = `video`;
        break;
      case itemTypes.books:
        titletype = `book`;
        break;
      case itemTypes.games:
        titletype = `game`;
        break;
      default:
        titletype = `collection`;
    }
    let eventData;
    if (eventType === "collectionEvent") {
      eventData = collectionEvent(profile, dataElement, titletype, countryCode);
    }
    else {
      eventData = titleEvent(profile, dataElement, titletype, countryCode);
    }
    return {
      ...deviceInfo,
      ...eventData
    };
  };

  handleTitleEvent = (event, dataElement) => {
    let data = this.handleEvent(dataElement, 'collectionEvent');
    const { itemTypes, webAppEvent, kafkaUrl } = this.props;
    const { type } = dataElement;
    if (
      type === itemTypes.videos ||
      type === itemTypes.books ||
      type === itemTypes.games
    ) {
      
    } else {
      let params = [
        {
          event_name: "collection_tapped",
          ...data
        }
      ];
      webAppEvent(kafkaUrl, params);
    }
  };

  handleFavoriteEvent = () => {
    const { webAppEvent, kafkaUrl, dataElement } = this.props;
    let data = this.handleEvent(dataElement, 'titleEvent');
    let params = [
      {
        event_name: "favorite",
        ...data
      }
    ];
    webAppEvent(kafkaUrl, params);
  };

  handleUnfavoriteEvent = () => {
    const { webAppEvent, kafkaUrl, dataElement } = this.props;
    let data = this.handleEvent(dataElement, 'titleEvent');
    let params = [
      {
        event_name: "unfavorite",
        ...data
      }
    ];
    webAppEvent(kafkaUrl, params);
  };

  getLinkUrl = () => {
    const { itemTypes, selectedChildId, dataElement = {} } = this.props;
    const { type, id } = dataElement;
    switch (type) {
      case itemTypes.videos:
        return `/${selectedChildId}/video/${id}`;
      case itemTypes.books:
        return `/${selectedChildId}/book/${id}`;
      case itemTypes.games:
        return `/${selectedChildId}/game/${id}`;
      default:
        return `/${selectedChildId}/collection/${id}`;
    }
  };

  addToFavourites = (event, id, status) => {
    event.preventDefault();
    const {
      selectedChildId,
      baseUrl,
      addToFavourites,
      removeToFavourites,
      getFavouriteList,
      favoriteErrorMessage,
      updateTile
    } = this.props;
    const params = {
      rid: id,
      cid: selectedChildId
    };
    const listParams = {
      cid: selectedChildId
    };
    this.setState({
      isLoading: true
    });
    status && removeToFavourites(baseUrl, params);
    status && this.handleUnfavoriteEvent();
    !status && addToFavourites(baseUrl, params);
    !status && this.handleFavoriteEvent();
    setTimeout(() => {
      selectedChildId && getFavouriteList(baseUrl, listParams);
      updateTile && updateTile(id, status);
      this.setState({
        isLoading: false
      });
      if (
        favoriteErrorMessage !== "" &&
        favoriteErrorMessage !== null &&
        favoriteErrorMessage !== undefined
      ) {
        toaster.notify("Something went wrong", {
          duration: 2000,
          position: "top"
        });
      }
    }, 1000);
  };

  handleLoad = () => {
    let tiles = document.getElementsByClassName("tile_link")[0];
    this.setState({
      tileMarginRight: window.getComputedStyle(tiles).marginRight
    });
  };

  handleImageLoaded = () => {
    this.setState({ imageStatus: "loaded" });
  };

  handleImageErrored = () => {
    this.setState({ imageStatus: "failed to load" });
  };

  render() {
    const {
      dataElement,
      isId,
      isFetchingDashboard,
      onClickLocked,
      className,
      noTileLink,
      nofav,
      pageName,
      assetsImagePath
    } = this.props;
    const { tileHeight, tileMarginRight, isLoading, imageStatus } = this.state;
    const subscriptionStatus = Cookies.get("subscriptionStatus");
    let url = "";
    if (!noTileLink) {
      url = this.getLinkUrl();
    }
    const tileWidth = tileHeight + parseInt(tileMarginRight);
    const fontSizeTitle = (tileWidth * 15) / 230;
    const fontSizeText = (tileWidth * 12) / 230;
    const content = (
      <div className={c(
        styles.tile,
        (get(dataElement, "category") !== "collection" && !get(dataElement, "offResponsiveBanner")) ? styles.show_border: ""
      )}>
        {get(dataElement, "category") !== "collection" && !nofav &&
          (dataElement.favorite === 1 ? (
            <Image
              folderName="ui_and_learning_area"
              fileName="favourites_thumbnail_icon"
              alt="Favourites Icon"
              className={styles.filled_heart}
              onClick={e => this.addToFavourites(e, dataElement.id, true)}
            />
          ) : (
            <Image
              folderName="ui_and_learning_area"
              fileName="favourites_icon_reversed"
              alt="Favourites Icon"
              className={styles.empty_heart}
              onClick={e => this.addToFavourites(e, dataElement.id, false)}
            />
          ))}
        {(isLoading || isFetchingDashboard) && isId === dataElement.id && (
          <Loader favClass="true" />
        )}

        {(subscriptionStatus === "Cancelled" ||
          subscriptionStatus === "Free Promotion Expired" || subscriptionStatus === "Free") && !nofav && !dataElement.isPublic && (
          <Loader favClass="lockicon" className="loader_lock" />
        )}
        {(moment().utc().diff(moment(parseInt(dataElement.resourceDate)).utc(), "days") < 21) && (
          <Image
            folderName="ui_and_learning_area"
            fileName="new_banner"
            alt="New"
            className={styles.new_banner}
          />
        )}
        <AssetsImage
          assetsImageName={get(dataElement, "thumbnails[0]", "")}
          className={c(
            styles.tile_thumbnail,
            "tile_thumbnail"
          )}
          onLoad={this.handleImageLoaded}
          onError={this.handleImageErrored}
        />
        {((isBedTime() && pageName === "PAGE_HOME") || pageName === "PAGE_BEDTIME") ? (
          <div className={styles.image_bedtime_overlay}
          style={(get(dataElement, "category") === "collection" || get(dataElement, "offResponsiveBanner")) ? {
            WebkitMaskBoxImage: `url(${assetsImagePath}${get(dataElement, "thumbnails[0]", "")})`,
            maskBoxImage: `url(${assetsImagePath}${get(dataElement, "thumbnails[0]", "")})`
          } : {} }
          ></div>
        ) : ""}
        {(get(dataElement, "category") !== "collection" && !get(dataElement, "offResponsiveBanner")) && (
          <div
            className={styles.tile_band}
            style={{ background: get(dataElement, "categoryColor") }}
          >
            <AssetsImage
              assetsImageName={get(dataElement, "categoryThumbnails[0]", "")}
              className={styles.category_image}
              categoryImage="true"
            />
            <div className={styles.tile_title}>
              {get(dataElement, "bannerData.header") !== "" && (
                <div className={c(
                    styles.tile_header,
                    get(dataElement, "bannerData.text") === "" ? styles.tile_header_double: ""
                  )}
                  style={{ fontSize: fontSizeTitle }}
                >{get(dataElement, "bannerData.header")}</div>
              )}
              {get(dataElement, "bannerData.text") !== "" && (
                <div className={styles.tile_text}
                  style={{ fontSize: fontSizeText }}
                >{get(dataElement, "bannerData.text")}</div>
              )}
            </div>
          </div>
        )}
        {imageStatus === "loading" && (
          <Loader favClass="true" className="image_loader" />
        )}
      </div>
    );

    return url &&
      ((subscriptionStatus !== "Cancelled" &&
      subscriptionStatus !== "Free Promotion Expired" &&
      subscriptionStatus !== "Free") || dataElement.isPublic) &&
      !((isLoading || isFetchingDashboard) && isId === dataElement.id) ? (
      <Link
        to={url}
        onClick={e => this.handleTitleEvent(e, dataElement)}
        className={c(styles.tile_link, "tile_link", styles[className])}
        onLoad={this.handleLoad}
        style={{ width: tileWidth }}
      >
        {content}
      </Link>
    ) : (
      <React.Fragment>
        {(subscriptionStatus === "Cancelled" ||
        subscriptionStatus === "Free Promotion Expired" ||
        subscriptionStatus === "Free") && !dataElement.isPublic ? (
          <React.Fragment>
            <div
              className={c(styles.tile_link, "tile_link", styles[className])}
              style={{ width: tileWidth }}
              onClick={e => {
                e.stopPropagation();
                onClickLocked(dataElement);
              }}
            >
              {content}
            </div>
          </React.Fragment>
        ) : (
          <div
            className={c(styles.tile_link, "tile_link", styles[className])}
            style={{ width: tileWidth }}
          >
            {content}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = state => ({
  itemTypes: selectItemTypes(state),
  selectedChildId: selectSelectedChildId(state),
  baseUrl: selectBaseUrl(state),
  assetsImagePath: selectAssetsImagePath(state),
  kafkaUrl: get(state, "appManifest.data.app_settings.kafka_url"),
  profile: selectUser(state),
  isFetchingDashboard: get(state, "dashboardList.isFetching"),
  isId: get(state, "favourites.isId"),
  favoriteErrorMessage: get(state, "favourites.errorMessage"),
  countryCode: selectCountryCode(state)
});

export const mapDispatchToProps = dispatch => ({
  addToFavourites(baseUrl, params) {
    dispatch(addToFavourites(baseUrl, params));
  },
  removeToFavourites(baseUrl, params) {
    dispatch(removeToFavourites(baseUrl, params));
  },
  getFavouriteList(baseUrl, params) {
    dispatch(getFavouriteList(baseUrl, params));
  },
  webAppEvent(url, params) {
    dispatch(webAppEvent(url, params));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tile);

export { Tile as TileComponent };
