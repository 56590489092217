import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import c from "classnames";
import styles from "./_Book.module.scss";
import { selectPageAudioDetails } from "selectors/epub";
import Image from "../Global/Image";
import { removePrecedingHash, highlightWord } from "utils/epub";
import { Swipeable } from 'react-swipeable';
import Cookies from "js-cookie";
import { selectIsCleverResource } from "selectors/profile";

class BookHtml extends React.Component {
  static propTypes = {
    pagesDetails: PropTypes.object,
    currentPage: PropTypes.number,
    bookId: PropTypes.string,
    pageAudio: PropTypes.object
  };

  static defaultProps = {
    pagesDetails: {},
    currentPage: "",
    bookId: "",
    pageAudio: {}
  };

  state = {
    audioCounter: 0,
    audioDetails: [],
    audioPlayingOfPage: "",
    showSpinner: true,
    autoplay: true,
    bumperScreenAutoPlaySet: 0,
    bookEndCalled: false
  };

  UNSAFE_componentWillMount() {
    const { autoplay, bookEndCalled } = this.state;
    const { scalingLoaded, bookEnd, noOfPages, currentPage, pagesToDisplay } = this.props;
    if (!autoplay) {
      if (noOfPages === currentPage + pagesToDisplay && !bookEndCalled) {
        bookEnd();
        this.setState({
          bookEndCalled: true
        });
      }
    }
    if (scalingLoaded) {
      this.setState({
        showSpinner: false
      });
      if (autoplay) {
        setTimeout(() => {
          this.selectCurrentPageAudio();
        }, 200);
      }
    }
  }

  componentDidMount() {
    this.updateIframeSrc();
  }

  componentDidUpdate(prevProps) {
    const { pageAudio, scalingLoaded, bookEnd, noOfPages, currentPage, pagesToDisplay, pagesDetails, bumperScreenClosed } = this.props;
    const { bumperScreenAutoPlaySet, bookEndCalled } = this.state;
    const { autoplay } = this.state;
    if (bumperScreenClosed && !bumperScreenAutoPlaySet) {
      this.setState({
        autoplay: true,
        bumperScreenAutoPlaySet: 1
      });
      let audio = document.getElementById("epubAudio");
      audio && audio.play();
    }
    if (pageAudio !== prevProps.pageAudio) {
      let audio = document.getElementById("epubAudio");
      if (audio) {
        audio.pause();
      }
      this.setState({
        showSpinner: true
      });
    }
    if (scalingLoaded !== prevProps.scalingLoaded && scalingLoaded) {
      this.setState({
        showSpinner: false
      });
      if (autoplay) {
        setTimeout(() => {
          this.selectCurrentPageAudio();
        }, 200);
      }
      else {
        if (noOfPages === currentPage + pagesToDisplay && !bookEndCalled) {
          bookEnd();
          this.setState({
            bookEndCalled: true
          });
        }
      }
    }
    if (pagesDetails !== prevProps.pagesDetails) {
      this.updateIframeSrc();
    }
  }

  updateIframeSrc = () => {
    const { pagesDetails } = this.props;
    if (pagesDetails.page1 && pagesDetails.page1.src) {
      let leftFrame = document.getElementsByClassName("left_frame")[0];
      if (leftFrame) {
        leftFrame.contentWindow.location.replace(pagesDetails.page1.src);
      }
    }
    if (pagesDetails.page2 && pagesDetails.page2.src) {
      let rightFrame = document.getElementsByClassName("right_frame")[0];
      if (rightFrame) {
        rightFrame.contentWindow.location.replace(pagesDetails.page2.src);
      }
    }
  }

  selectCurrentPageAudio = () => {
    const { pageAudio } = this.props;
    const { showSpinner } = this.state;
    if (pageAudio) {
      if (pageAudio.page1) {
        this.setState({
          audioDetails: pageAudio.page1,
          audioPlayingOfPage: 1,
          audioCounter: 0
        });
      }
      else if (pageAudio.page2) {
        this.setState({
          audioDetails: pageAudio.page2,
          audioPlayingOfPage: 2,
          audioCounter: 0
        });
      }
      else {
        this.setState({
          audioDetails: [],
          audioPlayingOfPage: "",
          audioCounter: 0,
          autoplay: false
        });
      }
      if ((pageAudio.page1 || pageAudio.page2) && !showSpinner) {
        this.playAudio();
      }
    }
  }

  setSecondPageAudioAfterFirst = () => {
    const { pageAudio } = this.props;
    if (pageAudio && pageAudio.page2) {
      this.setState({
        audioDetails: pageAudio.page2,
        audioPlayingOfPage: 2,
        audioCounter: 0
      });
      setTimeout(() => {
        this.playAudio();
      }, 200);
    }
  }

  playAudio = () => {
    const { isCleverResource } = this.props;
    const { audioDetails, audioCounter } = this.state;
    this.setState({
      autoplay: true
    });
    let audio = document.getElementById("epubAudio");
    if (audio) {
      if (audioDetails[audioCounter] && audioDetails[audioCounter].audioSrc && audioDetails[audioCounter].audioStartTime) {
        audio.src = audioDetails[audioCounter].audioSrc;
        audio.currentTime = audioDetails[audioCounter].audioStartTime;
        if (audioDetails[audioCounter].wordToHighlight) {
          this.highlightWordWithAudio(audioDetails[audioCounter].wordToHighlight);
        }
      }
      if (!isCleverResource || (isCleverResource && !Cookies.get("bumperScreenDisplay"))) {
        var playPromise = audio.play();
        if (playPromise !== undefined) {
          playPromise.then(_ => {
            // Automatic playback started!
            // Show playing UI.
          })
          .catch(error => {
            if (error.code === 0) {
              this.setState({
                autoplay: false
              });
            }
          });
        }
      }
      else {
        this.setState({
          autoplay: false
        });
      }
    }
  }

  highlightWordWithAudio = (wordToHighlight, prevHighlightedWord) => {
    const { audioPlayingOfPage } = this.state;
    let wordId, prevWordId;
    if (wordToHighlight) {
      wordId = removePrecedingHash(wordToHighlight);
    }
    if (prevHighlightedWord) {
      prevWordId = removePrecedingHash(prevHighlightedWord);
    }
    let currentFrameClass;
    if (audioPlayingOfPage && audioPlayingOfPage === 1) {
      currentFrameClass = "left_frame";
    }
    else if (audioPlayingOfPage && audioPlayingOfPage === 2) {
      currentFrameClass = "right_frame";
    }
    if (currentFrameClass) {
      let getcurrentFrame = document.getElementsByClassName(currentFrameClass)['0'];
      if (getcurrentFrame && getcurrentFrame.contentDocument) {
        highlightWord(getcurrentFrame.contentDocument, wordId, prevWordId);
      }
    }
  }

  timeupdate = () => {
    const { audioCounter, audioDetails, audioPlayingOfPage, showSpinner } = this.state;
    const { bookEnd, noOfPages, currentPage, pagesToDisplay } = this.props;
    if (!showSpinner) {
      let audio = document.getElementById("epubAudio");
      if (audioDetails[audioCounter] && audioDetails[audioCounter].audioEndTime && (audio.currentTime >= audioDetails[audioCounter].audioEndTime || audio.currentTime === audio.duration)) {
        if (audioDetails[audioCounter + 1]) {
          if (audioDetails[audioCounter].audioSrc === audioDetails[audioCounter + 1].audioSrc) {
            if (audioDetails[audioCounter + 1].wordToHighlight) {
              this.highlightWordWithAudio(audioDetails[audioCounter + 1].wordToHighlight, audioDetails[audioCounter].wordToHighlight);
            }
          }
          else {
            audio.pause();
          }
          this.setState({
            audioCounter: audioCounter + 1
          });
        }
        else if (audioDetails.length === audioCounter + 1 && audioPlayingOfPage === 1) {
          // End of First page
          audio.pause();
          if (audioDetails[audioCounter].wordToHighlight) {
            this.highlightWordWithAudio("", audioDetails[audioCounter].wordToHighlight);
          }
          if (noOfPages === currentPage + pagesToDisplay) {
            bookEnd();
          }
          else {
            this.setSecondPageAudioAfterFirst();
          }
        }
        else {
          // End of Second page
          audio.pause();
          if (audioDetails[audioCounter].wordToHighlight) {
            this.highlightWordWithAudio("", audioDetails[audioCounter].wordToHighlight);
          }
          if (noOfPages === currentPage + pagesToDisplay) {
            bookEnd();
          }
        }
      }
      /*else {
        audio.pause();
        this.setSecondPageAudioAfterFirst();
      }*/
    }
  }

  readItMyself = () => {
    const { audioDetails } = this.state;
    this.setState({
      autoplay: false
    });
    let audio = document.getElementById("epubAudio");
    if (audio) {
      audio.pause();
      audio.currentTime = audioDetails['0'].audioStartTime;
    }
  };

  showSpinner = () => {
    const { showSpinner } = this.state;
    if (showSpinner) {
      return (
        <div className={styles.book_page_spinner}>
          <Image
            folderName="ui_and_learning_area"
            fileName="spinner"
            alt="Loading"
          />
        </div>
      );
    }
  }

  swipedPrev = () => {
    const { prevClicked } = this.props;
    prevClicked();
  }

  swipedNext = () => {
    const { nextClicked } = this.props;
    nextClicked();
  }

  render() {
    const { pagesDetails, iFrameLoaded } = this.props;
    const { audioCounter, audioDetails, autoplay } = this.state;
    return (
      <React.Fragment>
        {(audioDetails[audioCounter] && audioDetails[audioCounter].audioSrc) && (
          <audio controls
            className={styles.audio} id="epubAudio"
            // onLoadedData={this.playAudio}
            onTimeUpdate={this.timeupdate}
          >
            <source src={audioDetails[audioCounter].audioSrc} type="audio/mpeg"/>
            Your browser does not support the audio element.
          </audio>
        )}
        <div className={styles.epub}>
          <div className={styles.left_page}>
            {pagesDetails.page1 ? (
              <React.Fragment>
                { this.showSpinner() }
                <Swipeable onSwipedRight={this.swipedPrev} trackMouse={true}>
                  <div className={styles.overlay}></div>
                </Swipeable>
                <iframe src="" className={c(
                    "left_frame",
                    styles.left_frame
                  )} width="100%" height="100%"
                  onLoad={iFrameLoaded}
                  title="left_frame"
                ></iframe>
              </React.Fragment>
            ) : (
              <div className={styles.read_icon_wrapper}>
                <div 
                  className={c(
                    styles.read_icon,
                    !autoplay && styles.read_icon_selected
                  )}
                  onClick={this.readItMyself}
                >
                  <Image
                    folderName="ui_and_learning_area"
                    fileName="read_myself"
                    alt="Read it myself"
                    className={styles.read_icon_img}
                  />
                  <span className={styles.read_icon_text}>Read it myself</span>
                  {!autoplay &&
                    <Image
                      folderName="ui_and_learning_area"
                      fileName="check"
                      alt="checked"
                      className={styles.read_icon_tick}
                    />
                  }
                </div>
                {audioDetails[audioCounter] && 
                  <div
                    className={c(
                      styles.read_icon,
                      autoplay && styles.read_icon_selected
                    )}
                    onClick={this.playAudio}
                  >
                    <Image
                      folderName="ui_and_learning_area"
                      fileName="audio"
                      alt="Read to me"
                      className={styles.read_icon_img}
                    />
                    <span className={styles.read_icon_text}>Read to me</span>
                    {autoplay &&
                      <Image
                        folderName="ui_and_learning_area"
                        fileName="check"
                        alt="checked"
                        className={styles.read_icon_tick}
                      />
                    }
                  </div>
                }
              </div>
            )}
          </div>
          <div className={styles.right_page}>
            {pagesDetails.page2 ? (
              <React.Fragment>
                { this.showSpinner() }
                <Swipeable onSwipedLeft={this.swipedNext} trackMouse={true}>
                  <div className={styles.overlay}></div>
                </Swipeable>
                <iframe src="" className={c(
                    "right_frame",
                    styles.right_frame
                  )} width="100%" height="100%"
                  onLoad={iFrameLoaded}
                  title="right_frame"
                ></iframe>
              </React.Fragment>
            ) : ( "" )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state, props) => {
  return {
    pageAudio: selectPageAudioDetails(state, props),
    isCleverResource: selectIsCleverResource(state)
  };
};

export default connect(
  mapStateToProps,
  null
)(BookHtml);
