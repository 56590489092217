import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import get from "lodash/get";
import brightcovePlayerLoader from "@brightcove/player-loader";
import Cookies from "js-cookie";
import { PATHS } from "constants/url";

import { launchDialog } from "actions/settings";
import { selectEntity, selectVideoPageData, selectEntityExist, selectItemTypes, selectCountryCode } from "selectors/dashboard";
import { selectIsApiRequestSyncing, selectBaseUrl } from "selectors";
import { selectSelectedChildId } from "selectors/children";

import fetchManifestData from "../FetchManifestData";
import styles from "./_Videos.module.scss";
import Loading from "../Loading";
import { DIALOG_IDS, ENTITY_TYPES, RESOURCE_NOT_FOUND_ERROR } from "../../constants";
import FullScreenModalTemplate from "../Templates/FullScreenModalTemplate";
import { webAppEvent, resourceProgressions } from "actions/common";
import { titleCompleted } from "../../helper.js";
import { selectUser, selectIsCleverResource } from "selectors/profile";
import Error from "../Error";
import { setScreenOrientation, cookieParams } from "utils";
import { PAGE_MODE } from "constants/screens";
import LockedPopup from "components/Dashboard/LockedPopup";

let myPlayer = null;
let videomanualclick = false;
let apiErrorMessageText = "";
class Video extends Component {
  static propTypes = {
    videoPageData: PropTypes.object,
    video: PropTypes.object,
    isApiRequestSyncing: PropTypes.bool,
    launchBumperScreen: PropTypes.func,
    history: PropTypes.shape({
      push: PropTypes.func
    }),
    selectedChildId: PropTypes.string,
    baseUrl: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      showLockedImage:false,
      timeOnTask: 0
    };
    this.timer = 0;
  }

  static defaultProps = {
    videoPageData: {},
    video: {},
    isApiRequestSyncing: false,
    launchBumperScreen() {},
    history: {
      push() {}
    },
    selectedChildId: "",
    baseUrl: "",
    videoLoaded: false
  };

  componentDidMount() {
    const { video } = this.props;
    const subscriptionStatus = Cookies.get("subscriptionStatus");
    Cookies.remove("bumperScreenDisplay");
    if (Object.keys(video).length !== 0) {
      if((subscriptionStatus === "Cancelled" || subscriptionStatus === "Free Promotion Expired" || subscriptionStatus === "Free") && !video.isPublic) {
        this.setState({showLockedImage:true})
      }
    }
  }

  handleTitleComplete = () => {
    const { webAppEvent, profile, kafkaUrl, video, countryCode } = this.props;
    const { timeOnTask } = this.state;
    clearInterval(this.timer);
    let params = [
      {
        ...titleCompleted(profile, video, timeOnTask, 'video', countryCode)
      }
    ];
    webAppEvent(kafkaUrl, params);
  };

  componentDidUpdate(prevProps) {
    const { video, videoPageData, videoNotExist, history, itemTypes, selectedChildId, isCleverResource, videoId } = this.props;
    const { showLockedImage, videoLoaded } = this.state;
    const subscriptionStatus = Cookies.get("subscriptionStatus");
    if (isCleverResource && !Cookies.get("bumperScreenDisplay")) {
      (myPlayer && !myPlayer.hasStarted_) && myPlayer.play();
    }
    if (video !== prevProps.video) {
      if((subscriptionStatus === "Cancelled" || subscriptionStatus === "Free Promotion Expired" || subscriptionStatus === "Free") && !video.isPublic) {
        this.setState({showLockedImage:true})
      }
    }
    if (!showLockedImage) {
      if ((!isCleverResource && videoNotExist) || (isCleverResource && isCleverResource.type !== "collection" && isCleverResource.id !== videoId) || (isCleverResource && isCleverResource.type === "collection" && videoNotExist) || (!isCleverResource && video !== prevProps.video && video.type !== itemTypes.videos)) {
        Cookies.set("resourceNotFound", 1, cookieParams);
        history.push(`/${selectedChildId}/dashboard`);
      }
      else {
        if (!videoLoaded && video.providerResourceIdentifier && videoNotExist != null && !videoNotExist) {
          this.setState({videoLoaded:true})
          this.loadVideo(video, videoPageData);
        }
        document.addEventListener("visibilitychange", onchange);
        onchange = () => {
          if (window.location.pathname.includes("/video/")) {
              if (document.visibilityState === "visible" && !videomanualclick) {
                if (isCleverResource && !Cookies.get("bumperScreenDisplay")) {
                  myPlayer && myPlayer.play();
                }
              } else {
                myPlayer && myPlayer.pause();
              }
            }
        };
        window.addEventListener("focus", function() {
          if (!window.location.pathname.includes("/video/")) {
            myPlayer && myPlayer.pause();
          }
        });
        this.handleVisibilityPage();
      }
    }
  }

  handleVisibilityPage = () => {
    const { isCleverResource } = this.props;
    let inView = false;
    window.onfocus = window.onblur = window.onpageshow = window.onpagehide = e => {
      if (window.location.pathname.includes("/video/")) {
        if ({ focus: 1, pageshow: 1 }[e.type]) {
          if (!inView) {
            inView = true;
            if (videomanualclick === false) {
              if (isCleverResource && !Cookies.get("bumperScreenDisplay")) {
                this.playVideo();
              }
            } else {
              myPlayer && myPlayer.pause();
            }
          }
        } else if (inView) {
          inView = false;
          this.pauseVideo();
        }
      }
    };
  };

  playVideo = () => {
    const playedPromise = myPlayer && myPlayer.play();
    if (playedPromise) {
      playedPromise.catch(e => {
        if (e.name === "NotAllowedError" || e.name === "NotSupportedError") {
          // Video playing failed!
        }
      });
    }
  };

  pauseVideo = () => {
    const playedPromise = myPlayer && myPlayer.pause();
    if (playedPromise) {
      playedPromise.catch(e => {
        if (e.name === "NotAllowedError" || e.name === "NotSupportedError") {
          // Video pause failed!
        }
      });
    }
  };

  countDown = () => {
    const { timeOnTask } = this.state;
    this.setState({
      timeOnTask: timeOnTask + 1
    });
  }

  loadVideo = (video, videoPageData) => {
    const { isCleverResource } = this.props;
    const that = this;
    let play = false;
    brightcovePlayerLoader({
      refNode: "#player-container",
      accountId: get(videoPageData, "brightcove_account_id", ""),
      playerId: get(videoPageData, "brightcove_player_id", ""),
      videoId: `ref:${get(video, "providerResourceIdentifier", "")}`,
      embedOptions: {
        responsive: "true"
      },
      options: {
        controlBar: {
          FullscreenToggle: true
        }
      }
    })
      .then(function(success) {
        that.timer = setInterval(that.countDown, 1000);
        myPlayer = success.ref;
        document.getElementsByClassName('vjs-fullscreen-control')[0].addEventListener("click", function() {
          myPlayer.controlBar.FullscreenToggle.blur();
          myPlayer.focus();
        });
        myPlayer.on("progress", function() {
          if (document.hasFocus()) {
            if (!play) {
              if (!isCleverResource || (isCleverResource && !Cookies.get("bumperScreenDisplay"))) {
                myPlayer && myPlayer.play();
              }
              play = true;
            }
          } else {
            if (play) {
              myPlayer && myPlayer.pause();
              play = false;
            }
          }
        });
        myPlayer.title.eventBusEl_.innerText = get(video, "title", "");
        myPlayer.contextmenuUI = function() {
          return false;
        };
        myPlayer.on("ended", function() {
          that.handleTitleComplete();
          that.onVideoEnd(success.ref.poster_);
        });
        myPlayer.errors({
          errors: {
            dismiss: false
          }
        });
      })
      .catch(function(error) {
        // Player creation failed!
      });
  };

  goToPage = () => {
    const {
      history,
      selectedChildId
    } = this.props;
    if (selectedChildId) {
      setScreenOrientation(PAGE_MODE.DASHBOARD);
      if (Cookies.get("collectionPath")) {
        history.push(Cookies.get("collectionPath"));
      }
      else {
        if(Cookies.get("prevPageforPolicy")==="Favourite"){
          history.push(`/${selectedChildId}/favourites`); 
        }
        else if(Cookies.get("prevPageforPolicy")==="Search"){
          history.push(`/${selectedChildId}/Search`);
        }
        else if(Cookies.get("prevPageforPolicy")==="Category"){
          history.push(Cookies.get("categoryPath"));
        }
        else{
          history.push(`/${selectedChildId}/dashboard`);
        }
      }
    }
  };

  onVideoEnd = videoThumbnail => {
    const { launchBumperScreen, video = {}, isCleverResource } = this.props;
    const { id } = video;
    if (id) {
      this.goToPage();
      Cookies.set("showAnimation", "FALSE", cookieParams);
      isCleverResource && Cookies.set("bumperScreenDisplay", 1, cookieParams);
      launchBumperScreen({
        id: DIALOG_IDS.BUMPER_SCREEN,
        data: {
          entityId: id,
          entityType: ENTITY_TYPES.VIDEO,
          thumbnail: videoThumbnail
        }
      });
      this.handleResourceProgressions();
    }
  };

  onClose = () => {
    const {
      history,
      selectedChildId
    } = this.props;
    setScreenOrientation(PAGE_MODE.DASHBOARD);
    if (Cookies.get("collectionPath")) {
      history.push(Cookies.get("collectionPath"));
    }
    else {
      if(Cookies.get("prevPageforPolicy")==="Favourite"){
        history.push(`/${selectedChildId}/favourites`); 
      }
      else if(Cookies.get("prevPageforPolicy")==="Search"){
        history.push(`/${selectedChildId}/Search`);
      }
      else if(Cookies.get("prevPageforPolicy")==="Category"){
        history.push(Cookies.get("categoryPath"));
      }
      else{
        history.push(`/${selectedChildId}/dashboard`);
      }
    }
    this.handleTitleComplete();
    this.handleResourceProgressions();
    myPlayer && myPlayer.pause();
    myPlayer && myPlayer.removeAttribute("src");
    myPlayer && myPlayer.load();
    myPlayer && myPlayer.dispose();
    myPlayer = "";
    videomanualclick = false;
    Cookies.remove("modal");
  };

  onCloselockedpop = () => {
    const { history, selectedChildId } = this.props;
    if (selectedChildId) {
      if (Cookies.get("collectionPath")) {
        history.push(Cookies.get("collectionPath"));
      }
      else {
        if(Cookies.get("prevPageforPolicy")==="Favourite"){
          history.push(`/${selectedChildId}/favourites`); 
        }
        else if(Cookies.get("prevPageforPolicy")==="Search"){
          history.push(`/${selectedChildId}/Search`);
        }
        else if(Cookies.get("prevPageforPolicy")==="Category"){
          history.push(Cookies.get("categoryPath"));
        }
        else {
          history.push(`/${selectedChildId}/dashboard`);
        }
      }
    } else {
      history.push(PATHS.HOME);
    }
    this.setState({showLockedImage:false})
  };

  handleResourceProgressions = () => {
    const {
      baseUrl,
      resourceProgressions,
      video = {},
      selectedChildId
    } = this.props;
    const { resourceId } = video;
    const duration = myPlayer && myPlayer.cache_ && myPlayer.cache_.duration;
    const currentTime =
      myPlayer && myPlayer.cache_ && myPlayer.cache_.currentTime;
    const avg = (currentTime && duration) ? (currentTime * 100) / duration : 0;
    let params = {
      resourceId: resourceId,
      completion: Math.floor(avg),
      time: Math.floor(currentTime) * 1000
    };
    resourceProgressions(baseUrl, params, selectedChildId);
  };

  videoClick = () => {
    if (myPlayer && myPlayer.paused() === true) {
      videomanualclick = true;
    } else {
      videomanualclick = false;
    }
  };
  render() {
    const { video, dashboardApiErrorMsg, videoNotExist, isCleverResource, itemTypes } = this.props;
    const { showLockedImage } = this.state;
    if (
      dashboardApiErrorMsg !== "" &&
      dashboardApiErrorMsg !== null &&
      dashboardApiErrorMsg !== undefined
    ) {
      apiErrorMessageText = dashboardApiErrorMsg;
    }
    return (
      <React.Fragment>
        {apiErrorMessageText !== "" && apiErrorMessageText !== undefined ? (
          <Error errorMessage={apiErrorMessageText} />
        ) : (
          <React.Fragment>
            {showLockedImage ? (
              <LockedPopup
                showLockedImage={showLockedImage}
                onClose={this.onCloselockedpop}
              />
            ) : (
              <React.Fragment>
                {(!video.providerResourceIdentifier || videoNotExist == null || videoNotExist) ? (
                  (isCleverResource && isCleverResource.id && ((videoNotExist != null && videoNotExist) || (video.type && video.type !== itemTypes.videos))) ? (
                    <Error errorMessage={RESOURCE_NOT_FOUND_ERROR} />
                  ) : (
                    <Loading loading="loading" />
                  )
                ) : (
                  <FullScreenModalTemplate onClose={this.onClose} page="VIDEOS">
                    <div
                      id="player-container"
                      className={styles.player_container}
                      onClick={this.videoClick}
                    />
                  </FullScreenModalTemplate>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state, ownProps) => {
  const id = get(ownProps, "match.params.videoId");
  const type = "video";

  return {
    videoPageData: selectVideoPageData(state),
    videoNotExist: selectEntityExist(state, { id, type }),
    video: selectEntity(state, { id }),
    selectedChildId: selectSelectedChildId(state),
    isApiRequestSyncing: selectIsApiRequestSyncing(state),
    profile: selectUser(state),
    kafkaUrl: get(state, "appManifest.data.app_settings.kafka_url"),
    baseUrl: selectBaseUrl(state),
    dashboardApiErrorMsg: get(state, "dashboardList.errorMessage"),
    itemTypes: selectItemTypes(state),
    countryCode: selectCountryCode(state),
    isCleverResource: selectIsCleverResource(state),
    videoId: id
  };
};

export const mapDispatchToProps = dispatch => ({
  launchBumperScreen(params) {
    dispatch(launchDialog(params));
  },
  webAppEvent(url, params) {
    dispatch(webAppEvent(url, params));
  },
  resourceProgressions(baseUrl, params, selectedChildId) {
    dispatch(resourceProgressions(baseUrl, params, selectedChildId));
  }
});

export default withRouter(
  fetchManifestData(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Video)
  )
);
