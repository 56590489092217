import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import FullScreenModalTemplate from "components/Templates/FullScreenModalTemplate";
import styles from "components/Dashboard/_LockedPopup.module.scss";
import Image from "../Global/Image";
import CustomButton from "components/Global/Button";
import get from "lodash/get";
import AgeCheck from "../AgeCheck";
import { launchAgeCheck, routeValuecheck } from "actions/settings";

class LockedPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      button: {
        buttonConfig: {
          type: "submit"
        }
      }
    };
  }

  static propTypes = {
    subscribeData: PropTypes.object,
    launchAgeChecked: PropTypes.func,
    routeValuecheck: PropTypes.func
  };

  static defaultProps = {
    subscribeData: {},
    launchAgeChecked() {},
    routeValuecheck() {}
  };

  OnSubscribe = () => {
    const { routeValuecheck, launchAgeChecked = {} } = this.props;
    launchAgeChecked(true);
    routeValuecheck("subscribe");
  };

  render() {
    const { showLockedImage, subscribeData, onClose } = this.props;
    const subscriptionStatus = Cookies.get("subscriptionStatus");
    return (
      <React.Fragment>
        {showLockedImage && (
          <FullScreenModalTemplate
            onClose={onClose}
            className={styles.lockedPopup}
          >
            <div className={styles.curiousGeorge}>
              <Image
                className={styles.spinner}
                folderName="ui_and_learning_area"
                fileName="curious_george"
                alt="Loading"
              />
            </div>
            <div className={styles.text_wrapper}>
              <div className={styles.heading_text}>
                {subscribeData["subscriber_sorry_text"]}
              </div>
              <div className={styles.sub_heading_text}>
                {subscribeData["curious_george_subscriber_text"]}
              </div>
            </div>
            <CustomButton
              config={this.state.button.buttonConfig}
              content={
                subscriptionStatus === "Free"
                  ? subscribeData["free_button_text"]
                  : subscribeData["subscribe_button_text"]
              }
              onClick={this.OnSubscribe}
              className={styles.subscribe_button}
            />
            <AgeCheck />
          </FullScreenModalTemplate>
        )}
      </React.Fragment>
    );
  }
}

export const mapDispatchToProps = dispatch => ({
  launchAgeChecked(params) {
    dispatch(launchAgeCheck(params));
  },
  routeValuecheck(params) {
    dispatch(routeValuecheck(params));
  }
});

export const mapStateToProps = (state, props) => ({
  subscribeData: get(state, "appManifest.data.subscribe")
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LockedPopup);
