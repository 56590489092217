// import cwManifest from "cwManifest.json";
import envUrl from "../envPath.json";
import {
  READ_MANIFEST_FAILURE,
  READ_MANIFEST_REQUEST,
  READ_MANIFEST_SUCCESS
} from "constants/actions";
import get from "lodash/get";
import { apiGetExternal } from "./core";

export const readManifestRequest = () => ({
  type: READ_MANIFEST_REQUEST,
  isFetching: true,
  errorMessage: ""
});

export const readManifestSuccess = manifestdata => ({
  type: READ_MANIFEST_SUCCESS,
  data: manifestdata,
  isFetching: false,
  errorMessage: ""
});

export const readManifestFailure = errorMessage => ({
  type: READ_MANIFEST_FAILURE,
  data: {},
  isFetching: false,
  errorMessage: errorMessage
});

export const readManifest = () => {
  return dispatch => {
    dispatch(readManifestRequest());
    return apiGetExternal({
      url: envUrl.Manifestdata_Url
    })
      .then(response =>
        dispatch(readManifestSuccess(get(response, "data", [])))
      )
      .catch(err => dispatch(readManifestFailure(get(err, "message", []))));
  };
};

// export const readManifest = () => {
//   return dispatch => {
//     return dispatch(readManifestSuccess(cwManifest));
//   };
// };
