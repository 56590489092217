import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./_AgeCheck.module.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import get from "lodash/get";
import Cookies from "js-cookie";
import FullScreenModalTemplate from "../Templates/FullScreenModalTemplate";
import { launchAgeCheck, routeValuecheck } from "actions/settings";
import Image from "components/Global/Image";
import { setScreenOrientation, cookieParams } from "utils";
import { PAGE_MODE } from "constants/screens";
import { grownupMenu, deviceInfo } from "../../helper.js";
import { selectUser } from "selectors/profile";
import { webAppEvent } from "actions/common";
import { setSelectedChildId } from "actions/children";
import { platform } from "helper.js";
import { selectCountryCode } from "selectors/dashboard";
import { isTablet } from "react-device-detect";
import Error from "../Error";

let yearval = "";
class AgeCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      birthYear: "",
      birthErrorMsg: "",
      AgeMessage:
        "Please enter your year of birth. We won't save this information - we just need to check you're an adult."
    };

    for (let i = 1; i < 10; i++) {
      this.state.items.push({ id: i });
    }
    this.state.items.push({ id: 0 });
  }

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    })
  };

  static defaultProps = {
    history: {
      push() {}
    }
  };

  onHandleChangeText = e => {
    const { birthYear } = this.state;
    this.setState({
      birthErrorMsg: ""
    });
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      if (
        e.target.value.length < 4 ||
        birthYear.length > e.target.value.length
      ) {
        this.setState({
          birthYear: e.target.value
        });
      }
      if (e.target.value.length === 4) {
        this.setState({
          birthYear: e.target.value
        });
        e.target.blur();
      }
      if (e.target.value.length >= 4) {
        if (
          new Date().getFullYear() - e.target.value < 18 ||
          new Date().getFullYear() - e.target.value > 100
        ) {
          setTimeout(() => {
            this.setState({
              birthYear: "",
              birthErrorMsg: "Sorry, you are not eligible to proceed at this time"
            });
            yearval = "";
          }, 500);
        } else {
          this.setState({
            birthErrorMsg: ""
          });
          const { routeValueRemove, closeAgeChecked = {} } = this.props;
          setTimeout(() => {
            closeAgeChecked(false);
            yearval = "";
            this.handleRouting();
            routeValueRemove("");
          }, 500);
        }
      }
      yearval = e.target.value;
    }
  };

  handleRouting = () => {
    const {
      selectedChildId,
      history,
      RouteValue,
      SidebarRouteValue,
      forgetPas_url,
      signUp_url,
      subscribeData,
      LoadingFn
    } = this.props;
    Cookies.set("showAnimation", "TRUE", cookieParams);
    if (SidebarRouteValue === "parent-area" && RouteValue === "") {
      if(isTablet){
        setScreenOrientation(PAGE_MODE.TABLET);
      }
      else{
      setScreenOrientation(PAGE_MODE.PARENT_AREA);
      }
      this.handleGrownupMenu();
      history.push(`/${selectedChildId}/${SidebarRouteValue}`);
    } else if (RouteValue === "edit-child") {
      if(isTablet) {
        setScreenOrientation(PAGE_MODE.TABLET);
      } 
      else{
      setScreenOrientation(PAGE_MODE.EDIT_CHILD);
      }
      history.push(`/${selectedChildId}/${RouteValue}`);
    } else if (RouteValue === "add-child") {
      if(isTablet) {
        setScreenOrientation(PAGE_MODE.TABLET);
      } 
      else{
      setScreenOrientation(PAGE_MODE.ADD_CHILD);}
      history.push(`/${RouteValue}`);
    } else if (RouteValue === "forgetPas") {
      LoadingFn();
      const url = forgetPas_url + platform;
      window.open(url, "_self").focus();
    } else if (RouteValue === "signUp") {
      LoadingFn();
      const url = signUp_url + platform;
      window.open(url, "_self").focus();
    } else if (RouteValue === "subscribe" || RouteValue === "subscribeHeader") {
      const url = subscribeData["subscribe_link"] + platform;
      var win = window.open(url, "_self");
      win.focus();
    }
  };

  handleGrownupMenu = () => {
    const { webAppEvent, kafkaUrl, profile, countryCode } = this.props;
    let params = [
      { 
        ...deviceInfo,
        ...grownupMenu(profile, countryCode)
      }
    ];
    webAppEvent(kafkaUrl, params);
  };

  onHandleChange = e => {
    let array = "";
    const { birthYear } = this.state;
    this.setState({
      birthErrorMsg: ""
    });
    if (
      yearval.length < 4 &&
      e.target.alt !== "Back" &&
      e.target.value !== "back"
    ) {
      yearval = yearval + e.target.value;
      this.setState({
        birthYear: birthYear + e.target.value
      });
    }
    if (
      yearval.length > 3 &&
      e.target.alt !== "Back" &&
      e.target.value !== "back"
    ) {
      e.target.blur();
      if (
        (new Date().getFullYear() - yearval < 18 ||
          new Date().getFullYear() - yearval > 100) &&
        e.target.value !== "Back"
      ) {
        setTimeout(() => {
          this.setState({
            birthYear: "",
            birthErrorMsg: "Sorry, you are not eligible to proceed at this time"
          });
          yearval = "";
        }, 500);
      } else {
        this.setState({
          birthErrorMsg: ""
        });
        const { routeValueRemove, closeAgeChecked = {} } = this.props;
        setTimeout(() => {
          closeAgeChecked(false);
          yearval = "";
          this.handleRouting();
          routeValueRemove("");
        }, 540);
      }
    } else if (e.target.alt === "Back" || e.target.value === "back") {
      for (let i = 0; i < birthYear.split("").length - 1; i++) {
        array = array + birthYear.split("")[i];
      }
      yearval = array;
      this.setState({
        birthYear: array
      });
    }
  };

  onClose = () => {
    if(!Cookies.get("lockedPopup") || Cookies.get("lockedPopup") !== "shown") {
      Cookies.set("showAnimation", "TRUE", cookieParams);
    }
    const { routeValueRemove, closeAgeChecked = {} } = this.props;
    this.setState({
      birthYear: "",
      birthErrorMsg: ""
    });
    yearval = "";
    closeAgeChecked(false);
    routeValueRemove("");
  };

  closeErrorpop = () => {
    this.setState({ birthErrorMsg: "" });
    this.onClose();
  };

  render() {
    const { AgeMessage, birthYear, birthErrorMsg } = this.state;
    const { AgeCheckShow } = this.props;
    return AgeCheckShow ? (
      <React.Fragment>
        {birthErrorMsg ? (
          <Error
            errorMessage={birthErrorMsg}
            okButton="true"
            closeErrorpop={this.closeErrorpop}
            buttonText="OK"
          />
        ) : (
          <FullScreenModalTemplate onClose={this.onClose} page="AGE_CHECk" AgeCheckShow={AgeCheckShow}>
            <div className={styles.modalheader}>
              <h2>Hi Grownup!</h2>
              <h4>{AgeMessage}</h4>
              <input
                type="text"
                onChange={this.onHandleChangeText}
                value={birthYear || ""}
              />
            </div>
            <div className={styles.modalbody}>
              <div className={styles.container}>
                {this.state.items.map((data, index) => {
                  return (
                    <button
                      className={styles.number_button}
                      onClick={this.onHandleChange}
                      value={data.id}
                      key={index}
                    >
                      {data.id}
                    </button>
                  );
                })}
                <button
                  className={styles.back_button}
                  onClick={this.onHandleChange}
                  value="back"
                >
                  <Image
                    folderName="ui_and_learning_area"
                    fileName="back_arrow"
                    alt="Back"
                  />
                </button>
              </div>
            </div>
            {birthErrorMsg && <span className="error">{birthErrorMsg}</span>}
          </FullScreenModalTemplate>
        )}
      </React.Fragment>
    ) : (
      ""
    );
  }
}
export const mapStateToProps = state => {
  return {
    AgeCheckShow: get(state, "settings.AgeCheckShow"),
    RouteValue: get(state, "settings.RouteValue"),
    signUp_url: get(state, "appManifest.data.app_settings.signup_url"),
    forgetPas_url: get(
      state,
      "appManifest.data.app_settings.forget_password_url"
    ),
    profile: selectUser(state),
    kafkaUrl: get(state, "appManifest.data.app_settings.kafka_url"),
    subscribeData: get(state, "appManifest.data.subscribe"),
    countryCode: selectCountryCode(state)
  };
};

export const mapDispatchToProps = dispatch => ({
  closeAgeChecked(params) {
    dispatch(launchAgeCheck(params));
  },
  routeValueRemove(params) {
    dispatch(routeValuecheck(params));
  },
  webAppEvent(url, params) {
    dispatch(webAppEvent(url, params));
  },
  selectChild(id) {
    dispatch(setSelectedChildId(id));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AgeCheck)
);
