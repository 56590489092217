import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { isAuthenticated } from "utils";
import AuthenticatedContentContainer from "../AuthenticatedContentContainer";
import UnauthenticatedContentContainer from "../UnauthenticatedContentContainer";
import VisibilityPopover from "../VisibilityPopover";
import { readManifest } from "actions/appManifest";
import connect from "react-redux/es/connect/connect";
import Loader from "../Loader";
import get from "lodash/get";
import httpService from "axios-interceptor";
import { setScreenOrientation } from "utils";
import { PAGE_MODE } from "constants/screens";
import { isMobile, isTablet } from "react-device-detect";
import {selectCountryCode} from "selectors/dashboard"
import Cookies from "js-cookie";
import { launchAgeCheck } from "actions/settings";
import Loading from "../Loading";
import Error from "../Error";
import { getCountry } from "actions/dashboard";
import { selectCountry } from "selectors/dashboard";
import envUrl from "envPath.json";
const queryString = require("query-string");
httpService.setupInterceptors(envUrl.token_name);

const mapStateToProps = state => ({
  manifestJson: get(state, "appManifest", {}),
  currentOrientation: get(state, "settings.ScreenOrientation"),
  AgeCheckvalue: get(state, "settings.AgeCheckShow"),
  cookieDomain: get(state, "appManifest.data.login.access_token_domain"),
  countryUrl: selectCountry(state),
  countryCode: selectCountryCode(state),
  tokenName: envUrl.token_name
});
const mapDispatchToProps = dispatch => ({
  readApiManifest() {
    dispatch(readManifest());
  },
  getCountry(countryUrl) {
    dispatch(getCountry(countryUrl));
  },
  closeAgeChecked(params) {
    dispatch(launchAgeCheck(params));
  }
});
class GateKeeper extends Component {
  static propTypes = {
    readApiManifest: PropTypes.func
  };

  static defaultProps = {
    readApiManifest: () => {}
  };

  state = {
    isManifest: false,
    showVisibilityPopover: false,
    orientationChanged: false,
    countryError: false,
    redirectUrl:"",
    site: ""
  };

  componentDidMount() {
    const { 
      readApiManifest,
      getCountry,
      countryUrl
    } = this.props;
    Cookies.remove("scrollPosition");
    Cookies.remove("itemLength");
    readApiManifest();
    document.addEventListener('contextmenu', this.handleContextMenu);
    document.addEventListener("keydown", this.preventKeys);
    if (isTablet || isMobile) {
      this.setState({ showVisibilityPopover: true });
    }
    if(countryUrl){
      getCountry(countryUrl);
    }
    const parsed = queryString.parse(window.location.search);
    this.setState({ site: parsed.site });
  }
  componentDidUpdate(nextProps) {
    const {
      manifestJson,
      closeAgeChecked,
      AgeCheckvalue,
      currentOrientation,
      countryCode,
      getCountry,
      countryUrl
    } = this.props;
    if (
      AgeCheckvalue &&
      nextProps.location.pathname !== this.props.location.pathname
    ) {
      closeAgeChecked(false);
    }
    if (manifestJson !== nextProps.manifestJson) {
      this.setState({ isManifest: true });
    }
    if(countryUrl !== nextProps.countryUrl){
      getCountry(countryUrl);
    }
    if(manifestJson.data.allowed_countries && countryCode && countryCode !== nextProps.countryCode){
      if (Object.values(manifestJson.data.allowed_countries).indexOf(countryCode) === -1)
       {this.setState({countryError: true, redirectUrl:manifestJson.data.login.redirect_if_not_subscribed}) }
       
    }
    if(isMobile){
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
    document.addEventListener("fullscreenchange", this.handlefullscreenChange);
    }
    window.onpopstate = event => {
      if (event.type === "popstate") {
        if (
          window.location.pathname.indexOf("dashboard") !== -1 ||
          window.location.pathname.indexOf("category") !== -1 ||
          window.location.pathname.indexOf("game") !== -1 ||
          window.location.pathname.indexOf("book") !== -1 ||
          window.location.pathname.indexOf("video") !== -1 ||
          window.location.pathname.indexOf("collection") !== -1 ||
          window.location.pathname.indexOf("Search") !== -1 ||
          window.location.pathname.indexOf("favourites") !== -1 || isTablet
        ) {
          setScreenOrientation(PAGE_MODE.DASHBOARD);
        } else {
          setScreenOrientation(PAGE_MODE.LOGIN);
        }
      }
    };
    if (currentOrientation !== nextProps.currentOrientation) {
      this.setState({ orientationChanged: true });
      setTimeout(() => {
        this.setState({ orientationChanged: false });
      }, 500);
    }
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      this.setState({ showVisibilityPopover: true });
    }
  };
  handlefullscreenChange = () => {
    if (document.fullscreen === false) {
      this.props.history.goBack();
      this.setState({ orientationChanged: true });
      setTimeout(() => {
        this.setState({ orientationChanged: false });
      }, 500);
    }
  };

  preventKeys = e => {
    // "I" key
    if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
      this.disabledEvent(e);
    }
    // "J" key
    if (e.ctrlKey && e.shiftKey && e.keyCode === 74) {
      this.disabledEvent(e);
    }
    // "S" key + macOS
    if (
      e.keyCode === 83 &&
      (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
    ) {
      this.disabledEvent(e);
    }
    // "U" key
    if (e.ctrlKey && e.keyCode === 85) {
      this.disabledEvent(e);
    }
    // "F12" key
    if (e.keyCode === 123) {
      this.disabledEvent(e);
    }
  };

  disabledEvent = e => {
    if (e.stopPropagation) {
      e.stopPropagation();
    } else if (window.event) {
      window.event.cancelBubble = true;
    }
    e.preventDefault();
    return false;
  };

  handleContextMenu = e => {
    e.preventDefault();
  };

  clickToGoBackToPage = () => {
    const { cookieDomain, tokenName } = this.props;
    if (isTablet ) {
      setScreenOrientation(PAGE_MODE.TABLET);
    } else {
      if(window.location.pathname.indexOf("login") !== -1 ||  !isAuthenticated(cookieDomain, tokenName) ||
          window.location.pathname.indexOf("add-child") !== -1 ||
          window.location.pathname.indexOf("edit-child") !== -1 ||
          window.location.pathname.indexOf("privacy-policy") !== -1 ||
          window.location.pathname.indexOf("delete-child") !== -1 ||
          window.location.pathname.indexOf("parent-area") !== -1 ||
          window.location.pathname.indexOf("parent-dashboard") !== -1 ||
          window.location.pathname.indexOf("my-children") !== -1 ||
          window.location.pathname.indexOf("terms-conditions") !== -1){
            setScreenOrientation("portrait");
          }
          else{
            setScreenOrientation("landscape");
          }
    }
    this.setState({ showVisibilityPopover: false });
  };

  render() {
    const {
      isManifest,
      showVisibilityPopover,
      orientationChanged,
      countryError,
      redirectUrl,
      site
    } = this.state;
    const {manifestJson, cookieDomain, tokenName} = this.props;
    return (
      <React.Fragment>
      {manifestJson.errorMessage || countryError ? (<Error errorMessage={manifestJson.errorMessage} url={redirectUrl} />):(
      <React.Fragment>
        {(orientationChanged || !manifestJson.data.login) ? (<Loading />) : (
          site !== "cw" && (isMobile || isTablet) && showVisibilityPopover ? (
            <VisibilityPopover buttonClick={this.clickToGoBackToPage} />
          ) : isManifest ? (
            isAuthenticated(cookieDomain, tokenName) ? (
              <AuthenticatedContentContainer />
            ) : (
              <UnauthenticatedContentContainer />
            )
          ) : (
            <Loader />
          )
        )}
      </React.Fragment>)}
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GateKeeper)
);
