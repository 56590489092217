import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import get from "lodash/get";
import c from "classnames";

import { PATHS } from "constants/url";
import Image from "components/Global/Image";
import styles from "components/Sidebar/_Sidebar.module.scss";
import { selectSelectedChild, selectSelectedChildId } from "selectors/children";
import WhoIsPlayingLayover from "./WhoIsPlayingLayover";
import CategoriesContainer from "components/CategoriesContainer";
import fetchManifestData from "components/FetchManifestData";
import AgeCheck from "../AgeCheck";
import { launchAgeCheck } from "actions/settings";
import { setScreenOrientation, getAvatarPositionFromUrl, cookieParams } from "utils";
import { PAGE_MODE } from "constants/screens";
import { selectEventAvatarImages } from "selectors/event";
import Cookies from "js-cookie";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      AgeCheckShow: false,
      whoIsPlayingLayover: false,
      categoryLayover: false,
      isFavourite: false,
      isSearch: false,
      isCategory: false
    };
  }

  static propTypes = {
    className: PropTypes.string,
    selectedChild: PropTypes.object,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    })
  };

  static defaultProps = {
    className: "",
    selectedChild: {},
    history: {
      push() {}
    }
  };

  componentDidMount() {
    const path = get(this.props, "match.path");
    switch (path) {
      case PATHS.FAVOURITES:
        this.toggleFavouriteOnLoad();
        break;
      case PATHS.SEARCH:
        this.toggleSearchOnLoad();
        break;
      case PATHS.VIDEO:
        this.isSelectedCategory();
        break;
      case PATHS.BOOK:
        this.isSelectedCategory();
        break;
      case PATHS.GAME:
        this.isSelectedCategory();
        break;
      case PATHS.CATEGORY:
        this.isSelectedCategory();
        break;
      default:
        break;
    }
  }

  toggleWhoIsPlayingLayover = () => {
    if (!this.state.whoIsPlayingLayover) {
      Cookies.set("showAnimation", "FALSE", cookieParams);
    }
    else {
      Cookies.set("showAnimation", "TRUE", cookieParams);
    }
    this.setState({
      categoryLayover: false,
      whoIsPlayingLayover: !this.state.whoIsPlayingLayover,
      isSearch: false,
      isFavourite: false,
      isCategory: false
    });
  };

  toggleCategoryLayover = () => {
    const { categoryLayover, isCategory } = this.state;
    if (!categoryLayover) {
      Cookies.set("showAnimation", "FALSE", cookieParams);
    }
    else {
      Cookies.set("showAnimation", "TRUE", cookieParams);
    }
    this.setState({
      whoIsPlayingLayover: false,
      categoryLayover: !categoryLayover,
      isCategory: !categoryLayover ? true : !isCategory,
      isSearch: false,
      isFavourite: false
    });
  };

  toggleFavouriteOnLoad = () => {
    this.setState({
      whoIsPlayingLayover: false,
      isCategory: false,
      categoryLayover: false,
      isSearch: false,
      isFavourite: true
    });
  }

  toggleFavourite = () => {
    Cookies.remove("scrollPosition");
    Cookies.remove("itemLength");
    this.toggleFavouriteOnLoad();
  };

  favouritesClick = () => {
    setScreenOrientation(PAGE_MODE.FAVOURITES);
  };

  searchClick = () => {
    setScreenOrientation(PAGE_MODE.SEARCH);
  };

  toggleSearchOnLoad = () => {
    this.setState({
      whoIsPlayingLayover: false,
      isCategory: false,
      categoryLayover: false,
      isFavourite: false,
      isSearch: true
    });
  }
  toggleSearch = () => {
    const { goBackToSearch } = this.props;
    Cookies.remove("scrollPosition");
    Cookies.remove("itemLength");
    this.toggleSearchOnLoad();
    if (goBackToSearch !== undefined) {
      goBackToSearch();
    }
  };

  isSelectedCategory = () => {
    this.setState({
      whoIsPlayingLayover: false,
      isFavourite: false,
      isSearch: false,
      isCategory: true
    });
  };

  backToPrevious = () => {
    const {
      selectedChild,
      history
    } = this.props;
    Cookies.set("searchText","", cookieParams);
    Cookies.remove("searchText");
    Cookies.remove("scrollPosition");
    Cookies.remove("itemLength");
    if (selectedChild) {
      setScreenOrientation(PAGE_MODE.DASHBOARD);
      history.push(`/${selectedChild.id}/dashboard`);
    } else {
      setScreenOrientation(PAGE_MODE.HOME);
      history.push(PATHS.HOME);
    }
  };

  getLinkUrl = () => {
    const { selectedChildId } = this.props;
    return `/${selectedChildId}/Search`;
  };

  handleClick = () => {
    Cookies.set("showAnimation", "FALSE", cookieParams);
    Cookies.remove("scrollPosition");
    Cookies.remove("itemLength");
    const { launchAgeChecked = {} } = this.props;
    launchAgeChecked(true);
  };

  getAvatarImageSrc = (child) => {
    const { eventAvatarImages, avatarsList } = this.props;
    if (eventAvatarImages) {
      const avatarImageUrl = get(child, "metadata.avatar", "");
      const avatarIndex = getAvatarPositionFromUrl(avatarImageUrl, avatarsList);
      return get(eventAvatarImages, `avatar-${avatarIndex + 1}`, "");
    } else {
      return get(child, "metadata.avatar", "");
    }
  }

  render() {
    const {
      className,
      selectedChild,
      selectedChildId,
      backButton,
      RouteValue,
      favItem
    } = this.props;
    const {
      whoIsPlayingLayover,
      categoryLayover,
      isFavourite,
      isSearch,
      isCategory
    } = this.state;
    const url = this.getLinkUrl();
    return (
      <div className={className}>
        <div className={c(
          styles.avatar_wrapper,
          styles.clickable
        )}>
          {backButton ? (
            <div onClick={this.toggleWhoIsPlayingLayover}>
              <img
                alt={selectedChild.name}
                src={this.getAvatarImageSrc(selectedChild)}
              />
              <div className={styles.avatar_image}>{selectedChild.name}</div>
              {whoIsPlayingLayover ? (
                <Image
                  folderName="static"
                  fileName="arrow_sidebar"
                  alt="Right Arrow"
                  className={styles.avatar_active_link}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            <Image
              folderName="ui_and_learning_area"
              fileName="back"
              alt="Back"
              className={styles.back_link}
              onClick={this.backToPrevious}
            />
          )}
        </div>
        <ul className={styles.top_icons}>
          <li onClick={this.toggleCategoryLayover}>
            <Image
              folderName="ui_and_learning_area"
              fileName="categories"
              alt="Categories Icon"
              className={styles.icon_image}
            />
            {isCategory ||
            this.props.location.pathname.indexOf("category") !== -1 ? (
              <Image
                folderName="static"
                fileName="arrow_sidebar"
                alt="Right Arrow"
                className={styles.active_link}
              />
            ) : (
              ""
            )}
          </li>
          {favItem > 0 && (
            <li onClick={this.toggleFavourite}>
              <Link
                to={`/${selectedChildId}/favourites`}
                onClick={this.favouritesClick}
              >
                {" "}
                {/* activeClassName="active" */}
                <Image
                  folderName="ui_and_learning_area"
                  fileName="favourites_button"
                  alt="Favourites Icon"
                  className={styles.icon_image}
                />
                {isFavourite ||
                (!isCategory &&
                  this.props.location.pathname.indexOf("favourites") !== -1) ? (
                  <Image
                    folderName="static"
                    fileName="arrow_sidebar"
                    alt="Right Arrow"
                    className={styles.active_link}
                  />
                ) : (
                  ""
                )}
              </Link>
            </li>
          )}
        </ul>
        <ul className={styles.bottom_icons}>
          <li onClick={this.toggleSearch}>
            <Link to={url} onClick={this.searchClick}>
              {" "}
              {/* activeClassName="active" */}
              <Image
                folderName="ui_and_learning_area"
                fileName="search"
                alt="Search Icon"
                className={styles.icon_image}
              />
              {isSearch ? (
                <Image
                  folderName="static"
                  fileName="arrow_sidebar"
                  alt="Right Arrow"
                  className={styles.active_link}
                />
              ) : (
                ""
              )}
            </Link>
          </li>
          <li>
            <Image
              folderName="ui_and_learning_area"
              fileName="parental_dashboard"
              alt="Parental Dashboard Icon"
              className={styles.icon_image}
              onClick={this.handleClick}
            />
          </li>
        </ul>
        {whoIsPlayingLayover && (
          <WhoIsPlayingLayover onClose={this.toggleWhoIsPlayingLayover} />
        )}
        {categoryLayover && (
          <CategoriesContainer onClose={this.toggleCategoryLayover} />
        )}
        {RouteValue === "" && (
          <AgeCheck
            selectedChildId={selectedChildId}
            SidebarRouteValue="parent-area"
          />
        )}
      </div>
    );
  }
}

export const mapDispatchToProps = dispatch => ({
  launchAgeChecked(params) {
    dispatch(launchAgeCheck(params));
  }
});

export const mapStateToProps = state => ({
  selectedChild: selectSelectedChild(state),
  selectedChildId: selectSelectedChildId(state),
  RouteValue: get(state, "settings.RouteValue"),
  avatarsList: get(state, "appManifest.data.icons.avatars", {}),
  eventAvatarImages: selectEventAvatarImages(state)
});

export default withRouter(
  fetchManifestData(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Sidebar)
  )
);
