import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import get from "lodash/get";

import Loader from "../Loader";
import c from "classnames";

import classes from "./_styles.module.scss";
import Image from "../Global/Image";
import { closeDialog } from "actions/settings";
import { selectEntity } from "selectors/dashboard";
import { selectSelectedChildId } from "selectors/children";
import { selectFavoriteList } from "selectors/dashboard";
import { addToFavourites, removeToFavourites } from "actions/favourites";
import { getFavouriteList } from "actions/dashboard";
import { selectBaseUrl } from "selectors";

import FullScreenModalTemplate from "../Templates/FullScreenModalTemplate";
import { DIALOG_IDS } from "../../constants";
import RelatedContent from "./RelatedContent";
import { webAppEvent } from "actions/common";
import { selectUser, selectIsCleverResource } from "selectors/profile";
import { selectItemTypes } from "selectors/dashboard";
import Cookies from "js-cookie";
import { cookieParams } from "utils";

class BumperScreen extends Component {
  static propTypes = {
    bumperScreenData: PropTypes.shape({
      play_again_text: PropTypes.string,
      add_to_favourites_text: PropTypes.string,
      added_to_favourites_text: PropTypes.string
    }),
    dataElement: PropTypes.object,
    dialogSettings: PropTypes.object,
    closeBumperScreen: PropTypes.func,
    selectedChildId: PropTypes.string,
    addToFavourites: PropTypes.func,
    removeToFavourites: PropTypes.func,
    getFavouriteList: PropTypes.func,
    favouriteList: PropTypes.array,
    baseUrl: PropTypes.string
  };

  state = {
    isLoading: false,
    pause: false
  };

  static defaultProps = {
    bumperScreenData: {
      play_again_text: "",
      add_to_favourites_text: "",
      added_to_favourites_text: ""
    },
    dataElement: {},
    dialogSettings: {},
    closeBumperScreen() {},
    selectedChildId: "",
    addToFavourites() {},
    removeToFavourites() {},
    getFavouriteList() {},
    favouriteList: [],
    baseUrl: ""
  };

  componentDidUpdate(prevProps) {
    const {
      selectedChildId,
      getFavouriteList,
      favouriteList,
      baseUrl,
      isFavLoader,
      dialogSettings
    } = this.props;
    const listParams = {
      cid: selectedChildId
    };
    if (dialogSettings.id === DIALOG_IDS.BUMPER_SCREEN && prevProps.isFavLoader === true && isFavLoader === false) {
      selectedChildId && getFavouriteList(baseUrl, listParams);
    }
    if (favouriteList && favouriteList !== prevProps.favouriteList) {
      this.setState({
        isLoading: false,
        pause: false
      });
    }
  }

  playAgain = () => {
    const { selectedChildId, dialogSettings, closeBumperScreen } = this.props;
    const entityId = get(dialogSettings, "data.entityId");
    const entityType = get(dialogSettings, "data.entityType");
    closeBumperScreen();
    Cookies.remove("bumperScreenDisplay");

    if (selectedChildId && entityId && entityType) {
      this.props.history.push(`/${selectedChildId}/${entityType}/${entityId}`);
    }
  };

  addToFavourites = () => {
    const {
      selectedChildId,
      dialogSettings,
      baseUrl,
      addToFavourites,
      removeToFavourites,
      favouriteList
    } = this.props;
    const entityId = dialogSettings && get(dialogSettings, "data.entityId");
    const params = {
      rid: entityId,
      cid: selectedChildId
    };
    this.setState({
      isLoading: true,
      pause: true
    });
    const favouriteStatus = favouriteList.includes(entityId);
    favouriteStatus && removeToFavourites(baseUrl, params);
    !favouriteStatus && addToFavourites(baseUrl, params);
  };

  closeScreen = () => {
    const {closeBumperScreen, selectedChildId, history} = this.props;
    const searchtext=Cookies.get("searchText");
    Cookies.remove("modal");
    if (Cookies.get("collectionPath")) {
      history.push(Cookies.get("collectionPath"));
    }
    else {
      if(searchtext !== "" && searchtext && searchtext!=="undefined" && searchtext!=null){
        Cookies.set("prevPageforPolicy", "Search", cookieParams);
        history.push(`/${selectedChildId}/Search`);
      }
      else if(Cookies.get("prevPageforPolicy")==="Favourite"){
        history.push(`/${selectedChildId}/favourites`); 
      }
      else if(Cookies.get("prevPageforPolicy")==="Category"){
        history.push(Cookies.get("categoryPath"));
      }
      else{
        history.push(`/${selectedChildId}/dashboard`);
      }
      Cookies.set("showAnimation", "TRUE", cookieParams);
    }
    closeBumperScreen();
  };
  
  render() {
    const {
      bumperScreenData = {},
      dataElement = {},
      dialogSettings,
      favouriteList,
      isCleverResource
    } = this.props;
    const { pause } = this.state;
    const {
      play_again_text: playAgainText,
      add_to_favourites_text: addToFavouritesText,
      added_to_favourites_text: addedToFavouritesText
    } = bumperScreenData;

    if (dialogSettings.id !== DIALOG_IDS.BUMPER_SCREEN) {
      return null;
    }
    const entityId = get(dialogSettings, "data.entityId");
    let favouriteStatus = favouriteList.includes(entityId);
    let thumbnail = get(dialogSettings, "data.thumbnail");

    return (
      <FullScreenModalTemplate onClose={this.closeScreen}>
        <div className={c(
          classes.item,
          isCleverResource && isCleverResource.id && classes.cleverResource
        )}>
          {thumbnail && (
            <img
              src={get(dialogSettings, "data.thumbnail")}
              alt={dataElement.title}
            />
          )}
          <div className={classes.item_overlay}>
            <span className={classes.item_title}>{dataElement.title}</span>
            <div className={classes.buttons}>
              <div className={classes.button_wrapper} onClick={this.playAgain}>
                <Image
                  folderName="static"
                  fileName="play_again_arrow"
                  alt="Play Again"
                  className={classes.icons}
                />
                <span className={classes.icon_text}>{playAgainText}</span>
              </div>
              {!isCleverResource && <div
                className={classes.button_wrapper}
                onClick={this.addToFavourites}
              >
                {this.state.isLoading ? (
                  <Loader className="fav_bumper_loader" />
                ) : (
                  ""
                )}
                <Image
                  folderName="ui_and_learning_area"
                  fileName={
                    favouriteStatus ? "remove_favorites" : "add_favorites"
                  }
                  alt="Favourites Icon"
                  className={c(
                    classes.icons,
                    this.state.isLoading ? classes.icon_faded : ""
                  )}
                />
                <span
                  className={c(
                    classes.icon_text,
                    this.state.isLoading ? classes.icon_faded : ""
                  )}
                >
                  {favouriteStatus
                    ? addedToFavouritesText
                    : addToFavouritesText}
                </span>
              </div>}
            </div>
          </div>
        </div>
        {!isCleverResource &&<RelatedContent
          onClose={this.closeScreen}
          entityId={entityId}
          pause={pause}
        />}
      </FullScreenModalTemplate>
    );
  }
}

export const mapStateToProps = state => {
  const dialogSettings = get(state, "settings.dialog", {});
  return {
    itemTypes: selectItemTypes(state),
    bumperScreenData: get(state, "appManifest.data.bumper_screen", {}),
    dialogSettings,
    dataElement: selectEntity(state, {
      id: get(dialogSettings, "data.entityId")
    }),
    selectedChildId: selectSelectedChildId(state),
    baseUrl: selectBaseUrl(state),
    favouriteList: selectFavoriteList(state),
    profile: selectUser(state),
    kafkaUrl: get(state, "appManifest.data.app_settings.kafka_url"),
    isFavLoader: get(state, "favourites.isFetching"),
    isCleverResource: selectIsCleverResource(state)
  };
};

export const mapDispatchToProps = dispatch => ({
  closeBumperScreen() {
    dispatch(closeDialog());
  },
  addToFavourites(baseUrl, params) {
    dispatch(addToFavourites(baseUrl, params));
  },
  removeToFavourites(baseUrl, params) {
    dispatch(removeToFavourites(baseUrl, params));
  },
  getFavouriteList(baseUrl, params) {
    dispatch(getFavouriteList(baseUrl, params));
  },
  webAppEvent(url, params) {
    dispatch(webAppEvent(url, params));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BumperScreen)
);
