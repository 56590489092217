import getfp from "lodash/fp/get";
import get from "lodash/get";
import { createSelector } from "reselect";

import { selectIsFetchingChildren } from "./children";
import { selectIsFetchingProfile } from "./profile";
import { selectIsFetchingDashboardApi } from "./dashboard";

export const selectBaseUrl = getfp(
  "appManifest.data.app_settings.api_base_url"
);

const selectImagePath = (state, props) =>
  get(state, "appManifest.data.app_settings.images_url");
const selectIcons = (state, props) =>
  get(state, "appManifest.data.icons");
const AppSetting = (state, props) =>
  get(state, "appManifest.data.app_settings");
const selectFolderName = (state, props) => props.folderName;
const selectFileName = (state, props) => props.fileName;

const imageSrcGenerator = ({
  imagePath,
  imageIcons,
  folderName,
  fileName,
  appSettingBaseUrl
}) => {
  const folderPath = appSettingBaseUrl
    ? appSettingBaseUrl[folderName + "_base_url"]
    : "";

  const filePath = imageIcons
    ? imageIcons[folderName]
      ? imageIcons[folderName][fileName]
      : ""
    : "";

  return folderPath && filePath ? `${folderPath}${filePath}` : "";
};

export const selectImageSrc = createSelector(
  [selectImagePath, selectIcons, selectFolderName, selectFileName, AppSetting],
  (imagePath, imageIcons, folderName, fileName, appSettingBaseUrl) =>
    imageSrcGenerator({
      imagePath,
      imageIcons,
      folderName,
      fileName,
      appSettingBaseUrl
    })
);

export const selectNoOfAvatars = state => {
  const iconsList = get(state, "appManifest.data.icons.avatars", []);
  return Object.keys(iconsList).length;
};

const selectAssetsImageName = (state, props) => props.assetsImageName;
export const selectAssetsImagePath = state =>
  get(state, "appManifest.data.app_settings.assets_url");

export const selectAssetsImageSrc = createSelector(
  [selectAssetsImageName, selectAssetsImagePath],
  (assetsImageName, assetsImagePath) => {
    return assetsImageName ? `${assetsImagePath}${assetsImageName}` : "";
  }
);

const selectCategoryImagePath = (state, props) =>
  get(state, "appManifest.data.app_settings.categories_base_url");

export const selectCategoryImageSrc = createSelector(
  [selectAssetsImageName, selectCategoryImagePath],
  (assetsImageName, categoryImagePath) => {
    return assetsImageName ? `${categoryImagePath}${assetsImageName}` : "";
  }
);

export const selectIsApiRequestSyncing = createSelector(
  [
    selectIsFetchingChildren,
    selectIsFetchingDashboardApi,
    selectIsFetchingProfile
  ],
  (
    isFetchingChildren = false,
    isFetchingDashboardApi = false,
    isFetchingProfile = false
  ) => {
    return isFetchingChildren || isFetchingDashboardApi || isFetchingProfile;
  }
);

export const selectSiteTheme = (state, props) =>
  get(state, "appManifest.data.theme");

export const selectCurrentOrientation = (state, props) =>
  get(state, "settings.ScreenOrientation");
