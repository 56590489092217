import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getChildren } from "actions/children";
import { selectBaseUrl } from "selectors";
import { selectUserId } from "selectors/profile";
import { selectChildren } from "selectors/children";

export const mapStateToProps = state => ({
  baseUrl: selectBaseUrl(state),
  userId: selectUserId(state),
  children: selectChildren(state)
});

export const mapDispatchToProps = dispatch => ({
  getMyChildren(baseUrl, userId) {
    dispatch(getChildren(baseUrl, userId));
  }
});

export default function withChildren(WrappedComponent) {
  class WithChildren extends Component {
    static propTypes = {
      baseUrl: PropTypes.string,
      userId: PropTypes.string,
      children: PropTypes.array,
      getMyChildren: PropTypes.func
    };

    static defaultProps = {
      children: [],
      userId: "",
      baseUrl: "",
      getMyChildren() {}
    };

    componentDidMount() {
      const { baseUrl, userId, getMyChildren, children } = this.props;

      if (baseUrl && userId && !children.length) {
        getMyChildren(baseUrl, userId);
      }
    }

    componentDidUpdate(prevProps) {
      const { baseUrl, userId, getMyChildren } = this.props;
      if (
        baseUrl &&
        userId &&
        (baseUrl !== prevProps.baseUrl || userId !== prevProps.userId)
      ) {
        getMyChildren(baseUrl, userId);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithChildren);
}
