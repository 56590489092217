import {
  STORE_EPUB_CONTENT,
  STORE_EPUB_AUDIO_CONTENT,
  GET_PRIMROSE_RESOURCE_FAILURE,
  GET_PRIMROSE_RESOURCE_REQUEST,
  GET_PRIMROSE_RESOURCE_SUCCESS
} from "../constants/actions";
import get from "lodash/get";
import { apiGetExternal, generateUrl } from "./core";

export const storeBookDetails = (bookId, epubbaseUrl) => ({
  type: STORE_EPUB_CONTENT,
  bookId,
  epubbaseUrl
});

export const storeAudioDetails = (bookId, page1AudioDetails, page2AudioDetails, pageNo, noOfPages) => ({
  type: STORE_EPUB_AUDIO_CONTENT,
  bookId,
  page1AudioDetails,
  page2AudioDetails,
  pageNo,
  noOfPages
});

export const getPrimroseResourceRequest = () => {
  return {
    type: GET_PRIMROSE_RESOURCE_REQUEST,
    isFetching: true,
    errorMessage: "",
    primroseResource: {}
  };
};

export const getPrimroseResourceSuccess = data => {
  return {
    type: GET_PRIMROSE_RESOURCE_SUCCESS,
    isFetching: false,
    errorMessage: "",
    primroseResource: data
  };
};

export const getPrimroseResourceFailure = errorMessage => {
  return {
    type: GET_PRIMROSE_RESOURCE_FAILURE,
    isFetching: false,
    errorMessage,
    primroseResource: {}
  };
};

export const getPrimroseResource = (baseUrl, bookId) => {
  const url = generateUrl(baseUrl, `/primrose-resource/${bookId}?_format=json`);

  return dispatch => {
    dispatch(getPrimroseResourceRequest());
    return apiGetExternal({ url })
      .then(response => dispatch(getPrimroseResourceSuccess(get(response, "data", []))))
      .catch(err => dispatch(getPrimroseResourceFailure(get(err, "message", []))));
  };
};
