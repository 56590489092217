import {
  GET_COUNTRY_REQUEST,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_FAILURE,
  GET_MANIFEST_RECOMMENDATION_REQUEST,
  GET_MANIFEST_RECOMMENDATION_SUCCESS,
  GET_MANIFEST_RECOMMENDATION_FAILURE,
  GET_MANIFEST_DATA_REQUEST,
  GET_MANIFEST_DATA_SUCCESS,
  GET_MANIFEST_DATA_FAILURE,
  GET_FAVOURITE_LIST_REQUEST,
  GET_FAVOURITE_LIST_SUCCESS,
  GET_FAVOURITE_LIST_FAILURE,
  GET_RELATED_CONTENT_REQUEST,
  GET_RELATED_CONTENT_SUCCESS,
  GET_RELATED_CONTENT_FAILURE,
  USER_DASHBOARD_REQUEST,
  USER_DASHBOARD_SUCCESS,
  USER_DASHBOARD_FAILURE
} from "constants/actions";
import { INDEXED_DB_CONST, INDEXED_DB_ERRORS } from "../constants";
import get from "lodash/get";
import { platform } from "helper.js";
import Cookies from "js-cookie";
import { apiGet, apiPost, apiGetExternal, generateUrl } from "./core";
import { createOrFetchIndexedDb, fetchObjectStore, deleteIndexedDb, cookieParams } from "utils";

export const getCountryRequest = () => {
  return {
    type: GET_COUNTRY_REQUEST,
    isFetching: true,
    errorMessage: "",
    country: {}
  };
};

export const getCountrySuccess = data => {
  return {
    type: GET_COUNTRY_SUCCESS,
    errorMessage: "",
    isFetching: false,
    country: data.countryCode
  };
};

export const getCountryFailure = errorMessage => {
  return {
    type: GET_COUNTRY_FAILURE,
    errorMessage,
    isFetching: false,
    country: {}
  };
};

export const getCountry = url => {
  return dispatch => {
    dispatch(getCountryRequest());
    return apiGetExternal({ url })
      .then(response => dispatch(getCountrySuccess(get(response, "data", []))))
      .catch(err => dispatch(getCountryFailure(get(err, "message", []))));
  };
};

export const getManifestRecommendationRequest = () => {
  return {
    type: GET_MANIFEST_RECOMMENDATION_REQUEST,
    isFetching: true,
    errorMessage: ""
  };
};

export const getManifestRecommendationSuccess = data => {
  return {
    type: GET_MANIFEST_RECOMMENDATION_SUCCESS,
    errorMessage: "",
    isFetching: false,
    recommendedManifestData: data
  };
};

export const getManifestRecommendationFailure = errorMessage => {
  return {
    type: GET_MANIFEST_RECOMMENDATION_FAILURE,
    errorMessage,
    isFetching: false,
    recommendedManifestData: {}
  };
};

export const getManifestRecommendation = (
  baseUrl,
  countryCode,
  deviceModel,
  age,
  apiVerson
) => {
  let apiurl = baseUrl + "/manifests/v1572504565";
  const subscriptionStatus = Cookies.get("subscriptionStatus")
  if (apiVerson) {
    apiurl = baseUrl + "/manifests/" + apiVerson;
  }
  const url = generateUrl(
    apiurl,
    `/itemRecommendations/premium?age=${age}&deviceModel=${deviceModel}&countryCode=${countryCode}&subscriptionStatus=${subscriptionStatus}`
  );

  return dispatch => {
    dispatch(getManifestRecommendationRequest());
    return apiGet({ url })
      .then(response =>
        dispatch(getManifestRecommendationSuccess(get(response, "data", [])))
      )
      .catch(err =>
        dispatch(getManifestRecommendationFailure(get(err, "message", [])))
      );
  };
};

export const getManifestDataRequest = () => {
  return {
    type: GET_MANIFEST_DATA_REQUEST,
    isFetching: true,
    errorMessage: ""
  };
};

export const getManifestDataSuccess = (data, itemType) => {
  return {
    type: GET_MANIFEST_DATA_SUCCESS,
    errorMessage: "",
    isFetching: false,
    manifestData: data,
    itemType: itemType
  };
};

export const getManifestDataFailure = errorMessage => {
  return {
    type: GET_MANIFEST_DATA_FAILURE,
    errorMessage,
    isFetching: false,
    manifestData: {}
  };
};

export const getManifestData = (
  baseUrl,
  countryCode,
  deviceModel,
  itemType,
  apiVerson
) => {
  if (localStorage !== null && localStorage !== undefined) {
    let version = localStorage.getItem("manifestVersionNew");
    if (
      localStorage.getItem("manifestVersion") !==
      localStorage.getItem("manifestVersionNew")
    ) {
      deleteIndexedDb(INDEXED_DB_CONST.DB_NAME);
      localStorage.clear("manifestVersion");
      localStorage.clear("manifestVersionNew");
      localStorage.setItem("manifestVersion", version);
    }

    const updatedReqKeysInManifest = Cookies.get("updatedReqKeysInManifest")
    if (!updatedReqKeysInManifest || updatedReqKeysInManifest < 8) {
      deleteIndexedDb(INDEXED_DB_CONST.DB_NAME);
      Cookies.set("updatedReqKeysInManifest", 8, cookieParams);
    }

    return dispatch => {
      return getIndexedDbData().then((data) => {
        if (data && data.length > 0) {
          return dispatch(getManifestDataSuccess(data, itemType));
        } else {
          return dispatch(
            getManifestDataAPI(
              baseUrl,
              countryCode,
              deviceModel,
              itemType,
              apiVerson
            )
          );
        }
      }, (error) => {
        if (error === INDEXED_DB_ERRORS.STORE_NOT_EXIST) {
          return dispatch(
            getManifestDataAPI(
              baseUrl,
              countryCode,
              deviceModel,
              itemType,
              apiVerson
            )
          );
        }
      });
    };
  } else {
    return dispatch => {
      return dispatch(
        getManifestDataAPI(
          baseUrl,
          countryCode,
          deviceModel,
          itemType,
          apiVerson
        )
      );
    };
  }
};

const getIndexedDbData = () => {
  return new Promise(function (resolve, reject) {
    /*let indexedDbExist = localStorage.getItem("indexedDbExist");
    if (indexedDbExist) {*/
      createOrFetchIndexedDb(INDEXED_DB_CONST.DB_NAME).then(function(db) {
        let objectStore = fetchObjectStore(INDEXED_DB_CONST.DB_STORE_NAME, 'readwrite', db);

        if (objectStore) {
          let req = objectStore.getAll();

          req.onsuccess = function (event) {
            resolve(this.result);
          };
        }
        else {
          deleteIndexedDb(INDEXED_DB_CONST.DB_NAME);
          reject(INDEXED_DB_ERRORS.STORE_NOT_EXIST);
        }
      });
    /*}
    else {
      reject(INDEXED_DB_ERRORS.STORE_NOT_EXIST);
    }*/
  })
}

/*const clearIndexedDb = () => {
  createOrFetchIndexedDb(INDEXED_DB_CONST.DB_NAME).then(function(db) {
    let objectStore = fetchObjectStore(INDEXED_DB_CONST.DB_STORE_NAME, 'readwrite', db);

    if (objectStore) {
      let req = objectStore.clear();

      req.onsuccess = function (event) {
        localStorage.clear("indexedDbExist");
      };
    }
  });
}*/

export const getManifestDataAPI = (
  baseUrl,
  countryCode,
  deviceModel,
  itemType,
  apiVerson
) => {
  let apiurl = baseUrl + "/manifests/v1572504565";
  if (apiVerson) {
    apiurl = baseUrl + "/manifests/" + apiVerson;
  }
  const url = generateUrl(
    apiurl,
    `/titles?_format=json&deviceModel=${deviceModel}&countryCode=${countryCode}`
  );

  return dispatch => {
    dispatch(getManifestDataRequest());
    return apiGet({ url })
      .then(response =>
        dispatch(getManifestDataSuccess(get(response, "data", []), itemType))
      )
      .catch(err => dispatch(getManifestDataFailure(get(err, "message", []))));
  };
};

export const getFavouriteListRequest = () => {
  return {
    type: GET_FAVOURITE_LIST_REQUEST,
    isFetching: true,
    errorMessageFavList: ""
  };
};

export const getFavouriteListSuccess = data => {
  return {
    type: GET_FAVOURITE_LIST_SUCCESS,
    errorMessageFavList: "",
    isFetching: false,
    favoriteList: data
  };
};

export const getFavouriteListFailure = errorMessageFavList => {
  return {
    type: GET_FAVOURITE_LIST_FAILURE,
    errorMessageFavList,
    isFetching: false,
    favoriteList: {}
  };
};

export const getFavouriteList = (baseUrl, params) => {
  const url = generateUrl(baseUrl, `/favourite/list`);

  return dispatch => {
    dispatch(getFavouriteListRequest());
    return apiPost({ url, params })
      .then(response =>
        dispatch(getFavouriteListSuccess(get(response, "data", [])))
      )
      .catch(err => dispatch(getFavouriteListFailure(get(err, "message", []))));
  };
};

export const getRelatedContentRequest = () => {
  return {
    type: GET_RELATED_CONTENT_REQUEST,
    isFetchingRelatedContent: true,
    errorMessage: ""
  };
};

export const getRelatedContentSuccess = (data, itemId) => {
  return {
    type: GET_RELATED_CONTENT_SUCCESS,
    errorMessage: "",
    isFetchingRelatedContent: false,
    relatedContent: data,
    itemId: itemId
  };
};

export const getRelatedContentFailure = errorMessage => {
  return {
    type: GET_RELATED_CONTENT_FAILURE,
    errorMessage,
    isFetchingRelatedContent: false,
    relatedContent: {},
    itemId: ""
  };
};

export const getRelatedContent = (
  baseUrl,
  contentLimit,
  age,
  deviceModel,
  itemId,
  countryCode,
  apiVerson
) => {
  let apiurl = baseUrl + "/manifests/v1572504565";
  if (apiVerson) {
    apiurl = baseUrl + "/manifests/" + apiVerson;
  }
  const url = generateUrl(
    apiurl,
    `/titles?limit=${contentLimit}&filter=related&age=${age}&deviceModel=${deviceModel}&itemId=${itemId}&countryCode=${countryCode}`
  );

  return dispatch => {
    dispatch(getRelatedContentRequest());
    return apiGet({ url })
      .then(response =>
        dispatch(getRelatedContentSuccess(get(response, "data", []), itemId))
      )
      .catch(err =>
        dispatch(getRelatedContentFailure(get(err, "message", [])))
      );
  };
};

export const userDashboardRequest = () => ({
  type: USER_DASHBOARD_REQUEST,
  isFetchingParentDashboard: true,
  errorMessage: ""
});

export const userDashboardSuccess = data => ({
  type: USER_DASHBOARD_SUCCESS,
  isFetchingParentDashboard: false,
  errorMessage: "",
  data
});

export const userDashboardFailure = errorMessage => ({
  type: USER_DASHBOARD_FAILURE,
  isFetchingParentDashboard: false,
  errorMessage
});

export const userDashboard = (baseUrl, userId, selectedChildId) => {
  return dispatch => {
    dispatch(userDashboardRequest());
    return apiGet({
      url: generateUrl(
        baseUrl,
        `/users/${userId}/dashboard/${selectedChildId}?platform=${platform}`
      )
    })
      .then(response =>
        dispatch(userDashboardSuccess(get(response, "data", [])))
      )
      .catch(err => dispatch(userDashboardFailure(get(err, "message", []))));
  };
};
