import React from "react";
import PropTypes from "prop-types";
// import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { PATHS } from "constants/url";
import { DIALOG_IDS } from "../../constants";
import { selectBaseUrl } from "selectors";
import { selectEventMessage } from "selectors/event";
import { getMyProfile } from "actions/myProfile";
import { eventBasedContent } from "actions/event";
import Loading from "../Loading";
import BumperScreen from "../BumperScreen";
import EventMessage from "../EventMessage";
import GetStarted from "../GetStarted";
import AllRoutes from "./AllRoutes";
import CleverResourceRoutes from "./CleverResourceRoutes";
import styles from "./_styles.module.scss";
import get from "lodash/get";
import { isMobile } from "react-device-detect";
import { GetStartedClicked, launchDialog } from "actions/settings";
import { selectUserId, selectIsCleverResource } from "selectors/profile";
import { readApiVersion, loadingScreenMessage } from "actions/common";
import { DIRECTLY_ALLOWED_LINKS } from "constants/index";
import Cookies from "js-cookie";
const queryString = require("query-string");

class AuthenticatedContentContainer extends React.Component {
  static propTypes = {
    getProfile: PropTypes.func,
    baseUrl: PropTypes.string,
    launchEventMessage: PropTypes.func,
    loadingScreenMessage: PropTypes.func
  };

  static defaultProps = {
    getProfile() {},
    baseUrl: "",
    launchEventMessage() {},
    loadingScreenMessage() {}
  };

  state = {
    universalPath: "",
    queryPath: ""
  };

  UNSAFE_componentWillMount() {
    let parsed = queryString.parse(window.location.search);
    if (parsed.view) {
      let sitePath, redirectPath;
      if (DIRECTLY_ALLOWED_LINKS.indexOf(parsed.view) !== -1) {
        sitePath = `${parsed.view}`;
      } else if (parsed.id && parsed.child) {
        sitePath = `${parsed.child}/${parsed.view}/${parsed.id}`;
      } else if (parsed.child) {
        sitePath = `${parsed.child}/${parsed.view}`;
      } else {
        sitePath = '/';
        redirectPath = window.location.search;
      }
      this.setState({
        universalPath: sitePath,
        queryPath: redirectPath
      });
    }
  }

  componentDidMount() {
    const { getProfile, baseUrl, getApiVersion, userId, getEventBasedContent, loadingScreenMessage, history } = this.props;
    const { universalPath, queryPath } = this.state;
    const subscriptionStatus = Cookies.get("subscriptionStatus");
    if (baseUrl) {
      Cookies.remove("bumperScreenDisplay");
      getProfile(baseUrl);
      if (subscriptionStatus === "Trial") {
        loadingScreenMessage(baseUrl);
      }
      getEventBasedContent(baseUrl);
      if (userId) {
        getApiVersion(baseUrl, "web-" + userId);
      }
    }
    if (queryPath && universalPath) {
      history.push({
        pathname: universalPath,
        search: queryPath
      });
    } else if (universalPath) {
      history.push({
        pathname: universalPath
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { baseUrl, getProfile, getApiVersion, userId, getEventBasedContent, launchEventMessage, eventMessage } = this.props;
    if (baseUrl && baseUrl !== prevProps.baseUrl) {
      getProfile(baseUrl);
      getEventBasedContent(baseUrl);
    }
    if (eventMessage.title && eventMessage !== prevProps.eventMessage) {
      launchEventMessage({
        id: DIALOG_IDS.EVENT_MESSAGE,
        data: {}
      });
    }
    if (userId && prevProps.userId !== userId) {
      getApiVersion(baseUrl, "web-" + userId);
    }
  }

  getStartedClicked = () => {
    const { history, GetStartedClicked } = this.props;
    GetStartedClicked(true);
    history.push(PATHS.HOME);
  };

  render() {
    const { baseUrl, reactLogin, getStartedClicked, userId, isCleverResource } = this.props;
    let flag = false;
    const subscriptionStatus = Cookies.get("subscriptionStatus");
    return (
      <React.Fragment>
        {isMobile &&
        !reactLogin &&
        !getStartedClicked &&
        flag ? (
          <GetStarted getStartedClicked={this.getStartedClicked} />
        ) : (baseUrl) ? (
          <div
            className={styles.routes_wrapper}
          >
            {userId ? (
              !isCleverResource ? (
                <AllRoutes />
              ) : (
                <CleverResourceRoutes cleverResource={isCleverResource} />
              )
            ) : (
              <Loading />
            )}
            <BumperScreen />
            {(subscriptionStatus === "Subscriber") && <EventMessage /> }
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  baseUrl: selectBaseUrl(state),
  reactLogin: get(state, "login.reactLogin"),
  getStartedClicked: get(state, "settings.getStartedClicked"),
  userId: selectUserId(state),
  eventMessage: selectEventMessage(state),
  isCleverResource: selectIsCleverResource(state)
});

const mapDispatchToProps = dispatch => ({
  getProfile(baseUrl) {
    dispatch(getMyProfile(baseUrl));
  },
  getApiVersion(baseUrl, deviceIDnew) {
    dispatch(readApiVersion(baseUrl, deviceIDnew));
  },
  GetStartedClicked(params) {
    dispatch(GetStartedClicked(params));
  },
  getEventBasedContent(baseUrl) {
    dispatch(eventBasedContent(baseUrl));
  },
  launchEventMessage(params) {
    dispatch(launchDialog(params));
  },
  loadingScreenMessage(baseUrl) {
    dispatch(loadingScreenMessage(baseUrl));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AuthenticatedContentContainer)
);
