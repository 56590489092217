import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import InfiniteScroll from "react-bidirectional-infinite-scroll";
import get from "lodash/get";

import SidebarHeaderTemplate from "components/Templates/SidebarHeaderTemplate";
import CustomButton from "components/Global/Button";
import Image from "components/Global/Image";
import {
  selectDashboardManifestData,
  selectItemTypes,
  selectCountryCode
} from "selectors/dashboard";
import { selectSelectedChildId, selectSelectedChild } from "selectors/children";
import styles from "./_styles.module.scss";
import classes from "components/Dashboard/_Dashboard.module.scss";
import { selectUser } from "selectors/profile";

import Loading from "../Loading";
import Tile from "../Dashboard/Tile";
import {
  deviceInfo,
  successfulSearch,
  unsuccessfulSearch,
  lockedTitleTapped
} from "../../helper.js";
import { webAppEvent } from "actions/common";
import LockedPopup from "components/Dashboard/LockedPopup";
import Error from "../Error";
import { isMobile } from "react-device-detect";
import c from "classnames";
import Cookies from "js-cookie";
import { cookieParams } from "utils";

let apiErrorMessageText = "";
class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchContainer: true,
      searchText: "",
      searchPage: true,
      searchHeader: false,
      error: "",
      button: {
        buttonConfig: {
          type: "submit"
        },
        text: "Search"
      },
      dataarray: [],
      data: [],
      items: [],
      length: 20,
      showLockedImage: false,
      okbutton:false,
      itemsClicked: false
    };
  }

  static propTypes = {
    dataElement: PropTypes.object,
    dashboardManifestData: PropTypes.array,
    selectedChildId: PropTypes.string
  };

  static defaultProps = {
    dataElement: {},
    dashboardManifestData: [],
    selectedChildId: ""
  };

  onHandleChange = e => {
    this.setState({
      searchText: e.target.value.toLowerCase()
    });
  };

  componentDidMount() {
    const { dashboardManifestData } = this.props;
    if(Cookies.get("prevPageforPolicy")!=="Search"){      
      Cookies.set("searchText","", cookieParams);
      Cookies.remove("searchText");
    }
    Cookies.set("prevPageforPolicy", "Search", cookieParams);
    const text= Cookies.get("searchText");
    if(text && text !=="undefined" && text !=="" && dashboardManifestData.length>0){
      Cookies.set("searchText","", cookieParams);
      Cookies.remove("searchText");
      this.setState({
        searchText:text
      })
      let val;
      this.onSearch(val,text);
    }
  };

  componentDidUpdate(prevProps) {
    let scrollPosition = Cookies.get("scrollPosition");
    let tiles_wrapper = document.getElementsByClassName("tiles_wrapper")[0];
    if (tiles_wrapper) {
      tiles_wrapper.getElementsByTagName("div")[0].scrollLeft = scrollPosition;
    }
  }
  // componentDidUpdate(prevProps) {
  //   const { dashboardManifestData } = this.props;
  //   const { length, searchText, dataarray, itemsClicked } = this.state;
  //   if (
  //     dashboardManifestData &&
  //     dashboardManifestData !== prevProps.dashboardManifestData
  //   ) {
  //     if (searchText !== "" && searchText.length > 1) {
  //       for (let i = 0; i < dashboardManifestData.length; i++) {
  //         let itemExist = dataarray.filter(function(item) {
  //           return item['id'] === dashboardManifestData[i].id;
  //         });
  //         if (
  //           dashboardManifestData[i].title
  //             .toLowerCase()
  //             .indexOf(searchText.trim().toLowerCase()) !== -1 && itemExist.length === 0
  //         ) {
  //           dataarray.push(dashboardManifestData[i]);
  //         }
  //         else if (
  //           dashboardManifestData[i].searchKeywords && dashboardManifestData[i].searchKeywords.find(keyword => keyword.toLowerCase().includes(searchText.trim().toLowerCase())) && itemExist.length === 0
  //         ) {
  //           dataarray.push(dashboardManifestData[i]);
  //         }
  //       }
  //       if (dataarray.length && !itemsClicked) {
  //         this.setState({
  //           data: dataarray,
  //           items: dataarray,
  //           itemsClicked: true,
  //           dataarray: []
  //         });
  //       }
  //       if (!dataarray.length) {
  //         this.setState({
  //           data: dataarray,
  //           items: [].concat(dataarray.slice(0, 0 + length)),
  //           dataarray: []
  //         });
  //       }
  //     }
  //   }
  // }
  onSearch = (e,text) => {
    if(e !== undefined){
     e.preventDefault();
    }
    const { dataarray, searchText, length } = this.state;
    const { dashboardManifestData } = this.props;
    let Searchtext=searchText;
    if(text){
      Searchtext=text;
    }
    Cookies.set("searchText",Searchtext, cookieParams);
    if (Searchtext.trim() !== "" && Searchtext.trim().length > 1) {
      for (let i = 0; i < dashboardManifestData.length; i++) {
        if (
          dashboardManifestData[i].title
            .toLowerCase()
            .indexOf(Searchtext.trim().toLowerCase()) !== -1
        ) {
          dataarray.push(dashboardManifestData[i]);
        }
        else if (
          dashboardManifestData[i].searchKeywords && dashboardManifestData[i].searchKeywords.find(keyword => keyword.toLowerCase().includes(Searchtext.trim().toLowerCase()))
        ) {
          dataarray.push(dashboardManifestData[i]);
        }
      }
      let scrollPosition = Cookies.get("scrollPosition");
      let itemLength = Cookies.get("itemLength");
      if (scrollPosition && scrollPosition !== 0 && itemLength) {
        this.setState({
          items: [].concat(dataarray.slice(0, itemLength))
        });
      }
      else {
        this.setState({
          items: [].concat(dataarray.slice(0, length))
        });
      }
      this.setState({
        searchContainer: false,
        error: "",
        data: dataarray,
        dataarray: [],
        searchHeader: true
      });
      dataarray.length > 0 && this.handleSuccessfulSearch();
      dataarray.length < 1 && this.handleUnsuccessfulSearch();
    } else {
      if(isMobile){
        apiErrorMessageText="Search text should have minimum two characters";
        this.setState({
         okbutton:true
        });
      }
      else{
      this.setState({
        error: "Search text should have minimum two characters"
      });
     }
    }
  };

  handleUnsuccessfulSearch = () => {
    const { webAppEvent, kafkaUrl, profile, countryCode } = this.props;
    const { searchText, dataarray } = this.state;
    let params = [
      {
        ...deviceInfo,
        ...unsuccessfulSearch(profile, searchText.trim(), dataarray.length, countryCode)
      }
    ];
    webAppEvent(kafkaUrl, params);
  };

  handleSuccessfulSearch = () => {
    const { webAppEvent, kafkaUrl, profile, countryCode } = this.props;
    const { searchText, dataarray } = this.state;
    let params = [
      {
        ...deviceInfo,
        ...successfulSearch(profile, searchText.trim(), dataarray.length, countryCode)
      }
    ];
    webAppEvent(kafkaUrl, params);
  };

  /*  componentDidMount() {
    const { length } = this.state;
    this.setState({
      searchHeader: true
    });
  } */

  getItems = (totalItemsInArray, length) => {
    const { data } = this.state;
    return data.slice(totalItemsInArray, totalItemsInArray + length);
  };

  goBackToSearch = () => {
    this.setState({
      searchContainer: true,
      searchText: "",
      searchHeader: false,
      dataarray: [],
      data: [],
      items: []
    });
    Cookies.set("searchText","", cookieParams);
    Cookies.remove("searchText");
  };

  goBackToSearchWithKeyword = () => {
    this.setState({
      searchContainer: true,
      searchHeader: false,
      dataarray: [],
      data: [],
      items: []
    });
  };

  handleHorizontalScroll = (position, previousPosition) => {
    let { items, length } = this.state;
    Cookies.set("scrollPosition", position, cookieParams);
    Cookies.set("itemLength", items.length, cookieParams);
    let scrollablePosition = document.getElementsByClassName("tiles_wrapper")[0].getElementsByTagName("div")[0].scrollWidth - document.getElementsByClassName("tiles_wrapper")[0].offsetWidth;
    if (scrollablePosition - position < 50) {
      items = items.concat(this.getItems(items.length, length));
      this.setState({ items });
    }
  }

  onClose = () => {
    this.setState({
      showLockedImage: false
    });
  };

  onClickLocked = item => {
    this.setState({
      showLockedImage: true
    });
    this.handleLockedTitleTapped(item);
  };

  handleLockedTitleTapped = item => {
    const {
      webAppEvent,
      kafkaUrl,
      itemTypes,
      selectedChild,
      profile,
      countryCode
    } = this.props;
    switch (item.type) {
      case itemTypes.videos:
        item.contentType = "video";
        break;
      case itemTypes.books:
        item.contentType = "book";
        break;
      case itemTypes.games:
        item.contentType = "game";
        break;
      default:
        item.contentType = "collection";
        break;
    }
    let params = [
      {
        ...lockedTitleTapped(profile, item, selectedChild, countryCode)
      }
    ];
    webAppEvent(kafkaUrl, params);
  };

  closeErrorpop = () => {
    this.setState({ okbutton: false });
    apiErrorMessageText = "";
  };

  updateTile = (id, status) => {
    const { items } = this.state;
    let clickedItem = items.filter(function(item) {
      return item['id'] === id;
    });

    if (status) {
      clickedItem['0']['favorite'] = 0;
    }
    else {
      clickedItem['0']['favorite'] = 1;
    }

    let updatedItem = items.map((item, index) => {
      if (item['id'] === id) {
        return clickedItem['0'];
      }
      return item;
    });

    this.setState({
      items: updatedItem,
      itemsClicked: false
    });
  }

  render() {
    const {
      searchContainer,
      searchText,
      error,
      button,
      items,
      searchHeader,
      showLockedImage,
      searchPage,
      okbutton
    } = this.state;
    const { dashboardManifestData, errorMessageApi ,errorMessageProfileApi } = this.props;
    let item1,
      item2 = null;
    const length = this.state.items.length;
    let count = 0;
    dashboardManifestData.map(dataElement => {
      if (dataElement.favorite === 1) {
        count++;
      }
      return count;
    });
    if (
      errorMessageApi !== null &&
      errorMessageApi !== "" &&
      errorMessageApi !== undefined
    ) {
      apiErrorMessageText = errorMessageApi;
    }
    else if (
      errorMessageProfileApi !== null &&
      errorMessageProfileApi !== "" &&
      errorMessageProfileApi !== undefined
    ) {
      apiErrorMessageText= errorMessageProfileApi;
    }
    return (
      <React.Fragment>
        {(apiErrorMessageText !== "" && apiErrorMessageText !== undefined) && (
          <Error errorMessage={apiErrorMessageText} okButton={okbutton} closeErrorpop={this.closeErrorpop}/>
        )}
        <React.Fragment>
          {dashboardManifestData.length < 1 && <Loading loading="loading" />}
          <SidebarHeaderTemplate
            searchHeader={searchHeader}
            searchPage={searchPage}
            searchText={searchText.trim()}
            goBackToSearch={this.goBackToSearch}
            goBackToSearchWithKeyword={this.goBackToSearchWithKeyword}
            favItem={count}
            backButton={false}
          >
            {searchContainer ? (
              <div>
                <div className={styles.input_fields}>
                  <div className={styles.search_text_with_error}>
                    <form onSubmit={this.onSearch}>
                      <div className={styles.search_text_wrapper}>
                        <div className={styles.input_wrapper}>
                          <input
                            type="text"
                            className={styles.search_text}
                            onChange={this.onHandleChange}
                            value={searchText || ""}
                            placeholder="Type search term"
                          />
                          {searchText && <Image
                            folderName="static"
                            fileName="cross_orange"
                            className={styles.clear_text}
                            onClick={this.goBackToSearch}
                          />}
                        </div>
                        <CustomButton
                          config={button.buttonConfig}
                          content={button.text}
                          onClick={this.onSearch}
                          className={styles.search_button}
                        />
                      </div>
                    </form>
                    <span className="error">{error}</span>
                  </div>
                </div>
              </div>
            ) : (
              <React.Fragment>
                {items && items.length ? (
                  <div className={c(
                    "tiles_wrapper",
                    classes.tiles_wrapper
                  )}>
                    <InfiniteScroll
                      onScroll={this.handleHorizontalScroll}
                      horizontal
                    >
                      {items.map((dataElement, index) => {
                        if (index % 2 === 0) {
                          item1 = dataElement;
                        } else {
                          item2 = dataElement;
                        }
                        if (index % 2 !== 0) {
                          return (
                            <div
                              key={index}
                              className={classes.tiles_wrapper_inner}
                            >
                              <Tile
                                dataElement={item1}
                                onClickLocked={this.onClickLocked}
                                updateTile={(id, status) => this.updateTile(id, status)}
                              />
                              <Tile
                                dataElement={item2}
                                onClickLocked={this.onClickLocked}
                                updateTile={(id, status) => this.updateTile(id, status)}
                              />
                            </div>
                          );
                        } else if (length === index + 1) {
                          return (
                            <div
                              key={index}
                              className={classes.tiles_wrapper_inner}
                            >
                              <Tile
                                dataElement={item1}
                                onClickLocked={this.onClickLocked}
                                updateTile={(id, status) => this.updateTile(id, status)}
                              />
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </InfiniteScroll>
                  </div>
                ) : (
                  <div className={styles.no_result}>No Records Found</div>
                )}
              </React.Fragment>
            )}
          </SidebarHeaderTemplate>
          <LockedPopup
            showLockedImage={showLockedImage}
            onClose={this.onClose}
          />
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = state => ({
  dashboardManifestData: selectDashboardManifestData(state),
  selectedChildId: selectSelectedChildId(state),
  profile: selectUser(state),
  kafkaUrl: get(state, "appManifest.data.app_settings.kafka_url"),
  errorMessageApi: get(state, "dashboardList.errorMessage"),
  itemTypes: selectItemTypes(state),
  errorMessageProfileApi: get(state, "myProfile.errorMessage"),
  selectedChild: selectSelectedChild(state),
  countryCode: selectCountryCode(state)
});

export const mapDispatchToProps = dispatch => ({
  webAppEvent(url, params) {
    dispatch(webAppEvent(url, params));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Search)
);
