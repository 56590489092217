import deepFreeze from "deep-freeze-es6";
import {
  LAUNCH_DIALOG,
  CLOSE_DIALOG,
  AGECHCEK_DIALOG,
  ROUTE_VALUE,
  GETSTARTED_CLICKED
} from "constants/actions";

const initialState = {
  dialog: {
    id: "",
    data: {}
  },
  AgeCheckShow: false,
  RouteValue: "",
  ScreenOrientation: "",
  getStartedClicked: false
};

export const launchDialog = (state, action = {}) => {
  const { id, data } = action;
  return id
    ? {
        ...state,
        dialog: {
          ...state.dialog,
          id,
          data
        }
      }
    : state;
};

export const closeDialog = (state, action = {}) => ({
  ...state,
  dialog: {
    ...initialState.dialog
  }
});
export const launchAgeCheck = (state, action = {}) => {
  const { AgeCheckShow } = action;
  return {
    ...state,
    AgeCheckShow
  };
};
export const routeValuecheck = (state, action = {}) => {
  const { RouteValue } = action;
  return {
    ...state,
    RouteValue
  };
};

export const GetStartedClicked = (state, action = {}) => {
  const { getStartedClicked } = action;
  return {
    ...state,
    getStartedClicked
  };
};
export default (state = initialState, action) => {
  deepFreeze(state);
  deepFreeze(action);

  switch (action.type) {
    case LAUNCH_DIALOG:
      return launchDialog(state, action);
    case CLOSE_DIALOG:
      return closeDialog(state, action);
    case AGECHCEK_DIALOG:
      return launchAgeCheck(state, action);
    case ROUTE_VALUE:
      return routeValuecheck(state, action);
    case GETSTARTED_CLICKED:
      return GetStartedClicked(state, action);
    default:
      return state;
  }
};
