import { combineReducers } from "redux";
import appManifest from "./appManifest";
import myProfile from "./myProfile";
import children from "./children";
import dashboardList from "./dashboardList";
import settings from "./settings";
import favourites from "./favourites";
import common from "./common";
import login from "./login";
import event from "./event";
import epub from "./epub";

export default combineReducers({
  appManifest,
  myProfile,
  children,
  dashboardList,
  settings,
  favourites,
  common,
  login,
  event,
  epub
});
