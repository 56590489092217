import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import get from "lodash/get";
import c from "classnames";
import Cookies from "js-cookie";

import { setSelectedChildId, getCountryList } from "actions/children";
import { selectAllCountryUrl, selectSelectedChildId } from "selectors/children";
import { PATHS } from "constants/url";
import { WHO_IS_PLAYING_SCREEN_TYPES } from "constants/index";
import basicTemplateClasses from "components/Templates/_BasicTemplate.module.scss";

import AgeCheck from "../AgeCheck";
import { launchAgeCheck, routeValuecheck } from "actions/settings";

import classes from "./_styles.module.scss";
import styles from "./_parentalChildren.module.scss";
import layoverClasses from "../Sidebar/_WhoIsPlayingLayover.module.scss";
import WhoIsPlayingHeader from "./WhoIsPlayingHeader";
import Avatars from "./Avatars";
import AvatarActions from "./AvatarActions";
import CountryList from "./CountryList";
import { Link } from "react-router-dom";
import Image from "components/Global/Image";
import { setScreenOrientation, cookieParams } from "utils";
import { PAGE_MODE } from "constants/screens";
import { isTablet } from "react-device-detect";
const queryString = require("query-string");

let countries = [];
class WhoIsPlaying extends Component {
  static propTypes = {
    addAChildText: PropTypes.string,
    children: PropTypes.array,
    editDeleteText: PropTypes.string,
    headingText: PropTypes.string,
    userId: PropTypes.string.isRequired,
    selectChild: PropTypes.func,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      goBack: PropTypes.func.isRequired
    }),
    onClose: PropTypes.func,
    isLayover: PropTypes.bool,
    screenType: PropTypes.string,
    subHeadingText: PropTypes.string,
    subHeadingDescription: PropTypes.string,
    closeIconClass: PropTypes.string,
    fetchCountryListUrl: PropTypes.string
  };

  static defaultProps = {
    addAChildText: "Add a child",
    children: [],
    editDeleteText: "Edit/Delete",
    userId: "",
    headingText: "",
    selectChild() {},
    history: {
      push() {},
      goBack() {}
    },
    onClose() {},
    isLayover: false,
    screenType: WHO_IS_PLAYING_SCREEN_TYPES.HOME,
    subHeadingText: "",
    subHeadingDescription: "",
    closeIconClass: "close_icon_right",
    fetchCountryListUrl: ""
  };

  state = {
    isCountry: false,
    editChildFlag: false,
    RouteValue: "",
    editchildid: ""
  };

  componentDidMount() {
    const { screenType } = this.props;
    let parsed = queryString.parse(window.location.search);
    if (screenType === WHO_IS_PLAYING_SCREEN_TYPES.HOME && parsed.params === "countries") {
      this.getListOfCountry();
    }
    if (parsed.params === "countries") {
      this.setState({
        isCountry: true
      });
    }
    if (parsed.view && parsed.view === "edit-child") {
      this.setState({
        editChildFlag: true
      });
    }
    if (screenType === WHO_IS_PLAYING_SCREEN_TYPES.MY_CHILDREN) {
      this.setState({
        editChildFlag: true
      });
    }
    if (screenType === WHO_IS_PLAYING_SCREEN_TYPES.HOME) {
      localStorage.removeItem("manifestdata");
    }
  }

  getListOfCountry = async () => {
    const { fetchCountryListUrl } = this.props;
    let response = await getCountryList(fetchCountryListUrl);
    countries = response;
  };

  addChild = () => {
    const { routeValuecheck, launchAgeChecked = {} ,screenType, history} = this.props;
    if (screenType === WHO_IS_PLAYING_SCREEN_TYPES.MY_CHILDREN) {
      if(isTablet) {
        setScreenOrientation(PAGE_MODE.TABLET);
      } 
      else{
        setScreenOrientation(PAGE_MODE.ADD_CHILD);
      }
      history.push(`/add-child`);
    }
    else{
    routeValuecheck("add-child");
    launchAgeChecked(true);
    }
  };

  editChild = () => {
    const { editChildFlag } = this.state;
    this.setState({
      editChildFlag: !editChildFlag
    });
  };

  onClose = () => {
    const {
      screenType,
      onClose,
      history,
      onSelectChild,
      selectedChildId
    } = this.props;

    if (screenType === WHO_IS_PLAYING_SCREEN_TYPES.LAYOVER) {
      onClose();
    } else if (screenType === WHO_IS_PLAYING_SCREEN_TYPES.MY_CHILDREN) {
      const { children, selectChild } = this.props;
      const id = get(children, "[0].id");
      if(isTablet){
        setScreenOrientation(PAGE_MODE.TABLET);
      }
      else{
      setScreenOrientation(PAGE_MODE.PARENT_AREA);
      }
      selectedChildId && history.push(`/${selectedChildId}/parent-area`);
      !selectedChildId && id && history.push(`/${id}/parent-area/`);
      !selectedChildId && id && selectChild(id);
      if (!id && !selectedChildId) {
        setScreenOrientation(PAGE_MODE.HOME);
        history.push(`/`);
      }
    } else if (screenType === WHO_IS_PLAYING_SCREEN_TYPES.PARENT_DASHBOARD) {
      if(isTablet){
        setScreenOrientation(PAGE_MODE.TABLET);
      }else{
      setScreenOrientation(PAGE_MODE.PARENT_DASHBOARD);
      }
      onSelectChild();
    } else {
      const { children, selectChild } = this.props;
      const id = get(children, "[0].id");
      setScreenOrientation(PAGE_MODE.DASHBOARD);
      if (selectedChildId) {
        selectChild(selectedChildId);
        history.push(`/${selectedChildId}/dashboard/`);
      } else {
        id && selectChild(id);
        id && history.push(`/${id}/dashboard/`);
      }
    }
  };

  onChildClick = id => {
    Cookies.set("showAnimation", "TRUE", cookieParams);
    const {
      selectChild,
      history,
      screenType,
      onSelectChild,
      onClose
    } = this.props;
    const { editChildFlag } = this.state;
    sessionStorage.setItem("screenType", screenType);
    if (editChildFlag) {
      let parsed = queryString.parse(window.location.search);
      if (screenType === WHO_IS_PLAYING_SCREEN_TYPES.MY_CHILDREN || parsed.view) {
        if(isTablet) {
          setScreenOrientation(PAGE_MODE.TABLET);
        } 
        else{
        setScreenOrientation(PAGE_MODE.EDIT_CHILD);}
        history.push(`/${id}/edit-child`);
      } else {
        this.setState({
          editchildid: id
        });
        const { routeValuecheck, launchAgeChecked = {} } = this.props;
        routeValuecheck("edit-child");
        launchAgeChecked(true);
      }
    } else {
      if (
        screenType === WHO_IS_PLAYING_SCREEN_TYPES.HOME
      ) {
        let parsed = queryString.parse(window.location.search);
        if (parsed.view) {
          let redirectPath = parsed.view;
          if (parsed.id) {
            redirectPath = `${parsed.view}/${parsed.id}`;
          }
          selectChild(id);
          history.push(`/${id}/${redirectPath}`);
        }
        else {
          setScreenOrientation(PAGE_MODE.DASHBOARD);
          selectChild(id);
          history.push(`/${id}/dashboard/`);
        }
      }
      else if (screenType === WHO_IS_PLAYING_SCREEN_TYPES.LAYOVER) {
        setScreenOrientation(PAGE_MODE.DASHBOARD);
        selectChild(id);
        history.push(`/${id}/dashboard/`);
        onClose();
        document.getElementsByClassName('tiles_wrapper')['0'].children[0].scrollLeft = 0;
      }
      else if (screenType === WHO_IS_PLAYING_SCREEN_TYPES.MY_CHILDREN) {
        if(isTablet) {
          setScreenOrientation(PAGE_MODE.TABLET);
        } 
        else{
        setScreenOrientation(PAGE_MODE.EDIT_CHILD);}
        selectChild(id);
        history.push(`/${id}/edit-child/`);
      }
      else if (screenType === WHO_IS_PLAYING_SCREEN_TYPES.PARENT_DASHBOARD) {
        if(isTablet){
          setScreenOrientation(PAGE_MODE.TABLET);
        }else{
        setScreenOrientation(PAGE_MODE.PARENT_DASHBOARD);
        }
        history.push(`/${id}/parent-dashboard/`);
        onSelectChild();
      }
    }
  };

  getClassName = classname => {
    return c([
      basicTemplateClasses[classname],
      classes[classname],
      {
        [layoverClasses[classname]]:
          this.props.screenType === WHO_IS_PLAYING_SCREEN_TYPES.LAYOVER
      },
      {
        [styles[classname]]:
          this.props.screenType === WHO_IS_PLAYING_SCREEN_TYPES.MY_CHILDREN
      }
    ]);
  };

  privacyClick = () => {
    if(isTablet){
      setScreenOrientation(PAGE_MODE.TABLET);
    } else{
      setScreenOrientation(PAGE_MODE.PRIVACY_POLICY);       
    }
  };

  render() {
    const {
      headingText,
      children,
      subHeadingText,
      subHeadingDescription,
      screenType,
      addAChildText,
      editDeleteText,
      closeIconClass,
      selectedChildId,
      homePageData,
      headingTextProp
    } = this.props;
    if (screenType === WHO_IS_PLAYING_SCREEN_TYPES.MY_CHILDREN) {
      Cookies.set("prevPageforPolicy", "myChildren", cookieParams);
    } else {
      Cookies.set("prevPageforPolicy", "whosePlaying", cookieParams);
    }
    const { isCountry, editChildFlag, editchildid } = this.state;
    const className = c(
      basicTemplateClasses.basic_template_content_wrapper,
      classes.who_is_playing_wrapper,
      {
        [layoverClasses.who_is_playing_wrapper]:
          screenType === WHO_IS_PLAYING_SCREEN_TYPES.LAYOVER
      },
      {
        [styles.who_is_playing_wrapper]:
          screenType === WHO_IS_PLAYING_SCREEN_TYPES.MY_CHILDREN
      },
      {
        [styles.who_is_playing_wrapper]:
          screenType === WHO_IS_PLAYING_SCREEN_TYPES.PARENT_DASHBOARD
      },
      {
        [classes.wip_full_wrapper]:
          screenType !== WHO_IS_PLAYING_SCREEN_TYPES.LAYOVER
      }
    );

    let titleText = "";
    if (screenType === WHO_IS_PLAYING_SCREEN_TYPES.PARENT_DASHBOARD) {
      titleText = headingTextProp;
    } else {
      titleText = headingText;
    }

    return (
      <React.Fragment>
        <div className={className}>
          {isCountry && <CountryList countries={countries} />}
          <WhoIsPlayingHeader
            screenType={screenType}
            getClassName={this.getClassName}
            onClose={this.onClose}
            headingText={titleText}
            subHeadingText={subHeadingText}
            subHeadingDescription={subHeadingDescription}
            closeIconClass={closeIconClass}
          />
          <Avatars
            getClassName={this.getClassName}
            onChildClick={this.onChildClick}
            children={children}
            editChildFlag={editChildFlag}
            selectedChildId={selectedChildId}
            screenType={screenType}
          />
          {screenType !== WHO_IS_PLAYING_SCREEN_TYPES.PARENT_DASHBOARD && (
            <AvatarActions
              addChild={this.addChild}
              editChild={this.editChild}
              getClassName={this.getClassName}
              children={children}
              addAChildText={addAChildText}
              editDeleteText={editDeleteText}
              isLayover={screenType === WHO_IS_PLAYING_SCREEN_TYPES.LAYOVER}
              editChildFlag={editChildFlag}
            />
          )}
          {screenType === WHO_IS_PLAYING_SCREEN_TYPES.MY_CHILDREN ||
          screenType === WHO_IS_PLAYING_SCREEN_TYPES.HOME ? (
            <div className="privacy privacy_white" onClick={this.privacyClick}>
              <Link to={PATHS.PRIVACY_POLICY}>
                <Image
                  folderName="static"
                  fileName="info_privacy"
                  alt="Privacy Icon"
                />
                {homePageData["privacy_text"]}
              </Link>
            </div>
          ) : (
            ""
          )}
        </div>
        <AgeCheck selectedChildId={editchildid} onClose={this.onClose} />
      </React.Fragment>
    );
  }
}

export const mapStateToProps = state => ({
  headingText: get(state, "appManifest.data.who_is_playing.heading_text"),
  addAChildText: get(state, "appManifest.data.who_is_playing.add_a_child_text"),
  editDeleteText: get(
    state,
    "appManifest.data.who_is_playing.edit_delete_text"
  ),
  fetchCountryListUrl: selectAllCountryUrl(state),
  selectedChildId: selectSelectedChildId(state),
  homePageData: get(state, "appManifest.data.home_page")
});

export const mapDispatchToProps = dispatch => ({
  selectChild(id) {
    dispatch(setSelectedChildId(id));
  },
  launchAgeChecked(params) {
    dispatch(launchAgeCheck(params));
  },
  routeValuecheck(params) {
    dispatch(routeValuecheck(params));
  }
});

export { WhoIsPlaying as WhoIsPlayingComponent };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WhoIsPlaying)
);
