import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import { Link, withRouter } from "react-router-dom";
import c from "classnames";

import { PATHS } from "constants/url";
import LockedPopup from "components/Dashboard/LockedPopup";

import BasicTemplate from "../Templates/BasicTemplate";
import basicTemplateClasses from "../Templates/_BasicTemplate.module.scss";
import classes from "./_styles.module.scss";
import Image from "../Global/Image";
import Cookies from "js-cookie";
import { selectSelectedChildId } from "selectors/children";
import AgeCheck from "../AgeCheck";
import { launchAgeCheck, routeValuecheck } from "actions/settings";
import { userLogout, webAppEvent } from "actions/common";
import { setScreenOrientation, isLocalServer, cookieParams } from "utils";
import { PAGE_MODE } from "constants/screens";
import { logoutCookieClear } from "actions/login";
import {
  grownupMenu,
  parentDashboardVisited,
  deviceInfo
} from "../../helper.js";
import { selectUser } from "selectors/profile";
import { selectBaseUrl } from "selectors";
import { selectCountryCode } from "selectors/dashboard";
import { isTablet } from "react-device-detect";
import moment from "moment";
import envUrl from "envPath.json";

let startTime = null;
export const mapDispatchToProps = dispatch => ({
  launchAgeChecked(params) {
    dispatch(launchAgeCheck(params));
  },
  routeValuecheck(params) {
    dispatch(routeValuecheck(params));
  },
  userLogout() {
    dispatch(userLogout());
  },
  webAppEvent(url, params) {
    dispatch(webAppEvent(url, params));
  },
  logoutCookieClear(baseUrl) {
    dispatch(logoutCookieClear(baseUrl));
  }
});

class ParentsArea extends Component {
  state = {
    AgeCheckShow: false,
    showLockedImage:false,
    lockedParentDashboard: false
  };

  static propTypes = {
    parentsAreaScreenData: PropTypes.object,
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired
    }),
    baseUrl: PropTypes.string
  };

  static defaultProps = {
    parentsAreaScreenData: {},
    history: {
      goBack() {}
    },
    baseUrl: ""
  };

  componentDidMount() {
    const { dashboardSelectedChildId } = this.props;
    Cookies.set("searchText","", cookieParams);
    Cookies.remove("searchText");
    dashboardSelectedChildId &&
      Cookies.set("childId", dashboardSelectedChildId, cookieParams);
    const subscriptionStatus = Cookies.get("subscriptionStatus");
    if(subscriptionStatus === "Cancelled" ||
    subscriptionStatus === "Free Promotion Expired" ||
    subscriptionStatus === "Free"){
      this.setState({
        lockedParentDashboard: true
      });
    }
  }

  goBack = () => {
    const { history, match } = this.props;
    let childId = Cookies.get("childId");
    if (!childId) {
      childId = get(match, "params.childId", "");
      Cookies.set("childId", childId, cookieParams);
    }
    setScreenOrientation(PAGE_MODE.DASHBOARD);
    childId && history.push(`/${childId}/dashboard/`);
  };

  openParentDashboard = () => {
    const { selectedChildId, history } = this.props;
    const subscriptionStatus = Cookies.get("subscriptionStatus");
    if(subscriptionStatus === "Cancelled" ||
    subscriptionStatus === "Free Promotion Expired" ||
    subscriptionStatus === "Free"){
      this.setState({
        showLockedImage: true
      });
    }
    else{
    this.handleParentDashboardVisited();
    history.push(`/${selectedChildId}/parent-dashboard/`);
    }
  };

  onClose = () => {
    this.setState({
      showLockedImage: false
    });
  };

  openMyChildren = () => {
    const { history } = this.props;
    if (isTablet) {
      setScreenOrientation(PAGE_MODE.TABLET);
    } else {
      setScreenOrientation(PAGE_MODE.MY_CHILDREN);
    }
    history.push(`/my-children`);
  };

  logout = () => {
    const { history, userLogout, loginScreenData, logoutCookieClear, baseUrl, tokenName } = this.props;
    let tokenCookieParams = "";
    if (loginScreenData.access_token_domain && !isLocalServer()) {
      tokenCookieParams = {
        domain: loginScreenData.access_token_domain,
        sameSite: 'none',
        secure: true
      };
    }
    logoutCookieClear(baseUrl);
    Cookies.set(tokenName, " ", tokenCookieParams);
    Cookies.remove(tokenName, tokenCookieParams);
    Cookies.remove("clever_user", tokenCookieParams);
    Cookies.remove("childId");
    Cookies.remove("subscriptionStatus");
    Cookies.remove("prevPageforPolicy");
    Cookies.remove("child_age");
    history.push(PATHS.LOGIN);
    this.handleGrownupMenu();
    Cookies.remove("subscriptionType");
    Cookies.remove("categoryPath");
    Cookies.remove("collectionPath");
    userLogout();
  };

  handleGrownupMenu = () => {
    const { webAppEvent, kafkaUrl, profile } = this.props;
    let params = [
      {
        ...grownupMenu(profile)
      }
    ];
    webAppEvent(kafkaUrl, params);
  };

  handleParentDashboardVisited = () => {
    const { webAppEvent, kafkaUrl, profile, countryCode } = this.props;
    let endTime = new Date().getTime();
    const spentTime = (endTime - startTime) / 1000;
    let params = [
      {
        ...deviceInfo,
        ...parentDashboardVisited(profile, spentTime, countryCode)
      }
    ];
    webAppEvent(kafkaUrl, params);
  };

  profileClick = () => {
    const { parentsAreaScreenData } = this.props;
    const {
      my_profile_link: myProfileLink,
    } = parentsAreaScreenData;
    let updatedProfileLink = `${myProfileLink}&updated=${moment().valueOf()}`;
    window.open(updatedProfileLink, "_self").focus();
  }

  render() {
    Cookies.set("prevPageforPolicy", "ParentArea", cookieParams);
    const { parentsAreaScreenData } = this.props;
    const { lockedParentDashboard } = this.state;
    const {
      sub_heading_text: subHeadingText,
      buttons,
      privacy_text: privacyText,
      terms_text: termsText,
      coppa
    } = parentsAreaScreenData;
    return (
      <BasicTemplate>
        <div
          className={c(
            basicTemplateClasses.basic_template_content_wrapper,
            classes.parents_area_wrapper
          )}
        >
          <Image
            folderName="static"
            fileName="close_window"
            className={basicTemplateClasses.close_icon}
            onClick={this.goBack}
          />
          <Image
            className={classes.logo_image}
            folderName="ui_and_learning_area"
            fileName="curious_world_logo"
            alt="CW Logo"
          />
          <span className={classes.sub_heading_text}>{subHeadingText}</span>
            <div
              className={c(
                "app_button",
                classes.button,
                classes.parent_dashboard_button
              )}
              onClick={this.openParentDashboard}
            >
              {lockedParentDashboard && (
                <Image
                  className={classes.lock_image}
                  folderName="static"
                  fileName="lock"
                  alt="lock"
                />
              )}
              {buttons.parent_dashboard_text}
            </div>
            <div
              className={c(
                "app_button",
                classes.button,
                classes.my_profile_button
              )}
              onClick={this.profileClick}
            >
              {buttons.my_profile_text}
            </div>
            <div
              className={c(
                "app_button",
                classes.button,
                classes.my_children_button
              )}
              onClick={this.openMyChildren}
            >
              {buttons.my_children_text}
            </div>
          <div
            className={c("app_button", classes.button)}
            onClick={this.logout}
          >
            <Image
              className={classes.logout_image}
              folderName="ui_and_learning_area"
              fileName="logout"
              alt="Logout"
            />
            {buttons.logout_text}
          </div>
          <div className={classes.links}>
            <div className={classes.link}>
              <Link to={PATHS.PRIVACY_POLICY}>
                <Image
                  folderName="static"
                  fileName="info_privacy"
                  alt="Privacy Icon"
                />
                {privacyText}
              </Link>
            </div>
            <div className={classes.link}>
              <Link to={PATHS.TERMS_CONDITIONS}>
                <Image
                  folderName="static"
                  fileName="info_privacy"
                  alt="Privacy Icon"
                />
                {termsText}
              </Link>
            </div>
          </div>
        </div>
        <a href={coppa.link} target="_blank" rel="noopener noreferrer" className={classes.coppa} ><img border="0" alt={coppa.alt_text} src={coppa.img} /></a>
        <AgeCheck />
        <LockedPopup
              showLockedImage={this.state.showLockedImage}
              onClose={this.onClose}
            />
      </BasicTemplate>
    );
  }
}

export const mapStateToProps = (state, ownProps) => {
  const selectedChildId = get(ownProps, "match.params.childId");
  return {
    parentsAreaScreenData: get(state, "appManifest.data.parents_area"),
    selectedChildId: selectedChildId,
    dashboardSelectedChildId: selectSelectedChildId(state),
    profile: selectUser(state),
    kafkaUrl: get(state, "appManifest.data.app_settings.kafka_url"),
    baseUrl: selectBaseUrl(state),
    loginScreenData: get(state, "appManifest.data.login", {}),
    tokenName: envUrl.token_name,
    countryCode: selectCountryCode(state)
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ParentsArea)
);
