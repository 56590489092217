import React from "react";
import { connect } from "react-redux";
const queryString = require("query-string");

class CountryList extends React.Component {
  state = {
    selectedValue: ""
  };

  componentDidMount() {
    let parsed = queryString.parse(window.location.search);
    if (parsed.countryCode && parsed.countryCode.length >= 2) {
      this.setState({
        selectedValue: parsed.countryCode
      });
    }
  }

   handleChange = event => {
    this.setState({ selectedValue: event.target.value });
    localStorage.removeItem("manifestdata")
  }; 

  componentDidUpdate() {
    this.props.dispatch({
      type: "GET_COUNTRY_SUCCESS",
      payload: this.state.selectedValue
    });
  }

  render() {
     const { countries } = this.props;
    const { selectedValue } = this.state; 
    return (
      <div>
        <select value={selectedValue} onChange={this.handleChange}>
          {countries.map((country, index) => (
            <option key={index} value={country.code}>
              {country.name}
            </option>
          ))}
          ;
        </select>
      </div>
    );
  }
}

export default connect()(CountryList);
