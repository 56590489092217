import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { PATHS } from "../../constants/url";
import Login from "../Login";
import styles from "components/AuthenticatedContentContainer/_styles.module.scss";
import PrivacyPolicy from "../BasicPage/PrivacyPolicy";
import DirectAccessBook from "../DirectAccessBook";
const queryString = require("query-string");

class UnauthenticatedContentContainer extends Component {

  state = {
    queryPath: ""
  };

  UNSAFE_componentWillMount() {
    let parsed = queryString.parse(window.location.search);
    if (parsed.view) {
      this.setState({
        queryPath: window.location.search
      });
    }
  }

  componentDidMount() {
    const { queryPath } = this.state;
    let parsed = queryString.parse(queryPath);
    if (parsed.view && parsed.view === "privacy-policy") {
      this.props.history.push({
        pathname: '/privacy-policy'
      });
    } else if (parsed.view) {
      this.props.history.push({
        pathname: '/login',
        search: queryPath
      });
    }
  }

  render() {
    return (
      <div
        className={styles.routes_wrapper}
      >
        <Switch>
          <Route exact path={PATHS.DIRECT_ACCESS_BOOK} component={DirectAccessBook} />
          <Route exact path={PATHS.PRIVACY_POLICY} component={PrivacyPolicy} />
          <Route exact path={PATHS.LOGIN} component={Login} />
          <Route render={() => <Redirect to={{ pathname: PATHS.LOGIN }} />} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(UnauthenticatedContentContainer);
