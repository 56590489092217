export const ORIENTATION = {
  LANDSCAPE: "landscape",
  PORTRAIT: "portrait"
};

export const PAGE_MODE = {
  DASHBOARD: ORIENTATION.LANDSCAPE,
  TERMS_CONDITIONS: ORIENTATION.PORTRAIT,
  PRIVACY_POLICY: ORIENTATION.PORTRAIT,
  ADD_CHILD: ORIENTATION.PORTRAIT,
  EDIT_CHILD: ORIENTATION.PORTRAIT,
  DELETE_CHILD: ORIENTATION.PORTRAIT,
  CATEGORY: ORIENTATION.LANDSCAPE,
  HOME: ORIENTATION.LANDSCAPE,
  PARENT_AREA: ORIENTATION.PORTRAIT,
  PARENT_DASHBOARD: ORIENTATION.PORTRAIT,
  MY_CHILDREN: ORIENTATION.PORTRAIT,
  GAME: ORIENTATION.LANDSCAPE,
  BOOK: ORIENTATION.LANDSCAPE,
  VIDEO: ORIENTATION.LANDSCAPE,
  COLLECTION: ORIENTATION.LANDSCAPE,
  SEARCH: ORIENTATION.LANDSCAPE,
  LOGIN: ORIENTATION.PORTRAIT,
  FAVOURITES: ORIENTATION.LANDSCAPE,
  TABLET:ORIENTATION.LANDSCAPE
};
