import InputsValidator from "./InputsValidator";

export default {
  validateLoginForm: ({ email, password }, loginScreenData) => {
    const emailStatus = InputsValidator.validateEmail(email || "", loginScreenData);
    const passwordStatus = InputsValidator.validatePassword(password || "", loginScreenData);
    return {
      emailMessage: emailStatus.error,
      passwordMessage: passwordStatus.error,
      hasErrors: emailStatus.hasError || passwordStatus.hasError
    };
  }
};
