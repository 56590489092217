import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  selectCategoryData,
  selectSelectedCategoryInfo,
  selectselectedCategoryId
} from "selectors/category";
import { selectItemTypes, isFetchingManifestData, isFetchingManifestRecommendation, selectCountryCode } from "selectors/dashboard";
import { selectSelectedChild } from "selectors/children";
import SidebarHeaderTemplate from "components/Templates/SidebarHeaderTemplate";
import InfiniteScroll from "react-bidirectional-infinite-scroll";
import get from "lodash/get";

import Tile from "components/Dashboard/Tile";
import styles from "components/Dashboard/_Dashboard.module.scss";
import Loading from "../Loading";
import LockedPopup from "components/Dashboard/LockedPopup";
import Error from "../Error";
import { lockedTitleTapped } from "../../helper.js";
import { selectUser } from "selectors/profile";
import { webAppEvent } from "actions/common";
import c from "classnames";
import Cookies from "js-cookie";
import { cookieParams } from "utils";

let apiErrorMessageText = "";
class Category extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      recommendedApiCalled: false,
      length: 20,
      showLockedImage: false
    };
  }

  static propTypes = {
    selectCategoryData: PropTypes.array,
    selectedCategoryInfo: PropTypes.object
  };

  static defaultProps = {
    selectCategoryData: [],
    selectedCategoryInfo: {}
  };

  getItems = (totalItemsInArray, length) => {
    const { selectCategoryData } = this.props;
    return selectCategoryData.slice(
      totalItemsInArray,
      totalItemsInArray + length
    );
  };

  handleHorizontalScroll = (position, previousPosition) => {
    let { items, length } = this.state;
    Cookies.set("scrollPosition", position, cookieParams);
    Cookies.set("itemLength", items.length, cookieParams);
    let scrollablePosition = document.getElementsByClassName("tiles_wrapper")[0].getElementsByTagName("div")[0].scrollWidth - document.getElementsByClassName("tiles_wrapper")[0].offsetWidth;
    if (scrollablePosition - position < 50) {
      items = items.concat(this.getItems(items.length, length));
      this.setState({ items });
    }
  }

  componentDidMount() {
    const { length } = this.state;
    Cookies.set("searchText","", cookieParams);
    Cookies.remove("searchText");
    let scrollPosition = Cookies.get("scrollPosition");
    let itemLength = Cookies.get("itemLength");
    if (scrollPosition && scrollPosition !== 0 && itemLength) {
      this.setState({
        items: [].concat(this.getItems(0, itemLength))
      });
    }
    else {
      this.setState({
        items: [].concat(this.getItems(0, length))
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { selectCategoryData, selectedCategoryId } = this.props;
    const { length, items } = this.state;
    if (
      selectCategoryData &&
      selectCategoryData !== prevProps.selectCategoryData
    ) {
      let scrollPosition = Cookies.get("scrollPosition");
      let itemLength = Cookies.get("itemLength");
      if (
        selectedCategoryId &&
        selectedCategoryId === prevProps.selectedCategoryId
        && items.length
      ) {
        this.setState({
          items: items,
          recommendedApiCalled: true
        });
      }
      else {
        if (scrollPosition && scrollPosition !== 0 && itemLength) {
          this.setState({
            items: [].concat(this.getItems(0, itemLength)),
            recommendedApiCalled: true
          });
        }
        else {
          this.setState({
            items: [].concat(this.getItems(0, length)),
            recommendedApiCalled: true
          });
        }
      }
      let tiles_wrapper = document.getElementsByClassName("tiles_wrapper")[0];
      if (tiles_wrapper) {
        tiles_wrapper.getElementsByTagName("div")[0].scrollLeft = scrollPosition;
      }
    }
  }

  onClose = () => {
    this.setState({
      showLockedImage: false
    });
  };

  onClickLocked = item => {
    this.setState({
      showLockedImage: true
    });
    this.handleLockedTitleTapped(item);
  };

  handleLockedTitleTapped = item => {
    const {
      webAppEvent,
      kafkaUrl,
      itemTypes,
      selectedChild,
      profile,
      countryCode
    } = this.props;
    switch (item.type) {
      case itemTypes.videos:
        item.contentType = "video";
        break;
      case itemTypes.books:
        item.contentType = "book";
        break;
      case itemTypes.games:
        item.contentType = "game";
        break;
      default:
        item.contentType = "collection";
        break;
    }
    let params = [
      {
        ...lockedTitleTapped(profile, item, selectedChild, countryCode)
      }
    ];
    webAppEvent(kafkaUrl, params);
  };

  updateTile = (id, status) => {
    const { items } = this.state;
    let clickedItem = items.filter(function(item) {
      return item['id'] === id;
    });

    if (status) {
      clickedItem['0']['favorite'] = 0;
    }
    else {
      clickedItem['0']['favorite'] = 1;
    }

    let updatedItem = items.map((item, index) => {
      if (item['id'] === id) {
        return clickedItem['0'];
      }
      return item;
    });

    this.setState({
      items: updatedItem
    });
  }

  render() {
    Cookies.set("prevPageforPolicy", "Category", cookieParams);
    Cookies.set("categoryPath", this.props.history.location.pathname, cookieParams);
    const {
      selectedCategoryInfo,
      dashboardApiErrorMsg,
      favListCount,
      isFetchingManifestData,
      isFetchingManifestRecommendation
    } = this.props;
    const { items, showLockedImage, recommendedApiCalled } = this.state;
    let item1,
      item2 = null;
    if (
      dashboardApiErrorMsg !== "" &&
      dashboardApiErrorMsg !== null &&
      dashboardApiErrorMsg !== undefined
    ) {
      apiErrorMessageText = dashboardApiErrorMsg;
    }
    let count = 0;
    if (favListCount && favListCount.data && favListCount.data.length) {
      count = favListCount.data.length;
    }
    return (
      <React.Fragment>
        {apiErrorMessageText !== "" && apiErrorMessageText !== undefined ? (
          <Error errorMessage={apiErrorMessageText} />
        ) : (
          <React.Fragment>
            {(!recommendedApiCalled || isFetchingManifestData || isFetchingManifestRecommendation) && <Loading loading="loading" />}
            <SidebarHeaderTemplate
              pageTitle={
                selectedCategoryInfo !== "" ? selectedCategoryInfo.title : ""
              }
              pageTitleClass="category_page_title"
              titleThumbnail={
                selectedCategoryInfo !== ""
                  ? get(selectedCategoryInfo, "thumbnails[0]", "")
                  : ""
              }
              category_color={
                selectedCategoryInfo !== ""
                  ? get(selectedCategoryInfo, "category_color", "")
                  : ""
              }
              favItem={count}
            >
              {items && items.length ? (
                <div className={c(
                  "tiles_wrapper",
                  styles.tiles_wrapper,
                  selectedCategoryInfo.title === "Bedtime" ? styles.bed_time_wrapper : ""
                )}>
                  <InfiniteScroll
                    onScroll={this.handleHorizontalScroll}
                    horizontal
                  >
                    {items.map((dataElement, index) => {
                      if (index % 2 === 0) {
                        item1 = dataElement;
                      } else {
                        item2 = dataElement;
                      }
                      if (index % 2 !== 0) {
                        return (
                          <div
                            key={index}
                            className={styles.tiles_wrapper_inner}
                          >
                            <Tile
                              dataElement={item1}
                              onClickLocked={this.onClickLocked}
                              updateTile={(id, status) => this.updateTile(id, status)}
                              pageName={selectedCategoryInfo.title === "Bedtime" ? "PAGE_BEDTIME" : ""}
                            />
                            <Tile
                              dataElement={item2}
                              onClickLocked={this.onClickLocked}
                              updateTile={(id, status) => this.updateTile(id, status)}
                              pageName={selectedCategoryInfo.title === "Bedtime" ? "PAGE_BEDTIME" : ""}
                            />
                          </div>
                        );
                      } else if (items.length === index + 1) {
                        return (
                          <div
                            key={index}
                            className={styles.tiles_wrapper_inner}
                          >
                            <Tile
                              dataElement={item1}
                              onClickLocked={this.onClickLocked}
                              updateTile={(id, status) => this.updateTile(id, status)}
                              pageName={selectedCategoryInfo.title === "Bedtime" ? "PAGE_BEDTIME" : ""}
                            />
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </InfiniteScroll>
                </div>
              ) : (
                <div className={styles.no_result}>No Content Found</div>
              )}
            </SidebarHeaderTemplate>
            <LockedPopup
              showLockedImage={showLockedImage}
              onClose={this.onClose}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state, props) => ({
  favListCount: get(state, "dashboardList.favoriteList"),
  selectedCategoryId: selectselectedCategoryId(state, props),
  selectCategoryData: selectCategoryData(state, props),
  selectedCategoryInfo: selectSelectedCategoryInfo(state, props),
  dashboardApiErrorMsg: get(state, "dashboardList.errorMessage"),
  kafkaUrl: get(state, "appManifest.data.app_settings.kafka_url"),
  profile: selectUser(state),
  itemTypes: selectItemTypes(state),
  selectedChild: selectSelectedChild(state),
  isFetchingManifestData: isFetchingManifestData(state),
  isFetchingManifestRecommendation: isFetchingManifestRecommendation(state),
  countryCode: selectCountryCode(state)
});

export const mapDispatchToProps = dispatch => ({
  webAppEvent(url, params) {
    dispatch(webAppEvent(url, params));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Category)
);
