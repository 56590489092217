import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import get from "lodash/get";
import { userDashboard } from "actions/dashboard";
import { selectSelectedChildId } from "selectors/children";
import { selectBaseUrl } from "selectors";
import { selectUser } from "selectors/profile";
import { selectCountryCode } from "selectors/dashboard";
import fetchManifestData from "../FetchManifestData";
import BasicTemplate from "../Templates/BasicTemplate";
import CategoryList from "./CategoryList";
import LearningArea from "./LearningArea";
import ViewedByChild from "./ViewedByChild";
import classes from "./_ParentDashboard.module.scss";
import Image from "../Global/Image";
import Loading from "../Loading";
import Cookies from "js-cookie";
import WhoIsPlaying from "components/WhoIsPlayingContainer/WhoIsPlaying";
import { WHO_IS_PLAYING_SCREEN_TYPES } from "constants/index";
import { webAppEvent } from "actions/common";
import { deviceInfo, parentDashboardVisited } from "../../helper.js";
import { BACKGROUNDS } from "constants/styles";
import basicTemplateClasses from "../Templates/_BasicTemplate.module.scss";
import c from "classnames";
import Error from "../Error";
import { setScreenOrientation, cookieParams } from "utils";
import { isTablet } from "react-device-detect";
import { PAGE_MODE } from "constants/screens";
import LockedPopup from "components/Dashboard/LockedPopup";
import withChildren from "../WithChildren";

let startTime = null;
let apiErrorMessageText = "";
class ParentDashboard extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      goBack: PropTypes.func
    }),
    children: PropTypes.array
  };

  static defaultProps = {
    history: {
      goBack() {}
    },
    children: []
  };

  state = {
    isSelectingChild: false,
    isPDChildSelected: false,
    lockedParentDashboard: false
  };

  componentDidMount() {
    const { userDashboard, baseUrl, profile, selectedChildId, children } = this.props;
    const { isPDChildSelected, isSelectingChild } = this.state;
    this.ParentDashboardState();
    if (profile && children.length && this.isValidChild(selectedChildId)) {
      const id = get(profile, "id", "");
      id && userDashboard(baseUrl, id, selectedChildId);
    }
    startTime = new Date().getTime();
    if (isPDChildSelected && !isSelectingChild) {
      setScreenOrientation(PAGE_MODE.PARENT_DASHBOARD);
    }
    sessionStorage.setItem("screenType", WHO_IS_PLAYING_SCREEN_TYPES.PARENT_DASHBOARD);
  }

  handleParentDashboardVisited = () => {
    const { webAppEvent, kafkaUrl, profile, countryCode } = this.props;
    let endTime = new Date().getTime();
    const spentTime = (endTime - startTime) / 1000;
    let params = [
      {
        ...deviceInfo,
        ...parentDashboardVisited(profile, spentTime, countryCode)
      }
    ];
    webAppEvent(kafkaUrl, params);
  };

  isValidChild = childId => {
    return !!(this.props.children || []).find(child => child.id === childId);
  };

  componentDidUpdate(prevProps) {
    const { userDashboard, baseUrl, profile, selectedChildId, children, history } = this.props;
    const { isPDChildSelected, isSelectingChild } = this.state;
    if (children.length && !this.isValidChild(selectedChildId)) {
      history.replace("/");
    }
    if (
      children.length && this.isValidChild(selectedChildId) &&
      ((profile && prevProps.profile !== profile) ||
      selectedChildId !== prevProps.selectedChildId ||
      children !== prevProps.children)
    ) {
      const id = get(profile, "id", "");
      userDashboard(baseUrl, id, selectedChildId);
    }
    if (isPDChildSelected && !isSelectingChild) {
      setScreenOrientation(PAGE_MODE.PARENT_DASHBOARD);
    }
  }

  ParentDashboardState = () => {
    const subscriptionStatus = Cookies.get("subscriptionStatus");
    if(subscriptionStatus === "Cancelled" ||
    subscriptionStatus === "Free Promotion Expired" ||
    subscriptionStatus === "Free"){
      this.setState({
        lockedParentDashboard: true
      });
    }
  };

  onClose = () => {
    const { match, history } = this.props;
    let childId = Cookies.get("childId");
    if (!childId) {
      childId = get(match, "params.childId", "");
      Cookies.set("childId", childId, cookieParams);
    }
    childId && history.push(`/${childId}/parent-area`);
    this.handleParentDashboardVisited();
  };

  onSelectChild = () => {
    const { isSelectingChild } = this.state;
    if (isTablet) {
      setScreenOrientation(PAGE_MODE.TABLET);
    } else {
      setScreenOrientation(PAGE_MODE.HOME);
    }
    this.setState({
      isSelectingChild: !isSelectingChild,
      isPDChildSelected: true
    });
  };

  render() {
    const {
      dashboardData,
      isFetching,
      parentsAreaData,
      profile,
      errorMessageApi,
      children
    } = this.props;
    const { isSelectingChild, lockedParentDashboard } = this.state;
    if (
      errorMessageApi !== null &&
      errorMessageApi !== "" &&
      errorMessageApi !== undefined
    ) {
      apiErrorMessageText = errorMessageApi;
    }
    return (
      <React.Fragment>
        { lockedParentDashboard ? (
          <LockedPopup
            showLockedImage={lockedParentDashboard}
            onClose={this.onClose}
          />
        ) : (
          <React.Fragment>
            {apiErrorMessageText !== "" && apiErrorMessageText !== undefined ? (
              <Error errorMessage={apiErrorMessageText} />
            ) : (
              <React.Fragment>
                {isFetching || !profile || children.length <= 0 ? (
                  <Loading />
                ) : isSelectingChild ? (
                  <BasicTemplate background={BACKGROUNDS.BG_NAVYBLUE}>
                    <WhoIsPlaying
                      screenType={WHO_IS_PLAYING_SCREEN_TYPES.PARENT_DASHBOARD}
                      closeIconClass="close_icon_right"
                      onSelectChild={this.onSelectChild}
                      headingTextProp={parentsAreaData.select_child_heading}
                      children={children}
                    />
                  </BasicTemplate>
                ) : (
                  <BasicTemplate autoHeight="true">
                    <div
                      className={c(
                        basicTemplateClasses.basic_template_content_wrapper,
                        classes.wrapper_container
                      )}
                    >
                      <Image
                        folderName="static"
                        fileName="close_window"
                        className={basicTemplateClasses.close_icon}
                        onClick={this.onClose}
                      />
                      <Image
                        className={classes.logo_image}
                        folderName="ui_and_learning_area"
                        fileName="curious_world_logo"
                        alt="CW Logo"
                      />
                      <div className={classes.heading_text}>
                        {parentsAreaData.parent_dashboard}
                      </div>
                      {/*<div className={classes.sub_heading_text}>
                        {parentsAreaData.time_spent_on_curious_world}
                      </div>*/}
                      <div className={classes.child_details}>
                        <CategoryList
                          child={get(dashboardData, "data.child")}
                          onSelectChild={this.onSelectChild}
                          parentsAreaData={parentsAreaData}
                          viewed={get(dashboardData, "data.viewed")}
                        />
                        <LearningArea
                          learning_areas={get(dashboardData, "data.learning_areas")}
                          parentsAreaData={parentsAreaData}
                        />
                        <ViewedByChild
                          viewed={get(dashboardData, "data.viewed")}
                          child={get(dashboardData, "data.child")}
                          parentsAreaData={parentsAreaData}
                        />
                      </div>
                    </div>
                  </BasicTemplate>
                )}
              </React.Fragment>
            )}
            <LockedPopup
              showLockedImage={lockedParentDashboard}
              onClose={this.onClose}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state, ownProps) => {
  const selectedChildId = get(ownProps, "match.params.childId");
  return {
    selectedChildId: selectedChildId,
    profile: selectUser(state),
    baseUrl: selectBaseUrl(state),
    dashboardData: get(state, "dashboardList.data"),
    isFetching: get(state, "dashboardList.isFetchingParentDashboard"),
    dashboardSelectedChildId: selectSelectedChildId(state),
    parentsAreaData: get(state, "appManifest.data.parents_area"),
    kafkaUrl: get(state, "appManifest.data.APP_SETTINGS.kafka_url"),
    errorMessageApi: get(state, "dashboardList.errorMessage"),
    countryCode: selectCountryCode(state)
  };
};

export const mapDispatchToProps = dispatch => ({
  userDashboard(baseUrl, userId, selectedChildId) {
    dispatch(userDashboard(baseUrl, userId, selectedChildId));
  },
  webAppEvent(url, params) {
    dispatch(webAppEvent(url, params));
  }
});

export default withRouter(
  fetchManifestData(
    withChildren(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(ParentDashboard)
    )
  )
);
