import React from "react";
import PropTypes from "prop-types";
import Image from "../Global/Image";

const AvatarActions = ({
  isLayover,
  children,
  getClassName,
  addChild,
  editChild,
  editChildFlag
}) => (
  <div className={getClassName("avatar_actions")}>
    {children.length < 4 ? (
      <div className={getClassName("action")} onClick={addChild}>
        <Image
          folderName="static"
          fileName="add_child"
          alt="add"
          className={getClassName("action_image")}
        />
        <span className={getClassName("action_text")}>Add a child</span>
      </div>
    ) : null}
    {!editChildFlag && children.length ? (
      <div className={getClassName("action")} onClick={editChild}>
        <Image
          folderName="static"
          fileName="edit_delete"
          alt="edit/delete"
          className={getClassName("action_image")}
        />
        <span className={getClassName("action_text")}>Edit/Delete</span>
      </div>
    ) : null}
  </div>
);

AvatarActions.propTypes = {
  isLayover: PropTypes.bool,
  children: PropTypes.array,
  getClassName: PropTypes.func,
  addChild: PropTypes.func,
  editChild: PropTypes.func
};

AvatarActions.defaultProps = {
  isLayover: false,
  children: [],
  getClassName() {},
  addChild() {},
  editChild() {}
};

export default AvatarActions;
