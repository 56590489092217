import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { BACKGROUNDS } from "constants/styles";
import { connect } from "react-redux";
import { PATHS } from "constants/url";
import get from "lodash/get";

import BasicTemplate from "../Templates/BasicTemplate";
import WhoIsPlaying from "./WhoIsPlaying";
import Loading from "../Loading";
import Error from "../Error";
import withChildren from "../WithChildren";
import { selectIsFetchingChildren } from "selectors/children";
import { setScreenOrientation } from "utils";
import { PAGE_MODE } from "constants/screens";
import { isMobile, isTablet } from "react-device-detect";
import { selectBaseUrl, selectCurrentOrientation } from "selectors";
import { WHO_IS_PLAYING_SCREEN_TYPES, DIRECT_LINKS } from "constants/index";
const queryString = require("query-string");

let apiErrorMessageText = "";
class WhoIsPlayingContainer extends Component {
  static propTypes = {
    isFetchingChild: PropTypes.bool,
    children: PropTypes.array,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }),
    baseUrl: PropTypes.string
  };

  static defaultProps = {
    isFetchingChild: false,
    children: [],
    history: {
      push() {}
    },
    baseUrl: ""
  };

  componentDidMount() {
    const {
      children,
      isFetchingChild,
      baseUrl
    } = this.props;
    sessionStorage.setItem("screenType", WHO_IS_PLAYING_SCREEN_TYPES.HOME);
    if (!isFetchingChild) {
      if (children.length < 1) {
        if(isTablet && baseUrl){
          setScreenOrientation(PAGE_MODE.TABLET);
        }
        else if(isMobile && !isTablet && baseUrl ){
          setScreenOrientation(PAGE_MODE.ADD_CHILD);
        }
        this.redirectToRespectivePage();
      } else {
        if(isTablet && baseUrl){
          setScreenOrientation(PAGE_MODE.TABLET);
        }
        else if(isMobile && !isTablet && baseUrl ){
          setScreenOrientation(PAGE_MODE.HOME);
        }
        this.redirectDirectLinks();
      }
    }
  }

  componentDidUpdate() {
    const {
      children,
      isFetchingChild,
      currentOrientation
    } = this.props;
    if (!isFetchingChild) {
      if (children.length < 1) {
        setScreenOrientation(PAGE_MODE.ADD_CHILD);
        this.redirectToRespectivePage();
      } else {
        if (
          isMobile &&
          document.fullscreenEnabled &&
          currentOrientation !== PAGE_MODE.HOME
        ) {
          setScreenOrientation(PAGE_MODE.HOME);
        }
        this.redirectDirectLinks();
      }
    }
  }

  redirectDirectLinks = () => {
    const { history } = this.props;
    let parsed = queryString.parse(window.location.search);
    if (parsed.view) {
      if (DIRECT_LINKS.indexOf(parsed.view) !== -1) {
        history.push({
          pathname: `${parsed.view}`
        });
      }
    }
  }

  redirectToRespectivePage = () => {
    const { history } = this.props;
    let parsed = queryString.parse(window.location.search);
    if (parsed.view) {
      history.push({
        pathname: PATHS.ADD_CHILD,
        search: window.location.search
      });
    } else {
      history.push(PATHS.ADD_CHILD);
    }
  }

  render() {
    const {
      children,
      isFetchingChild,
      errorMessageProfile,
      getChildrenErrorMessage,
      isFetchingProfile
    } = this.props;
    if (
      errorMessageProfile !== null &&
      errorMessageProfile !== "" &&
      errorMessageProfile !== undefined
    ) {
      apiErrorMessageText = errorMessageProfile;
    } else if (
      getChildrenErrorMessage !== null &&
      getChildrenErrorMessage !== "" &&
      getChildrenErrorMessage !== undefined
    ) {
      apiErrorMessageText = getChildrenErrorMessage;
    }
    return (
      <React.Fragment>
        {apiErrorMessageText !== "" && apiErrorMessageText !== undefined ? (
          <Error errorMessage={apiErrorMessageText} />
        ) : (
          <React.Fragment>
            {(isFetchingChild || isFetchingProfile) && (
              <Loading loading="loading" />
            )}
            <BasicTemplate background={BACKGROUNDS.BG_NAVYBLUE}>
              <WhoIsPlaying children={children} />
            </BasicTemplate>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = state => ({
  isFetchingChild: selectIsFetchingChildren(state),
  errorMessageProfile: get(state, "myProfile.errorMessage"),
  getChildrenErrorMessage: get(state, "children.getChildrenErrorMessage"),
  reactLogin: get(state, "login.reactLogin"),
  isFetchingProfile: get(state, "myProfile.isFetching"),
  baseUrl: selectBaseUrl(state),
  currentOrientation: selectCurrentOrientation(state)
});

export default withRouter(
  withChildren(
    connect(
      mapStateToProps,
      null
    )(WhoIsPlayingContainer)
  )
);
