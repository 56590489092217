import {
  LAUNCH_DIALOG,
  CLOSE_DIALOG,
  AGECHCEK_DIALOG,
  ROUTE_VALUE,
  GETSTARTED_CLICKED
} from "constants/actions";

export const launchDialog = ({ id, data }) => ({
  type: LAUNCH_DIALOG,
  id,
  data
});

export const closeDialog = () => ({
  type: CLOSE_DIALOG
});

export const launchAgeCheck = AgeCheckShow => ({
  type: AGECHCEK_DIALOG,
  AgeCheckShow
});

export const routeValuecheck = RouteValue => ({
  type: ROUTE_VALUE,
  RouteValue
});

export const GetStartedClicked = getStartedClicked => ({
  type: GETSTARTED_CLICKED,
  getStartedClicked
});
