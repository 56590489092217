import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

import { WHO_IS_PLAYING_SCREEN_TYPES } from "constants/index";

import WhoIsPlaying from "components/WhoIsPlayingContainer/WhoIsPlaying";
import BasicTemplate from "../Templates/BasicTemplate";
import withChildren from "../WithChildren";
import Loading from "../Loading";

class MyChildren extends React.Component {
  static propTypes = {
    parentsAreaScreenData: PropTypes.object
  };

  static defaultProps = {
    parentsAreaScreenData: {}
  };

  componentDidMount() {
    sessionStorage.setItem("screenType", WHO_IS_PLAYING_SCREEN_TYPES.MY_CHILDREN);
  }

  render() {
    const { parentsAreaScreenData, children } = this.props;
    const {
      sub_heading_text: subHeadingText,
      sub_heading_description: subHeadingDescription
    } = parentsAreaScreenData;

    return (
      <React.Fragment>
        {!children.length && <Loading loading="loading" />}
        <BasicTemplate>
          <WhoIsPlaying
            screenType={WHO_IS_PLAYING_SCREEN_TYPES.MY_CHILDREN}
            subHeadingText={subHeadingText}
            subHeadingDescription={subHeadingDescription}
            closeIconClass="close_icon"
            children={children}
          />
        </BasicTemplate>
      </React.Fragment>
    );
  }
};

export const mapStateToProps = state => ({
  parentsAreaScreenData: get(state, "appManifest.data.my_children", {})
});

export default withChildren(
  connect(
    mapStateToProps,
    null
  )(MyChildren)
);
