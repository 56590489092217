import get from "lodash/get";
import { createSelector } from "reselect";

const selectEventData = (state, props) =>
  get(state, "event.eventBasedContent.data.event_data");

export const selectEventImage = createSelector(
  [selectEventData],
  (eventData) => {
    return eventData ? (
    	eventData.background ? (
    		eventData.background.home ? (
    			eventData.background.home.desktop ? `${eventData.background.home.desktop}` : ""
    		) : ""
    	) : ""
    ) : "";
  }
);

export const selectEventAvatarImages = createSelector(
  [selectEventData],
  (eventData) => {
    return eventData ? get(eventData, "avatars_images", "") : "";
  }
);

export const selectEventMessage = createSelector(
  [selectEventData],
  (eventData) => {
    return eventData ? get(eventData, "message", {}) : {};
  }
);
