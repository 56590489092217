import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { PATHS } from "constants/url";

import DirectAccessBook from "../DirectAccessBook";
import Book from "../Book";
import Video from "../Video";
import Game from "../Game";
import Collection from "../Collection";
import RouteWithValidChild from "./RouteWithValidChild";
import withChildren from "components/WithChildren";
import Loading from "../Loading";

class CleverResourceRoutes extends React.Component {
  renderSwitch() {
    const { cleverResource } = this.props;
    switch(cleverResource.type) {
      case 'video':
        return {
          "path": PATHS.VIDEO,
          "component": Video
        };
      case 'book':
        return {
          "path": PATHS.BOOK,
          "component": Book
        };
      case 'game':
        return {
          "path": PATHS.GAME,
          "component": Game
        };
      default:
        break;
    }
  }

  render() {
    const { cleverResource, children } = this.props;
    let componentData = this.renderSwitch();
    return (
      <React.Fragment>
      {children.length ? (
        cleverResource.type === "collection" ? (
          <Switch>
            <Route 
              exact 
              path={PATHS.DIRECT_ACCESS_BOOK} 
              component={DirectAccessBook} 
            />
            <RouteWithValidChild exact path={PATHS.BOOK} component={Book} />
            <RouteWithValidChild exact path={PATHS.VIDEO} component={Video} />
            <RouteWithValidChild exact path={PATHS.GAME} component={Game} />
            <RouteWithValidChild
              exact
              path={PATHS.COLLECTION}
              component={Collection}
            />
            <Route render={props => (
              <Redirect to={`/${children[0].id}/${cleverResource.type}/${cleverResource.id}`} />
            )}/>
          </Switch>
        ) : (
          <Switch>
            <Route 
              exact 
              path={PATHS.DIRECT_ACCESS_BOOK} 
              component={DirectAccessBook} 
            />
            <RouteWithValidChild exact path={componentData.path} component={componentData.component} />
            <Route render={props => (
              <Redirect to={`/${children[0].id}/${cleverResource.type}/${cleverResource.id}`} />
            )}/>
          </Switch>
        )
      ) : (
        <Loading />
      )}
      </React.Fragment>
    );
  }
}

export default withRouter(
  withChildren(CleverResourceRoutes)
);
