import React from "react";
import Cookies from "js-cookie";
import errorMessageImage from "../../images/errorMessageImage.png";
import noInternetConnection from "../../images/noInternetConnection.png";
import closeIcon from "../../images/closeWindowIconPurple.png";
import BasicTemplate from "../Templates/BasicTemplate";
import styles from "./_styles.module.scss";
import CustomButton from "components/Global/Button";
import FullScreenModalTemplate from "../Templates/FullScreenModalTemplate";
import { cookieParams } from "utils";

class Error extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      button: {
        buttonConfig: {
          type: "submit"
        },
        text: "Try again",
        buttontext:"Ok"
      }
    };
  }

  onReload = () => {
    Cookies.set("showAnimation", "TRUE", cookieParams);
    if(this.props.errorMessage){      
      window.location.href =this.props.url;
    }
    window.location.reload();
  };

  onRedirect = () => {
    Cookies.set("showAnimation", "TRUE", cookieParams);
    window.location.href =this.props.url;
  };

  render() {
    Cookies.set("showAnimation", "FALSE", cookieParams);
    const { errorMessage, okButton, closeErrorpop, buttonText,url } = this.props;
    return (
      <React.Fragment>
        {errorMessage !== "Network Error" && errorMessage ? (
          <BasicTemplate background="errorImg">
            <div className={styles.error_popup_wrapper}>
              <div className={styles.error_popup}>
                <img
                  src={closeIcon}
                  alt=""
                  className={styles.close_icon}
                  onClick={!okButton ? this.onReload : closeErrorpop}
                />
                <img
                  src={errorMessageImage}
                  alt=""
                  className={styles.curious_george}
                />
                <div className={styles.error_text_wrapper}>
                  <div className={styles.error_title}>Whoops!</div>
                  <div className={styles.error_desc}>{errorMessage}</div>
                  {!okButton ? (
                    <CustomButton
                      config={this.state.button.buttonConfig}
                      content={this.state.button.text}
                      onClick={this.onReload}
                      className={styles.error_button}
                    />
                  ) : (
                    <React.Fragment>
                      {buttonText ? (
                        <CustomButton
                          config={this.state.button.buttonConfig}
                          content={buttonText}
                          onClick={closeErrorpop}
                          className={styles.error_button}
                        />
                      ) : (
                        <CustomButton
                          config={this.state.button.buttonConfig}
                          content={this.state.button.text}
                          onClick={closeErrorpop}
                          className={styles.error_button}
                        />
                      )}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </BasicTemplate>
        ) : (
          <React.Fragment>
            {url ?(
                <FullScreenModalTemplate onClose={this.onRedirect}>
                <div className={styles.error_wrapper}>
                  <div className={styles.title}>Sorry</div>
                  <div className={styles.desc}>
                   Curious World is not available in your country yet!
                  </div>
                  <CustomButton
                    config={this.state.button.buttonConfig}
                    content={this.state.button.buttontext}
                    onClick={this.onRedirect}
                    className={styles.button}
                  />
                </div>
              </FullScreenModalTemplate>
            ):(
          <FullScreenModalTemplate onClose={this.onReload}>
            <div className={styles.error_wrapper}>
              <img src={noInternetConnection} alt="" />
              <div className={styles.title}>No Internet Connection</div>
              <div className={styles.desc}>
                You're not connected to the Internet. Ask a grown-up for help!{" "}
              </div>
              <CustomButton
                config={this.state.button.buttonConfig}
                content={this.state.button.text}
                onClick={this.onReload}
                className={styles.button}
              />
            </div>
          </FullScreenModalTemplate>
          )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Error;
