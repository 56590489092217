import deepFreeze from "deep-freeze-es6";
import {
  LOGIN_EVENT_REQUEST,
  LOGIN_EVENT_SUCCESS,
  LOGIN_EVENT_FAILURE,
  REACT_LOGIN,
  LOGOUT_COOKIE_CLEAR
} from "constants/actions";

const initialState = {
  isFetching: false,
  data: {},
  errorMessage: "",
  reactLogin: false
};

export const loginEventRequest = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage
  };
};

export const loginEventSuccess = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    data: action.data
  };
};

export const loginEventFailure = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    data: action.data
  };
};

export const setReactLogin = (state, action = {}) => {
  return {
    ...state,
    reactLogin: true
  };
};

export const logoutCookieClear = (state, action = {}) => {
  return {
    ...state
  };
};

export default (state = initialState, action) => {
  deepFreeze(state);
  deepFreeze(action);

  switch (action.type) {
    case LOGIN_EVENT_REQUEST:
    case LOGIN_EVENT_SUCCESS:
      return loginEventSuccess(state, action);
    case LOGIN_EVENT_FAILURE:
      return loginEventFailure(state, action);
    case REACT_LOGIN:
      return setReactLogin(state, action);
    case LOGOUT_COOKIE_CLEAR:
      return logoutCookieClear(state, action);
    default:
      return state;
  }
};
