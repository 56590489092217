import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectImageSrc } from "selectors";

class Image extends React.Component {
  static propTypes = {
    imageSrc: PropTypes.string,
    className: PropTypes.string,
    alt: PropTypes.string.isRequired,
    folderName: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    onClick: PropTypes.func
  };

  static defaultProps = {
    imageSrc: "",
    className: "",
    alt: ""
  };

  render() {
    const { imageSrc, className, alt, onClick, onLoad } = this.props;
    return (
      <React.Fragment>
        {imageSrc ? (
          <img
            src={imageSrc}
            alt={alt}
            className={className}
            onClick={onClick}
            onLoad={onLoad}
          />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  imageSrc: selectImageSrc(state, props)
});

export default connect(
  mapStateToProps,
  null
)(Image);
