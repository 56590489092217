import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import get from "lodash/get";
import fetchManifestData from "../FetchManifestData";
import { selectEntity, selectEntityExist, selectItemTypes } from "selectors/dashboard";
import { selectSelectedChildId } from "selectors/children";
import Loading from "../Loading";
import Error from "../Error";
import BookReader from "./BookReader";
import LockedPopup from "components/Dashboard/LockedPopup";
import Cookies from "js-cookie";
import { PATHS } from "constants/url";
import { RESOURCE_NOT_FOUND_ERROR } from "constants/index";
import { isLocalServer, cookieParams } from "utils";
import { selectIsCleverResource } from "selectors/profile";

class Book extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func
    }),
    extractedEpubBaseUrl: PropTypes.string,
    book: PropTypes.object,
    bookId: PropTypes.string,
    selectedChildId: PropTypes.string
  };

  static defaultProps = {
    history: {
      push() {}
    },
    extractedEpubBaseUrl: "",
    book: {},
    bookId: "",
    selectedChildId: ""
  };

  state = {
    showLockedImage:false
  };

  componentDidMount() {
    const { book } = this.props;
    const subscriptionStatus = Cookies.get("subscriptionStatus");
    if (Object.keys(book).length !== 0) {
      if((subscriptionStatus === "Cancelled" || subscriptionStatus === "Free Promotion Expired" || subscriptionStatus === "Free") && !book.isPublic){
        this.setState({showLockedImage:true})
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { bookNotExist, history, book, itemTypes, selectedChildId, isCleverResource, bookId } = this.props;
    const { showLockedImage } = this.state;
    const subscriptionStatus = Cookies.get("subscriptionStatus");
    if (book !== prevProps.book) {
      if((subscriptionStatus === "Cancelled" || subscriptionStatus === "Free Promotion Expired" || subscriptionStatus === "Free") && !book.isPublic){
        this.setState({showLockedImage:true})
      }
    }
    if (!showLockedImage && ((!isCleverResource && bookNotExist) || (isCleverResource && isCleverResource.type !== "collection" && isCleverResource.id !== bookId) || (isCleverResource && isCleverResource.type === "collection" && bookNotExist) || (!isCleverResource && book !== prevProps.book && book.type !== itemTypes.books))) {
      Cookies.set("resourceNotFound", 1, cookieParams);
      history.push(`/${selectedChildId}/dashboard`);
    }
  }

  onCloselockedpop = () => {
    const { history, selectedChildId } = this.props;
    if (selectedChildId) {
      if (Cookies.get("collectionPath")) {
        history.push(Cookies.get("collectionPath"));
      }
      else {
        if(Cookies.get("prevPageforPolicy")==="Favourite"){
          history.push(`/${selectedChildId}/favourites`); 
        }
        else if(Cookies.get("prevPageforPolicy")==="Search"){
          history.push(`/${selectedChildId}/Search`);
        }
        else if(Cookies.get("prevPageforPolicy")==="Category"){
          history.push(Cookies.get("categoryPath"));
        }
        else {
          history.push(`/${selectedChildId}/dashboard`);
        }
      }
    } else {
      history.push(PATHS.HOME);
    }
    this.setState({showLockedImage:false})
  };

  updateExtractedEpubBaseUrl = () => {
    const { extractedEpubBaseUrl, domainUrl } = this.props;
    if (!isLocalServer()) {
      let url = extractedEpubBaseUrl.split("/");
      url[2] = domainUrl;
      return url.join("/");
    }
    else {
      // Only 1 book is added in local - book/3658 (Goldilocks and three bears)
      return process.env.PUBLIC_URL;
    }
  };

  render() {
    const { book, bookId, bookNotExist, isCleverResource, itemTypes } = this.props;
    const { showLockedImage } = this.state;
    return (
      <React.Fragment>
        {showLockedImage ? (
          <LockedPopup
            showLockedImage={showLockedImage}
            onClose={this.onCloselockedpop}
          />
        ) : (
          <React.Fragment>
            {(Object.keys(book).length === 0 || bookNotExist == null || bookNotExist) ? (
              (isCleverResource && isCleverResource.id && ((bookNotExist != null && bookNotExist) || (book.type && book.type !== itemTypes.books))) ? (
                <Error errorMessage={RESOURCE_NOT_FOUND_ERROR} />
              ) : (
                <Loading />
              )
            ):(
              <BookReader
                book={book}
                extractedEpubBaseUrl={this.updateExtractedEpubBaseUrl()}
                bookId={bookId}
              />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state, ownProps) => {
  const id = get(ownProps, "match.params.bookId");
  const type = "book";
  return {
    book: selectEntity(state, { id }),
    bookNotExist: selectEntityExist(state, { id, type }),
    extractedEpubBaseUrl: get(state, "appManifest.data.app_settings.audio_url"),
    selectedChildId: selectSelectedChildId(state),
    bookId: id,
    domainUrl: get(state, "appManifest.data.book_page.book_base_url"),
    itemTypes: selectItemTypes(state),
    isCleverResource: selectIsCleverResource(state)
  };
};

export default withRouter(
  fetchManifestData(
    connect(
      mapStateToProps,
      null
    )(Book)
  )
);