import deepFreeze from "deep-freeze-es6";
import {
  GET_MY_PROFILE_REQUEST,
  GET_MY_PROFILE_SUCCESS,
  GET_MY_PROFILE_FAILURE
} from "constants/actions";
const queryString = require("query-string");

const initialState = {
  isFetching: false,
  data: {},
  errorMessage: ""
};

export const getMyProfileRequest = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage
  };
};

export const getMyProfileSuccess = (state, action) => {
  // let obj = {
  //   status: {...action.data.status},
  //   // data: {...action.data.data, cleverUser: true}
  //   data: {
  //     ...action.data.data,
  //     cleverLink: "https://kidsy.curiousworld.com/?view=collection&id=6653"
  //     // cleverResource: {
  //     //   id: "36641",
  //     //   type: "video"
  //     // } //game/35640 //game/3568-u //book/3749 //book/3521-u //video/3573 //video/36641-u // collection/6653 // collection/4414-u
  //   }
  // }
  let myProfileData = action.data;
  if (action.data.data.cleverLink) { //obj.data.cleverLink
    let cleverLinkQuery = action.data.data.cleverLink.split("?"); //obj.data.cleverLink.split("?");
    const parsed = queryString.parse(cleverLinkQuery['1']);

    myProfileData = {
      status: {...action.data.status},
      data: {
        ...action.data.data,
        cleverResource: {
          id: parsed.id,
          type: parsed.view
        }
      }
    }
  }

  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    data: myProfileData
  };
};

export const getMyProfileFailure = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    data: action.data
  };
};

export default (state = initialState, action) => {
  deepFreeze(state);
  deepFreeze(action);

  switch (action.type) {
    case GET_MY_PROFILE_REQUEST:
      return getMyProfileRequest(state, action);
    case GET_MY_PROFILE_SUCCESS:
      return getMyProfileSuccess(state, action);
    case GET_MY_PROFILE_FAILURE:
      return getMyProfileFailure(state, action);
    default:
      return state;
  }
};
