import React from "react";
import get from "lodash/get";
import InfiniteScroll from "react-bidirectional-infinite-scroll";
import ViewTile from "./ViewTile";
import classes from "./_ViewedByChild.module.scss";
import Loader from "../Loader";

let videos = [];
let books = [];
let games = [];

class ViewedByChild extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      length: 15,
      scrolled: false,
      status: false
    };
  }

  getItems = (totalItemsInArray, length, data) => {
    return data && data.slice(totalItemsInArray, totalItemsInArray + length);
  };

  handleScrollRight = (e, itemName, data) => {
    let { length } = this.state;
    switch (itemName) {
      case "Videos":
        videos = videos.concat(this.getItems(videos.length, length, data));
        break;
      case "Books":
        books = books.concat(this.getItems(books.length, length, data));
        break;
      case "Games":
        games = games.concat(this.getItems(games.length, length, data));
        break;
      default:
        break;
    }
    setTimeout(() => {
      this.setState({ scrolled: true });
    }, 500);
  };

  viewedByChildData = (image, itemName, time, viewedItem) => {
    let { length, scrolled, status } = this.state;
    if ( !scrolled ) {
      switch (itemName) {
        case "Videos":
          videos = [].concat(this.getItems(0, length, Object.keys(viewedItem)));
          break;
        case "Books":
          books = [].concat(this.getItems(0, length, Object.keys(viewedItem)));
          break;
        case "Games":
          games = [].concat(this.getItems(0, length, Object.keys(viewedItem)));
          break;
        default:
          break;
      }
    }

    return (
      <React.Fragment>
        <div className={classes.viewed_by_child_text_wrapper}>
          <img
            className={classes.viewed_by_child_img}
            src={image}
            alt={itemName}
          />
          <div className={classes.viewed_by_child_title}>
            <span>
              {itemName} ({Object.keys(viewedItem).length}):
            </span>{" "}
            {time}
          </div>
        </div>
        <div className={classes.tiles_wrapper}>
          {!status && <Loader favClass="true" />}
          <InfiniteScroll
            onReachRight={e =>
              this.handleScrollRight(e, itemName, Object.keys(viewedItem))
            }
            horizontal
          >
            {itemName === "Videos" &&
              videos.map((key, index) => {
                return (
                  <ViewTile
                    key={index}
                    id={key}
                    handleLoad={this.handleLoad}
                    className="viewed_tile_link"
                  />
                );
              })}
            {itemName === "Books" &&
              books.map((key, index) => {
                return (
                  <ViewTile
                    key={index}
                    id={key}
                    handleLoad={this.handleLoad}
                    className="viewed_tile_link"
                  />
                );
              })}
            {itemName === "Games" &&
              games.map((key, index) => {
                return (
                  <ViewTile
                    key={index}
                    id={key}
                    handleLoad={this.handleLoad}
                    className="viewed_tile_link"
                  />
                );
              })}
          </InfiniteScroll>
        </div>
      </React.Fragment>
    );
  };

  handleLoad = () => {
    this.setState({ status: true });
  };

  render() {
    const { viewed, child, parentsAreaData } = this.props;
    let contentExist = false;
    if (
      viewed &&
      (Object.keys(get(viewed, "books", "")).length ||
        Object.keys(get(viewed, "games", "")).length ||
        Object.keys(get(viewed, "videos", "")).length)
    ) {
      contentExist = true;
    }
    return (
      <React.Fragment>
        {contentExist && (
          <React.Fragment>
            {(
              (Object.keys(get(viewed, "books", "")).length) ||
              Object.keys(get(viewed, "games", "")).length ||
              Object.keys(get(viewed, "videos", "")).length
            ) ? (
              <div className={classes.section_title}>
                {parentsAreaData.viewed_by_your_child}
              </div>
            ) : ( "" )}
            <ul className={classes.viewed_by_child_wrapper}>
              {Object.keys(get(viewed, "books", "")).length ? (
                <li>
                  {this.viewedByChildData(
                    get(viewed, "books_image", ""),
                    "Books",
                    get(child, "book_time", ""),
                    get(viewed, "books", "")
                  )}
                </li>
              ) : (
                ""
              )}
              {Object.keys(get(viewed, "games", "")).length ? (
                <li>
                  {this.viewedByChildData(
                    get(viewed, "games_image", ""),
                    "Games",
                    get(child, "game_time", ""),
                    get(viewed, "games", "")
                  )}
                </li>
              ) : (
                ""
              )}
              {Object.keys(get(viewed, "videos", "")).length ? (
                <li>
                  {this.viewedByChildData(
                    get(viewed, "videos_image", ""),
                    "Videos",
                    get(child, "video_time", ""),
                    get(viewed, "videos", "")
                  )}
                </li>
              ) : (
                ""
              )}
            </ul>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default ViewedByChild;
