import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectBaseUrl } from "selectors";
import { getRelatedContent } from "actions/dashboard";
import {
  selectRelatedData,
  selectVideoPageData,
  selectCountryCode,
  selectDashboardApi,
  selectItemTypes,
  selectIsFetchingRelatedContent
} from "selectors/dashboard";
import {
  selectSelectedChildAge,
  selectSelectedChildId
} from "selectors/children";
import get from "lodash/get";
import Cookies from "js-cookie";
import RelatedTile from "./RelatedTile";
import styles from "./_RelatedContent.module.scss";
import InfiniteScroll from "react-bidirectional-infinite-scroll";
import { withRouter } from "react-router-dom";
import { platform } from "helper.js";
import c from "classnames";
import { cookieParams } from "utils";

// let relatedContentArray = [];
class RelatedContent extends Component {
  constructor(props) {
    super(props);
    const { bumperScreenData = {} } = this.props;
    const { time_seconds: timeSeconds } = bumperScreenData;

    this.state = {
      items: [],
      length: 10,
      seconds: timeSeconds,
      isPush: true,
      itemsClicked: false,
      noContentRedirected: false
    };
    this.timer = 0;
    this.isScrolling = 0;
  }

  static propTypes = {
    baseUrl: PropTypes.string,
    getRelatedContent: PropTypes.func,
    videoPageData: PropTypes.object,
    selectedChildAge: PropTypes.number,
    dashboardApiData: PropTypes.object,
    dialogSettings: PropTypes.object,
    countryCode: PropTypes.string,
    relatedData: PropTypes.array,
    selectedChildId: PropTypes.string,
    itemTypes: PropTypes.objectOf(PropTypes.string),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }),
    bumperScreenData: PropTypes.shape({
      time_seconds: PropTypes.number
    })
  };

  static defaultProps = {
    baseUrl: "",
    getRelatedContent() {},
    videoPageData: {},
    selectedChildAge: "",
    dashboardApiData: {},
    dialogSettings: {},
    countryCode: "",
    relatedData: [],
    selectedChildId: "",
    itemTypes: {},
    history: {
      push() {}
    },
    bumperScreenData: {
      time_seconds: ""
    }
  };

  componentDidMount() {
    const {
      getRelatedContent,
      baseUrl,
      videoPageData,
      selectedChildAge,
      dialogSettings,
      countryCode,
      ApiVerson
    } = this.props;

    const { length } = this.state;
    /*const modal = Cookies.get("modal");
    if (!modal) {
      relatedContentArray = [];
      Cookies.set("modal", true);
    }*/
    getRelatedContent(
      baseUrl,
      get(videoPageData, "related_video_limit", ""),
      selectedChildAge,
      platform,
      get(dialogSettings, "data.entityId", ""),
      countryCode,
      ApiVerson
    );
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
    this.setState({
      items: [].concat(this.getItems(0, length)),
      isPush: true
    });
  }

  countDown = () => {
    const { pause } = this.props;
    if (!pause) {
      let seconds = this.state.seconds - 1;

      this.setState({
        seconds: seconds
      });

      // Check if we're at zero.
      if (seconds === -1) {
        clearInterval(this.timer);
        this.goToPage();
        Cookies.set("searchText","", cookieParams);
        Cookies.remove("searchText");
      }
    }
  };

  goToPage = () => {
    const {
      relatedData,
      onClose,
      selectedChildId,
      itemTypes,
      history
    } = this.props;

    let type = relatedData && relatedData.length ? relatedData["0"].type : "";
    let relatedDataFirstId =
      relatedData && relatedData.length ? relatedData["0"].id : "";

    if (type && selectedChildId && relatedDataFirstId) {
      onClose();
      switch (type) {
        case itemTypes.videos:
          history.push(`/${selectedChildId}/video/${relatedDataFirstId}`);
          return;
        case itemTypes.books:
          history.push(`/${selectedChildId}/book/${relatedDataFirstId}`);
          return;
        case itemTypes.games:
          history.push(`/${selectedChildId}/game/${relatedDataFirstId}`);
          return;
        default:
          return "";
      }
    }
  };

  getItems = (totalItemsInArray, length) => {
    const { relatedData } = this.props;
    return relatedData.slice(totalItemsInArray, totalItemsInArray + length);
  };

  handleHorizontalScroll = (position, previousPosition) => {
    let { items, length, seconds } = this.state;
    clearTimeout( this.isScrolling );
    clearInterval(this.timer);
    let that = this;
    this.isScrolling = setTimeout(function() {
      if (seconds > 0) {
        that.timer = setInterval(that.countDown, 1000);
      }
    }, 500);
    let scrollablePosition = document.getElementsByClassName("tiles_wrapper")[1].getElementsByTagName("div")[0].scrollWidth - document.getElementsByClassName("tiles_wrapper")[1].offsetWidth;
    if (scrollablePosition - position < 50) {
      items = items.concat(this.getItems(items.length, length));
      this.setState({ items });
    }
  }

  componentDidUpdate(prevProps) {
    const { relatedData, isFetchingRelatedContent } = this.props;
    const { length, items, itemsClicked, noContentRedirected } = this.state;
    if (relatedData && relatedData !== prevProps.relatedData) {
      if (items.length && !itemsClicked) {
        this.setState({
          items: items,
          itemsClicked: true
        });
      }
      if (!items.length) {
        this.setState({
          items: [].concat(this.getItems(0, length))
        });
      }
    }
    else if (!isFetchingRelatedContent && relatedData.length === 0 && !noContentRedirected) {
      this.redirectHomeScreen();
    }
  }

  redirectHomeScreen = () => {
    const { onClose } = this.props;
    setTimeout(() => {
      onClose();
    }, 5000);
    this.setState({
      noContentRedirected: true
    });
  };

  updateTile = (id, status) => {
    const { items } = this.state;
    let clickedItem = items.filter(function(item) {
      return item['id'] === id;
    });

    if (status) {
      clickedItem['0']['favorite'] = 0;
    }
    else {
      clickedItem['0']['favorite'] = 1;
    }

    let updatedItem = items.map((item, index) => {
      if (item['id'] === id) {
        return clickedItem['0'];
      }
      return item;
    });

    this.setState({
      items: updatedItem,
      itemsClicked: false
    });
  }

  render() {
    const { relatedData, onClose, pause, isFetchingRelatedContent } = this.props;
    const { seconds } = this.state;
    return (
      <React.Fragment>
        {!isFetchingRelatedContent && relatedData && relatedData.length ? (
          <div className={c(
            "tiles_wrapper",
            styles.tiles_wrapper
          )}>
            <InfiniteScroll
              onScroll={this.handleHorizontalScroll}
              horizontal
            >
              {this.state.items.map((dataElement, index) => {
                return (
                  <RelatedTile
                    dataElement={dataElement}
                    key={index}
                    relatedDataFirstId={get(relatedData, "0.id", "")}
                    onClose={onClose}
                    seconds={seconds}
                    pause={pause}
                    updateTile={(id, status) => this.updateTile(id, status)}
                  />
                );
              })}
            </InfiniteScroll>
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = state => {
  return {
    baseUrl: selectBaseUrl(state),
    videoPageData: selectVideoPageData(state),
    selectedChildAge: selectSelectedChildAge(state),
    dashboardApiData: selectDashboardApi(state),
    dialogSettings: get(state, "settings.dialog", {}),
    countryCode: selectCountryCode(state),
    relatedData: selectRelatedData(state),
    selectedChildId: selectSelectedChildId(state),
    itemTypes: selectItemTypes(state),
    bumperScreenData: get(state, "appManifest.data.bumper_screen", {}),
    ApiVerson: get(state, "common.apiversiondata"),
    isFetchingRelatedContent: selectIsFetchingRelatedContent(state)
  };
};

export const mapDispatchToProps = dispatch => ({
  getRelatedContent(
    baseUrl,
    contentLimit,
    age,
    deviceModel,
    itemId,
    countryCode,
    ApiVerson
  ) {
    dispatch(
      getRelatedContent(
        baseUrl,
        contentLimit,
        age,
        deviceModel,
        itemId,
        countryCode,
        ApiVerson
      )
    );
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RelatedContent)
);
