import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import c from "classnames";
import get from "lodash/get";

import basicTemplateClasses from "../Templates/_BasicTemplate.module.scss";
import classes from "./_styles.module.scss";
import Image from "../Global/Image";
import BasicTemplate from "components/Templates/BasicTemplate";
import { BACKGROUNDS } from "constants/styles";

class Loading extends React.Component {
  static propTypes = {
    loadingScreenData: PropTypes.object
  };

  static defaultProps = {
    loadingScreenData: {
      sub_heading_text: "ORIGINALS"
    }
  };

  render() {
    const { loadingScreenData, loading, randomMessagesList } = this.props;
    const { sub_heading_text: subHeadingText } = loadingScreenData;

    let randomNumber;
    if (typeof randomMessagesList !== 'undefined') {
      randomNumber = Math.floor(Math.random() * randomMessagesList.length) + 1;
    }

    return (
      <BasicTemplate
        background={BACKGROUNDS.BG_IMAGE}
        bgClassName={loading ? "fixed_layout" : ""}
      >
        <div
          className={c(
            basicTemplateClasses.basic_template_content_wrapper,
            classes.loading_wrapper
          )}
        >
          <Image
            className={classes.logo_image}
            folderName="ui_and_learning_area"
            fileName="curious_world_logo_double"
            alt="CW Logo"
          />
          <span className={classes.loading_sub_text}>{subHeadingText}</span>
          <Image
            className={classes.spinner}
            folderName="ui_and_learning_area"
            fileName="spinner"
            alt="Loading"
          />
          {typeof randomMessagesList !== 'undefined' && <div className={classes.randomMessage}>{randomMessagesList[randomNumber - 1]}</div>}
        </div>
      </BasicTemplate>
    );
  }
}

const mapStateToProps = state => ({
  loadingScreenData: get(state, "appManifest.data.loading"),
  randomMessagesList: get(state, "common.messageList.laoder_message")
});

export default connect(mapStateToProps)(Loading);
