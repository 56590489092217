import React from "react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import Image from "../Global/Image";
import styles from "./_SidebarLayoverTemplate.module.scss";
import { cookieParams } from "utils";

class SidebarLayoverTemplate extends React.Component {
  static propTypes = {
    headingText: PropTypes.string,
    onClose: PropTypes.func
  };

  static defaultProps = {
    headingText: "",
    onClose() {}
  };

  closeLayover = () => {
    Cookies.set("showAnimation", "TRUE", cookieParams);
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const { children, headingText } = this.props;
    return (
      <React.Fragment>
        <div className={styles.layover_overlay} onClick={this.closeLayover} />
        <div className={styles.layover_wrapper}>
          <div className={styles.layover}>
            <Image
              folderName="static"
              fileName="close_window"
              className={styles.close_icon}
              onClick={this.closeLayover}
            />
            <div className={styles.heading_text}>{headingText}</div>
            {children}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SidebarLayoverTemplate;
