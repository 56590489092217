import {
  LOGIN_EVENT_REQUEST,
  LOGIN_EVENT_SUCCESS,
  LOGIN_EVENT_FAILURE,
  REACT_LOGIN,
  LOGOUT_COOKIE_CLEAR
} from "../constants/actions";
import get from "lodash/get";
import { apiPostLogin, apiPost, generateUrl } from "./core";

export const loginEventRequest = () => ({
  type: LOGIN_EVENT_REQUEST,
  isFetching: true,
  errorMessage: ""
});

export const loginEventSuccess = data => ({
  type: LOGIN_EVENT_SUCCESS,
  isFetching: false,
  errorMessage: "",
  data
});

export const loginEventFailure = (err, errorMessage) => ({
  type: LOGIN_EVENT_FAILURE,
  isFetching: false,
  data: err,
  errorMessage
});

export const loginEvent = (baseUrl, params) => {
  return dispatch => {
    dispatch(loginEventRequest());
    return apiPostLogin({
      url: `${baseUrl}/Login`,
      params
    })
      .then(response => dispatch(loginEventSuccess(get(response, "data", []))))
      .catch(err =>
        dispatch(
          loginEventFailure(
            get(err, "response.data", []),
            get(err, "message", [])
          )
        )
      );
  };
};

export const reactLogin = () => ({
  type: REACT_LOGIN
});

export const logoutCookieClearSuccess = () => {
  return {
    type: LOGOUT_COOKIE_CLEAR
  };
};

export const logoutCookieClear = (baseUrl) => {
  const url = generateUrl(
    baseUrl,
    `/react/logout`
  );

  return dispatch => {
    return apiPost({ url })
      .then(response =>
        dispatch(logoutCookieClearSuccess())
      )
      .catch(err => dispatch(logoutCookieClearSuccess()));
  };
};
