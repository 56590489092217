import React from "react";
import get from "lodash/get";
import classes from "./_LearningArea.module.scss";

class LearningArea extends React.Component {
  render() {
    const { learning_areas, parentsAreaData } = this.props;
    const keys = learning_areas && Object.keys(learning_areas);
    return (
      <React.Fragment>
        <div className={classes.section_title}>
          {parentsAreaData.learning_areas}
        </div>

        <ul className={classes.learning_areas_wrapper}>
          {learning_areas &&
            keys.map((key, index) => {
              const percentage = get(learning_areas[key], "percentage", "");
              return (
                <li key={index}>
                  <img
                    className={classes.learning_areas_img}
                    src={get(learning_areas[key], "image", "")}
                    alt={key}
                  />
                  <div className={classes.learning_areas_text_wrapper}>
                    <div className={classes.learning_areas_title}>
                      <span>{key}: </span>
                      {get(learning_areas[key], "time", "")}
                    </div>
                    <div className={classes.progress_bar_wrapper}>
                      <div
                        className={classes.progress_bar}
                        style={{ width: percentage + "%" }}
                      />
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      </React.Fragment>
    );
  }
}

export default LearningArea;
