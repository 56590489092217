import {
  ADD_TO_FAVOURITES_REQUEST,
  ADD_TO_FAVOURITES_SUCCESS,
  ADD_TO_FAVOURITES_FAILURE,
  REMOVE_TO_FAVOURITES_REQUEST,
  REMOVE_TO_FAVOURITES_SUCCESS,
  REMOVE_TO_FAVOURITES_FAILURE
} from "../constants/actions";
import get from "lodash/get";
import { apiPost, generateUrl } from "./core";

export const addToFavouritesRequest = id => ({
  type: ADD_TO_FAVOURITES_REQUEST,
  isFetching: true,
  isId: id,
  errorMessage: ""
});

export const addToFavouritesSuccess = data => ({
  type: ADD_TO_FAVOURITES_SUCCESS,
  isFetching: false,
  errorMessage: ""
});

export const addToFavouritesFailure = errorMessage => ({
  type: ADD_TO_FAVOURITES_FAILURE,
  isFetching: false,
  errorMessage
});

export const addToFavourites = (baseUrl, params) => {
  return dispatch => {
    dispatch(addToFavouritesRequest(params.rid));
    return apiPost({
      url: generateUrl(baseUrl, `/favourite/add`),
      params
    })
      .then(response =>
        dispatch(addToFavouritesSuccess(get(response, "data", [])))
      )
      .catch(err => dispatch(addToFavouritesFailure(get(err, "message", []))));
  };
};

export const removeToFavouritesRequest = id => ({
  type: REMOVE_TO_FAVOURITES_REQUEST,
  isFetching: true,
  isId: id,
  errorMessage: ""
});

export const removeToFavouritesSuccess = data => ({
  type: REMOVE_TO_FAVOURITES_SUCCESS,
  isFetching: false,
  errorMessage: ""
});

export const removeToFavouritesFailure = errorMessage => ({
  type: REMOVE_TO_FAVOURITES_FAILURE,
  isFetching: false,
  errorMessage
});

export const removeToFavourites = (baseUrl, params) => {
  return dispatch => {
    dispatch(removeToFavouritesRequest(params.rid));
    return apiPost({
      url: generateUrl(baseUrl, `/favourite/remove`),
      params
    })
      .then(response =>
        dispatch(removeToFavouritesSuccess(get(response, "data", [])))
      )
      .catch(err =>
        dispatch(removeToFavouritesFailure(get(err, "message", [])))
      );
  };
};
