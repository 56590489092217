import { createSelector } from "reselect";
import get from "lodash/get";

const selectEpubData = (state) =>
  get(state, "epub.epubData");

const selectBookId = (state, props = {}) => props.bookId;

export const selectBookDataBasedOnId = createSelector(
  [selectEpubData, selectBookId],
  (epubData = [], bookId = "") => epubData.find(data => data.bookId === bookId)
);

const selectCurrentPage = (state, props = {}) => props.currentPage + 1;

export const selectPageAudioDetails = createSelector(
  [selectBookDataBasedOnId, selectCurrentPage],
  (bookDataBasedOnId, currentPage) => {
    if (currentPage === 1) {
      return {
        page1: "",
        page2: bookDataBasedOnId.audio[`page${currentPage}`]
      }
    }
    else {
      return {
        page1: bookDataBasedOnId.audio[`page${currentPage}`],
        page2: bookDataBasedOnId.audio[`page${currentPage + 1}`]
      }
    }
  }
);
