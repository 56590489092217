import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import classes from "./_styles.module.scss";
import styles from "./_parentalChildren.module.scss";

import Image from "../Global/Image";
import { WHO_IS_PLAYING_SCREEN_TYPES } from "../../constants";
import { selectSelectedChildId } from "selectors/children";

const WhoIsPlayingHeader = ({
  isLayover,
  getClassName,
  onClose,
  headingText,
  subHeadingText,
  subHeadingDescription,
  screenType,
  closeIconClass,
  selectedChildId
}) => {
  if (screenType === WHO_IS_PLAYING_SCREEN_TYPES.LAYOVER) {
    return null;
  }

  return (
    <React.Fragment>
      {(selectedChildId ||
        screenType === WHO_IS_PLAYING_SCREEN_TYPES.MY_CHILDREN) && (
          <Image
            folderName="static"
            fileName="close_window"
            className={getClassName(closeIconClass)}
            onClick={onClose}
          />
        )}
      <Image
        className={getClassName("logo_image")}
        folderName="ui_and_learning_area"
        fileName="curious_world_logo"
        alt="CW Logo"
      />
      {screenType === WHO_IS_PLAYING_SCREEN_TYPES.PARENT_DASHBOARD ||
      screenType === WHO_IS_PLAYING_SCREEN_TYPES.HOME ? (
        <span className={classes.heading_text}>{headingText}</span>
      ) : (
        ""
      )}
      {screenType === WHO_IS_PLAYING_SCREEN_TYPES.MY_CHILDREN && (
        <span className={styles.sub_heading_text}>{subHeadingText}</span>
      )}
      {screenType === WHO_IS_PLAYING_SCREEN_TYPES.MY_CHILDREN && (
        <span className={styles.sub_heading_description}>
          {subHeadingDescription}
        </span>
      )}
    </React.Fragment>
  );
};

WhoIsPlayingHeader.propTypes = {
  isLayover: PropTypes.bool,
  getClassName: PropTypes.func,
  onClose: PropTypes.func,
  headingText: PropTypes.string,
  subHeadingText: PropTypes.string,
  subHeadingDescription: PropTypes.string,
  closeIconClass: PropTypes.string,
  screenType: PropTypes.string,
  selectedChildId: PropTypes.string
};

WhoIsPlayingHeader.defaultProps = {
  isLayover: false,
  getClassName() {},
  onClose() {},
  subHeadingText: "",
  subHeadingDescription: "",
  closeIconClass: "close_icon_right",
  screenType: WHO_IS_PLAYING_SCREEN_TYPES.HOME,
  selectedChildId: ""
};

export const mapStateToProps = state => ({
  selectedChildId: selectSelectedChildId(state)
});

export default connect(
  mapStateToProps,
  null
)(WhoIsPlayingHeader);
