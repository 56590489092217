import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import FullScreenModalTemplate from "../Templates/FullScreenModalTemplate";
import { DIALOG_IDS } from "../../constants";
import { selectEventMessage } from "selectors/event";
import { closeDialog } from "actions/settings";
import styles from "./_eventMessage.module.scss";
import { selectUserId } from "selectors/profile";

class EventMessage extends React.Component {
  static propTypes = {
    dialogSettings: PropTypes.object,
    eventMessage: PropTypes.object,
    closeEventMessage: PropTypes.func
  };

  static defaultProps = {
    dialogSettings: {},
    eventMessage: {},
    closeEventMessage() {}
  };

  componentDidUpdate(prevProps) {
    const { dialogSettings, userId } = this.props;
    if (dialogSettings.id === DIALOG_IDS.EVENT_MESSAGE && userId) {
      let dataEMD = localStorage.getItem("eventMessageDialogShowed");
      if (dataEMD) {
        let dataEMDArr = dataEMD.split(",");
        if (dataEMDArr.indexOf(userId) === -1) {
          let addedEMD = userId;
          if (dataEMD) {
            addedEMD = `${dataEMD},${userId}`
          }
          localStorage.setItem("eventMessageDialogShowed", addedEMD);
        }
      }
      else {
        localStorage.setItem("eventMessageDialogShowed", userId);
      }
    }
  }

  onClose = () => {
    const { closeEventMessage } = this.props;
    closeEventMessage();
  };

  render() {
    const { dialogSettings, eventMessage, eventsData, userId } = this.props;
    let dataEMD = localStorage.getItem("eventMessageDialogShowed");
    let EMDShowed = false;
    if (dataEMD) {
      let dataEMDArr = dataEMD.split(",");
      if (userId && dataEMDArr) {
        if (dataEMDArr.indexOf(userId) !== -1) {
          EMDShowed = true;
        }
      }
    }

    if (dialogSettings.id !== DIALOG_IDS.EVENT_MESSAGE) {
      return null;
    }
    if (!EMDShowed) {
      return (
        <React.Fragment>
          <FullScreenModalTemplate onClose={this.onClose} className={styles.event_message_popup} page="EVENT_MESSAGE">
            <div className={styles.event_header}>
              <img
                src={get(eventMessage, "image.desktop", "")}
                className={styles.event_image}
                alt=""
              />
              {/*<div className={styles.event_title}>{get(eventMessage, "title", "")}</div>*/}
            </div>
            <div className={styles.event_content}>
              <div className={styles.event_subtitle}>{get(eventMessage, "title", "")}</div>
              <div
                className={styles.event_desc}
                dangerouslySetInnerHTML={{ __html: get(eventMessage, "description", "") }}
              />
              <div className={styles.event_button}
                onClick={this.onClose}
              >{eventsData.button_text}</div>
            </div>
          </FullScreenModalTemplate>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

export const mapStateToProps = state => ({
  dialogSettings: get(state, "settings.dialog"),
  eventMessage: selectEventMessage(state),
  eventsData: get(state, "appManifest.data.events"),
  userId: selectUserId(state)
});

export const mapDispatchToProps = dispatch => ({
  closeEventMessage() {
    dispatch(closeDialog());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventMessage);
