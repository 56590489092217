import React, { Component } from "react";
import PropTypes from "prop-types";

class CustomButton extends Component {
  render() {
    const { content, config, disabled, className, ...others } = this.props;
    const classes = ["app_button", "btn"];
    classes.push(className);

    return (
      <React.Fragment>
        <button
          type={config.type}
          disabled={disabled}
          className={classes.join(" ")}
          id={config.id ? config.id : null}
          {...others}
        >
          {content}
        </button>
      </React.Fragment>
    );
  }
}

export default CustomButton;

CustomButton.propTypes = {
  config: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired
};

CustomButton.defaultProps = {
  disabled: false
};
