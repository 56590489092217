import React, { Component } from "react";
import PropTypes from "prop-types";
import BasicTemplate from "../Templates/BasicTemplate";
import styles from "../AddEditChild/_styles.module.scss";
import c from "classnames";
import basicTemplateClasses from "components/Templates/_BasicTemplate.module.scss";
import get from "lodash/get";
import { connect } from "react-redux";
import { BACKGROUNDS } from "../../constants/styles";
import CustomButton from "components/Global/Button";
import FormValidator from "utils/FormValidator";
import { loginEvent, reactLogin } from "actions/login";
import { selectBaseUrl } from "selectors";
import { selectCountryCode } from "selectors/dashboard";
import Loader from "../Loader";
import { PATHS } from "../../constants/url";
import Cookies from "js-cookie";
import classes from "./_styles.module.scss";
import AgeCheck from "../AgeCheck";
import { Link } from "react-router-dom";
import Image from "components/Global/Image";
import { launchAgeCheck, routeValuecheck } from "actions/settings";
import { deviceInfo, signIn } from "../../helper.js";
import { webAppEvent } from "actions/common";
import Error from "../Error";
import { setScreenOrientation, isLocalServer, cookieParams } from "utils";
import { PAGE_MODE } from "constants/screens";
import { isMobile, isTablet } from "react-device-detect";
import Loading from "../Loading";
import envUrl from "envPath.json";

let apiErrorMessageText = "";
let oKButton = false;
let flag = 0;
export const mapStateToProps = (state) => {
  return {
    loginScreenData: get(state, "appManifest.data.login", {}),
    baseUrl: selectBaseUrl(state),
    loginDetails: get(state, "login"),
    homePageData: get(state, "appManifest.data.home_page"),
    kafkaUrl: get(state, "appManifest.data.app_settings.kafka_url"),
    errorMessageApi: get(state, "login.errorMessage"),
    errorMessageManifest: get(state, "appManifest.errorMessage"),
    tokenName: envUrl.token_name,
    countryCode: selectCountryCode(state),
  };
};
export const mapDispatchToProps = (dispatch) => ({
  loginEvent(baseUrl, params) {
    dispatch(loginEvent(baseUrl, params));
  },
  launchAgeChecked(params) {
    dispatch(launchAgeCheck(params));
  },
  routeValuecheck(params) {
    dispatch(routeValuecheck(params));
  },
  webAppEvent(url, params) {
    dispatch(webAppEvent(url, params));
  },
  reactLogin() {
    dispatch(reactLogin());
  },
});

class Login extends Component {
  static propTypes = {
    email: PropTypes.string,
    password: PropTypes.string,
    button_text: PropTypes.string,
    loginEvent: PropTypes.func,
    launchAgeChecked: PropTypes.func,
    baseUrl: PropTypes.string,
  };

  static defaultProps = {
    email: "Email",
    password: "Password",
    button_text: "Log In",
    loginEvent: () => {},
    launchAgeChecked() {},
    baseUrl: "",
  };

  constructor(props) {
    super(props);
    const { email, password, button_text, loginScreenData } = this.props;
    this.initialForm = {
      email: {
        elementConfig: {
          type: "text",
          placeholder: loginScreenData.email || email,
          // maxLength: 24,
          tabIndex: 0,
          autoComplete: "username", //used from accidentally showing pre-filled
        },
        value: "",
        errorMessage: "",
      },
      password: {
        elementConfig: {
          type: "password",
          placeholder: loginScreenData.password || password,
          // maxLength: 24,
          tabIndex: 0,
          autoComplete: "current-password", //used from accidentally showing pre-filled
        },
        value: "",
        errorMessage: "",
      },
    };
    this.state = {
      loginForm: { ...this.initialForm },
      button: {
        buttonConfig: {
          type: "submit",
        },
        text: loginScreenData.button_text || button_text,
      },
      mobileErrormsg: false,
      errorStatus: false,
      paramsLogin: {},
      loading: false,
      defaultInputFontSize: 0,
    };
  }

  componentDidMount() {
    const { history, loginScreenData, tokenName } = this.props;
    let cookieDomain = "";
    if (loginScreenData.access_token_domain && !isLocalServer()) {
      cookieDomain = {
        domain: loginScreenData.access_token_domain,
      };
    }
    if (isTablet) {
      setScreenOrientation(PAGE_MODE.TABLET);
    } else {
      setScreenOrientation(PAGE_MODE.LOGIN);
    }
    const token = Cookies.get(tokenName, { domain: cookieDomain });
    // const token = Cookies.get("access_token", { domain: cookieDomain });
    if (token) {
      history.push(PATHS.HOME);
    }
    let self = this;
    window.addEventListener(
      "resize",
      function() {
        self.setEmailFieldHeight("email", "password");
        self.setDefaultInputFontSize();
      },
      false
    );
    this.setDefaultInputFontSize();
  }

  componentDidUpdate(nextProps) {
    const {
      history,
      reactLogin,
      loginDetails: { data },
      loginScreenData,
      tokenName,
    } = this.props;
    if (data && data !== nextProps.loginDetails.data) {
      if (data.status && data.status.code === 1) {
        reactLogin();
        let tokenCookieParams = "";
        if (loginScreenData.access_token_domain && !isLocalServer()) {
          tokenCookieParams = {
            domain: loginScreenData.access_token_domain,
            sameSite: "none",
            secure: true,
            expires: 365,
          };
        }
        console.log("tokenCookieParams", tokenCookieParams);
        Cookies.set(tokenName, data.data.access_token, tokenCookieParams);
        Cookies.set(
          "subscriptionStatus",
          data.data.subscriptionStatus,
          cookieParams
        );
        Cookies.set(
          "subscriptionType",
          data.data.subscriptionType,
          cookieParams
        );
        if (data.data.remainingFreeDays) {
          Cookies.set(
            "remainingFreeDays",
            data.data.remainingFreeDays,
            cookieParams
          );
        }
        setScreenOrientation(PAGE_MODE.HOME);
        this.eventLogIn(data.data.uid);
        if (window.location.search) {
          history.push(window.location.search);
        } else if (window.location.pathname) {
          history.push(window.location.pathname);
        } else {
          history.push(PATHS.HOME);
        }
      }
    }
  }

  eventLogIn = (uid) => {
    const { webAppEvent, kafkaUrl, countryCode } = this.props;
    const { paramsLogin } = this.state;
    let params = [
      {
        ...deviceInfo,
        ...signIn(countryCode),
        ...paramsLogin,
        mobi_userId: uid,
      },
    ];
    webAppEvent(kafkaUrl, params);
  };

  inputChangedHandler = (inputValue, inputIdentifier) => {
    const { loginForm } = this.state;
    const updatedForm = { ...loginForm };
    const updatedFormElement = { ...updatedForm[inputIdentifier] };
    updatedFormElement.value = inputValue.trim();
    updatedFormElement.errorMessage = "";
    updatedForm[inputIdentifier] = updatedFormElement;
    this.setState({ loginForm: updatedForm, errorStatus: false });
  };

  inputKeyUpHandler = (e, inputValue, inputIdentifier) => {
    if (inputIdentifier === "email") {
      this.setEmailFieldHeight(inputIdentifier, "password");
      this.resizeTextSize(e, inputIdentifier);
    }
  };

  setDefaultInputFontSize = () => {
    let inputField = document.getElementById("email");
    if (inputField) {
      let element = inputField.nextSibling;
      inputField.style.fontSize = "";
      element.style.fontSize = "";
      let inputStyle = window
        .getComputedStyle(inputField, null)
        .getPropertyValue("font-size");
      let inputFontSize = parseFloat(inputStyle);
      this.setState({ defaultInputFontSize: inputFontSize });
    }
  };

  setEmailFieldHeight = (inputIdentifier, heightBasedOnIdentifier) => {
    let inputField = document.getElementById(inputIdentifier);
    if (inputField) {
      let basedOnField = document.getElementById(heightBasedOnIdentifier);
      let basedOnFieldHeight = basedOnField.offsetHeight;
      inputField.style.height = basedOnFieldHeight + "px";
    }
  };

  resizeTextSize = (e, inputIdentifier) => {
    const { defaultInputFontSize } = this.state;
    let inputField = document.getElementById(inputIdentifier);
    let inputFieldWidth = inputField.offsetWidth;
    let element = inputField.nextSibling;
    element.style.display = "block";
    let style = window
      .getComputedStyle(element, null)
      .getPropertyValue("font-size");
    let fontSize = parseFloat(style);
    if (
      e.keyCode !== 8 &&
      inputFieldWidth < element.offsetWidth &&
      fontSize - 1 >= 16
    ) {
      this.reduceFontSize(inputFieldWidth, element, fontSize, (newFontSize) => {
        element.style.fontSize = newFontSize + "px";
        inputField.style.fontSize = newFontSize + "px";
      });
    } else if (e.keyCode === 8 && fontSize + 1 <= defaultInputFontSize) {
      this.increaseFontSize(
        inputFieldWidth,
        element,
        fontSize,
        (newFontSize) => {
          element.style.fontSize = newFontSize + "px";
          inputField.style.fontSize = newFontSize + "px";
        }
      );
    }
    element.style.display = "none";
  };

  reduceFontSize = (inputWidth, span, fontSize, callback) => {
    if (span.offsetWidth > inputWidth && fontSize - 1 > 16) {
      span.style.fontSize = fontSize - 1 + "px";
      this.reduceFontSize(inputWidth, span, fontSize - 1, callback);
    } else {
      callback(fontSize);
    }
  };

  increaseFontSize = (inputWidth, span, fontSize, callback) => {
    const { defaultInputFontSize } = this.state;
    if (
      span.offsetWidth <= inputWidth &&
      fontSize + 1 <= defaultInputFontSize
    ) {
      span.style.fontSize = fontSize + 1 + "px";
      this.increaseFontSize(inputWidth, span, fontSize + 1, callback);
    } else {
      if (fontSize - 1 < 16 || fontSize === defaultInputFontSize) {
        callback(fontSize);
      } else {
        callback(fontSize - 1);
      }
    }
  };

  submitForm = (e) => {
    const { loginForm } = this.state;
    const { loginScreenData } = this.props;
    flag = 1;
    e.preventDefault();
    const validateObj = {};
    const formKeys = Object.keys(loginForm);
    formKeys.forEach((key) => {
      validateObj[key] = loginForm[key].value;
    });
    const formStatus = FormValidator.validateLoginForm(
      validateObj,
      loginScreenData
    );
    const { hasErrors, ...errorMessages } = formStatus;
    if (hasErrors) {
      const updatedForm = { ...loginForm };
      formKeys.forEach((key) => {
        const inputKey = `${key}Message`;
        const errorMessage = errorMessages[inputKey];
        if (errorMessage && errorMessage !== "") {
          // updatedForm[key].valid = false;
          updatedForm[key].errorMessage = errorMessage;
          oKButton = false;
          if (isMobile) {
            apiErrorMessageText = errorMessage;
            oKButton = true;
            this.setState({ mobileErrormsg: true });
          }
        }
      });
      this.setState({ loginForm: updatedForm });
    } else {
      this.handleLogin(validateObj);
    }
  };

  handleLogin = (userDetail) => {
    const { loginEvent, baseUrl, loginScreenData } = this.props;
    let scope = "react";
    let client_id = "f940a35d-b6ae-4657-8b4b-79c12efe2bbc";
    let params = {
      mail: userDetail.email,
      password: userDetail.password,
      client_secret: loginScreenData.client_secret,
      client_id: client_id,
      scope: scope,
      grant_type: loginScreenData.grant_type,
      deviceId: deviceInfo.deviceId,
    };
    this.setState({
      errorStatus: true,
      paramsLogin: {
        // client_id: client_id,
        // scope: scope,
        action: "Success",
      },
    });
    loginEvent(baseUrl, params);
  };

  onForget = () => {
    const { routeValuecheck, launchAgeChecked = {} } = this.props;
    launchAgeChecked(true);
    routeValuecheck("forgetPas");
  };

  onSignUp = () => {
    const { routeValuecheck, launchAgeChecked = {} } = this.props;
    launchAgeChecked(true);
    routeValuecheck("signUp");
  };

  privacyClick = () => {
    if (isTablet) {
      setScreenOrientation(PAGE_MODE.TABLET);
    } else {
      setScreenOrientation(PAGE_MODE.PRIVACY_POLICY);
    }
  };

  closeErrorpop = () => {
    this.setState({ mobileErrormsg: false });
    apiErrorMessageText = "";
    oKButton = false;
    flag = 0;
  };

  LoadingFn = () => {
    this.setState({ loading: true });
  };

  render() {
    Cookies.set("prevPageforPolicy", "Login", cookieParams);
    const { loginForm, button, mobileErrormsg, errorStatus } = this.state;
    const {
      loginDetails: { data, isFetching },
      loginScreenData: { sign_up_text, forgot_password_text },
      homePageData,
      errorMessageManifest,
      errorMessageApi,
    } = this.props;
    const formElementsArray = [];
    const formKeys = Object.keys(loginForm);
    formKeys.forEach((key) => {
      formElementsArray.push({
        id: key,
        config: loginForm[key],
      });
    });
    if (data && data.length === 0) {
      apiErrorMessageText = errorMessageApi;
    } else if (
      isMobile &&
      data &&
      data.status &&
      data.status.code === 0 &&
      flag === 1
    ) {
      if (
        data.status.message !== "" &&
        data.status.message !== null &&
        data.status.message !== undefined
      ) {
        oKButton = true;
        apiErrorMessageText = data.status.message;
      }
    } else if (errorMessageManifest) {
      apiErrorMessageText = errorMessageManifest;
    }
    return (
      <React.Fragment>
        {((apiErrorMessageText !== "" && apiErrorMessageText !== undefined) ||
          mobileErrormsg) && (
          <Error
            errorMessage={apiErrorMessageText}
            okButton={oKButton}
            closeErrorpop={this.closeErrorpop}
          />
        )}
        <React.Fragment>
          {this.state.loading || !homePageData ? (
            <Loading />
          ) : (
            <BasicTemplate
              background={BACKGROUNDS.LOGIN_BG_IMAGE}
              bgClassName="login_bg"
            >
              <React.Fragment>
                <div
                  className={c(
                    basicTemplateClasses.basic_template_content_wrapper,
                    styles.add_edit_wrapper,
                    classes.login_wrapper
                  )}
                >
                  <div className={c(styles.input_fields, classes.input_fields)}>
                    <form autoComplete="off" onSubmit={this.submitForm}>
                      {formElementsArray &&
                        formElementsArray.map((elem) => {
                          return (
                            <div
                              className={styles.profile_name_with_error}
                              key={elem.id}
                            >
                              <div className={styles.profile_name_wrapper}>
                                <input
                                  type={elem.config.elementConfig.type}
                                  className={c(
                                    styles.profile_name,
                                    classes.profile_name
                                  )}
                                  onChange={(event) =>
                                    this.inputChangedHandler(
                                      event.target.value,
                                      elem.id
                                    )
                                  }
                                  onKeyUp={(event) =>
                                    this.inputKeyUpHandler(
                                      event,
                                      event.target.value,
                                      elem.id
                                    )
                                  }
                                  id={elem.id}
                                  value={elem.config.value || ""}
                                  placeholder={
                                    elem.config.elementConfig.placeholder
                                  }
                                />
                                <span
                                  className={c(
                                    styles.profile_name,
                                    classes.profile_name,
                                    "email_input_span"
                                  )}
                                >
                                  {elem.config.value || ""}
                                </span>
                              </div>
                              {elem.config.errorMessage && !isMobile && (
                                <span className="error">
                                  {elem.config.errorMessage}
                                </span>
                              )}
                            </div>
                          );
                        })}
                      <div className={classes.login_button_wrapper}>
                        <CustomButton
                          config={button.buttonConfig}
                          content={button.text}
                          onClick={this.submitForm}
                          className={classes.login_button}
                          disabled={isFetching}
                        />
                        {isFetching && <Loader className="login_loader" />}
                      </div>
                      {data &&
                        data.status &&
                        data.status.code === 0 &&
                        !isMobile && (
                          <div
                            className={c("error", classes.common_form_error)}
                          >
                            {errorStatus && data.status.message}
                          </div>
                        )}
                    </form>
                    <div className={classes.signup_wrapper}>
                      <div className={classes.forget} onClick={this.onForget}>
                        {forgot_password_text}
                      </div>{" "}
                      <div className={classes.forget} onClick={this.onSignUp}>
                        {sign_up_text}
                      </div>
                    </div>
                  </div>
                </div>
                <AgeCheck LoadingFn={this.LoadingFn} />
                <div
                  className={c("privacy", "privacy_white")}
                  onClick={this.privacyClick}
                >
                  <Link to={PATHS.PRIVACY_POLICY}>
                    <Image
                      folderName="static"
                      fileName="info_privacy"
                      alt="Privacy Icon"
                    />
                    {homePageData["privacy_text"]}
                  </Link>
                </div>
              </React.Fragment>
            </BasicTemplate>
          )}
        </React.Fragment>
      </React.Fragment>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
