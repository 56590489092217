import get from "lodash/get";
import { connect } from "react-redux";
import { selectSelectedChildId } from "selectors/children";
import TermsAndPrivacyTemplate from "./TermsAndPrivacyTemplate";

export const mapStateToProps = state => ({
  url: get(state, "appManifest.data.app_settings.terms_conditions_url"),
  headingText: get(state, "appManifest.data.terms_conditions.heading_text"),
  selectedChildId: selectSelectedChildId(state)
});

export default connect(mapStateToProps)(TermsAndPrivacyTemplate);
