import deepFreeze from "deep-freeze-es6";
import {
  EVENT_BASED_CONTENT_REQUEST,
  EVENT_BASED_CONTENT_SUCCESS,
  EVENT_BASED_CONTENT_FAILURE
} from "constants/actions";

const initialState = {
  isFetching: false,
  errorMessage: "",
  eventBasedContent: ""
};

export const eventBasedContentRequest = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage
  };
};

export const eventBasedContentSuccess = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    eventBasedContent: action.eventBasedContent
  };
};

export const eventBasedContentFailure = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    eventBasedContent: action.eventBasedContent
  };
};

export default (state = initialState, action) => {
  deepFreeze(state);
  deepFreeze(action);

  switch (action.type) {
    case EVENT_BASED_CONTENT_REQUEST:
      return eventBasedContentRequest(state, action);
    case EVENT_BASED_CONTENT_SUCCESS:
      return eventBasedContentSuccess(state, action);
    case EVENT_BASED_CONTENT_FAILURE:
      return eventBasedContentFailure(state, action);
    default:
      return state;
  }
};
