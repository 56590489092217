import deepFreeze from "deep-freeze-es6";
import {
  READ_MANIFEST_REQUEST,
  READ_MANIFEST_SUCCESS,
  READ_MANIFEST_FAILURE
} from "constants/actions";

const initialState = {
  isFetching: false,
  data: {},
  errorMessage: ""
};

export const readManifestRequest = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage
  };
};

export const readManifest = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    data: action.data.data ? action.data.data : action.data,
    errorMessage: action.errorMessage
  };
};

export const readManifestFailure = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    data: action.data
  };
};

export default (state = initialState, action) => {
  deepFreeze(state);
  deepFreeze(action);

  switch (action.type) {
    case READ_MANIFEST_REQUEST:
      return readManifestRequest(state, action);
    case READ_MANIFEST_SUCCESS:
      return readManifest(state, action);
    case READ_MANIFEST_FAILURE:
      return readManifestRequest(state, action);
    default:
      return state;
  }
};
