import Cookies from "js-cookie";
import moment from "moment";
import get from "lodash/get";
import { isMobile } from "react-device-detect";

export const isAuthenticated = (cookieDomain, tokenName) => {
  // todo modify this logic
  const access_token = Cookies.get(tokenName, { domain: cookieDomain }); // fetching the token from browser
  return access_token || false;
};

export const getAgeFromBirthDate = birthDate => {
  return moment().diff(birthDate, "years");
};

export const isLocalServer = () => {
  return (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1" ||
    window.location.hostname === ""
  );
};


export const cookieParams = !isLocalServer() ? { sameSite: 'none', secure: true } : "";

export const getAvatarPositionFromUrl = (
  avatarImageUrl = "",
  avatarsList = {}
) => {
  const imageName = avatarImageUrl.substr(avatarImageUrl.lastIndexOf("/") + 1);
  let avatarIndex = -1;

  for (const [index, [key, value]] of Object.entries(
    Object.entries(avatarsList)
  )) {
    if (value === imageName) {
      avatarIndex = index;
      break;
    }
  }

  return +avatarIndex;
};

export const getAvatarIndexInUse = (
  children = {},
  avatarsList = {},
  childId
) => {
  let childList = children;
  if (childId) {
    childList = children.filter(function(child, i) {
      return child.id !== childId;
    });
  }
  return childList.map((child, index) =>
    getAvatarPositionFromUrl(get(child, "metadata.avatar", {}), avatarsList)
  );
};

export const setScreenOrientation = ScreenOrientation => {
  if (isMobile && document.fullscreenEnabled) {
    if (document.fullscreenElement) {
      window.screen.orientation
        .lock(ScreenOrientation)
        .then(function() {})
        .catch(function(error) {
          console.log(error);
        });
    } else {
      document.documentElement
        .requestFullscreen()
        .then(function() {
          window.screen.orientation
            .lock(ScreenOrientation)
            .then(function() {})
            .catch(function(error) {
              console.log(error);
            });
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }
};

export const createOrFetchIndexedDb = (db_name) => {
  return new Promise(function (resolve, reject) {
    if (window.indexedDB) {
      var request = indexedDB.open(db_name);
      request.onsuccess = function (event) {
        resolve(this.result);
      };
      request.onupgradeneeded = function(event) {
        resolve(this.result);
      };
      request.onerror = function (evt) {
        console.error("openDb:", evt.target.errorCode);
      };
    }
    else {
      console.error("Your browser doesn't support a stable version of IndexedDB.");
    }
  })
};

export const fetchObjectStore = (store_name, mode, db) => {
  if (db.objectStoreNames.contains(store_name)) {
    let tx = db.transaction([store_name], mode);
    return tx.objectStore(store_name);
  }
}

export const createOrFetchObjectStore = (store_name, mode, db) => {
  let objectStore;
  if (!db.objectStoreNames.contains(store_name)) {
    objectStore = db.createObjectStore(store_name, { keyPath: 'id' });
  }
  else {
    objectStore = fetchObjectStore(store_name, mode, db);
  }
  return objectStore;
}

export const deleteIndexedDb = async (db_name) => {
  if (window.indexedDB) {
    var req = await indexedDB.deleteDatabase(db_name);
    req.onsuccess = function () {
      console.log("IndexedDB Deleted");
    };
  }
  else {
    console.error("Your browser doesn't support a stable version of IndexedDB.");
  }
}

export const isBedTime = () => {
  if (moment().hour() >= 18 || moment().hour() < 5 || (moment().hour() === 5 && moment().minute() <= 29)) {
    return true;
  }
}
