import deepFreeze from "deep-freeze-es6";
import {
  GET_COUNTRY_REQUEST,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_FAILURE,
  GET_MANIFEST_RECOMMENDATION_REQUEST,
  GET_MANIFEST_RECOMMENDATION_SUCCESS,
  GET_MANIFEST_RECOMMENDATION_FAILURE,
  GET_MANIFEST_DATA_REQUEST,
  GET_MANIFEST_DATA_SUCCESS,
  GET_MANIFEST_DATA_FAILURE,
  GET_FAVOURITE_LIST_REQUEST,
  GET_FAVOURITE_LIST_SUCCESS,
  GET_FAVOURITE_LIST_FAILURE,
  GET_RELATED_CONTENT_REQUEST,
  GET_RELATED_CONTENT_SUCCESS,
  GET_RELATED_CONTENT_FAILURE,
  USER_DASHBOARD_REQUEST,
  USER_DASHBOARD_SUCCESS,
  USER_DASHBOARD_FAILURE
} from "constants/actions";
import { INDEXED_DB_CONST } from "../../constants";
import get from "lodash/get";
import Cookies from "js-cookie";
import { createOrFetchIndexedDb, createOrFetchObjectStore, cookieParams } from "utils";

const initialState = {
  isFetching: false,
  data: {},
  errorMessage: "",
  errorMessageFavList: "",
  manifestData: [],
  recommendedManifestData: [],
  favoriteList: [],
  country: {},
  relatedContent: [],
  countryCode: null,
  isFetchingParentDashboard: false,
  isFetchingFavList: true,
  isFetchingRelatedContent: false,
  isFetchingManifestData: true,
  isFetchingManifestRecommendation: true
};

export const getDashboardListRequest = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage
  };
};

export const getDashboardListSuccess = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    data: action.data
  };
};

export const getDashboardListFailure = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    data: action.data
  };
};

export const getCountrySuccess = (state, action) => {
  if (action.payload) {
    let country = {
      countryCode:  action.payload
    };
    Cookies.set("countryCode", country, cookieParams);
    return {
      ...state,
      country: country
    };
  }
  let country = {
    countryCode: state.countryCode
  };
  Cookies.set("countryCode", (state.countryCode && country) || action.country, cookieParams);
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    country: (state.countryCode && country) || action.country
  };
};

export const getCountryFailure = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    country: action.country
  };
};

export const getManifestRecommendationRequest = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    isFetchingManifestRecommendation: true
  };
};

export const getManifestRecommendationSuccess = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    recommendedManifestData: action.recommendedManifestData,
    isFetchingManifestRecommendation: false
  };
};

export const getManifestRecommendationFailure = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    recommendedManifestData: action.recommendedManifestData,
    isFetchingManifestRecommendation: false
  };
};

export const getManifestDataRequest = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    isFetchingManifestData: true
  };
};

export const getManifestDataSuccess = (state, action) => {
  let processedManifestData = [];
  if(action.manifestData.data) {
    let manifestData=action.manifestData.data;
    let categoryData = manifestData.filter(function(data, i) {
      if (
        data["category"] === "category" &&
        data["title"].toLowerCase() in action.itemType
      ) {
        return true;
      }
      return false;
    });

    let processedCategoryData = categoryData.map((catData, index) => ({
      thumbnails: catData.thumbnails.filter(data => {
        return data.indexOf("Icn.png") === data.length - "Icn.png".length;
      }),
      title: catData.title,
      type: action.itemType[catData.title.toLowerCase()],
      bgColor: catData.background_color
    }));

    processedManifestData = manifestData.reduce(
      (processedManifestData, e) => {
        let data, processedManifestNodes;
        if (e.category !== "title") {
          if (e.titles && e.titles.length && e.thumbnails["0"] !== null) {
            processedManifestNodes = e.titles.reduce(
              (processedManifestNodes, v) => {
                let contentNodes;
                if (v.type &&
                  v.thumbnails["0"] !== null &&
                  (
                    (v.type === action.itemType["games"] && v.isReactGame === true) ||
                    (v.type === action.itemType["videos"]) || 
                    (v.type === action.itemType["books"])
                  )
                ) {
                  let filteredCategoryData = processedCategoryData.filter(
                    function(categoryData, i) {
                      return categoryData["type"] === v.type;
                    }
                  );
                  contentNodes = {
                    id: v.id,
                    title: v.title,
                    type: v.type,
                    publishers: v.publishers,
                    isOriginal: v.isOriginal,
                    isFeatured: v.isFeatured,
                    isPublic: v.isPublic,
                    resourceId: v.resourceId,
                    resourceDate: v.resourceDate,
                    dependencyHref: v.dependencyHref,
                    href: v.href,
                    thumbnails: v.thumbnails,
                    providerResourceIdentifier: v.providerResourceIdentifier,
                    categoryThumbnails: get(
                      filteredCategoryData,
                      "0.thumbnails",
                      {}
                    ),
                    categoryColor: get(filteredCategoryData, "0.bgColor", {}),
                    ages: v.ages,
                    offResponsiveBanner: v.offResponsiveBanner,
                    bannerData: v.bannerData
                  };
                  processedManifestNodes.push(contentNodes);
                }
                return processedManifestNodes;
              },
              []
            );
            if (processedManifestNodes.length) {
              data = {
                id: e.id,
                title: e.title,
                category: e.category,
                isFeatured: e.isFeatured,
                isPublic: e.isPublic,
                resourceId: e.resourceId,
                thumbnails: e.thumbnails,
                bg_color: e.background_color,
                titles: processedManifestNodes
              };
              processedManifestData.push(data);
            }
          }
        } else if (e.category === "title") {
          if (e.type &&
            e.thumbnails["0"] !== null &&
            (
              (e.type === action.itemType["games"] && e.isReactGame === true) ||
              (e.type === action.itemType["videos"]) || 
              (e.type === action.itemType["books"])
            )
          ) {
            let filteredCategoryData = processedCategoryData.filter(function(
              categoryData,
              i
            ) {
              return categoryData["type"] === e.type;
            });
            data = {
              id: e.id,
              title: e.title,
              type: e.type,
              publishers: e.publishers,
              isOriginal: e.isOriginal,
              isFeatured: e.isFeatured,
              isPublic: e.isPublic,
              resourceId: e.resourceId,
              resourceDate: e.resourceDate,
              category: e.category,
              dependencyHref: e.dependencyHref,
              href: e.href,
              thumbnails: e.thumbnails,
              providerResourceIdentifier: e.providerResourceIdentifier,
              categoryThumbnails: get(filteredCategoryData, "0.thumbnails", {}),
              categoryColor: get(filteredCategoryData, "0.bgColor", {}),
              searchKeywords: e.searchKeywords,
              offResponsiveBanner: e.offResponsiveBanner,
              bannerData: e.bannerData
            };
            processedManifestData.push(data);
          }
        }
        return processedManifestData;
      },
      []
    );

    storeDataInIndexedDb(processedManifestData);

    /*if (localStorage !== null && localStorage !== undefined) {
      try {
        localStorage.setItem("manifestdata", JSON.stringify(processedManifestData));
      } catch (e) {
        localStorage.clear("manifestdata");
        localStorage.setItem("manifestdata", JSON.stringify(processedManifestData));
      }
    }*/
  }
  else{
    processedManifestData=action.manifestData;
  }

  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    manifestData: processedManifestData,
    isFetchingManifestData: false
  };
};

const storeDataInIndexedDb = (processedManifestData) => {
  createOrFetchIndexedDb(INDEXED_DB_CONST.DB_NAME).then(function(db) {
    let objectStore = createOrFetchObjectStore(INDEXED_DB_CONST.DB_STORE_NAME, 'readwrite', db);
    for (var i in processedManifestData) {
      objectStore.add(processedManifestData[i]);
    }
    // localStorage.setItem("indexedDbExist", true);
  });
};


export const getManifestDataFailure = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    manifestData: action.manifestData,
    isFetchingManifestData: false
  };
};

export const getFavouriteListRequest = (state, action) => {
  return {
    ...state,
    isFetchingFavList: action.isFetching,
    errorMessage: action.errorMessage
  };
};

export const getFavouriteListSuccess = (state, action) => {
  return {
    ...state,
    isFetchingFavList: action.isFetching,
    errorMessageFavList: action.errorMessageFavList,
    favoriteList: action.favoriteList
  };
};

export const getFavouriteListFailure = (state, action) => {
  return {
    ...state,
    isFetchingFavList: action.isFetching,
    errorMessageFavList: action.errorMessageFavList,
    favoriteList: action.favoriteList
  };
};

export const getRelatedContentRequest = (state, action) => {
  return {
    ...state,
    isFetchingRelatedContent: action.isFetchingRelatedContent,
    errorMessage: action.errorMessage
  };
};

export const getRelatedContentSuccess = (state, action) => {
  let processedrelatedContentData = get(action, "relatedContent.data", {}) && get(action, "relatedContent.data", {}).map(
    (relatedData, index) => relatedData.id
  );

  let relatedContentData =
    get(state, "relatedContent", []).length < 1
      ? []
      : get(state, "relatedContent", []);

  let itemId = get(action, "itemId", {});

  let relatedContentExist = relatedContentData.filter(function(v, i) {
    return v["itemId"] === itemId;
  });

  if (!relatedContentExist.length) {
    relatedContentData = [
      ...relatedContentData,
      {
        itemId: itemId,
        relatedData: processedrelatedContentData
      }
    ];
  }

  return {
    ...state,
    isFetchingRelatedContent: action.isFetchingRelatedContent,
    errorMessage: action.errorMessage,
    relatedContent: relatedContentData
  };
};

export const getRelatedContentFailure = (state, action) => {
  return {
    ...state,
    isFetchingRelatedContent: action.isFetchingRelatedContent,
    errorMessage: action.errorMessage,
    relatedContent: action.relatedContent
  };
};

export const userDashboardRequest = (state, action) => {
  return {
    ...state,
    isFetchingParentDashboard: action.isFetchingParentDashboard,
    errorMessage: action.errorMessage
  };
};

export const userDashboardSuccess = (state, action) => {
  return {
    ...state,
    isFetchingParentDashboard: action.isFetchingParentDashboard,
    errorMessage: action.errorMessage,
    data: action.data
  };
};

export const userDashboardFailure = (state, action) => {
  return {
    ...state,
    isFetchingParentDashboard: action.isFetchingParentDashboard,
    errorMessage: action.errorMessage
  };
};

export default (state = initialState, action) => {
  deepFreeze(state);
  deepFreeze(action);

  switch (action.type) {
    case GET_COUNTRY_REQUEST:
      return getDashboardListRequest(state, action);
    case GET_COUNTRY_SUCCESS:
      return getCountrySuccess(state, action);
    case GET_COUNTRY_FAILURE:
      return getCountryFailure(state, action);
    case GET_MANIFEST_RECOMMENDATION_REQUEST:
      return getManifestRecommendationRequest(state, action);
    case GET_MANIFEST_RECOMMENDATION_SUCCESS:
      return getManifestRecommendationSuccess(state, action);
    case GET_MANIFEST_RECOMMENDATION_FAILURE:
      return getManifestRecommendationFailure(state, action);
    case GET_MANIFEST_DATA_REQUEST:
      return getManifestDataRequest(state, action);
    case GET_MANIFEST_DATA_SUCCESS:
      return getManifestDataSuccess(state, action);
    case GET_MANIFEST_DATA_FAILURE:
      return getManifestDataFailure(state, action);
    case GET_FAVOURITE_LIST_REQUEST:
      return getFavouriteListRequest(state, action);
    case GET_FAVOURITE_LIST_SUCCESS:
      return getFavouriteListSuccess(state, action);
    case GET_FAVOURITE_LIST_FAILURE:
      return getFavouriteListFailure(state, action);
    case GET_RELATED_CONTENT_REQUEST:
      return getRelatedContentRequest(state, action);
    case GET_RELATED_CONTENT_SUCCESS:
      return getRelatedContentSuccess(state, action);
    case GET_RELATED_CONTENT_FAILURE:
      return getRelatedContentFailure(state, action);
    case USER_DASHBOARD_REQUEST:
      return userDashboardRequest(state, action);
    case USER_DASHBOARD_SUCCESS:
      return userDashboardSuccess(state, action);
    case USER_DASHBOARD_FAILURE:
      return userDashboardFailure(state, action);
    default:
      return state;
  }
};
