import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "components/Templates/_SidebarHeaderTemplate.module.scss";
import Header from "components/Header";
import Sidebar from "components/Sidebar";
import Image from "components/Global/Image";
import { selectSiteTheme } from "selectors";
import { selectEventImage } from "selectors/event";
import get from "lodash/get";
import { isBedTime } from "utils";

class SidebarHeaderTemplate extends React.Component {
  static propTypes = {
    pageTitle: PropTypes.string,
    siteTheme: PropTypes.object
  };

  static defaultProps = {
    pageTitle: "",
    siteTheme: {}
  };

  goBackToSearch = () => {};

  render() {
    const {
      children,
      pageTitle,
      pageTitleClass,
      titleThumbnail,
      siteTheme,
      category_color,
      backButton,
      searchHeader,
      searchPage,
      searchText,
      goBackToSearch,
      goBackToSearchWithKeyword,
      isFav,
      favItem,
      eventBgImage,
      pageName
    } = this.props;
    const bg_color = category_color
      ? category_color
      : get(siteTheme, "default_bg_color");
    const gradient_base_color = get(siteTheme, "gradient_base_color");

    return (
      <React.Fragment>
        <div
          className="bg_image"
          style={{
            background: bg_color,
            background: `-moz-linear-gradient(top, ${bg_color} 0%, ${gradient_base_color} 100%)`,
            background: `-webkit-linear-gradient(top, ${bg_color} 0%,${gradient_base_color} 100%)`,
            background: `linear-gradient(to bottom, ${bg_color} 0%,${gradient_base_color} 100%)`,
            filter: `progid:DXImageTransform.Microsoft.gradient( startColorstr=${bg_color}, endColorstr=${gradient_base_color},GradientType=0 )`,
          }}
        >
          {((isBedTime() && pageName === "PAGE_HOME") || pageTitle === "Bedtime") ? (
            <Image
              className={styles.arrow}
              folderName="background"
              fileName="bedtime_background"
              alt="Background"
            />
          ) : (
            (eventBgImage && pageName === "PAGE_HOME") ? (
              <img
                src={eventBgImage}
                alt="Background"
                className={styles.arrow}
              />
            ) : (
              <Image
                className={styles.arrow}
                folderName="background"
                fileName={
                  isFav ? "favourite_background_graphics" : "background_transparent"
                }
                alt="Background"
              />
            )
          )}
        </div>
        <div className={styles.page_wrapper}>
          <Sidebar
            className={styles.sidebar}
            backButton={backButton}
            favItem={favItem}
            goBackToSearch={goBackToSearch !== undefined ? goBackToSearch : this.goBackToSearch}
          />
          <div className={styles.content_wrapper}>
            <Header
              className={styles.header}
              pageTitle={pageTitle}
              pageTitleClass={pageTitleClass}
              titleThumbnail={titleThumbnail}
              searchPage={searchPage}
              searchHeader={searchHeader}
              searchText={searchText}
              goBackToSearch={goBackToSearch}
              goBackToSearchWithKeyword={goBackToSearchWithKeyword}
              isFav={isFav}
            />
            <div className={styles.content}>{children}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = state => ({
  siteTheme: selectSiteTheme(state),
  eventBgImage: selectEventImage(state)
});

export default connect(
  mapStateToProps,
  null
)(SidebarHeaderTemplate);
