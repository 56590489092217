import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import get from "lodash/get";
import SidebarLayoverTemplate from "components/Templates/SidebarLayoverTemplate";
import { selectCategoryList } from "selectors/category";
import AssetsImage from "../Global/AssetsImage";
import styles from "./_CategoriesContainer.module.scss";
import { selectSelectedChildId } from "selectors/children";
import { webAppEvent } from "actions/common";
import { deviceInfo, categoryTapped } from "../../helper.js";
import { selectUser } from "selectors/profile";
import { setScreenOrientation, cookieParams } from "utils";
import { PAGE_MODE } from "constants/screens";
import { selectCountryCode } from "selectors/dashboard";

class CategoriesContainer extends React.Component {
  static propTypes = {
    categoryList: PropTypes.array,
    selectedChildId: PropTypes.string,
    onClose: PropTypes.func
  };

  static defaultProps = {
    categoryList: [],
    selectedChildId: "",
    onClose() {}
  };

  state = {
    categoryNewList: []
  };

  handleCategoryTapped = id => {
    const { webAppEvent, kafkaUrl, profile, categoryList, countryCode } = this.props;
    let category = categoryList.find(cat => cat.id === id);
    let params = [
      {
        ...deviceInfo,
        ...categoryTapped(profile, category, countryCode)
      }
    ];
    webAppEvent(kafkaUrl, params);
  };

  clickCategory = id => {
    Cookies.set("showAnimation", "TRUE", cookieParams);
    const {
      selectedChildId,
      history,
      onClose
    } = this.props;
    setScreenOrientation(PAGE_MODE.CATEGORY);
    history.push(`/${selectedChildId}/category/${id}`);
    this.handleCategoryTapped(id);
    Cookies.remove("scrollPosition");
    Cookies.remove("itemLength");
    if(document.getElementsByClassName('tiles_wrapper')['0'] && document.getElementsByClassName('tiles_wrapper')['0'].children){
     document.getElementsByClassName('tiles_wrapper')['0'].children[0].scrollLeft = 0;
    }
    onClose();
  };

  componentDidMount() {
    const { categoryList, recomendCategoryList } = this.props;
    let datalist = [];
    if (recomendCategoryList && categoryList) {
      for (let i = 0; i < recomendCategoryList.length; i++) {
        let data1 = categoryList.filter(
          data => 
            (recomendCategoryList[i].recommended.length > 0 && data.id === recomendCategoryList[i].itemId)
        );
        if (data1.length > 0) {
          datalist.push(data1[0]);
        }
      }
      this.setState({
        categoryNewList: datalist
      });
    }
  }

  render() {
    const { onClose } = this.props;
    const { categoryNewList } = this.state;
    return (
      <SidebarLayoverTemplate headingText="Choose a Category" onClose={onClose}>
        {categoryNewList && categoryNewList.length ? (
          <ul className={styles.category_wrapper}>
            {categoryNewList.map((category, index) => {
              return (
                <React.Fragment key={index}>
                  <li onClick={() => this.clickCategory(category.id)}>
                    <div
                      className={styles.assets_image}
                      style={{ background: category.category_color }}
                    >
                      <AssetsImage
                        assetsImageName={category.thumbnails["0"]}
                        alt={category.title}
                        categoryImage="true"
                      />
                    </div>
                    <div className={styles.category_title}>
                      {category.title}
                    </div>
                  </li>
                </React.Fragment>
              );
            })}
          </ul>
        ) : (
          ""
        )}
      </SidebarLayoverTemplate>
    );
  }
}

export const mapStateToProps = state => ({
  categoryList: selectCategoryList(state),
  recomendCategoryList: get(
    state,
    "dashboardList.recommendedManifestData.data.categories"
  ),
  selectedChildId: selectSelectedChildId(state),
  profile: selectUser(state),
  kafkaUrl: get(state, "appManifest.data.app_settings.kafka_url"),
  countryCode: selectCountryCode(state)
});

export const mapDispatchToProps = dispatch => ({
  webAppEvent(url, params) {
    dispatch(webAppEvent(url, params));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CategoriesContainer)
);
