import {
  EVENT_BASED_CONTENT_REQUEST,
  EVENT_BASED_CONTENT_SUCCESS,
  EVENT_BASED_CONTENT_FAILURE
} from "../constants/actions";
import get from "lodash/get";
import { apiGetExternal, generateUrl } from "./core";

export const eventBasedContentRequest = () => ({
  type: EVENT_BASED_CONTENT_REQUEST,
  isFetching: true,
  errorMessage: ""
});

export const eventBasedContentSuccess = data => ({
  type: EVENT_BASED_CONTENT_SUCCESS,
  isFetching: false,
  errorMessage: "",
  eventBasedContent: data
});

export const eventBasedContentFailure = errorMessage => ({
  type: EVENT_BASED_CONTENT_FAILURE,
  isFetching: false,
  errorMessage
});

export const eventBasedContent = baseUrl => {
  const url = generateUrl(baseUrl, `/event?_format=json`);

  return dispatch => {
    dispatch(eventBasedContentRequest());
    return apiGetExternal({ url })
      .then(response => dispatch(eventBasedContentSuccess(get(response, "data", []))))
      .catch(err => dispatch(eventBasedContentFailure(get(err, "message", []))));
  };
};
