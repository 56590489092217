export const getViewportDimensions = (doc) => {
  let viewportContent = doc.head.getElementsByTagName("meta").viewport.content;
  // let viewportContent = 'width=600, height=800';
  let viewportItems = viewportContent.split(",");
  let viewportDimensions = [];
  viewportItems.forEach(function(item, index) {
    let viewportItemsArray = item.split("=");
    if (viewportItemsArray['0'].trim() === "width" || viewportItemsArray['0'].trim() === "height" ) {
      viewportDimensions[viewportItemsArray['0'].trim()] = parseInt(viewportItemsArray['1']);
    }
  });
  return viewportDimensions;
}

export const highlightWord = (parentDoc, wordId, prevWordId) => {
  if (wordId && parentDoc.getElementById(wordId)) {
    parentDoc.getElementById(wordId).style.backgroundColor = 'yellow';
  }
  if (prevWordId && parentDoc.getElementById(prevWordId)) {
    parentDoc.getElementById(prevWordId).style.backgroundColor = '';
  }
}


export const removePrecedingHash = (hashId) => {
  if (hashId.charAt(0) === '#') {
    return hashId.substring(1, hashId.length);
  }
  else {
    return hashId;
  }
}

export const fetchAudio = (pagesDetails, opfPath) => {
  let page1AudioDetails, page2AudioDetails;
  if (pagesDetails.page1) {
    if (pagesDetails.page1.audio) {
      page1AudioDetails = fetchAudioDetails(pagesDetails.page1.audio, opfPath);
    }
  }
  if (pagesDetails.page2) {
    if (pagesDetails.page2.audio) {
      page2AudioDetails = fetchAudioDetails(pagesDetails.page2.audio, opfPath);
    }
  }
  return {
    page1: page1AudioDetails,
    page2: page2AudioDetails  
  };
}

const fetchAudioDetails = (smilPath, opfPath) => {
  let audioSmil = loadXMLFileAsObject(smilPath);
  let par = audioSmil.getElementsByTagName("par");
  let audioSrc, audioStartTime, audioEndTime, wordToHighlight;
  let audioDetails = [];
  for (let item of par) {
    let audioTag = item.querySelectorAll('audio')['0'];
    let textTag = item.querySelectorAll('text')['0'];
    audioSrc = opfPath + removePrecedingDotsFromPath(audioTag.getAttribute("src"));
    audioStartTime = removeSuccedingSFromTime(audioTag.getAttribute("clipBegin"));
    audioEndTime = removeSuccedingSFromTime(audioTag.getAttribute("clipEnd"));
    wordToHighlight = getHashTag(textTag.getAttribute("src"));
    audioDetails.push({
      audioSrc: audioSrc,
      audioStartTime: audioStartTime,
      audioEndTime: audioEndTime,
      wordToHighlight: wordToHighlight
    });
  }
  return audioDetails;
}

const removeSuccedingSFromTime = (time) => {
  if (time.charAt(time.length-1) === 's') {
    return time.substring(0, time.length-1);
  }
  else {
    return time;
  }
}

const getHashTag = (link) => {
  var linkArr = link.split("#");
  return '#' + linkArr[1];
}

const removePrecedingDotsFromPath = (path) => {
  var pathArr = path.split("/");
  if (pathArr['0'] === '..') {
    pathArr.shift();
    if (pathArr['0'] === '..') {
      path = pathArr.join("/");
      return removePrecedingDotsFromPath(path)
    }
    else {
      return pathArr.join("/");
    }
  }
  else {
    return path;
  }
}

export const fetchBothPagesDetails = (epubData, pageToSkip, pagesToDisplay, pagePosition) => {

  let page1, page2;
  if (pagesToDisplay === 2) {
    page1 = fetchPageDetails(epubData, pageToSkip);
    if (page1 !== "BOOK_END") {
      page2 = fetchPageDetails(epubData, pageToSkip + 1);
    } else {
      page2 = "";
    }
  }
  else if (pagePosition === "LEFT_PAGE") {
    page1 = fetchPageDetails(epubData, pageToSkip);
    page2 = "";
  }
  else {
    page1 = "";
    page2 = fetchPageDetails(epubData, pageToSkip);
  }

  return {
    page1: page1,
    page2: page2
  }

  /*return {
    src: epubData.opfPath + pageUrl,
    audio: epubData.opfPath + pageAudio
  }*/
}

const fetchPageDetails = (epubData, pageToSkip) => {
  if (pageToSkip + 1 <= epubData.noOfPages) {
    let page = epubData.pagesFromOtpFile["page"+(pageToSkip + 1)];
    return {
      src: epubData.opfPath + page.pageUrl,
      audio: page.pageAudio ? epubData.opfPath + page.pageAudio : ""
    }
  }
  else {
    return "BOOK_END"
  }
  // let pageId = getPageFromSpine(epubData.epubContent.getElementsByTagName("spine")['0'], pageToSkip);

  // if (pageId) {
  //   let pageIdTag = epubData.epubContent.querySelectorAll('#'+pageId)['0'];

  //   let pageUrl = getPageSrc(pageIdTag);

  //   let pageAudio = getPageAudio(epubData.epubContent, pageIdTag);

  //   return {
  //     src: epubData.opfPath + pageUrl,
  //     audio: pageAudio ? epubData.opfPath + pageAudio : ""
  //   }
  // }
  // else {
  //   return "BOOK_END"
  // }
}

const getPageAudio = (epubContent, pageIdTag) => {
  if (pageIdTag.hasAttribute("media-overlay")) {
    let pageMediaId = pageIdTag.getAttribute("media-overlay");
    let pageMediaPath = epubContent.querySelectorAll('#'+pageMediaId)['0'].getAttribute("href");

    return pageMediaPath;
  }
  else {
    return null;
  }
/*  let mediaFileContent = loadXMLFileAsObject(epubBasePath + '/' + otpFileLocation + firstPageMediaPath);

  highlightWord(mediaFileContent);*/
}

/* <manifest>: Defines the pages and resources which were used in the <spine>
element, including content documents, CSS style sheets, images and
navigation control file (NCX); */
const getPageSrc = (pageIdTag) => {
  let pageUrl = pageIdTag.getAttribute('href');
  return pageUrl;
}

/* <spine>: Specifies the order of pages of the book. */
// const getPageFromSpine = (spine, pageToSkip, index = 0) => {
//   let itemRef = spine.getElementsByTagName("itemref")[index];
//   if (itemRef) {
//     if (itemRef.getAttribute('linear') == 'yes') {
//       if (pageToSkip > 0) {
//         return getPageFromSpine(spine, --pageToSkip, ++index);
//       }
//       return itemRef.getAttribute("idref");
//     }
//     else {
//       return getPageFromSpine(spine, pageToSkip, ++index);
//     }
//   } else {
//     return null;
//   }
// }

const getPagesFromOtpFile = (opfFileContent, opfPath) => {
  let spine = opfFileContent.getElementsByTagName("spine")['0'];
  let itemRef = spine.getElementsByTagName("itemref");
  let pagesFromOtpFile = {};
  Object.values(itemRef).forEach(function(item, index) {
    if (item.getAttribute('linear') === 'yes') {
      let idRef = item.getAttribute("idref");
      let pageIdTag = opfFileContent.querySelectorAll('#'+idRef)['0'];
      let pageUrl = getPageSrc(pageIdTag);
      let pageAudio = getPageAudio(opfFileContent, pageIdTag);
      // let audioDetails = null;
      // if (pageAudio) {
      //   audioDetails = fetchAudioDetails(opfPath + pageAudio);
      // }
      pagesFromOtpFile["page"+(index+1)] = {
        pageUrl: pageUrl,
        pageAudio: pageAudio,
        // audioDetails: audioDetails ? audioDetails : null
      }
    }
  });
  return pagesFromOtpFile;
}

export const getOtpFileDetails = (epubBasePath) => {
  let opfFilePath = getOpfFilePath(epubBasePath);

  let opfFileContent = loadXMLFileAsObject(epubBasePath + '/' + opfFilePath);

  let otpFileLocation = getOtpFileLocation(opfFilePath);

  let opfPath = epubBasePath + '/' + otpFileLocation;

  let pagesFromOtpFile = getPagesFromOtpFile(opfFileContent, opfPath);

  let noOfPages = getPageCount(opfFileContent.getElementsByTagName("spine")['0']);

  return {
    opfPath: opfPath,
    pagesFromOtpFile: pagesFromOtpFile,
    noOfPages: noOfPages
    // opfPath: epubBasePath + '/' + otpFileLocation,
    // epubContent: opfFileContent,
    // noOfPages: noOfPages
  }
}

const getPageCount = (spine) => {
  let noOfPages = Object.entries(spine.getElementsByTagName("itemref")).filter(function(itemRef) {
    return itemRef['1'].getAttribute('linear') === 'yes';
  });
  return noOfPages.length;
}

/* This is required to get the location of the preceding files like page, audio etc */
const getOtpFileLocation = (opfFilePath) => {
  let opfFilePathSplit = opfFilePath.split("/");
  opfFilePathSplit.pop();
  return opfFilePathSplit.join("/") + '/';
}

/* the Open Packaging Format file (OPF) serves as a container manifest
and defines all of the files included in the EPUB zip file.*/
const getOpfFilePath = (epubBasePath) => {
  // container.xml file tells the eReader app where to find OPF file. 
  let startingFileName = '/META-INF/container.xml';
  let rootXml = loadXMLFileAsObject(epubBasePath + startingFileName);
  let opfFilePath = rootXml.getElementsByTagName("rootfile")[0].getAttribute("full-path");

  return opfFilePath;
}

const loadXMLFileAsObject = fileName => {
  let xml = loadXMLFile(fileName);

  return parseXML(xml);
}

const loadXMLFile = fileName => {
  let xmlHTTP = new XMLHttpRequest();
  try
  {
    xmlHTTP.open("GET", fileName, false);
    xmlHTTP.send(null);
  }
  catch (e) {
    console.log("Unable to load the requested file.");
    return;
  }
  return xmlHTTP.responseText;
}

const parseXML = xml => {
  let parser = new DOMParser();
  let xmlDoc = parser.parseFromString(xml,"text/xml");
  return xmlDoc;
}
