import React from "react";
import get from "lodash/get";
import classes from "./_CategoryList.module.scss";
import { selectEventAvatarImages } from "selectors/event";
import { connect } from "react-redux";
import { getAvatarPositionFromUrl } from "utils";

class CategoryList extends React.Component {
  categoryData = (image, itemName, time, percentage) => {
    return (
      <div className={classes.list_inner}>
        <div className={classes.category_text_wrapper}>
          <div className={classes.category_text_wrapper_inner}>
            <img className={classes.category_img} src={image} alt={itemName} />
            <div className={classes.category_title}>{itemName}</div>
          </div>
        </div>
        <div className={classes.progress_bar_wrapper}>
          <div
            className={classes.progress_bar}
            style={{ width: percentage + "%" }}
          />
          <div className={classes.progress_time}>{time}</div>
        </div>
      </div>
    );
  };

  getAvatarImageSrc = (child) => {
    const { eventAvatarImages, avatarsList } = this.props;
    if (eventAvatarImages) {
      const avatarImageUrl = get(child, "avatar", "");
      const avatarIndex = getAvatarPositionFromUrl(avatarImageUrl, avatarsList);
      return get(eventAvatarImages, `avatar-${avatarIndex + 1}`, "");
    } else {
      return get(child, "avatar", "");
    }
  }

  render() {
    const { viewed, child, onSelectChild } = this.props;
    return (
      <React.Fragment>
        <div className={classes.child_wrapper}>
          <div className={classes.child_img} onClick={onSelectChild}>
            <img
              className={classes.avatar_img}
              src={this.getAvatarImageSrc(child)}
              alt={get(child, "name", "")}
            />
            <div className={classes.child_name}>{get(child, "name", "")}</div>
          </div>
          <div className={classes.total_time_spend}>
            {get(child, "total_time", "")}{" "}
            <div className={classes.total_days}>
              in the last {get(child, "days", "")}{" "}
              {get(child, "days", "") === 1 ? "day" : "days"}
            </div>
          </div>
        </div>
        {viewed && (
          <ul className={classes.category_wrapper}>
            <li>
              {this.categoryData(
                get(viewed, "books_image", ""),
                "Books",
                get(child, "book_time", ""),
                get(child, "book_time_percentage", "")
              )}
            </li>
            <li>
              {this.categoryData(
                get(viewed, "games_image", ""),
                "Games",
                get(child, "game_time", ""),
                get(child, "game_time_percentage", "")
              )}
            </li>
            <li>
              {this.categoryData(
                get(viewed, "videos_image", ""),
                "Videos",
                get(child, "video_time", ""),
                get(child, "video_time_percentage", "")
              )}
            </li>
          </ul>
        )}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = state => ({
  avatarsList: get(state, "appManifest.data.icons.avatars", {}),
  eventAvatarImages: selectEventAvatarImages(state)
});

export default connect(
  mapStateToProps,
  ""
)(CategoryList);
