import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { PATHS } from "constants/url";

import TermsConditions from "../BasicPage/TermsConditions";
import PrivacyPolicy from "../BasicPage/PrivacyPolicy";
import DirectAccessBook from "../DirectAccessBook";
import Dashboard from "../Dashboard";
import Category from "../Category";
import Book from "../Book";
import Video from "../Video";
import Game from "../Game";
import Collection from "../Collection";
import Favourites from "../Favourites";
import ParentsArea from "../ParentsArea";
import Search from "../Search";
import WhoIsPlayingContainer from "../WhoIsPlayingContainer";
import AddEditChild from "../AddEditChild";
import DeleteChild from "../DeleteChild";
import ParentDashboard from "../ParentsArea/ParentDashboard";
import MyChildren from "../ParentsArea/MyChildren";
import RouteWithValidChild from "./RouteWithValidChild";

class AllRoutes extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path={PATHS.TERMS_CONDITIONS}
          component={TermsConditions}
        />
        <Route
          exact
          path={PATHS.PRIVACY_POLICY}
          component={PrivacyPolicy}
        />
        <Route 
          exact 
          path={PATHS.DIRECT_ACCESS_BOOK} 
          component={DirectAccessBook} 
        />
        <RouteWithValidChild
          exact
          path={PATHS.DASHBOARD}
          component={Dashboard}
        />
        <RouteWithValidChild
          exact
          path={PATHS.CATEGORY}
          component={Category}
        />
        <RouteWithValidChild exact path={PATHS.BOOK} component={Book} />
        <RouteWithValidChild exact path={PATHS.VIDEO} component={Video} />
        <RouteWithValidChild exact path={PATHS.GAME} component={Game} />
        <RouteWithValidChild
          exact
          path={PATHS.COLLECTION}
          component={Collection}
        />
        <RouteWithValidChild
          exact
          path={PATHS.FAVOURITES}
          component={Favourites}
        />
        <RouteWithValidChild exact path={PATHS.PARENT_AREA} component={ParentsArea} />
        <RouteWithValidChild
          exact
          path={PATHS.SEARCH}
          component={Search}
        />
        <Route
          exact
          path={PATHS.HOME}
          component={WhoIsPlayingContainer}
        />
        <Route exact path={PATHS.ADD_CHILD} component={AddEditChild} />
        <RouteWithValidChild
          exact
          path={PATHS.EDIT_CHILD}
          component={AddEditChild}
          isEdit
        />
        <RouteWithValidChild
          exact
          path={PATHS.DELETE_CHILD}
          component={DeleteChild}
          saveChild={false}
        />
        <RouteWithValidChild
          exact
          path={PATHS.PARENT_DASHBOARD}
          component={ParentDashboard}
        />
        <Route exact path={PATHS.MY_CHILDREN} component={MyChildren} />
        <Route
          render={() => <Redirect to={{ pathname: PATHS.HOME }} />}
        />
      </Switch>
    );
  }
}

export default withRouter(AllRoutes);