import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectAssetsImageSrc, selectCategoryImageSrc } from "selectors";

class AssetsImage extends React.Component {
  static propTypes = {
    assetsImageSrc: PropTypes.string,
    className: PropTypes.string,
    alt: PropTypes.string.isRequired,
    assetsImageName: PropTypes.string.isRequired,
    onClick: PropTypes.func
  };

  static defaultProps = {
    assetsImageSrc: "",
    className: "",
    alt: ""
  };

  render() {
    const { assetsImageSrc, className, alt, onClick, onLoad, categoryImageSrc, categoryImage } = this.props;
    let imageSrc = assetsImageSrc;
    if (categoryImage) {
      imageSrc = categoryImageSrc;
    }
    return (
      <React.Fragment>
        {assetsImageSrc ? (
          <img
            src={imageSrc}
            alt={alt}
            className={className}
            onClick={onClick}
            onLoad={onLoad}
          />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  assetsImageSrc: selectAssetsImageSrc(state, props),
  categoryImageSrc: selectCategoryImageSrc(state, props)
});

export default connect(
  mapStateToProps,
  null
)(AssetsImage);
