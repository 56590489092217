import deepFreeze from "deep-freeze-es6";
import get from "lodash/get";
import {
  GET_ALL_CHILDREN_REQUEST,
  GET_ALL_CHILDREN_SUCCESS,
  GET_ALL_CHILDREN_FAILURE,
  ADD_CHILD_FAILURE,
  ADD_CHILD_REQUEST,
  ADD_CHILD_SUCCESS,
  EDIT_CHILD_FAILURE,
  EDIT_CHILD_REQUEST,
  EDIT_CHILD_SUCCESS,
  SET_SELECTED_CHILD,
  DELETE_CHILD_FAILURE,
  DELETE_CHILD_REQUEST,
  DELETE_CHILD_SUCCESS
} from "constants/actions";
import { USER_LOGGED_OUT } from "../../constants/actions";

const initialState = {
  isFetching: true,
  data: {},
  errorMessage: "",
  selectedChildId: "",
  isFetchingChild: true,
  getChildrenErrorMessage: ""
};

export const setRequest = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage
  };
};

export const getAllChildrenRequest = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    isFetchingChild: action.isFetching,
    errorMessage: action.errorMessage,
    getChildrenErrorMessage: action.getChildrenErrorMessage
  };
};

export const getAllChildrenSuccess = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    isFetchingChild: action.isFetching,
    errorMessage: action.errorMessage,
    getChildrenErrorMessage: action.getChildrenErrorMessage,
    data: action.data
  };
};

export const getAllChildrenFailure = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    isFetchingChild: action.isFetching,
    errorMessage: action.errorMessage,
    getChildrenErrorMessage: action.getChildrenErrorMessage,
    data: action.data
  };
};

export const setSelectedChildId = (state, action) => {
  return {
    ...state,
    selectedChildId: action.data
  };
};

export const addEditDeleteFailure = (state, action) => ({
  ...state,
  isFetching: action.isFetching,
  errorMessage: action.errorMessage
});

export const addChildSuccess = (state, action) => {
  const child = get(action, "data.data");
  const children = [...get(state, "data.data", [])];

  if (child && child.id) {
    return {
      ...state,
      isFetching: false,
      errorMessage: "",
      data: {
        ...state.data,
        data: [...children, child]
      },
      addedChildId: child.id
    };
  }

  return state;
};

export const getMatchingChildIndex = (childrenArr = [], childId) => {
  return childrenArr.findIndex((child = {}) => child.id === childId);
};

export const editChildSuccess = (state, action) => {
  const { childId, data } = action;
  const children = [...get(state, "data.data", [])];

  if (children.length) {
    const editedChildIndex = getMatchingChildIndex(children, childId);

    if (editedChildIndex > -1) {
      children[editedChildIndex] = data && data.data;
      return {
        ...state,
        isFetching: false,
        errorMessage: "",
        data: {
          ...state.data,
          data: children
        }
      };
    }
  }
  return state;
};

export const deleteChildSuccess = (state, action) => {
  const { childId } = action;
  const children = [...get(state, "data.data", [])];

  if (children.length) {
    const deletedChildIndex = getMatchingChildIndex(children, childId);

    if (deletedChildIndex > -1) {
      children.splice(deletedChildIndex, 1);
      return {
        ...state,
        isFetching: false,
        errorMessage: "",
        data: {
          ...state.data,
          data: children
        }
      };
    }
  }

  return state;
};

export const userLogout = (state, action) => {
  return {
    ...state,
    selectedChildId: "",
    data: {},
    isFetchingChild: true
  };
};

export default (state = initialState, action) => {
  deepFreeze(state);
  deepFreeze(action);

  switch (action.type) {
    case GET_ALL_CHILDREN_REQUEST:
      return getAllChildrenRequest(state, action);
    // Note - the following actions in this case block do not change any state except
    // isFetching and errorMessage. Separate them out if needed
    case ADD_CHILD_REQUEST:
    case EDIT_CHILD_REQUEST:
    case DELETE_CHILD_REQUEST:
      return setRequest(state, action);
    case GET_ALL_CHILDREN_SUCCESS:
      return getAllChildrenSuccess(state, action);
    case GET_ALL_CHILDREN_FAILURE:
      return getAllChildrenFailure(state, action);
    case SET_SELECTED_CHILD:
      return setSelectedChildId(state, action);
    case ADD_CHILD_SUCCESS:
      return addChildSuccess(state, action);
    case EDIT_CHILD_SUCCESS:
      return editChildSuccess(state, action);
    case DELETE_CHILD_SUCCESS:
      return deleteChildSuccess(state, action);
    case EDIT_CHILD_FAILURE:
    case ADD_CHILD_FAILURE:
    case DELETE_CHILD_FAILURE:
      return addEditDeleteFailure(state, action);
    case USER_LOGGED_OUT:
      return userLogout(state, action);
    default:
      return state;
  }
};
