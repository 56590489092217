import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import Image from "components/Global/Image";
import c from "classnames";
import { WHO_IS_PLAYING_SCREEN_TYPES } from "../../constants";
import { selectEventAvatarImages } from "selectors/event";
import { getAvatarPositionFromUrl } from "utils";

class Avatars extends Component {
  getAvatarImageSrc = (child) => {
    const { eventAvatarImages, avatarsList } = this.props;
    if (eventAvatarImages) {
      const avatarImageUrl = get(child, "metadata.avatar", "");
      const avatarIndex = getAvatarPositionFromUrl(avatarImageUrl, avatarsList);
      return get(eventAvatarImages, `avatar-${avatarIndex + 1}`, "");
    } else {
      return get(child, "metadata.avatar", "");
    }
  }

  render() {
    const {
      children,
      getClassName,
      onChildClick,
      editChildFlag,
      selectedChildId,
      screenType
    } = this.props;
    if (!children || !children.length) {
      return null;
    }
    return (
      <div className={getClassName("avatars_wrapper")}>
        {children.map((child, index) => (
          <div
            id={`avatar${index}`} /* id is being used in test */
            className={c(
              getClassName("avatar"),
              selectedChildId &&
                screenType !== WHO_IS_PLAYING_SCREEN_TYPES.MY_CHILDREN
                ? getClassName("selected_child")
                : "",
              selectedChildId === child.id ? getClassName("active") : ""
            )}
            key={index}
            onClick={() => onChildClick(child.id)}
          >
            {editChildFlag && (
              <Image
                className={getClassName("edit-child")}
                folderName="static"
                fileName="edit"
                alt="edit"
              />
            )}
            <img
              className={getClassName("avatar_image")}
              src={this.getAvatarImageSrc(child)}
              alt={child.name}
            />
            <span className={getClassName("child_name")}>{child.name}</span>
          </div>
        ))}
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  avatarsList: get(state, "appManifest.data.icons.avatars", {}),
  eventAvatarImages: selectEventAvatarImages(state)
});

Avatars.propTypes = {
  children: PropTypes.array,
  getClassName: PropTypes.func,
  onChildClick: PropTypes.func
};

Avatars.defaultProps = {
  children: [],
  getClassName() {},
  onChildClick() {}
};

export default connect(
  mapStateToProps,
  null
)(Avatars);
