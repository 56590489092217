import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import get from "lodash/get";
import BookReader from "../Book/BookReader";
import { isLocalServer } from "utils";
import { getPrimroseResource } from "actions/epub";
import { selectBaseUrl } from "selectors";
import Loading from "../Loading";

class DirectAccessBook extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func
    }),
    extractedEpubBaseUrl: PropTypes.string,
    id: PropTypes.string,
    domainUrl: PropTypes.string,
    baseUrl: PropTypes.string
  };

  static defaultProps = {
    history: {
      push() {}
    },
    extractedEpubBaseUrl: "",
    id: "",
    domainUrl: "",
    baseUrl: ""
  };

  componentDidMount() {
    const { id, getPrimroseResource, baseUrl } = this.props;
    getPrimroseResource(baseUrl, id);
  }

  componentDidUpdate(prevProps) {
    const { primroseBook, id, history } = this.props;
    if (primroseBook !== prevProps.primroseBook) {
      if (id !== "4389" && !primroseBook.status) {
        history.push(`/`);
      }
    }
  }

  updateExtractedEpubBaseUrl = () => {
    const { extractedEpubBaseUrl, domainUrl } = this.props;
    if (!isLocalServer()) {
      let url = extractedEpubBaseUrl.split("/");
      url[2] = domainUrl;
      return url.join("/");
    }
    else {
      // Only 1 book is added in local - book/3658 (Goldilocks and three bears)
      return process.env.PUBLIC_URL;
    }
  };

  render() {
    const { id, primroseBook } = this.props;
    let { data: book } = primroseBook
    return (
      <React.Fragment>
        {book ? (
          <BookReader
            book={book}
            extractedEpubBaseUrl={this.updateExtractedEpubBaseUrl()}
            bookId={id}
            directBook="true"
          />
        ) : (
          <Loading loading="loading" />
        )}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state, ownProps) => {
  const id = get(ownProps, "match.params.bookId");
  return {
    extractedEpubBaseUrl: get(state, "appManifest.data.app_settings.audio_url"),
    id: id,
    domainUrl: get(state, "appManifest.data.book_page.book_base_url"),
    baseUrl: selectBaseUrl(state),
    primroseBook: get(state, "epub.primroseResource")
  };
};

const mapDispatchToProps = dispatch => ({
  getPrimroseResource(baseUrl, bookId) {
    dispatch(getPrimroseResource(baseUrl, bookId));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DirectAccessBook)
);
