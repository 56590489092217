import React from "react";
import "./_App.scss";
import { Route, BrowserRouter } from "react-router-dom";
import GateKeeper from "../GateKeeper";
import get from "lodash/get";
import connect from "react-redux/es/connect/connect";

class App extends React.Component {
  render() {
    const { disableDebugMode } = this.props;
    if (disableDebugMode) {
      console.warn = function() {};
    }
    return (
      <BrowserRouter>
        <Route component={GateKeeper} />
      </BrowserRouter>
    );
  }
}

export const mapStateToProps = state => {
  return {
    disableDebugMode: get(state, "appManifest.data.debug_mode.debug_mode_warn", {})
  };
};

export { App as AppComponent };

export default connect(
  mapStateToProps,
  null
)(App);
