import React, { Component } from "react";
import PropTypes from "prop-types";

import BasicTemplate from "components/Templates/BasicTemplate";
import { BACKGROUNDS } from "constants/styles";
import { apiGetExternal } from "actions/core";
import Cookies from "js-cookie";

import basicTemplateClasses from "../Templates/_BasicTemplate.module.scss";
import Image from "../Global/Image";
import Loading from "../Loading";
import styles from "./_styles.module.scss";
import { setScreenOrientation } from "utils";
import { PAGE_MODE } from "constants/screens";
import { isTablet } from "react-device-detect";

class TermsAndPrivacyTemplate extends Component {
  static propTypes = {
    headingText: PropTypes.string,
    selectedChildId: PropTypes.string,
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired
    })
  };

  static defaultProps = {
    headingText: "",
    selectedChildId: "",
    history: {
      goBack() {}
    }
  };

  state = {
    data: "",
    isLoading: false
  };

  componentDidMount() {
    if (this.props.url) {
      this.getData();
    }
  }

  getData = () => {
    this.setState({ isLoading: true }, () => {
      apiGetExternal({ url: this.props.url })
        .then(response => {
          this.setState({ data: response.data, isLoading: false });
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    });
  };

  close = () => {
    const { history, selectedChildId } = this.props;
    if (Cookies.get("prevPageforPolicy") === "Dashboard") {
      setScreenOrientation(PAGE_MODE.DASHBOARD);
    } else if (Cookies.get("prevPageforPolicy") === "ParentArea") {
      if(isTablet){
        setScreenOrientation(PAGE_MODE.TABLET);
      }
      else{
      setScreenOrientation(PAGE_MODE.PARENT_AREA);
      }
    } else if (Cookies.get("prevPageforPolicy") === "Search") {
      setScreenOrientation(PAGE_MODE.SEARCH);
    }  else if (Cookies.get("prevPageforPolicy") === "Add-Edit") {
      if(isTablet){
        setScreenOrientation(PAGE_MODE.TABLET);
      }
      else{
        setScreenOrientation(PAGE_MODE.ADD_CHILD);
      }
    } 
    else if (Cookies.get("prevPageforPolicy") === "Login") {
      if(isTablet){
        setScreenOrientation(PAGE_MODE.TABLET);
      }
      else{
        setScreenOrientation(PAGE_MODE.LOGIN);
      }
    } else if (Cookies.get("prevPageforPolicy") === "whosePlaying") {
      setScreenOrientation(PAGE_MODE.HOME);
    } else if (Cookies.get("prevPageforPolicy") === "myChildren") {
      if (isTablet) {
        setScreenOrientation(PAGE_MODE.TABLET);
      } else {
        setScreenOrientation(PAGE_MODE.MY_CHILDREN);
      }
    }
    if (Cookies.get("prevPageforPolicy") === "Add-Edit" && Cookies.get("parsedView") === "terms-conditions") {
      Cookies.remove("parsedView");
      history.push("/");
    } else if (!selectedChildId) {
      history.push("/");
    }
    else {
      history.goBack();
    }
  };

  componentDidUpdate(prevProps) {
    const { url } = this.props;
    if (url && url !== prevProps.url) {
      this.getData();
    }
  }

  render() {
    const { headingText } = this.props;
    let { data, isLoading } = this.state;
    if (isLoading) {
      return <Loading />;
    }

    return (
      <BasicTemplate background={BACKGROUNDS.BG_WHITE}>
        <div className={basicTemplateClasses.basic_template_content_wrapper}>
          <Image
            folderName="static"
            fileName="close_window_indigo"
            className={styles.close_icon}
            onClick={this.close}
          />
          <Image
            className={styles.logo_image}
            folderName="ui_and_learning_area"
            fileName="curious_world_logo_colored"
            alt="CW Logo"
          />
          <span className={styles.sub_heading_text}>{headingText}</span>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: data }}
          />
        </div>
      </BasicTemplate>
    );
  }
}

export default TermsAndPrivacyTemplate;
