import React from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import c from "classnames";
import classes from "./_styles.module.scss";
import Image from "components/Global/Image";
import BasicTemplate from "../Templates/BasicTemplate";
import { BACKGROUNDS } from "constants/styles";
import { isMobileOnly } from "react-device-detect";

class GetStarted extends React.Component {
  render() {
    const { getStartedClicked, getStartedData } = this.props;
    return (
      <BasicTemplate background={BACKGROUNDS.LOGIN_BG_IMAGE}>
        <div className={classes.content_wrapper}>
          {isMobileOnly ? (
            <Image
              folderName="ui_and_learning_area"
              fileName="curious_world_logo_double"
              alt="CW Logo"
              className={classes.logo}
            />
          ) : (
            <Image
              folderName="ui_and_learning_area"
              fileName="curious_world_logo"
              alt="CW Logo"
              className={classes.logo}
            />
          )}
          <Image
            folderName="ui_and_learning_area"
            fileName="get_started_rectangle"
            alt="CW Logo"
            className={classes.rectangle_image}
          />
          <div
            className={c("app_button", classes.app_button)}
            onClick={getStartedClicked}
          >
            {getStartedData.button_text}
          </div>
        </div>
      </BasicTemplate>
    );
  }
}

export const mapStateToProps = state => {
  return {
    getStartedData: get(state, "appManifest.data.get_started")
  };
};

export default connect(
  mapStateToProps,
  null
)(GetStarted);
