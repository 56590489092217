import {
  GET_MY_PROFILE_REQUEST,
  GET_MY_PROFILE_SUCCESS,
  GET_MY_PROFILE_FAILURE
} from "../constants/actions";
import get from "lodash/get";
import { apiGet, generateUrl } from "./core";

export const getMyProfileRequest = () => {
  return {
    type: GET_MY_PROFILE_REQUEST,
    isFetching: true,
    errorMessage: ""
  };
};

export const getMyProfileSuccess = data => {
  return {
    type: GET_MY_PROFILE_SUCCESS,
    data,
    errorMessage: "",
    isFetching: false
  };
};

export const getMyProfileFailure = errorMessage => {
  return {
    type: GET_MY_PROFILE_FAILURE,
    data: {},
    errorMessage,
    isFetching: false
  };
};

export const getMyProfile = baseUrl => {
  const url = generateUrl(baseUrl, "/users/me");

  return dispatch => {
    dispatch(getMyProfileRequest());
    return apiGet({ url })
      .then(response =>
        dispatch(getMyProfileSuccess(get(response, "data", [])))
      )
      .catch(err => dispatch(getMyProfileFailure(get(err, "message", []))));
  };
};
