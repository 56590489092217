export const EVENT_TOKEN = "VEo0OGVyNFNVMndScGZ6Y3Zaa0R5aEJKcEQ0dG5ielg=";

export const WHO_IS_PLAYING_SCREEN_TYPES = {
  LAYOVER: "LAYOVER",
  HOME: "HOME",
  MY_CHILDREN: "MY_CHILDREN",
  PARENT_DASHBOARD: "PARENT_DASHBOARD"
};

export const ERROR_MESSAGES = {
  NAME_NOT_EMPTY: "Please add child's first name.",
  BIRTH_NOT_EMPTY: "Please enter a valid birthday.",
  AVATAR_NOT_EMPTY: "Please select an Avatar for the child."
};

export const ENTITY_TYPES = {
  BOOK: "book",
  VIDEO: "video",
  GAME: "game"
};

export const DIALOG_IDS = {
  BUMPER_SCREEN: "bumper_screen",
  EVENT_MESSAGE: "event_message"
};

export const PAGE = {
  ID: "page0001"
};

export const BUILD = {
  BUNDLE: "1"
};

export const INDEXED_DB_CONST = {
  DB_NAME: "curious_world",
  DB_STORE_NAME: "manifestdata"
};

export const INDEXED_DB_ERRORS = {
  STORE_NOT_EXIST: "STORE_NOT_EXIST"
};

export const DIRECT_LINKS = ["add-child", "my-children", "privacy-policy", "terms-conditions"]

export const DIRECTLY_ALLOWED_LINKS = ["add-child", "privacy-policy"]

export const RESOURCE_NOT_FOUND_ERROR = "Looks like this resource isn't available for your age or country."