import { createSelector } from "reselect";
import {
  selectManifestData,
  selectRecommendedManifestData,
  selectFavoriteList
} from "./dashboard";
import get from "lodash/get";

export const selectCategoryList = createSelector(
  [selectManifestData],
  manifestData => {
    if (manifestData.length) {
      let processedCategoryList = manifestData.reduce(
        (processedCategoryList, e) => {
          let data;
          let thumbnails = e.thumbnails.filter(data => {
            return data.indexOf("Icn.png") === data.length - "Icn.png".length;
          });
          if (e.category === "category") {
            data = {
              id: e.id,
              title: e.title,
              thumbnails: thumbnails,
              category_color: e.bg_color
            };
            processedCategoryList.push(data);
          }
          return processedCategoryList;
        },
        []
      );
      return processedCategoryList;
    }
  }
);

export const selectselectedCategoryId = (state, props) =>
  get(props.match, "params.categoryId", "");

export const selectCategoryData = createSelector(
  [
    selectselectedCategoryId,
    selectRecommendedManifestData,
    selectManifestData,
    selectFavoriteList
  ],
  (
    selectedCategoryId,
    recommendedManifestData,
    manifestData,
    favoriteList = []
  ) => {
    if (recommendedManifestData !== "" && manifestData.length) {
      let recommendedCategoryData = get(
        recommendedManifestData,
        "data.categories",
        []
      ).filter(category => category.itemId === selectedCategoryId);

      let categoryEntityList = get(
        recommendedManifestData,
        "data.recommended",
        []
      ).filter(entityItemId => {
        if (get(
          recommendedCategoryData,
            "[0].recommended",
            []
        ).includes(entityItemId)) {
          return entityItemId
        }
      });

      /*let categoryManifestData = manifestData.filter(
        data => data.category === "category" && data.id === selectedCategoryId
      );*/

      let categoryData = categoryEntityList.reduce((recommendedCategoryData, rcd) => {
        /*let findCategoryDataTitles = get(
          categoryManifestData,
          "[0].titles",
          []
        ).filter(data => data.id === rcd);*/
        let findCategoryDataTitles = manifestData.filter(data => data.id === rcd);
        let fvrt = 0;
        if (favoriteList.length && favoriteList.indexOf(rcd) !== -1) {
          fvrt = 1;
        }
        if (findCategoryDataTitles.length) {
          let categoryDataTitles = {
            ...findCategoryDataTitles["0"],
            favorite: fvrt
          };
          recommendedCategoryData.push(categoryDataTitles);
        }

        return recommendedCategoryData;
      }, []);
      return categoryData;
    }
  }
);

export const selectSelectedCategoryInfo = createSelector(
  [selectselectedCategoryId, selectCategoryList],
  (selectedCategoryId, categoryList) => {
    if (categoryList instanceof Array) {
      let selectedCategoryInfo = categoryList.filter(
        category => category.id === selectedCategoryId
      );
      return selectedCategoryInfo["0"];
    }
  }
);
