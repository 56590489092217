import deepFreeze from "deep-freeze-es6";
import Cookies from "js-cookie";
import {
  WEB_APP_EVENT_REQUEST,
  WEB_APP_EVENT_SUCCESS,
  WEB_APP_EVENT_FAILURE,
  RESOURCE_PROGRESSIONS_REQUEST,
  RESOURCE_PROGRESSIONS_SUCCESS,
  RESOURCE_PROGRESSIONS_FAILURE,
  AUTHENTICATE_URL_REQUEST,
  AUTHENTICATE_URL_SUCCESS,
  AUTHENTICATE_URL_FAILURE,
  READ_API_VERSION_FAILURE,
  READ_API_VERSION_REQUEST,
  READ_API_VERSION_SUCCESS,
  LOADING_SCREEN_MESSAGE_REQUEST,
  LOADING_SCREEN_MESSAGE_SUCCESS,
  LOADING_SCREEN_MESSAGE_FAILURE,
  USER_LOGGED_OUT
} from "constants/actions";
import { cookieParams } from "utils";

const initialState = {
  isFetching: false,
  data: {},
  errorMessage: "",
  apiversiondata: "",
  entityData: ""
};

export const webAppEventRequest = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage
  };
};

export const webAppEventSuccess = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    data: action.data
  };
};

export const webAppEventFailure = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    data: action.data
  };
};

export const resourceProgressionsRequest = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage
  };
};

export const resourceProgressionsSuccess = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    data: action.data
  };
};

export const resourceProgressionsFailure = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    data: action.data
  };
};

export const authenticateUrlRequest = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    entityData: ""
  };
};

export const authenticateUrlSuccess = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    entityData: action.data
  };
};

export const authenticateUrlFailure = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    entityData: action.data
  };
};

export const getApiVersionRequest = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage
  };
};

export const getApiVersionSuccess = (state, action) => {
  if(action.apiversiondata && action.apiversiondata.data){
    if (
      localStorage !== null &&
      localStorage !== undefined &&
      action.apiversiondata.data.id
    ) {
      if (localStorage.getItem("manifestVersion") === null) {
        localStorage.setItem(
          "manifestVersion",
          action.apiversiondata.data.id
        );
      }
      localStorage.setItem(
        "manifestVersionNew",
        action.apiversiondata.data.id
      );
    }
    Cookies.set("remainingFreeDays", action.apiversiondata.data.remainingFreeDays, cookieParams);  
    return {
      ...state,
      isFetching: action.isFetching,
      errorMessage: action.errorMessage,
      apiversiondata: action.apiversiondata.data.id
    };
  } else {
    return {
      ...state,
      isFetching: action.isFetching,
      errorMessage: action.errorMessage,
      apiversiondata: ""
    };
  }
};

export const getApiVersionFailure = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage
  };
};

export const loadingScreenMessageRequest = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    messageList: ""
  };
};
export const loadingScreenMessageSuccess = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage,
    messageList: action.messageList.data
  };
};
export const loadingScreenMessageFailure = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage
  };
};

export const userLogout = (state, action) => {
  return {
    ...state,
    messageList: ""
  };
};

export default (state = initialState, action) => {
  deepFreeze(state);
  deepFreeze(action);

  switch (action.type) {
    case WEB_APP_EVENT_REQUEST:
      return webAppEventRequest(state, action);
    case WEB_APP_EVENT_SUCCESS:
      return webAppEventSuccess(state, action);
    case WEB_APP_EVENT_FAILURE:
      return webAppEventFailure(state, action);
    case RESOURCE_PROGRESSIONS_REQUEST:
      return resourceProgressionsRequest(state, action);
    case RESOURCE_PROGRESSIONS_SUCCESS:
      return resourceProgressionsSuccess(state, action);
    case RESOURCE_PROGRESSIONS_FAILURE:
      return resourceProgressionsFailure(state, action);
    case AUTHENTICATE_URL_REQUEST:
      return authenticateUrlRequest(state, action);
    case AUTHENTICATE_URL_SUCCESS:
      return authenticateUrlSuccess(state, action);
    case AUTHENTICATE_URL_FAILURE:
      return authenticateUrlFailure(state, action);
    case READ_API_VERSION_FAILURE:
      return getApiVersionFailure(state, action);
    case READ_API_VERSION_REQUEST:
      return getApiVersionRequest(state, action);
    case READ_API_VERSION_SUCCESS:
      return getApiVersionSuccess(state, action);
    case LOADING_SCREEN_MESSAGE_REQUEST:
      return loadingScreenMessageRequest(state, action);
    case LOADING_SCREEN_MESSAGE_SUCCESS:
      return loadingScreenMessageSuccess(state, action);
    case LOADING_SCREEN_MESSAGE_FAILURE:
      return loadingScreenMessageFailure(state, action);
    case USER_LOGGED_OUT:
      return userLogout(state, action);
    default:
      return state;
  }
};
