import { createSelector } from "reselect";
import getfp from "lodash/fp/get";
import { getAgeFromBirthDate } from "utils";
import get from "lodash/get";

export const selectChildren = getfp("children.data.data");

export const selectSelectedChildId = getfp("children.selectedChildId");

export const selectAddedChildId = getfp("children.addedChildId");

export const selectSelectedChild = createSelector(
  [selectChildren, selectSelectedChildId],
  (children = [], selectedChildId = "") =>
    children.find(child => child.id === selectedChildId)
);

const selectChildId = (state, props = {}) => props.childId;

export const selectChildBasedOnId = createSelector(
  [selectChildren, selectChildId],
  (children = [], childId = "") => children.find(child => child.id === childId)
);

export const selectSelectedChildAge = createSelector(
  [selectSelectedChild],
  (children = []) => getAgeFromBirthDate(children.birthday)
);

export const selectIsFetchingChildren = getfp("children.isFetchingChild");

export const selectAllCountryUrl = state =>
  get(state, "appManifest.data.app_settings.fetch_all_countries");
