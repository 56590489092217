import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

import withChildren from "../WithChildren";
import WhoIsPlaying from "components/WhoIsPlayingContainer/WhoIsPlaying";
import SidebarLayoverTemplate from "components/Templates/SidebarLayoverTemplate";

import { WHO_IS_PLAYING_SCREEN_TYPES } from "../../constants";

const WhoIsPlayingLayover = ({ onClose, headingText, children }) => (
  <SidebarLayoverTemplate headingText={headingText} onClose={onClose}>
    <WhoIsPlaying
      screenType={WHO_IS_PLAYING_SCREEN_TYPES.LAYOVER}
      onClose={onClose}
      children={children}
    />
  </SidebarLayoverTemplate>
);

WhoIsPlayingLayover.propTypes = {
  onClose: PropTypes.func,
  headingText: PropTypes.string
};

WhoIsPlayingLayover.defaultProps = {
  onClose() {},
  headingText: ""
};

export const mapStateToProps = state => ({
  headingText: get(state, "appManifest.data.who_is_playing.heading_text")
});

export default withChildren(
  connect(mapStateToProps)(WhoIsPlayingLayover)
);
