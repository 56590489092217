import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import c from "classnames";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import Cookies from "js-cookie";

import Image from "components/Global/Image";
import { addChild, editChild } from "actions/children";
import { selectUserId } from "selectors/profile";
import {
  selectBaseUrl,
  selectNoOfAvatars
} from "selectors";
import { setSelectedChildId } from "actions/children";
import {
  selectSelectedChild,
  selectAddedChildId,
  selectChildBasedOnId,
  selectIsFetchingChildren,
  selectSelectedChildId
} from "selectors/children";
import { getAvatarPositionFromUrl, getAvatarIndexInUse, cookieParams } from "utils";
import basicTemplateClasses from "components/Templates/_BasicTemplate.module.scss";
import { webAppEvent } from "actions/common";

import { ERROR_MESSAGES } from "../../constants";
import BasicTemplate from "../Templates/BasicTemplate";
import styles from "./_styles.module.scss";
import DateOfBirth from "./DateOfBirth";
import withChildren from "../WithChildren";
import Loading from "../Loading";
import Loader from "../Loader";
import {
  deviceInfo,
  newChildAdded
} from "../../helper.js";
// import { initialChildProfileCreated } from "../../helper.js";
import { selectUser } from "selectors/profile";
import { selectEventAvatarImages } from "selectors/event";
import { selectCountryCode } from "selectors/dashboard";
import { Link } from "react-router-dom";
import { PATHS } from "constants/url";
import { setScreenOrientation } from "utils";
import { PAGE_MODE } from "constants/screens";
import { WHO_IS_PLAYING_SCREEN_TYPES } from "constants/index";
import Error from "../Error";
import { isMobile, isTablet } from "react-device-detect";
import { DIRECT_LINKS } from "constants/index";
const queryString = require("query-string");

let oKButton = false;
let apiErrorMessageText = "";
class AddEditChild extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    baseUrl: PropTypes.string,
    userId: PropTypes.string,
    noOfAvatars: PropTypes.number,
    saveBtnText: PropTypes.string,
    deleteBtnText: PropTypes.string,
    addChild: PropTypes.func,
    editChild: PropTypes.func,
    selectedChild: PropTypes.object,
    avatarsList: PropTypes.objectOf(PropTypes.string),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }),
    selectChild: PropTypes.func,
    addedChildId: PropTypes.string,
    homePageData: PropTypes.object,
    children: PropTypes.array
  };

  static defaultProps = {
    isEdit: false,
    baseUrl: "",
    userId: "",
    noOfAvatars: 0,
    saveBtnText: "Save",
    deleteBtnText: "Delete",
    addChild() {},
    editChild() {},
    selectedChild: {},
    avatarsList: {},
    history: {
      push() {}
    },
    selectChild() {},
    addedChildId: "",
    homePageData: {},
    children: []
  };

  state = {
    name: "",
    birthday: null,
    avatarIndex: 0,
    error: "",
    birthdayError: "",
    avatarError: "",
    birthdayStatus: true,
    currentAvatarInUse: false,
    isAvatarLoading: false,
    isDisable: false,
    isChildren: false,
    mobileErrormsg: false
  };

  componentDidMount() {
    const {
      isEdit,
      childBasedOnId,
      children,
      history
    } = this.props;
    const { avatarIndex } = this.state;
    let parsed = queryString.parse(window.location.search);
    if (parsed.view) {
      Cookies.set("parsedView", parsed.view, cookieParams);
    }
    if (!isEdit && children.length >= 4) {
      setScreenOrientation(PAGE_MODE.HOME);
      history.push("/");
    }
    if (isEdit && childBasedOnId) {
      this.setSelectedChildValues();
    }
    if (!isEdit) {
      this.checkAvatarInUse(avatarIndex);
      this.setState({
        birthdayStatus: false
      });
    }
    let screenType = sessionStorage.getItem("screenType");
    this.setState({
      isDisable: false,
      isChildren: screenType === "MY_CHILDREN" ? true : false
    });
    this.onSwiped();
  }

  componentDidUpdate(prevProps) {
    const {
      isEdit,
      childBasedOnId,
      children,
      history
    } = this.props;
    const { avatarIndex } = this.state;
    if (!isEdit && children.length >= 4) {
      setScreenOrientation(PAGE_MODE.HOME);
      history.push("/");
    }
    if (!isEqual(childBasedOnId, prevProps.childBasedOnId) && isEdit) {
      this.setSelectedChildValues();
    }
    if (!isEqual(children, prevProps.children) && !isEdit) {
      this.checkAvatarInUse(avatarIndex);
    }
  }

  isValidChild = childId => {
    return !(this.props.children || []).find(child => child.id === childId);
  };

  setSelectedChildValues = () => {
    const { childBasedOnId = {}, avatarsList = {} } = this.props;

    const { name, birthday } = childBasedOnId;
    const avatarImageUrl = get(childBasedOnId, "metadata.avatar", "");
    const avatarIndex = getAvatarPositionFromUrl(avatarImageUrl, avatarsList);

    this.setState({
      name,
      birthday,
      avatarIndex
    });
  };

  onNameChange = event =>
    this.setState({ name: event.target.value.trim(), error: "" });

  onNameKeyDown = event => {
    if (event.keyCode === 32) {
      event.preventDefault();
    }
  };

  onDobChange = (birthday, year, month, day) => {
    let d = new Date();
    let curMonth = d.getMonth() + 1;
    let curDay = d.getDate();
    let curYear = d.getFullYear();
    let isValidDate = false;
    if (parseInt(month) < curMonth && parseInt(year) === curYear) {
      isValidDate = true;
    } else if (
      parseInt(month) === curMonth &&
      parseInt(day) <= curDay &&
      parseInt(year) === curYear
    ) {
      isValidDate = true;
    } else if (parseInt(year) !== curYear) {
      isValidDate = true;
    } else {
      isValidDate = false;
    }
    this.setState({ birthday });
    if (parseInt(year) > -1 && parseInt(month) > -1 && parseInt(day) > -1 && isValidDate) {
      this.setState({
        birthdayError: "",
        birthdayStatus: true,
        year: year,
        month: month,
        day: day
      });
    } else {
      this.setState({
        birthdayStatus: false
      });
    }
  };

  // handleInitialChildProfileCreated = data => {
  //   const { webAppEvent, kafkaUrl, profile } = this.props;
  //   const { year, month, day } = this.state;
  //   let params = [
  //     {
  //       ...deviceInfo,
  //       ...initialChildProfileCreated(data, day, month, year, profile)
  //     }
  //   ];
  //   webAppEvent(kafkaUrl, params);
  // };

  handleNewChildAdded = data => {
    const { webAppEvent, kafkaUrl, profile, countryCode } = this.props;
    let params = [
      {
        ...deviceInfo,
        ...newChildAdded(profile, data, countryCode)
      }
    ];
    webAppEvent(kafkaUrl, params);
  };

  onSave = () => {
    const {
      name,
      birthday,
      birthdayStatus,
      currentAvatarInUse,
      isDisable,
      avatarIndex
    } = this.state;
    const {
      addChild,
      editChild,
      isEdit
    } = this.props;
    const save = isEdit ? editChild : addChild;

    const params = {
      name,
      metadata: { avatar: `avatar-${avatarIndex + 1}` },
      birthday
    };
    let nameerror = false;
    let birtherror = false;
    let imageerror = false;
    if (this.isValidForSubmission()) {
      if (birthdayStatus) {
        if (!currentAvatarInUse) {
          this.setState({
            isDisable: true
          });
          !isDisable &&
            save(params, this.getChildIdFromUrl()).then(() => {
              setScreenOrientation(PAGE_MODE.DASHBOARD);
              this.selectUpdatedChild(params);
            });
        } else {
          this.setState({
            error: "",
            birthdayError: "",
            avatarError: ERROR_MESSAGES.AVATAR_NOT_EMPTY
          });
          nameerror = false;
          birtherror = false;
          imageerror = true;
        }
      } else {
        this.setState({
          error: "",
          birthdayError: ERROR_MESSAGES.BIRTH_NOT_EMPTY,
          avatarError: ""
        });
        nameerror = false;
        birtherror = true;
        imageerror = false;
      }
    } else {
      this.setState({
        error: ERROR_MESSAGES.NAME_NOT_EMPTY,
        birthdayError: "",
        avatarError: ""
      });
      nameerror = true;
      birtherror = false;
      imageerror = false;
    }
    oKButton = false;
    if (isMobile) {
      if (nameerror) {
        apiErrorMessageText = ERROR_MESSAGES.NAME_NOT_EMPTY;
        oKButton = true;
        this.setState({
          mobileErrormsg: true
        });
      } else if (birtherror) {
        apiErrorMessageText = ERROR_MESSAGES.BIRTH_NOT_EMPTY;
        oKButton = true;
        this.setState({
          mobileErrormsg: true
        });
      } else if (imageerror) {
        apiErrorMessageText = ERROR_MESSAGES.AVATAR_NOT_EMPTY;
        oKButton = true;
        this.setState({
          mobileErrormsg: true
        });
      }
    }
  };

  getChildIdFromUrl = () => {
    const { match } = this.props;
    return get(match, "params.childId", "");
  };

  selectUpdatedChild = params => {
    const { history, isEdit, addedChildId, selectChild } = this.props;
    if (isEdit) {
      let childId = this.getChildIdFromUrl();
      selectChild(childId);
      history.push(`/${childId}/dashboard`);
    } else {
      this.handleNewChildAdded(params);
      selectChild(addedChildId);
      this.redirectToRespectivePage();
      // history.push(`/${addedChildId}/dashboard`);
    }
  };

  redirectToRespectivePage = () => {
    const { history, addedChildId } = this.props;
    let parsed = queryString.parse(window.location.search);
    if (parsed.view) {
      let sitePath;
      if (DIRECT_LINKS.indexOf(parsed.view) !== -1) {
        sitePath = `${parsed.view}`;
      } else if (parsed.id) {
        sitePath = `${addedChildId}/${parsed.view}/${parsed.id}`;
      } else {
        sitePath = `${addedChildId}/${parsed.view}`;
      }
      history.push({
        pathname: sitePath
      });
    } else {
      history.push(`/${addedChildId}/dashboard`);
    }
  }

  onDelete = () => {
    Cookies.remove("parsedView");  
    if(isTablet){
      setScreenOrientation(PAGE_MODE.TABLET);
    }
    else{
      setScreenOrientation(PAGE_MODE.DELETE_CHILD);
    }
    this.props.history.push(`/${this.props.childBasedOnId.id}/delete-child`);
  };

  privacyClick = () => {
    if (isTablet) {
      setScreenOrientation(PAGE_MODE.TABLET);
    } else {
      setScreenOrientation(PAGE_MODE.PRIVACY_POLICY);
    }
  };

  checkAvatarInUse = avatarIndexToCheck => {
    const { children, avatarsList, isEdit } = this.props;
    const childId = isEdit ? this.getChildIdFromUrl() : "";
    let avatarListInUse = getAvatarIndexInUse(children, avatarsList, childId);
    let currentAvatarInUse = false;
    if (avatarListInUse.indexOf(avatarIndexToCheck) > -1) {
      currentAvatarInUse = true;
    }
    this.setState({
      currentAvatarInUse: currentAvatarInUse
    });
    if (!currentAvatarInUse) {
      this.setState({
        avatarError: ""
      });
    }
  };

  loadNextAvatar = () => {
    const { avatarIndex } = this.state;
    this.setState({
      isAvatarLoading: true
    });
    const isLast = avatarIndex + 1 === this.props.noOfAvatars;
    const avatarIndexToCheck = isLast ? 0 : avatarIndex + 1;
    this.checkAvatarInUse(avatarIndexToCheck);
    this.setState({
      avatarIndex: avatarIndexToCheck
    });
  };

  loadPreviousAvatar = () => {
    const { avatarIndex } = this.state;
    this.setState({
      isAvatarLoading: true
    });
    const isFirst = avatarIndex - 1 === -1;
    const avatarIndexToCheck = isFirst
      ? this.props.noOfAvatars - 1
      : avatarIndex - 1;
    this.checkAvatarInUse(avatarIndexToCheck);
    this.setState({
      avatarIndex: avatarIndexToCheck
    });
  };

  goBack = () => {
    const { history, selectedChildId, match } = this.props;
    const screenType = sessionStorage.getItem("screenType");
    setScreenOrientation(PAGE_MODE.DASHBOARD);
    let childId;
    Cookies.remove("parsedView");
    if (selectedChildId) {
      childId = selectedChildId;
    }
    else {
      childId = get(match, "params.childId", "");
    }
    if (childId && screenType === WHO_IS_PLAYING_SCREEN_TYPES.MY_CHILDREN) {
      history.push(`/my-children`);
    } else if (childId && screenType === WHO_IS_PLAYING_SCREEN_TYPES.PARENT_DASHBOARD) {
      history.push(`/${childId}/parent-dashboard`);
    } else if (childId && screenType === WHO_IS_PLAYING_SCREEN_TYPES.LAYOVER) {
      history.push(`/${childId}/dashboard`);
    } else {
      history.push(`/`);
    }
  };

  isValidForSubmission = () => {
    return !!this.state.name.trim();
  };

  avatarloader = () => {
    setTimeout(() => {
      this.setState({
        isAvatarLoading: false
      });
    }, 200);
  };

  closeErrorpop = () => {
    this.setState({
      mobileErrormsg: false
    });
    apiErrorMessageText = "";
    oKButton = false;
  };

  onSwiped = () => {
    let touchstartX = 0;
    let touchendX = 0;
    let self = this;
    const gestureZone = document.getElementById('swipe');

    gestureZone.addEventListener('touchstart', function(event) {
      touchstartX = event.changedTouches[0].screenX;
    }, false);

    gestureZone.addEventListener('touchend', function(event) {
      touchendX = event.changedTouches[0].screenX;
      self.handleGesture(touchstartX, touchendX);
    }, false);
  }

  handleGesture = (touchstartX, touchendX) => {
    if (touchendX < touchstartX) {
      this.loadPreviousAvatar();
    }

    if (touchendX > touchstartX) {
      this.loadNextAvatar();
    }
  }

  render() {
    Cookies.set("prevPageforPolicy", "Add-Edit", cookieParams);
    const {
      isEdit,
      saveBtnText,
      deleteBtnText,
      isFetchingChild,
      childBasedOnId,
      homePageData,
      children,
      child_not_available,
      addChildLoader,
      errorMessageApi,
      errorMessageProfile,
      eventAvatarImages
    } = this.props;
    const {
      name,
      birthday,
      avatarIndex,
      error,
      birthdayError,
      avatarError,
      currentAvatarInUse,
      isAvatarLoading,
      mobileErrormsg
    } = this.state;
    if (
      errorMessageApi !== null &&
      errorMessageApi !== "" &&
      errorMessageApi !== undefined
    ) {
      apiErrorMessageText = errorMessageApi;
    } else if (
      errorMessageProfile !== null &&
      errorMessageProfile !== "" &&
      errorMessageProfile !== undefined
    ) {
      apiErrorMessageText = errorMessageProfile;
    }
    return (
      <React.Fragment>
        {((apiErrorMessageText !== "" && apiErrorMessageText !== undefined) ||
          mobileErrormsg) && (
          <Error
            errorMessage={apiErrorMessageText}
            okButton={oKButton}
            closeErrorpop={this.closeErrorpop}
          />
        )}
        <React.Fragment>
          {((isEdit && !childBasedOnId) ||
            addChildLoader ||
            (!isEdit && isFetchingChild)) && <Loading loading="loading" />}
          <BasicTemplate>
            <div
              className={c(
                basicTemplateClasses.basic_template_content_wrapper,
                styles.add_edit_wrapper
              )}
            >
              {children.length === 0 && (
                <span className="error">{child_not_available}</span>
              )}
              {children.length > 0 && (
                <Image
                  folderName="static"
                  fileName="close_window"
                  className={basicTemplateClasses.close_icon}
                  onClick={this.goBack}
                />
              )}
              <div className={styles.input_fields}>
                <div className={styles.profile_name_with_error}>
                  <div className={styles.disclaimer}>Only enter your child's first name or nickname</div>
                  <div className={styles.profile_name_wrapper}>
                    <input
                      type="text"
                      className={styles.profile_name}
                      onChange={this.onNameChange}
                      onKeyDown={this.onNameKeyDown}
                      value={name || ""}
                      placeholder="First Name"
                      onFocus={e => (e.target.placeholder = "")}
                      onBlur={e => (e.target.placeholder = "First Name")}
                      maxLength="25"
                    />
                    {isEdit ? (
                      <Image
                        className={styles.edit}
                        folderName="static"
                        fileName="edit"
                        alt="edit"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  {!isMobile && error && <span className="error">{error}</span>}
                </div>
                <div className={styles.dob_wrapper}>
                  <div className={styles.dob_with_error}>
                    <DateOfBirth
                      value={birthday}
                      onDobChange={this.onDobChange}
                      isEdit={isEdit}
                    />
                    {isEdit ? (
                      <Image
                        className={styles.edit}
                        folderName="static"
                        fileName="edit"
                        alt="edit"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  {!isMobile && birthdayError && (
                    <span className="error">{birthdayError}</span>
                  )}
                </div>
              </div>
              <div className={styles.avatar_wrapper_outer}>
                <div className={styles.avatar_wrapper}>
                  <Image
                    className={c(styles.arrow, styles.left)}
                    folderName="static"
                    fileName="left_arrow"
                    alt="previous"
                    onClick={this.loadPreviousAvatar}
                  />
                  <div
                    className={c(
                      styles.avatar_image_wrapper,
                      currentAvatarInUse ? styles.avatar_in_use : "",
                      isAvatarLoading ? styles.avatar_loading : ""
                    )}
                    id="swipe"
                  >
                    {isAvatarLoading && (
                      <Loader favClass="true" className="avatar_loader" />
                    )}
                    {eventAvatarImages ? (
                      <img
                        className={styles.avatar}
                        src={get(eventAvatarImages, `avatar-${avatarIndex + 1}`, "")}
                        onLoad={this.avatarloader}
                        alt={`avatar-${avatarIndex + 1}`}
                      />
                    ) : (
                      <Image
                        className={styles.avatar}
                        folderName="avatars"
                        fileName={`avatar${avatarIndex + 1}`}
                        onLoad={this.avatarloader}
                      />
                    )}
                    {currentAvatarInUse ? <span>In Use</span> : ""}
                  </div>
                  <Image
                    className={styles.arrow}
                    folderName="static"
                    fileName="right_arrow"
                    alt="next"
                    onClick={this.loadNextAvatar}
                  />
                </div>
              </div>
              {!isMobile && avatarError && (
                <span className="error">{avatarError}</span>
              )}
              <div
                role="presentation"
                className={c("app_button", styles.app_button)}
                onClick={this.onSave}
              >
                {saveBtnText}
              </div>
              {isEdit && (
                <div
                  role="presentation"
                  className={styles.delete_button}
                  onClick={this.onDelete}
                >
                  {deleteBtnText}
                </div>
              )}
              <div
                className="privacy privacy_white"
                onClick={this.privacyClick}
              >
                <Link to={PATHS.PRIVACY_POLICY}>
                  <Image
                    folderName="static"
                    fileName="info_privacy"
                    alt="Privacy Icon"
                  />
                  {homePageData["privacy_text"]}
                </Link>
              </div>
            </div>
          </BasicTemplate>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state, ownProps) => {
  const childId = get(ownProps, "match.params.childId", "");

  return {
    baseUrl: selectBaseUrl(state),
    userId: selectUserId(state),
    noOfAvatars: selectNoOfAvatars(state),
    saveBtnText: get(state, "appManifest.data.add_edit_page.save_button_text"),
    deleteBtnText: get(
      state,
      "appManifest.data.add_edit_page.delete_button_text"
    ),
    selectedChild: selectSelectedChild(state),
    avatarsList: get(state, "appManifest.data.icons.avatars", {}),
    addedChildId: selectAddedChildId(state),
    childBasedOnId: selectChildBasedOnId(state, { childId }),
    isFetchingChild: selectIsFetchingChildren(state),
    kafkaUrl: get(state, "appManifest.data.app_settings.kafka_url"),
    profile: selectUser(state),
    homePageData: get(state, "appManifest.data.home_page"),
    addChildLoader: get(state, "children.isFetching"),
    errorMessageApi: get(state, "children.errorMessage"),
    errorMessageProfile: get(state, "myProfile.errorMessage"),
    eventAvatarImages: selectEventAvatarImages(state),
    selectedChildId: selectSelectedChildId(state),
    countryCode: selectCountryCode(state)
  };
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { baseUrl, userId } = stateProps;
  const { dispatch } = dispatchProps;

  return {
    ...stateProps,
    ...ownProps,
    addChild(params) {
      return dispatch(addChild(baseUrl, userId, params));
    },
    editChild(params, childId) {
      return dispatch(editChild(baseUrl, userId, childId, params));
    },
    selectChild(id) {
      return dispatch(setSelectedChildId(id));
    },
    webAppEvent(url, params) {
      dispatch(webAppEvent(url, params));
    }
  };
};

export default withRouter(
  withChildren(
    connect(
      mapStateToProps,
      null,
      mergeProps
    )(AddEditChild)
  )
);

export { AddEditChild as AddEditChildComponent };
