import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import InfiniteScroll from "react-bidirectional-infinite-scroll";
import get from "lodash/get";

import SidebarHeaderTemplate from "components/Templates/SidebarHeaderTemplate";
import {
  selectItemTypes,
  selectFavoriteData,
  isFetchingFavList,
  selectCountryCode
} from "selectors/dashboard";
import { selectSelectedChild, selectSelectedChildId } from "selectors/children";

import Tile from "../Dashboard/Tile";
import styles from "../Dashboard/_Dashboard.module.scss";
import Loading from "../Loading";
import LockedPopup from "components/Dashboard/LockedPopup";
import Error from "../Error";
import { lockedTitleTapped } from "../../helper.js";
import { selectUser } from "selectors/profile";
import { webAppEvent } from "actions/common";
import c from "classnames";
import Cookies from "js-cookie";
import { cookieParams } from "utils";

let apiErrorMessageText = "";
class Favourites extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      length: 20,
      showLockedImage: false,
      button: {
        buttonConfig: {
          type: "submit"
        }
      },
      itemsClicked: false
    };
  }

  componentDidMount() {
    Cookies.set("searchText","", cookieParams);
    Cookies.remove("searchText");
  }

  getItems = (totalItemsInArray, length) => {
    const { favData } = this.props;
    return favData.slice(totalItemsInArray, totalItemsInArray + length);
  };

  handleHorizontalScroll = (position, previousPosition) => {
    let { items, length } = this.state;
    Cookies.set("scrollPosition", position, cookieParams);
    Cookies.set("itemLength", items.length, cookieParams);
    let scrollablePosition = document.getElementsByClassName("tiles_wrapper")[0].getElementsByTagName("div")[0].scrollWidth - document.getElementsByClassName("tiles_wrapper")[0].offsetWidth;
    if (scrollablePosition - position < 50) {
      items = items.concat(this.getItems(items.length, length));
      this.setState({ items });
    }
  }

  componentDidUpdate(prevProps) {
    const { history, selectedChildId, favData, isFetchingFavList } = this.props;
    const { length, items, itemsClicked } = this.state;
    if (!isFetchingFavList && items.length === 0 && (!favData || favData.length === 0)) {
      if (selectedChildId) {
        history.push(`/${selectedChildId}/dashboard`);
      }
      else {
        history.push(`/`);
      }
    }
    if (
      favData &&
      favData !== prevProps.favData
    ) {
      let scrollPosition = Cookies.get("scrollPosition");
      let itemLength = Cookies.get("itemLength");
      if (items.length && !itemsClicked) {
        this.setState({
          items: items,
          itemsClicked: true
        });
      }
      if (!items.length) {
        if (scrollPosition && scrollPosition !== 0 && itemLength) {
          this.setState({
            items: [].concat(this.getItems(0, itemLength))
          });
        }
        else {
          this.setState({
            items: [].concat(this.getItems(0, length))
          });
        }
      }
      let tiles_wrapper = document.getElementsByClassName("tiles_wrapper")[0];
      if (tiles_wrapper) {
        tiles_wrapper.getElementsByTagName("div")[0].scrollLeft = scrollPosition;
      }
    }
  }

  onClose = () => {
    this.setState({
      showLockedImage: false
    });
  };

  onClickLocked = item => {
    this.setState({
      showLockedImage: true
    });
    this.handleLockedTitleTapped(item);
  };

  handleLockedTitleTapped = item => {
    const {
      webAppEvent,
      kafkaUrl,
      itemTypes,
      selectedChild,
      profile,
      countryCode
    } = this.props;
    switch (item.type) {
      case itemTypes.videos:
        item.contentType = "video";
        break;
      case itemTypes.books:
        item.contentType = "book";
        break;
      case itemTypes.games:
        item.contentType = "game";
        break;
      default:
        item.contentType = "collection";
        break;
    }
    let params = [
      {
        ...lockedTitleTapped(profile, item, selectedChild, countryCode)
      }
    ];
    webAppEvent(kafkaUrl, params);
  };

  updateTile = (id, status) => {
    const { items } = this.state;
    let clickedItem = items.filter(function(item) {
      return item['id'] !== id;
    });

    this.setState({
      items: clickedItem,
      itemsClicked: false
    });
  }

  render() {
    Cookies.set("prevPageforPolicy", "Favourite", cookieParams);
    const {
      favorites,
      theme,
      favAddListErrorMsg,
      errorMessageApi,
      favData
    } = this.props;
    const { showLockedImage } = this.state;
    let item1,
      item2 = null;
    const length = this.state.items.length;
    if (
      favAddListErrorMsg !== "" &&
      favAddListErrorMsg !== null &&
      favAddListErrorMsg !== undefined
    ) {
      apiErrorMessageText = favAddListErrorMsg;
    } else if (
      errorMessageApi !== null &&
      errorMessageApi !== "" &&
      errorMessageApi !== undefined
    ) {
      apiErrorMessageText = favAddListErrorMsg;
    }
    return (
      <React.Fragment>
        {apiErrorMessageText !== "" && apiErrorMessageText !== undefined ? (
          <Error errorMessage={apiErrorMessageText} />
        ) : (
          <React.Fragment>
            {(!favData || favData.length === 0) && <Loading loading="loading" />}
            <SidebarHeaderTemplate
              backButton={false}
              pageTitle={favorites.page_title}
              category_color={theme.reddish_purple}
              isFav={true}
              titleThumbnail="favourites_icon"
              favItem={length}
            >
              {favData && favData.length ? (
                <div className={c(
                  "tiles_wrapper",
                  styles.tiles_wrapper
                )}>
                  <InfiniteScroll
                    onScroll={this.handleHorizontalScroll}
                    horizontal
                  >
                    {this.state.items.map((dataElement, index) => {
                      if (index % 2 === 0) {
                        item1 = dataElement;
                      } else {
                        item2 = dataElement;
                      }
                      if (index % 2 !== 0) {
                        return (
                          <div
                            key={index}
                            className={styles.tiles_wrapper_inner}
                          >
                            <Tile
                              dataElement={item1}
                              onClickLocked={this.onClickLocked}
                              updateTile={(id, status) => this.updateTile(id, status)}
                            />
                            <Tile
                              dataElement={item2}
                              onClickLocked={this.onClickLocked}
                              updateTile={(id, status) => this.updateTile(id, status)}
                            />
                          </div>
                        );
                      } else if (length === index + 1) {
                        return (
                          <div
                            key={index}
                            className={styles.tiles_wrapper_inner}
                          >
                            <Tile dataElement={item1} 
                              updateTile={(id, status) => this.updateTile(id, status)} />
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </InfiniteScroll>
                </div>
              ) : (
                ""
              )}
            </SidebarHeaderTemplate>
            <LockedPopup
              showLockedImage={showLockedImage}
              onClose={this.onClose}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = state => ({
  favorites: get(state, "appManifest.data.favourites", {}),
  theme: get(state, "appManifest.data.theme", {}),
  favAddListErrorMsg: get(state, "dashboardList.errorMessageFavList"),
  errorMessageApi: get(state, "dashboardList.errorMessage"),
  kafkaUrl: get(state, "appManifest.data.app_settings.kafka_url"),
  profile: selectUser(state),
  itemTypes: selectItemTypes(state),
  selectedChild: selectSelectedChild(state),
  favData: selectFavoriteData(state),
  isFetchingFavList: isFetchingFavList(state),
  selectedChildId: selectSelectedChildId(state),
  countryCode: selectCountryCode(state)
});

export const mapDispatchToProps = dispatch => ({
  webAppEvent(url, params) {
    dispatch(webAppEvent(url, params));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Favourites)
);
