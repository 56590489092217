import React, { Component } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import c from "classnames";
import BasicTemplate from "../Templates/BasicTemplate";
import Image from "components/Global/Image";
import Loading from "components/Loading";
import { BACKGROUNDS } from "constants/styles";
import classes from "./_styles.module.scss";

class VisibilityPopover extends Component {
  render() {
    const { buttonClick, visibilityPopoverData } = this.props;
    return (
      <React.Fragment>
        {visibilityPopoverData ? (
          <BasicTemplate background={BACKGROUNDS.LOGIN_BG_IMAGE}>
            <div className={classes.content_wrapper}>
              <h2 className={classes.title}>{visibilityPopoverData.title}</h2>
              <Image
                folderName="ui_and_learning_area"
                fileName="curious_world_logo_double"
                alt="CW Logo"
                className={classes.logo}
              />
              <div
                className={c("app_button", classes.app_button)}
                onClick={buttonClick}
              >
                {visibilityPopoverData.button_text}
              </div>
            </div>
          </BasicTemplate>
        ) : (
          <Loading />
        )}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = state => {
  return {
    visibilityPopoverData: get(state, "appManifest.data.welcome_back")
  };
};

export default connect(
  mapStateToProps,
  null
)(VisibilityPopover);
