import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import get from "lodash/get";
import InfiniteScroll from "react-bidirectional-infinite-scroll";

import SidebarHeaderTemplate from "components/Templates/SidebarHeaderTemplate";
import {
  selectDashboardManifestData,
  selectItemTypes,
  selectCountryCode
} from "selectors/dashboard";
import { setSelectedChildId } from "actions/children";
import {
  selectSelectedChildId,
  selectSelectedChild,
  selectSelectedChildAge,
  selectChildren
} from "selectors/children";
import Cookies from "js-cookie";
import Error from "../Error";

import Tile from "./Tile";
import Loader from "../Loader";
import styles from "./_Dashboard.module.scss";
import Loading from "../Loading";
import LockedPopup from "components/Dashboard/LockedPopup";
import { lockedTitleTapped } from "../../helper.js";
import { selectUser } from "selectors/profile";
import { webAppEvent } from "actions/common";
import { WHO_IS_PLAYING_SCREEN_TYPES, RESOURCE_NOT_FOUND_ERROR } from "constants/index";
import c from "classnames";
import Idle from 'react-idle';
import Image from "components/Global/Image";
import { isMobile } from "react-device-detect";
import { isBedTime, cookieParams } from "utils";

let apiErrorMessageText = "";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      length: 20,
      showLockedImage: false,
      button: {
        buttonConfig: {
          type: "submit"
        }
      },
      itemsClicked: false,
      childChanged: true,
      resourceNotFound: Cookies.get("resourceNotFound"),
      animationPosition: 0,
      showAnimation: "TRUE"
    };
  }

  static propTypes = {
    dashboardManifestData: PropTypes.array,
    children: PropTypes.array,
    selectChild: PropTypes.func,
    selectedChildId: PropTypes.string,
    selectedChildAge: PropTypes.number
  };

  static defaultProps = {
    dashboardManifestData: [],
    children: [],
    selectChild() {},
    selectedChildId: "",
    selectedChildAge: ""
  };

  componentDidMount() {
    Cookies.set("showAnimation", "TRUE", cookieParams);
    Cookies.remove("lockedPopup");
    const { length } = this.state;
    this.setSelectedChild();
    Cookies.remove("parsedView");
    let scrollPosition = Cookies.get("scrollPosition");
    let itemLength = Cookies.get("itemLength");
    if (scrollPosition && scrollPosition !== 0 && itemLength) {
      this.setState({
        items: [].concat(this.getItems(0, itemLength))
      });
    }
    else {
      this.setState({
        items: [].concat(this.getItems(0, length))
      });
    }
    sessionStorage.setItem("screenType", WHO_IS_PLAYING_SCREEN_TYPES.LAYOVER);
  }

  getItems = (totalItemsInArray, length) => {
    const { dashboardManifestData } = this.props;
    return dashboardManifestData.slice(
      totalItemsInArray,
      totalItemsInArray + length
    );
  };

  handleHorizontalScroll = (position, previousPosition) => {
    let { items, length } = this.state;
    Cookies.set("scrollPosition", position, cookieParams);
    Cookies.set("itemLength", items.length, cookieParams);
    let scrollablePosition = document.getElementsByClassName("tiles_wrapper")[0].getElementsByTagName("div")[0].scrollWidth - document.getElementsByClassName("tiles_wrapper")[0].offsetWidth;
    if (scrollablePosition - position < 50) {
      items = items.concat(this.getItems(items.length, length));
      this.setState({ items });
    }
    this.setAnimationPosition();
  }

  componentDidUpdate(prevProps) {
    const { dashboardManifestData, selectedChildAge, selectedChildId } = this.props;
    const { length, items, itemsClicked, childChanged, animationPosition } = this.state;
    if (selectedChildId && selectedChildId !== prevProps.selectedChildId) {
      this.setState({
        childChanged: true
      });
      Cookies.remove("scrollPosition");
      Cookies.remove("itemLength");
    }
    if (
      dashboardManifestData &&
      dashboardManifestData !== prevProps.dashboardManifestData
    ) {
      if (items.length && !itemsClicked) {
        this.setState({
          items: items,
          itemsClicked: true
        });
      }
      if (!items.length || childChanged) {
        let scrollPosition = Cookies.get("scrollPosition");
        let itemLength = Cookies.get("itemLength");
        if (scrollPosition && scrollPosition !== 0 && itemLength) {
          this.setState({
            items: [].concat(this.getItems(0, itemLength)),
            animationPosition: 0
          });
        }
        else {
          this.setState({
            items: [].concat(this.getItems(0, length)),
            animationPosition: 0
          });
        }
        setTimeout(() => {
          this.setState({
            childChanged: false
          });
        }, 2100);
        let tiles_wrapper = document.getElementsByClassName("tiles_wrapper")[0];
        if (tiles_wrapper) {
          tiles_wrapper.getElementsByTagName("div")[0].scrollLeft = scrollPosition;
        }
      }
      Cookies.set("child_age", selectedChildAge, cookieParams);
      if (animationPosition === 0) {
        this.setAnimationPosition();
        var that = this;
        window.addEventListener(
          "resize",
          function() {
            setTimeout(() => {
              that.setAnimationPosition();
            }, 200);
          },
          false
        );
      }
      Cookies.remove("childId");
    }
  }

  setAnimationPosition = () => {
    var that = this;
    let tilesLoadedInterval = setInterval(function() {
      let tiles_wrapper = document.getElementsByClassName("tiles_wrapper")[0];
      if (tiles_wrapper) {
        clearInterval(tilesLoadedInterval);
        let viewportWidth = tiles_wrapper.offsetWidth;
        let contentWidth = tiles_wrapper.getElementsByTagName("div")[0].scrollWidth;
        let scrollLeft = tiles_wrapper.getElementsByTagName("div")[0].scrollLeft;
        let viewportThumbWidth = (viewportWidth*((viewportWidth/contentWidth)*100))/100;
        let viewportScroll = (viewportWidth*((scrollLeft/contentWidth)*100))/100;
        if (viewportScroll + (viewportThumbWidth/2) + 75 > viewportWidth) {
          that.setState({
            animationPosition: 'auto'
          });
        }
        else {
          that.setState({
            animationPosition: viewportScroll + (viewportThumbWidth/2)
          });
        }
      }
    }, 500);
  }

  isValidChild = childId => {
    return !!(this.props.children || []).find(child => child.id === childId);
  };

  setSelectedChild = () => {
    const { match, selectChild, selectedChildId } = this.props;
    const childId = get(match, "params.childId", "");
    if (childId && !selectedChildId && this.isValidChild(childId)) {
      selectChild(childId);
    }
  };

  onClose = () => {
    Cookies.set("showAnimation", "TRUE", cookieParams);
    Cookies.remove("lockedPopup");
    this.setState({
      showLockedImage: false
    });
  };

  onClickLocked = item => {
    Cookies.set("showAnimation", "FALSE", cookieParams);
    Cookies.set("lockedPopup", "shown", cookieParams);
    this.setState({
      showLockedImage: true
    });
    this.handleLockedTitleTapped(item);
  };

  handleLockedTitleTapped = item => {
    const {
      webAppEvent,
      kafkaUrl,
      itemTypes,
      selectedChild,
      profile,
      countryCode
    } = this.props;
    switch (item.type) {
      case itemTypes.videos:
        item.contentType = "video";
        break;
      case itemTypes.books:
        item.contentType = "book";
        break;
      case itemTypes.games:
        item.contentType = "game";
        break;
      default:
        item.contentType = "collection";
        break;
    }
    let params = [
      {
        ...lockedTitleTapped(profile, item, selectedChild, countryCode)
      }
    ];
    webAppEvent(kafkaUrl, params);
  };

  updateTile = (id, status) => {
    const { items } = this.state;
    let clickedItem = items.filter(function(item) {
      return item['id'] === id;
    });

    if (status) {
      clickedItem['0']['favorite'] = 0;
    }
    else {
      clickedItem['0']['favorite'] = 1;
    }

    let updatedItem = items.map((item, index) => {
      if (item['id'] === id) {
        return clickedItem['0'];
      }
      return item;
    });

    this.setState({
      items: updatedItem,
      itemsClicked: false
    });
  }

  closeErrorpop = () => {
    Cookies.set("showAnimation", "TRUE", cookieParams);
    Cookies.remove("resourceNotFound");
    this.setState({ resourceNotFound: "" });
  };

  render() {
    Cookies.set("prevPageforPolicy", "Dashboard", cookieParams);
    const { dashboardManifestData, dashboardApiErrorMsg } = this.props;
    const { showLockedImage, childChanged, resourceNotFound, animationPosition } = this.state;
    let item1,
      item2 = null;
    let count = 0;
    dashboardManifestData.map(dataElement => {
      if (dataElement.favorite === 1) {
        count++;
      }
      return count;
    });
    const length = this.state.items.length;
    if (
      dashboardApiErrorMsg !== "" &&
      dashboardApiErrorMsg !== null &&
      dashboardApiErrorMsg !== undefined
    ) {
      apiErrorMessageText = dashboardApiErrorMsg;
    }
    return (
      <React.Fragment>
        {apiErrorMessageText !== "" && apiErrorMessageText !== undefined ? (
          <Error errorMessage={apiErrorMessageText} />
        ) : (
          <React.Fragment>
            {dashboardManifestData.length < 1 && <Loading loading="loading" />}
            <SidebarHeaderTemplate backButton="false" favItem={count} pageName="PAGE_HOME">
              {dashboardManifestData && dashboardManifestData.length ? (
                <React.Fragment>
                  {childChanged && <Loader className="dashboard_loader" />}
                  <div className={c(
                    "tiles_wrapper",
                    styles.tiles_wrapper,
                    isBedTime() ? styles.bed_time_wrapper : ""
                  )}>
                    <InfiniteScroll
                      onScroll={this.handleHorizontalScroll}
                      horizontal
                    >
                      {this.state.items.map((dataElement, index) => {
                        if (index % 2 === 0) {
                          item1 = dataElement;
                        } else {
                          item2 = dataElement;
                        }
                        if (index % 2 !== 0) {
                          return (
                            <div
                              key={index}
                              className={styles.tiles_wrapper_inner}
                            >
                              <Tile
                                dataElement={item1}
                                onClickLocked={this.onClickLocked}
                                updateTile={(id, status) => this.updateTile(id, status)}
                                pageName="PAGE_HOME"
                              />
                              <Tile
                                dataElement={item2}
                                onClickLocked={this.onClickLocked}
                                updateTile={(id, status) => this.updateTile(id, status)}
                                pageName="PAGE_HOME"
                              />
                            </div>
                          );
                        } else if (length === index + 1) {
                          return (
                            <div
                              key={index}
                              className={styles.tiles_wrapper_inner}
                            >
                              <Tile
                                dataElement={item1}
                                onClickLocked={this.onClickLocked}
                                updateTile={(id, status) => this.updateTile(id, status)}
                                pageName="PAGE_HOME"
                              />
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </InfiniteScroll>
                  </div>
                  <Idle
                    timeout={5000}
                    onChange={({ idle }) => {
                      if (idle) {
                        let showAnimation = Cookies.get("showAnimation");
                        this.setState({
                          showAnimation: showAnimation
                        });
                      }
                    }}
                    render={({ idle }) =>
                      <React.Fragment>
                        {idle && this.state.showAnimation !== "FALSE"
                          ? (
                            isMobile ? (
                              <div className={c(
                                styles.idle_wrapper,
                                styles.idle_wrapper_mobile
                              )}>
                                <Image
                                  folderName="static"
                                  fileName="animation_bg_mobile"
                                  className={styles.animation_bg}
                                />
                                <Image
                                  folderName="static"
                                  fileName="scroll_direction"
                                  className={styles.scroll_direction}
                                />
                                <Image
                                  folderName="static"
                                  fileName="hand_animation"
                                  className={styles.pointer}
                                />
                              </div>
                            ) : (
                              <div className={styles.idle_wrapper} 
                                style={ animationPosition === 'auto' ? {
                                  left: 'auto',
                                  right: 0,
                                  transform: 'none'
                                } : {
                                  left: animationPosition
                                }}
                              >
                                <Image
                                  folderName="static"
                                  fileName="animation_bg"
                                  className={styles.animation_bg}
                                />
                                <Image
                                  folderName="static"
                                  fileName="scroll_direction"
                                  className={styles.scroll_direction}
                                />
                                <Image
                                  folderName="static"
                                  fileName="pointer"
                                  className={styles.pointer}
                                />
                              </div>
                            )
                          ) : ""
                        }
                      </React.Fragment>
                    }
                  />
                  <Image
                    folderName="static"
                    fileName="animation_bg"
                    className={styles.hide}
                  />
                </React.Fragment>
              ) : (
                ""
              )}
            </SidebarHeaderTemplate>
            <LockedPopup
              showLockedImage={showLockedImage}
              onClose={this.onClose}
            />
            {(resourceNotFound === "1") && (
              <Error
                errorMessage={RESOURCE_NOT_FOUND_ERROR}
                okButton="true"
                buttonText="Ok"
                closeErrorpop={this.closeErrorpop}
              />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = state => ({
  dashboardManifestData: selectDashboardManifestData(state),
  selectedChildId: selectSelectedChildId(state),
  selectedChild: selectSelectedChild(state),
  selectedChildAge: selectSelectedChildAge(state),
  children: selectChildren(state),
  dashboardApiErrorMsg: get(state, "dashboardList.errorMessage"),
  profile: selectUser(state),
  kafkaUrl: get(state, "appManifest.data.app_settings.kafka_url"),
  itemTypes: selectItemTypes(state),
  countryCode: selectCountryCode(state)
});

export const mapDispatchToProps = dispatch => ({
  selectChild(id) {
    dispatch(setSelectedChildId(id));
  },
  webAppEvent(url, params) {
    dispatch(webAppEvent(url, params));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Dashboard)
);
