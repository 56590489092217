export default {
  validateEmail: (email, loginScreenData) => {
    let error = "";
    let hasError = false;
    const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email) {
      error = loginScreenData.empty_email_error;
      hasError = true;
    } else if (!regexEmail.test(String(email).toLowerCase())) {
      error = loginScreenData.invalid_email_error;
      hasError = true;
    } else {
      error = "";
    }
    return { error, hasError };
  },

  validatePassword: (password, loginScreenData) => {
    const minLength = 8;
    let error = "";
    let hasError = false;
    if (!password) {
      error = loginScreenData.empty_password_error;
      hasError = true;
    } else if (password.length < minLength || !(/\d/.test(password))) {
      error = loginScreenData.invalid_password_error;
      hasError = true;
    } else {
      error = "";
    }
    return { error, hasError };
  }
};
