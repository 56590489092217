import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

import { selectFavoriteList, selectEntity, selectCountryCode } from "selectors/dashboard";
import Tile from "../Dashboard/Tile";
import { selectItemTypes } from "selectors/dashboard";
import { selectSelectedChild } from "selectors/children";
import { lockedTitleTapped } from "../../helper.js";
import { selectUser } from "selectors/profile";
import { webAppEvent } from "actions/common";

class ViewTile extends Component {
  static propTypes = {
    dataElement: PropTypes.object
  };

  static defaultProps = {
    dataElement: {}
  };

  state = {
    showLockedImage: false,
    button: {
      buttonConfig: {
        type: "submit"
      }
    }
  };

  onClose = () => {
    this.setState({
      showLockedImage: false
    });
  };

  onClickLocked = item => {
    this.setState({
      showLockedImage: true
    });
    this.handleLockedTitleTapped(item);
  };

  handleLockedTitleTapped = item => {
    const {
      webAppEvent,
      kafkaUrl,
      itemTypes,
      selectedChild,
      profile,
      countryCode
    } = this.props;
    switch (item.type) {
      case itemTypes.videos:
        item.contentType = "video";
        break;
      case itemTypes.books:
        item.contentType = "book";
        break;
      case itemTypes.games:
        item.contentType = "game";
        break;
      default:
        item.contentType = "collection";
        break;
    }
    let params = [
      {
        ...lockedTitleTapped(profile, item, selectedChild, countryCode)
      }
    ];
    webAppEvent(kafkaUrl, params);
  };

  render() {
    let { dataElement, favoriteList, handleLoad, className } = this.props;
    let favorite = 0;
    let isFav =
      dataElement &&
      favoriteList &&
      favoriteList.find(item => item === dataElement.id);
    if (isFav) {
      favorite = 1;
    }
    dataElement = { ...dataElement, favorite: favorite };
    if (dataElement.id) {
      handleLoad();
    }
    return (
      <React.Fragment>
        {dataElement.id && (
          <Tile
            dataElement={dataElement}
            onClickLocked={this.onClickLocked}
            className={className}
            noTileLink={true}
            nofav={true}
          />
        )}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state, ownProps) => {
  const id = get(ownProps, "id");
  return {
    dataElement: selectEntity(state, { id }),
    favoriteList: selectFavoriteList(state),
    kafkaUrl: get(state, "appManifest.data.app_settings.kafka_url"),
    itemTypes: selectItemTypes(state),
    profile: selectUser(state),
    selectedChild: selectSelectedChild(state),
    countryCode: selectCountryCode(state)
  };
};

export const mapDispatchToProps = dispatch => ({
  webAppEvent(url, params) {
    dispatch(webAppEvent(url, params));
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewTile);
