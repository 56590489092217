import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import get from "lodash/get";
import c from "classnames";
import styles from "./_Book.module.scss";
import { setScreenOrientation, cookieParams } from "utils";
import { fetchBothPagesDetails, fetchAudio, getViewportDimensions } from "utils/epub";
import Loading from "../Loading";
import BookHtml from "./BookHtml";
import { storeBookDetails, storeAudioDetails } from "actions/epub";
import { selectBookDataBasedOnId } from "selectors/epub";
import FullScreenModalTemplate from "../Templates/FullScreenModalTemplate";
import { launchDialog } from "actions/settings";
import { webAppEvent, resourceProgressions } from "actions/common";
import { selectAssetsImagePath, selectBaseUrl } from "selectors";
import { DIALOG_IDS, ENTITY_TYPES } from "../../constants";
import { PAGE_MODE } from "constants/screens";
import { selectSelectedChildId } from "selectors/children";
import Image from "../Global/Image";
import Cookies from "js-cookie";
import { selectUser, selectIsCleverResource } from "selectors/profile";
import { selectCountryCode } from "selectors/dashboard";
import { titleCompleted } from "../../helper.js";
const queryString = require("query-string");

let bumperScreenTimeOut;
class BookReader extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func
    }),
    extractedEpubBaseUrl: PropTypes.string,
    book: PropTypes.object,
    launchBumperScreen: PropTypes.func,
    selectedChildId: PropTypes.string,
    assetsImagePath: PropTypes.string,
    baseUrl: PropTypes.string
  };

  static defaultProps = {
    history: {
      push() {}
    },
    extractedEpubBaseUrl: "",
    book: {},
    launchBumperScreen() {},
    selectedChildId: "",
    assetsImagePath: "",
    baseUrl: ""
  };
  
  constructor(props) {
    super(props);

    this.state = {
      pagesDetails: {},
      currentPage: 0,
      pagesToDisplay: 1,
      showNext: true,
      fetchedAudioData: false,
      startTime: null,
      firstPageLoaded: false,
      bookDimensions: [],
      bookScaleValue: 1,
      scalingLoaded: false,
      showFirstPageLoader: true,
      timeOnTask: 0
    };
    this.timer = 0;
  }

  componentDidMount() {
    const { book, extractedEpubBaseUrl, bookId, storeBookDetails, epubData } = this.props;
    const { fetchedAudioData } = this.state;
    if (!fetchedAudioData && epubData) {
      this.processEpubData();
    }
    else {
      const bookHref = book && get(book, "href");
      const folderName = bookHref && bookHref.toString().split(".")[0];
      setTimeout(() => {
        storeBookDetails(bookId, extractedEpubBaseUrl + folderName);
      }, 200);
    }
  }

  componentDidUpdate(prevProps) {
    const { epubData } = this.props;
    const { fetchedAudioData } = this.state;
    if (!fetchedAudioData && epubData && epubData !== prevProps.epubData) {
      this.processEpubData();
    }
  }

  processEpubData = () => {
    const { epubData, bookId, storeAudioDetails } = this.props;
    const { currentPage, pagesToDisplay } = this.state;
    let pagesDetails = fetchBothPagesDetails(epubData, currentPage, pagesToDisplay);
    let audioDetails = fetchAudio(pagesDetails, epubData.opfPath);
    storeAudioDetails(bookId, audioDetails.page1, audioDetails.page2, currentPage + 1, epubData.noOfPages)
    this.setState({
      pagesDetails: pagesDetails,
      fetchedAudioData: true
    });
    if (currentPage === 0) {
      this.setState({
        startTime: new Date().getTime()
      });
    }
  }

  prevClicked = () => {
    const { epubData } = this.props;
    const { currentPage, pagesToDisplay } = this.state;
    if (epubData) {
      let displayPages = currentPage - pagesToDisplay < 0 ? 1 : 2;
      let pagesDetails = fetchBothPagesDetails(epubData, currentPage - displayPages, displayPages);
      this.setState({
        pagesDetails: pagesDetails,
        currentPage: currentPage - displayPages < 0 ? 0 : currentPage - displayPages,
        pagesToDisplay: displayPages,
        showNext: true,
        scalingLoaded: false
      });
    }
    // this.setOverflowHidden();
  }

  nextClicked = () => {
    const { epubData, bookId, storeAudioDetails } = this.props;
    const { currentPage, pagesToDisplay } = this.state;
    if (epubData) {
      if (epubData.noOfPages >= currentPage + pagesToDisplay + 2) {
        let pagesDetails = fetchBothPagesDetails(epubData, currentPage + pagesToDisplay, 2);
        let audioDetails = fetchAudio(pagesDetails, epubData.opfPath);
        storeAudioDetails(bookId, audioDetails.page1, audioDetails.page2, currentPage + pagesToDisplay + 1, epubData.noOfPages)
        this.setState({
          pagesDetails: pagesDetails,
          currentPage: currentPage + pagesToDisplay,
          pagesToDisplay: 2,
          scalingLoaded: false
        });
        if (epubData.noOfPages === currentPage + pagesToDisplay + 2) {
          this.setState({
            showNext: false,
          });
        }
      }
      else if (epubData.noOfPages === currentPage + pagesToDisplay + 1) {
        let pagesDetails = fetchBothPagesDetails(epubData, currentPage + pagesToDisplay, 1, "LEFT_PAGE");
        let audioDetails = fetchAudio(pagesDetails, epubData.opfPath);
        storeAudioDetails(bookId, audioDetails.page1, "", currentPage + pagesToDisplay + 1, epubData.noOfPages)
        this.setState({
          pagesDetails: pagesDetails,
          currentPage: currentPage + pagesToDisplay,
          pagesToDisplay: 1,
          scalingLoaded: false
        });
        this.setState({
          showNext: false,
        });
      }
    }
    // this.setOverflowHidden();
  }

  bookEnd = () => {
    const { scalingLoaded } = this.state;
    const { siteDomain, isCleverResource } = this.props;
    const parsed = queryString.parse(window.location.search);
    let site = parsed.site;
    if (scalingLoaded && site !== "cw") {
      this.loadBumperScreen();
      isCleverResource && Cookies.set("bumperScreenDisplay", 1, cookieParams);
    }
    if (scalingLoaded && site === 'cw') {
      window.parent.postMessage("BOOK_ENDS", siteDomain);
    }
  }

  loadBumperScreen = () => {
    bumperScreenTimeOut = setTimeout(() => {
      const { launchBumperScreen, book = {}, assetsImagePath } = this.props;
      const { id } = book;
      if (id) {
        this.goToPage();
        Cookies.set("showAnimation", "FALSE", cookieParams);
        launchBumperScreen({
          id: DIALOG_IDS.BUMPER_SCREEN,
          data: {
            entityId: id,
            entityType: ENTITY_TYPES.BOOK,
            thumbnail: `${assetsImagePath}${get(book, "thumbnails.0")}`
          }
        });
        this.handleResourceProgressions();
        // startTime = null;
      }
    }, 3000);
  };

  goToPage = () => {
    const {
      history,
      selectedChildId
    } = this.props;
    if (selectedChildId) {
      setScreenOrientation(PAGE_MODE.DASHBOARD);
      if (Cookies.get("collectionPath")) {
        history.push(Cookies.get("collectionPath"));
      }
      else {
        if(Cookies.get("prevPageforPolicy")==="Favourite"){
          history.push(`/${selectedChildId}/favourites`); 
        }
        else if(Cookies.get("prevPageforPolicy")==="Search"){
          history.push(`/${selectedChildId}/Search`);
        }
        else if(Cookies.get("prevPageforPolicy")==="Category"){
          history.push(Cookies.get("categoryPath"));
        }
        else{
          history.push(`/${selectedChildId}/dashboard`);
        }
      }
    }
  };

  handleTitleComplete = () => {
    const { webAppEvent, profile, kafkaUrl, book, countryCode } = this.props;
    const { timeOnTask } = this.state;
    clearInterval(this.timer);
    let params = [
      {
        ...titleCompleted(profile, book, timeOnTask, 'book', countryCode)
      }
    ];
    webAppEvent(kafkaUrl, params);
  };

  countDown = () => {
    const { timeOnTask } = this.state;
    this.setState({
      timeOnTask: timeOnTask + 1
    });
  }

  onClose = () => {
    const { history, selectedChildId } = this.props;
    clearTimeout(bumperScreenTimeOut);
    if (selectedChildId) {
      if (Cookies.get("collectionPath")) {
        history.push(Cookies.get("collectionPath"));
      }
      else {
        if(Cookies.get("prevPageforPolicy")==="Favourite"){
          history.push(`/${selectedChildId}/favourites`); 
        }
        else if(Cookies.get("prevPageforPolicy")==="Search"){
          history.push(`/${selectedChildId}/Search`);
        }
        else if(Cookies.get("prevPageforPolicy")==="Category"){
          history.push(Cookies.get("categoryPath"));
        }
        else{
          history.push(`/${selectedChildId}/dashboard`);
        }
      }
    }
    else {
      history.push("/");
    }
    this.handleTitleComplete();
    this.handleResourceProgressions();
    Cookies.remove("modal");
  };

  handleResourceProgressions = () => {
    const {
      baseUrl,
      resourceProgressions,
      book = {},
      selectedChildId,
      epubData
    } = this.props;
    const { startTime, currentPage, pagesToDisplay } = this.state;
    const avg = ((currentPage + pagesToDisplay) * 100) / epubData.noOfPages;
    const { resourceId } = book;
    let endTime = new Date().getTime();
    let params = {
      resourceId: resourceId,
      completion: Math.floor(avg),
      time: Math.floor(endTime - startTime)
    };
    resourceProgressions(baseUrl, params, selectedChildId);
  };

  iFrameLoaded = () => {
    const { firstPageLoaded } = this.state;
    let that = this;
    if (!firstPageLoaded) {
      let firstPageInterval = setInterval(function() {
        let getcurrentFrame = document.getElementsByClassName("right_frame")['0'];
        if (getcurrentFrame && getcurrentFrame.contentDocument) {
          clearInterval(firstPageInterval);
          let viewportDimensions = getViewportDimensions(getcurrentFrame.contentDocument)
          // let viewportDimensions = getViewportDimensions();
          that.setState({
            bookDimensions: viewportDimensions
          });
          that.transformScale();
          that.setOverflowHidden();
        }
      }, 500);
      this.timer = setInterval(this.countDown, 1000);
      this.setState({
        firstPageLoaded: true
      });
    }
    else {
      this.setOverflowHidden();
    }
  }

  transformScale = (resizeLoaded = false) => {
    const { bookDimensions } = this.state;
    const { directBook } = this.props;
    let that = this;
    let spaceMarginWidth = 50;
    let spaceMarginHeight = 50;
    let spacePadding = 67;
    if (directBook) {
      spaceMarginWidth = 80;
      spaceMarginHeight = 0;
      spacePadding = 0;
    }
    var scaleValue = 1;
    if (Math.floor(bookDimensions['width'] * 2) + spacePadding > window.innerWidth - spaceMarginWidth || Math.floor(bookDimensions['height']) + spacePadding > window.innerHeight - spaceMarginHeight) {
      scaleValue = 0.99;
      this.transformScaleDecrement(scaleValue);
    }
    else if (Math.floor(bookDimensions['width'] * 2) + spacePadding < window.innerWidth - spaceMarginWidth && Math.floor(bookDimensions['height']) + spacePadding < window.innerHeight - spaceMarginHeight) {
      scaleValue = 1.01;
      this.transformScaleIncrement(scaleValue);
    }
    if (!resizeLoaded) {
      window.addEventListener(
        "resize",
        function() {
          setTimeout(() => {
            that.transformScale(true);
            that.setOverflowHidden();
          }, 200);
        },
        false
      );
    }
  }

  transformScaleIncrement = (scaleValue) => {
    const { bookDimensions } = this.state;
    const { directBook } = this.props;
    let spaceMarginWidth = 50;
    let spaceMarginHeight = 50;
    let spacePadding = 67;
    if (directBook) {
      spaceMarginWidth = 80;
      spaceMarginHeight = 0;
      spacePadding = 0;
    }
    scaleValue = scaleValue.toFixed(2);
    if (Math.floor(bookDimensions['width'] * scaleValue * 2) + spacePadding > window.innerWidth - spaceMarginWidth || Math.floor(bookDimensions['height'] * scaleValue) + spacePadding > window.innerHeight - spaceMarginHeight) {
      this.setState({
        bookScaleValue: parseFloat(scaleValue) - 0.01
      });
    }
    else {
      this.transformScaleIncrement(parseFloat(scaleValue) + 0.01)
    }
  }

  transformScaleDecrement = (scaleValue) => {
    const { bookDimensions } = this.state;
    const { directBook } = this.props;
    let spaceMarginWidth = 50;
    let spaceMarginHeight = 50;
    let spacePadding = 67;
    if (directBook) {
      spaceMarginWidth = 80;
      spaceMarginHeight = 0;
      spacePadding = 0;
    }
    scaleValue = scaleValue.toFixed(2);
    if (Math.floor(bookDimensions['width'] * scaleValue * 2) + spacePadding < window.innerWidth - spaceMarginWidth && Math.floor(bookDimensions['height'] * scaleValue) + spacePadding < window.innerHeight - spaceMarginHeight) {
      this.setState({
        bookScaleValue: parseFloat(scaleValue)
      });
    }
    else {
      this.transformScaleDecrement(parseFloat(scaleValue) - 0.01)
    }
  }

  setOverflowHidden = () => {
    let that = this;
    let innerPageInterval = setInterval(function() {
      let frameClass = ["left_frame", "right_frame"];
      frameClass.forEach(function(item, index) {
        let getcurrentFrame = document.getElementsByClassName(item)['0'];
        // that.setState({
        //   scalingLoaded: true,
        //   showFirstPageLoader: false
        // });
        // clearInterval(innerPageInterval);
        if (getcurrentFrame && getcurrentFrame.contentDocument) {
          clearInterval(innerPageInterval);
          that.setFrameCss(getcurrentFrame.contentDocument);
        }
      });
    }, 500);
  }

  setFrameCss = (parentDoc) => {
    const { bookDimensions, bookScaleValue } = this.state;
    if (parentDoc.documentElement) {
      parentDoc.documentElement.style.overflow = "hidden";
      parentDoc.documentElement.style.width = `${bookDimensions["width"]}px`;
      parentDoc.documentElement.style.height = `${bookDimensions["height"]}px`;
      parentDoc.documentElement.getElementsByTagName('body')['0'].style.overflow = "hidden";
      parentDoc.documentElement.getElementsByTagName('body')['0'].style.margin = `0`;
      parentDoc.documentElement.getElementsByTagName('body')['0'].style.transform = `scale(${bookScaleValue})`;
      parentDoc.documentElement.getElementsByTagName('body')['0'].style.transformOrigin = "top left";
    }
    this.setState({
      scalingLoaded: true,
      showFirstPageLoader: false
    });
  }

  render() {
    const { bookId, epubData, directBook, isCleverResource } = this.props;
    const { pagesDetails, currentPage, showNext, bookDimensions, bookScaleValue, scalingLoaded, showFirstPageLoader, pagesToDisplay } = this.state;
    const content = (
      <React.Fragment>
        {currentPage > 0 ? (
          <div className={c(
            styles.prev,
            directBook && styles.direct_book_prev
          )}
          onClick={this.prevClicked}
          >
            <Image
              folderName="static"
              fileName="left_arrow"
              alt="Prev"
            />
          </div>
        ) : ( "" )}
        <BookHtml 
          pagesDetails={pagesDetails}
          currentPage={currentPage}
          bookId={bookId}
          iFrameLoaded={this.iFrameLoaded}
          scalingLoaded={scalingLoaded}
          bookEnd={this.bookEnd}
          noOfPages={get(epubData, "noOfPages")}
          pagesToDisplay={pagesToDisplay}
          prevClicked={this.prevClicked}
          nextClicked={this.nextClicked}
          bumperScreenClosed = {(isCleverResource && !Cookies.get("bumperScreenDisplay")) ? 1 : 0}
        />
        {showNext ? (
          <div className={c(
            styles.next,
            directBook && styles.direct_book_next
          )}
          onClick={this.nextClicked}
          >
            <Image
              folderName="static"
              fileName="right_arrow"
              alt="Next"
            />
          </div>
        ) : ( "" )}
      </React.Fragment>
    );
    let bookWidth, bookHeight;
    if (bookDimensions && (bookDimensions['width'] || bookDimensions['height'])) {
      bookWidth = Math.floor(bookDimensions['width'] * bookScaleValue * 2) - 1;
      bookHeight = Math.floor(bookDimensions['height'] * bookScaleValue);
    }
    return (
      <React.Fragment>
        {pagesDetails.page1 || pagesDetails.page2 ? (
          <React.Fragment>
          {(!scalingLoaded && showFirstPageLoader) && <Loading loading="loading" />}
          {!directBook ? (
            <FullScreenModalTemplate
              page="BOOKS"
              onClose={this.onClose}
              bookDimensions={bookDimensions}
              bookScaleValue={bookScaleValue}
            >
              <div className={styles.book_wrapper}>
                {content}
              </div>
            </FullScreenModalTemplate>
          ) : (
            <div className={styles.direct_book_wrapper}>
              <div 
                style={ bookWidth || bookHeight ? {
                  width: bookWidth,
                  height: bookHeight
                } : {}}
              >
                {content}
              </div>
            </div>
          )}
          </React.Fragment>
        ):(
          <Loading />
        )}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state, props) => {
  return {
    epubData: selectBookDataBasedOnId(state, props),
    assetsImagePath: selectAssetsImagePath(state),
    selectedChildId: selectSelectedChildId(state),
    baseUrl: selectBaseUrl(state),
    kafkaUrl: get(state, "appManifest.data.app_settings.kafka_url"),
    profile: selectUser(state),
    siteDomain: get(state, "appManifest.data.app_settings.site_domain"),
    countryCode: selectCountryCode(state),
    isCleverResource: selectIsCleverResource(state)
  };
};

export const mapDispatchToProps = dispatch => ({
  storeBookDetails(bookId, epubbaseUrl) {
    dispatch(storeBookDetails(bookId, epubbaseUrl));
  },
  storeAudioDetails(bookId, page1AudioDetails, page2AudioDetails, pageNo, noOfPages) {
    dispatch(storeAudioDetails(bookId, page1AudioDetails, page2AudioDetails, pageNo, noOfPages) );
  },
  launchBumperScreen(params) {
    dispatch(launchDialog(params));
  },
  resourceProgressions(baseUrl, params, selectedChildId) {
    dispatch(resourceProgressions(baseUrl, params, selectedChildId));
  },
  webAppEvent(url, params) {
    dispatch(webAppEvent(url, params));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BookReader)
);
