import deepFreeze from "deep-freeze-es6";
import {
  ADD_TO_FAVOURITES_REQUEST,
  ADD_TO_FAVOURITES_SUCCESS,
  ADD_TO_FAVOURITES_FAILURE,
  REMOVE_TO_FAVOURITES_REQUEST,
  REMOVE_TO_FAVOURITES_SUCCESS,
  REMOVE_TO_FAVOURITES_FAILURE
} from "constants/actions";

const initialState = {
  isFetching: false,
  errorMessage: "",
  isId: null
};

export const addToFavouritesRequest = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    isId: action.isId,
    errorMessage: action.errorMessage
  };
};

export const addToFavouritesSuccess = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage
  };
};

export const addToFavouritesFailure = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage
  };
};

export const removeToFavouritesRequest = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    isId: action.isId,
    errorMessage: action.errorMessage
  };
};

export const removeToFavouritesSuccess = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage
  };
};

export const removeToFavouritesFailure = (state, action) => {
  return {
    ...state,
    isFetching: action.isFetching,
    errorMessage: action.errorMessage
  };
};

export default (state = initialState, action) => {
  deepFreeze(state);
  deepFreeze(action);

  switch (action.type) {
    case ADD_TO_FAVOURITES_REQUEST:
      return addToFavouritesRequest(state, action);
    case ADD_TO_FAVOURITES_SUCCESS:
      return addToFavouritesSuccess(state, action);
    case ADD_TO_FAVOURITES_FAILURE:
      return addToFavouritesFailure(state, action);
    case REMOVE_TO_FAVOURITES_REQUEST:
      return removeToFavouritesRequest(state, action);
    case REMOVE_TO_FAVOURITES_SUCCESS:
      return removeToFavouritesSuccess(state, action);
    case REMOVE_TO_FAVOURITES_FAILURE:
      return removeToFavouritesFailure(state, action);
    default:
      return state;
  }
};
