import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import get from "lodash/get";
import c from "classnames";

import { selectBaseUrl } from "selectors";
import { selectUserId } from "selectors/profile";
import Image from "components/Global/Image";
import { deleteChild, setSelectedChildId } from "actions/children";
import { selectSelectedChildId } from "selectors/children";
import basicTemplateClasses from "components/Templates/_BasicTemplate.module.scss";

import BasicTemplate from "../Templates/BasicTemplate";
import classes from "./_styles.module.scss";
import { PATHS } from "../../constants/url";
import { WHO_IS_PLAYING_SCREEN_TYPES } from "constants/index";
import { setScreenOrientation } from "utils";
import { PAGE_MODE } from "constants/screens";
import Loading from "../Loading";
import { isTablet } from "react-device-detect";

class DeleteChild extends Component {
  static propTypes = {
    baseUrl: PropTypes.string
  };

  static defaultProps = {
    baseUrl: ""
  };

  state = {
    isDisable: false
  };

  goBack = () => {
    const { history, selectedChildId, match } = this.props;
    if (selectedChildId) {
      history.push(`/${selectedChildId}/edit-child`);
    }
    else {
      let childId = get(match, "params.childId", "");
      history.push(`/${childId}/edit-child`);
    }
  };

  onDelete = () => {
    const {
      deleteChild,
      history,
      match,
      selectedChildId
    } = this.props;
    const { isDisable } = this.state;
    const childId = get(match, "params.childId", "");
    this.setState({
      isDisable: true
    });
    !isDisable &&
      deleteChild().then(() => {
        if (selectedChildId === childId) {
          this.props.selectChild("");
        }
        if (
          sessionStorage.getItem("screenType") ===
          WHO_IS_PLAYING_SCREEN_TYPES.MY_CHILDREN && 
          selectedChildId !== childId
        ) {
          if(isTablet){
            setScreenOrientation(PAGE_MODE.TABLET);
          }else{
            setScreenOrientation(PAGE_MODE.MY_CHILDREN);
          }
          history.push(PATHS.MY_CHILDREN);
        } else {
          setScreenOrientation(PAGE_MODE.HOME);
          history.push(PATHS.HOME);
        }
        sessionStorage.removeItem("screenType");
      });
  };

  render() {
    const { deleteScreenData = {}, deleteChildLoader } = this.props;
    const {
      heading_text: headingText,
      content_line_1: line1,
      content_line_2: line2,
      content_line_3: line3,
      delete_button_text: deleteButtonText,
      cancel_button_text: cancelButtonText
    } = deleteScreenData;
    return (
      <React.Fragment>
        {deleteChildLoader && <Loading loading="loading" />}
        <BasicTemplate>
          <div
            className={c(
              basicTemplateClasses.basic_template_content_wrapper,
              classes.delete_child_wrapper
            )}
          >
            <Image
              folderName="static"
              fileName="close_window"
              className={basicTemplateClasses.close_icon}
              onClick={this.goBack}
            />
            <span className={classes.heading_text}>{headingText}</span>
            <div className={classes.content}>
              <p className={classes.content_line}>{line1}</p>
              <p className={classes.content_line}>{line2}</p>
              <p className={classes.content_line}>{line3}</p>
            </div>
            <div
              className={c("app_button", classes.app_button)}
              onClick={this.onDelete}
            >
              {deleteButtonText}
            </div>
            <div className={classes.delete_button} onClick={this.goBack}>
              {cancelButtonText}
            </div>
          </div>
        </BasicTemplate>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = state => ({
  baseUrl: selectBaseUrl(state),
  userId: selectUserId(state),
  deleteScreenData: get(state, "appManifest.data.delete_child"),
  selectedChildId: selectSelectedChildId(state),
  deleteChildLoader: get(state, "children.isFetching")
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { baseUrl, userId } = stateProps;
  const { dispatch } = dispatchProps;
  const childId = get(ownProps, "match.params.childId", "");

  return {
    ...stateProps,
    ...ownProps,
    deleteChild() {
      return dispatch(deleteChild(baseUrl, userId, childId));
    },
    selectChild(id) {
      dispatch(setSelectedChildId(id));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null,
    mergeProps
  )(DeleteChild)
);
