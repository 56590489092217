import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import get from "lodash/get";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import {
  selectEntity,
  selectItemTypes,
  selectFavoriteList,
  selectEntityExist
} from "selectors/dashboard";
import { selectSelectedChildId, selectSelectedChildAge } from "selectors/children";
import { selectIsCleverResource } from "selectors/profile";
import Error from "../Error";
import { PATHS } from "constants/url";
import { RESOURCE_NOT_FOUND_ERROR } from "constants/index";

import fetchManifestData from "../FetchManifestData";
import FullScreenModalTemplate from "../Templates/FullScreenModalTemplate";
import Loading from "../Loading";
import styles from "./_styles.module.scss";
import c from "classnames";
import Tile from "components/Dashboard/Tile";
import { setScreenOrientation, cookieParams } from "utils";
import { PAGE_MODE } from "constants/screens";
import Cookies from "js-cookie";
import LockedPopup from "components/Dashboard/LockedPopup";

let content = [];
let item1 = null;
let item2 = null;
let apiErrorMessageText = "";
class Collection extends Component {
  static propTypes = {
    collection: PropTypes.object,
    itemTypes: PropTypes.objectOf(PropTypes.string),
    selectedChildId: PropTypes.string,
    collectionMsg: PropTypes.object,
    collectionId: PropTypes.string
  };

  static defaultProps = {
    collection: {},
    itemTypes: {},
    selectedChildId: "",
    collectionMsg: {},
    collectionId: ""
  };

  state = {
    desktopMaxWidth: 5000,
    desktopMinWidth: 1025,
    tabletMaxWidth: 1024,
    tabletMinWidth: 992,
    mobileMaxWidth: 991,
    mobileMinWidth: 0,
    desktopCarouselItems: 4,
    tabletCarouselItems: 3,
    mobileCarouselItems: 1,
    showcollection: false,
    setCollectionBoxDesignCalled: false,
    showLockedImage:false
  };

  onClose = () => {
    const { history, selectedChildId } = this.props;
    this.setState({showLockedImage:false})
    setScreenOrientation(PAGE_MODE.DASHBOARD);
    content = [];
    Cookies.remove("collectionPath");
    if(Cookies.get("prevPageforPolicy")==="Favourite"){
      history.push(`/${selectedChildId}/favourites`); 
    }
    else if(Cookies.get("prevPageforPolicy")==="Search"){
      history.push(`/${selectedChildId}/Search`);
    }
    else if(Cookies.get("prevPageforPolicy")==="Category"){
      history.push(Cookies.get("categoryPath"));
    }
    else{
      history.push(`/${selectedChildId}/dashboard`);
    }
  };

  getLinkUrl = collectionItem => {
    const { itemTypes, selectedChildId } = this.props;
    const { type, id } = collectionItem;
    switch (type) {
      case itemTypes.videos:
        return `/${selectedChildId}/video/${id}`;
      case itemTypes.books:
        return `/${selectedChildId}/book/${id}`;
      case itemTypes.games:
        return `/${selectedChildId}/game/${id}`;
      default:
        return "";
    }
  };

  waitForElement = (element, callback) => {
    let waitingElement = setInterval(function() {
      if (element) {
        clearInterval(waitingElement);
        callback();
      }
    }, 100);
  };

  getNewCollection = (collection, favoriteList) => {
    let { selectedChildAge, history } = this.props;
    if (collection.titles && favoriteList) {
      let collectionarray = [];
      for (var i = 0; i < collection.titles.length; i++) {
        if (selectedChildAge <= 2) {
          selectedChildAge = 2;
          if (collection.titles[i].ages && (collection.titles[i].ages.indexOf("1") !== -1 || 
          collection.titles[i].ages.indexOf("2") !== -1)) {
            collectionarray.push(collection.titles[i]);
          }
        }
        else if (selectedChildAge >= 7) {
          selectedChildAge = 7;
          if (collection.titles[i].ages && (collection.titles[i].ages.indexOf("7") !== -1 || 
          collection.titles[i].ages.indexOf("8") !== -1 || 
          collection.titles[i].ages.indexOf("9") !== -1)) {
            collectionarray.push(collection.titles[i]);
          }
        }
        else {
          if(collection.titles[i].ages && collection.titles[i].ages.indexOf(selectedChildAge.toString()) !== -1) {
            collectionarray.push(collection.titles[i]);
          }
        }
      }
      content = [];
      collectionarray &&
        collectionarray.map((item, index) => {
          this.setState({ showcollection: true });
          let favorite = 0;
          let isFav =
            favoriteList && favoriteList.find(data => data === item.id);
          if (isFav) {
            favorite = 1;
          }
          item = { ...item, favorite: favorite };
          if (index % 2 === 0) {
            item1 = item;
          } else {
            item2 = item;
          }
          if (index % 2 !== 0) {
            content.push(
              <div key={index}>
                <Tile dataElement={item1} className="collection_tile" />
                <Tile dataElement={item2} className="collection_tile" />
              </div>
            );
          } else if (collectionarray.length === index + 1) {
            content.push(
              <div key={index}>
                <Tile dataElement={item1} className="collection_tile" />
              </div>
            );
          }
          return content;
        });
      if (collectionarray.length === 0) {
        history.push(PATHS.HOME);
      }
    }
  };
  componentDidMount() {
    const { mobileMaxWidth, setCollectionBoxDesignCalled } = this.state;
    const { collection } = this.props;
    const subscriptionStatus = Cookies.get("subscriptionStatus");
    if((subscriptionStatus === "Cancelled" || subscriptionStatus === "Free Promotion Expired" || subscriptionStatus === "Free") && !collection.isPublic) {
      this.setState({showLockedImage:true})
    }
    else{
    if (window.innerWidth > mobileMaxWidth && !setCollectionBoxDesignCalled) {
      this.setCollectionBoxDesign();
    }
  }
  }

  componentDidUpdate(prevProps) {
    const { mobileMaxWidth, setCollectionBoxDesignCalled, showLockedImage } = this.state;
    const { favoriteList, collection, collectionNotExist, history, isCleverResource, collectionId } = this.props;
    if (!showLockedImage && (!isCleverResource && collectionNotExist) || (isCleverResource && isCleverResource.type !== "collection") || (isCleverResource && isCleverResource.type === "collection" && isCleverResource.id !== collectionId)) {
      history.push(PATHS.HOME);
    }
    if (window.innerWidth > mobileMaxWidth && !setCollectionBoxDesignCalled) {
      this.setCollectionBoxDesign();
    }
    if (
      collection.titles !== prevProps.collection.titles ||
      favoriteList !== prevProps.favoriteList
    ) {
      this.getNewCollection(collection, favoriteList);
      Cookies.set("collectionPath", history.location.pathname, cookieParams);
    }
  }

  setCollectionBoxDesign = () => {
    const {
      desktopMinWidth,
      tabletMinWidth,
      desktopCarouselItems,
      tabletCarouselItems,
      mobileCarouselItems
    } = this.state;

    let that = this;
    let collection = document.getElementsByClassName("collection")[0];
    let collection_track = document.getElementsByClassName(
      "react-multi-carousel-track "
    )[0];
    if (collection && collection_track) {
      this.waitForElement(
        collection_track.getElementsByTagName("li"),
        function() {
          that.setState({ setCollectionBoxDesignCalled: true });
          let collection_track_li = collection_track.getElementsByTagName("li");
          let collection_track_img = collection_track.getElementsByClassName(
            "tile_thumbnail"
          )[0];

          let noOfElements = desktopCarouselItems;
          if (window.innerWidth < tabletMinWidth) {
            noOfElements = mobileCarouselItems;
          } else if (window.innerWidth < desktopMinWidth) {
            noOfElements = tabletCarouselItems;
          }

          if (collection_track_img) {
            collection.style.width =
              collection_track_img.clientWidth * noOfElements +
              parseFloat(
                window
                  .getComputedStyle(collection_track_li[0], null)
                  .getPropertyValue("margin-left")
              ) *
                (noOfElements * 2 + 1) +
              parseFloat(
                window
                  .getComputedStyle(collection, null)
                  .getPropertyValue("padding-left")
              ) +
              parseFloat(
                window
                  .getComputedStyle(collection, null)
                  .getPropertyValue("padding-right")
              ) +
              "px";

            setTimeout(function() {
              if (typeof collection_track_li == "object") {
                for (let [key, value] of Object.entries(collection_track_li)) {
                  value.style.width = collection_track_img.clientWidth + "px";
                }
              }
            }, 500);
          }
        }
      );
    }
  };

  render() {
    const { collection, collectionMsg, dashboardApiErrorMsg, collectionNotExist, isCleverResource, collectionId } = this.props;
    const {
      desktopMaxWidth,
      desktopMinWidth,
      tabletMaxWidth,
      tabletMinWidth,
      mobileMaxWidth,
      mobileMinWidth,
      desktopCarouselItems,
      tabletCarouselItems,
      mobileCarouselItems,
      showLockedImage
    } = this.state;
    const responsive = {
      desktop: {
        breakpoint: { max: desktopMaxWidth, min: desktopMinWidth },
        items: desktopCarouselItems,
        slidesToSlide: desktopCarouselItems
      },
      tablet: {
        breakpoint: { max: tabletMaxWidth, min: tabletMinWidth },
        items: tabletCarouselItems,
        slidesToSlide: tabletCarouselItems
      },
      mobile: {
        breakpoint: { max: mobileMaxWidth, min: mobileMinWidth },
        items: mobileCarouselItems,
        slidesToSlide: mobileCarouselItems
      }
    };
    const length = Object.keys(collection).length;
    const titleLen = length && collection.titles && collection.titles.length;
    if (
      dashboardApiErrorMsg !== "" &&
      dashboardApiErrorMsg !== null &&
      dashboardApiErrorMsg !== undefined
    ) {
      apiErrorMessageText = dashboardApiErrorMsg;
    }
    return (
      <React.Fragment>
        {apiErrorMessageText !== "" && apiErrorMessageText !== undefined ? (
          <Error errorMessage={apiErrorMessageText} />
        ) : (
          <React.Fragment>
            {!length || content.length < 1 || collectionNotExist == null || collectionNotExist ? (
              (isCleverResource && isCleverResource.type === "collection" && isCleverResource.id === collectionId && collection.category && (collection.category !== "collection" || (collectionNotExist !== null && collectionNotExist))) ? (
                <Error errorMessage={RESOURCE_NOT_FOUND_ERROR} />
              ) : (
                <Loading />
              )
            ) : (
              <FullScreenModalTemplate
                onClose={this.onClose}
                className={c(styles.collection, "collection")}
                page="COLLECTION"
              >
                <div className={c(styles.collection_title, "collection_title")}>
                  {collection.title}
                </div>
                {titleLen === 0 && collectionMsg.no_collection_found}
                <Carousel
                  showDots={content.length > 4 ? true : false}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  itemClass={styles.carousel_item}
                >
                  {content &&
                    content.map(item => {
                      return item;
                    })}
                </Carousel>
              </FullScreenModalTemplate>
            )}
             <LockedPopup
              showLockedImage={showLockedImage}
              onClose={this.onClose}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state, ownProps) => {
  const id = get(ownProps, "match.params.collectionId");
  const type = "collection";
  return {
    collectionNotExist: selectEntityExist(state, { id, type }),
    collection: selectEntity(state, { id }),
    itemTypes: selectItemTypes(state),
    selectedChildId: selectSelectedChildId(state),
    collectionMsg: get(state, "appManifest.data.collection", {}),
    favoriteList: selectFavoriteList(state),
    dashboardApiErrorMsg: get(state, "dashboardList.errorMessage"),
    selectedChildAge: selectSelectedChildAge(state),
    collectionId: id,
    isCleverResource: selectIsCleverResource(state)
  };
};

export const mapDispatchToProps = dispatch => ({});

export default withRouter(
  fetchManifestData(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Collection)
  )
);
