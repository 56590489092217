export const PATHS = {
  DASHBOARD: "/:childId/dashboard",
  TERMS_CONDITIONS: "/terms-conditions",
  PRIVACY_POLICY: "/privacy-policy",
  ADD_CHILD: "/add-child",
  EDIT_CHILD: "/:childId/edit-child",
  DELETE_CHILD: "/:childId/delete-child",
  CATEGORY: "/:childId/category/:categoryId",
  HOME: "/",
  PARENT_AREA: "/:childId/parent-area",
  PARENT_DASHBOARD: "/:childId/parent-dashboard",
  MY_CHILDREN: "/my-children",
  GAME: "/:childId/game/:gameId",
  BOOK: "/:childId/book/:bookId",
  VIDEO: "/:childId/video/:videoId",
  COLLECTION: "/:childId/collection/:collectionId",
  SEARCH: "/:childId/search",
  LOGIN: "/login",
  FAVOURITES: "/:childId/favourites",
  DIRECT_ACCESS_BOOK: "/book/:bookId"
};
