import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classes from "./_fullScreenModal.module.scss";
import c from "classnames";
import basicTemplateClasses from "../Templates/_BasicTemplate.module.scss";
import Image from "../Global/Image";
import { selectSiteTheme } from "selectors";
import { selectIsCleverResource } from "selectors/profile";
import get from "lodash/get";
import Cookies from "js-cookie";

class FullScreenModalTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ageCheckVisible: ""
    };
  }

  static propTypes = {
    onClose: PropTypes.func,
    children: PropTypes.node.isRequired,
    history: PropTypes.shape({
      goBack: PropTypes.func
    }),
    siteTheme: PropTypes.object
  };

  static defaultProps = {
    onClose() {},
    history: {
      goBack() {}
    },
    siteTheme: {}
  };

  onClickClose = event => {
    event.stopPropagation();
    this.props.onClose();
    Cookies.remove("modal");
  };

  componentDidMount() {
    const { AgeCheckShow } = this.props;
    if (AgeCheckShow) {
      this.setState({
        ageCheckVisible: ""
      });
      setTimeout(() => {
        this.setState({
          ageCheckVisible: "age_check_visible"
        });
      }, 200);
    }
  }

  render() {
    const {
      children,
      onClose,
      bookFirstPageCheck,
      siteTheme,
      className,
      page,
      bookDimensions,
      bookScaleValue,
      isCleverResource
    } = this.props;
    const { ageCheckVisible } = this.state;
    const bg_color = get(siteTheme, "default_bg_color");
    const gradient_base_color = get(siteTheme, "gradient_base_color");

    let close_icon = "close_window";
    if (
      page === "AGE_CHECk" ||
      page === "GAMES" ||
      page === "BOOKS" ||
      page === "VIDEOS"
    ) {
      close_icon = "close_windowicon_purple";
    }
    else if (page === "EVENT_MESSAGE") {
      close_icon = "close_with_shadow";
    }

    let bookWidth, bookHeight;
    if (bookDimensions && (bookDimensions['width'] || bookDimensions['height'])) {
      bookWidth = Math.floor(bookDimensions['width'] * bookScaleValue * 2) - 1;
      bookHeight = Math.floor(bookDimensions['height'] * bookScaleValue);
    }

    return (
      <React.Fragment>
        {page !== "AGE_CHECk" && page !== "EVENT_MESSAGE" ? (
          <div
            className="bg_image"
            style={{
              background: bg_color,
              background: `-moz-linear-gradient(top, ${bg_color} 0%, ${gradient_base_color} 100%)`,
              background: `-webkit-linear-gradient(top, ${bg_color} 0%,${gradient_base_color} 100%)`,
              background: `linear-gradient(to bottom, ${bg_color} 0%,${gradient_base_color} 100%)`,
              filter: `progid:DXImageTransform.Microsoft.gradient( startColorstr=${bg_color}, endColorstr=${gradient_base_color},GradientType=0 )`,
            }}
          >
            <Image
              folderName="background"
              fileName="background_transparent"
              alt="Background"
            />
          </div>
        ) : (
          ""
        )}
        <div
          className={c(
            classes.full_screen_modal,
            page === "AGE_CHECk" ? classes.age_check : "",
            page === "GAMES" ? classes.page_games : "",
            page === "EVENT_MESSAGE" ? classes.page_event_message : "",
            page === "BOOKS" ? classes.page_books : ""
          )}
        >
          <div
            className={classes.transparent_layover}
            // onClick={this.onClickClose}
          />
          <div className={classes.bumper_content_wrapper}>
            <div
              className={c(
                basicTemplateClasses.basic_template_content_wrapper,
                className,
                classes.bumper_content,
                classes[ageCheckVisible],
                page === "BOOKS" ? "books_content_wrapper" : ""
              )}
              style={ bookWidth || bookHeight ? {
                width: bookWidth,
                height: bookHeight
              } : {}}
            >
              {(((isCleverResource && isCleverResource.type !== "collection" && !isCleverResource.id) || (isCleverResource && isCleverResource.type === "collection" && page !== "COLLECTION")) || (!isCleverResource && !bookFirstPageCheck)) && (
                <Image
                  folderName="static"
                  fileName={close_icon}
                  className={c(
                    basicTemplateClasses.close_icon_right,
                    classes.close_icon,
                    page === "AGE_CHECk" ? classes.age_check_close : "",
                    page === "GAMES" || page === "VIDEOS"
                      ? classes.media_close
                      : ""
                  )}
                  onClick={onClose}
                />
              )}
              {children}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = state => ({
  siteTheme: selectSiteTheme(state),
  isCleverResource: selectIsCleverResource(state)
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(FullScreenModalTemplate)
);
