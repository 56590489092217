import get from "lodash/get";
import getfp from "lodash/fp/get";
import { createSelector } from "reselect";
import { selectIsCleverResource } from "./profile";
import Cookies from "js-cookie";
import { cookieParams } from "utils";

export const selectCountry = state =>
  get(state, "appManifest.data.app_settings.country_url");

export const selectCountryCode = getfp("dashboardList.country");

export const selectRecommendedManifestData = getfp(
  "dashboardList.recommendedManifestData"
);

export const selectManifestData = getfp("dashboardList.manifestData");

export const selectFavoriteList = getfp("dashboardList.favoriteList.data");

export const isFetchingFavList = getfp("dashboardList.isFetchingFavList");

export const selectFavoriteData = createSelector(
  [selectManifestData, selectFavoriteList],
  (manifestData, favoriteList = []) => {
    if (manifestData.length && favoriteList.length) {
      let favoriteData = favoriteList.reduce((favoriteData, e) => {
        let favDataFromId = manifestData.filter(data => data.id === e);
        if (favDataFromId.length) {
          let fvrtDataTitles = { ...favDataFromId["0"], favorite: 1 };
          favoriteData.push(fvrtDataTitles);
        }
        return favoriteData;
      }, []);
      return favoriteData;
    }
  }
);

export const selectDashboardManifestData = createSelector(
  [selectRecommendedManifestData, selectManifestData, selectFavoriteList],
  (recommendedManifestData, manifestData, favoriteList = []) => {
    if (recommendedManifestData !== "" && manifestData.length) {
      let dashboardManifestData = get(
        recommendedManifestData,
        "data.recommended",
        []
      ).reduce((dashboardManifestData, e) => {
        let directData = manifestData.filter(data => data.category === "title");
        let findDirectData = directData.filter(data => data.id === e);
        let fvrt = 0;
        if (favoriteList.length && favoriteList.indexOf(e) !== -1) {
          fvrt = 1;
        }
        if (findDirectData.length) {
          let directDataTitles = { ...findDirectData["0"], favorite: fvrt };
          dashboardManifestData.push(directDataTitles);
        }

        let collectionData = manifestData.filter(
          data => data.category === "collection"
        );
        let findCollectionData = collectionData.filter(data => data.id === e);
        fvrt = 0;
        if (favoriteList.length && favoriteList.indexOf(e) !== -1) {
          fvrt = 1;
        }
        if (findCollectionData.length) {
          let collectionDataTitles = {
            ...findCollectionData["0"],
            favorite: fvrt
          };
          dashboardManifestData.push(collectionDataTitles);
        }

        /*let categoryData = manifestData.filter(data => data.category === "category");
        let findCategoryData = categoryData.reduce((findCategoryData, cd) => {

          let findCategoryDataTitles = cd.titles.filter(data => data.id === e);
          if (findCategoryDataTitles.length) {
            dashboardManifestData.push(findCategoryDataTitles['0']);
          }

          return findCategoryData;
        }, []);*/

        return dashboardManifestData;
      }, []);
      return dashboardManifestData;
    }
  }
);

const selectEntityId = (state, props = {}) => props.id;
const selectEntityType = (state, props = {}) => props.type;

export const selectEntity = createSelector(
  [selectManifestData, selectEntityId],
  (manifestData = [], entityId) => {
    if (manifestData.length && entityId) {
      return manifestData.find(data => data.id === entityId);
    }
  }
);

const selectCleverCollection = createSelector(
  [selectManifestData, selectIsCleverResource],
  (manifestData = [], isCleverResource) => {
    if (manifestData.length && isCleverResource && isCleverResource.type === "collection") {
      return manifestData.find(data => data.id === isCleverResource.id);
    }
    else {
      return false;
    }
  }
);

export const selectEntityExist = createSelector(
  [selectRecommendedManifestData, selectEntityId, selectEntityType, selectManifestData, selectIsCleverResource, selectCleverCollection],
  (recommendedManifestData = [], entityId, entityType, manifestData = [], isCleverResource, cleverCollection) => {
    if (recommendedManifestData.data && recommendedManifestData.data.recommended && recommendedManifestData.data.recommended.length && manifestData.length && (!cleverCollection || cleverCollection.id)) {
      if(!(recommendedManifestData.data.recommended.find(data => data === entityId)) || !(manifestData.find(data => data.id === entityId))){
        return true;
      }
      else {
        if (isCleverResource && isCleverResource.id && isCleverResource.type !== "collection") {
          if (isCleverResource.id === entityId) {
            return false;
          }
          else {
            return true;
          }
        }
        else if (isCleverResource && isCleverResource.id && isCleverResource.type === "collection") {
          if ((entityType==="collection" && isCleverResource.id === entityId) || (entityType!=="collection" && cleverCollection && cleverCollection.titles && cleverCollection.titles.find(title => title.id === entityId))) {
            return false;
          }
          else {
            return true;
          }
        }
        else {
          return false;
        }
      }
    }
  }
);

export const selectIsFetchingDashboardApi = getfp("dashboardList.isFetching");

const selectRelatedContent = getfp("dashboardList.relatedContent");

export const selectIsFetchingRelatedContent = getfp("dashboardList.isFetchingRelatedContent");

export const selectVideoPageData = state =>
  get(state, "appManifest.data.video_page");

const selectDialogSettings = getfp("settings.dialog");

const selectViewedRelatedData = createSelector(
  [
    selectRelatedContent,
    selectDialogSettings
  ],
  (relatedContent, dialogSettings) => {
    if (
      relatedContent.length &&
      dialogSettings !== ""
    ) {
      let relatedContentData = relatedContent.filter(function(v, i) {
        return v["itemId"] === get(dialogSettings, "data.entityId", "");
      });
      const modal = Cookies.get("modal");
      if (modal) {
        let modalArr = modal.split(',');

        let relatedData = get(relatedContentData, "0.relatedData", []);
        let spliceData, appendedData;
        modalArr.forEach(function (value) {
          let relatedViewedData = relatedData.filter(data => data === value);
          if (relatedViewedData[0]) {
            spliceData = relatedData.filter(data => data !== value);
            appendedData = [...spliceData, relatedViewedData[0]]
            relatedData = appendedData;
          }
        });
        if (appendedData) {
          return appendedData;
        } else {
          return relatedData;
        }
        // return relatedViewedData.concat(modalArr);
      }
      else {
        return get(relatedContentData, "0.relatedData", []);
      }
    }
  }
);

export const selectRelatedData = createSelector(
  [
    selectViewedRelatedData,
    selectManifestData,
    selectDialogSettings,
    selectFavoriteList
  ],
  (relatedContentData, manifestData = [], dialogSettings, favoriteList) => {
    if (
      relatedContentData &&
      manifestData.length &&
      dialogSettings !== "" &&
      favoriteList !== ""
    ) {
      let relatedManifestData = relatedContentData.reduce((relatedManifestData, e) => {
        let directData = manifestData.filter(data => data.category === "title");
        let findDirectData = directData.filter(data => data.id === e);
        let fvrt = 0;
        if (
          favoriteList &&
          favoriteList.length &&
          favoriteList.indexOf(e) !== -1
        ) {
          fvrt = 1;
        }
        if (findDirectData.length) {
          let directDataTitles = { ...findDirectData["0"], favorite: fvrt };
          relatedManifestData.push(directDataTitles);
        }

        let entityId = get(dialogSettings, "data.entityId", "");
        const modal = Cookies.get("modal");
        if (modal) {
          let modalArr = modal.split(',');
          if (!modalArr.includes(entityId)) {
            modalArr.push(entityId);
            Cookies.set("modal", modalArr.join(), cookieParams);
          }
        }
        else {
          Cookies.set("modal", entityId, cookieParams);
        }

        return relatedManifestData;
      }, []);
      return relatedManifestData;
    }
  }
);

export const selectDashboardApi = state =>
  get(state, "appManifest.data.dashboard_api");

export const selectItemTypes = state =>
  get(state, "appManifest.data.dashboard_api.item_type");

export const isFetchingManifestData = getfp("dashboardList.isFetchingManifestData");

export const isFetchingManifestRecommendation = getfp("dashboardList.isFetchingManifestRecommendation");
