import React, { Component } from "react";
import PropTypes from "prop-types";
import DropdownDate from "react-dropdown-date";
import moment from "moment";

import styles from "./_styles.module.scss";

export default class DateOfBirth extends Component {
  static propTypes = {
    value: PropTypes.number,
    onDobChange: PropTypes.func
  };

  static defaultProps = {
    value: moment().valueOf(),
    onDobChange() {}
  };

  state = {
    year: "",
    month: "",
    day: "",
    selectedDate: "",
    yearPlaceholderClass: true,
    monthPlaceholderClass: true,
    dayPlaceholderClass: true,
    dobChanged: false,
    initYear: "",
    addedFocus: false,
    prevSelectedMonth: "",
    prevSelectedDay: ""
  };

  componentDidMount() {
    const { value } = this.props;
    if (value) {
      this.setDate(value);
    } else {
      //this.setDate(moment().valueOf());
    }
  }

  componentDidUpdate(prevProps) {
    const { value, isEdit } = this.props;
    const { year, month, day, addedFocus, initYear } = this.state;
    if (value && prevProps.value !== value) {
      this.setDate(value);
    }
    let that = this;
    let yearLimit = moment().subtract(18,'y').format('YYYY');
    if (isEdit && year && month && day && initYear < yearLimit) {
      document.getElementById("select-year").addEventListener("focus", function() {
        if (!addedFocus) {
          that.setState({
            dobChanged: true,
            addedFocus: true
          });
          that.onChange("year", -1);
        }
      });
    }
  }

  setDate = date => {
    const { initYear } = this.state;
    const { isEdit } = this.props;
    const momentDate = moment(date);
    let month = '' + (momentDate.month() + 1),
        day = '' + momentDate.date(),
        year = momentDate.year()
    // if (month.length < 2) month = '0' + month;
    // if (day.length < 2) day = '0' + day;

    if (isEdit && !initYear) {
      this.setState({
        initYear: year
      });
    }

    this.setState({
      year: year,
      month: month,
      day: day,
      selectedDate: [year, month, day].join('-'),
      yearPlaceholderClass: false,
      monthPlaceholderClass: false,
      dayPlaceholderClass: false
    });
  };

  onChange = (key, value) => {
    const { day, year, month, initYear, dobChanged, prevSelectedMonth, prevSelectedDay } = this.state;
    const { isEdit } = this.props;
    let yearLimit = moment().subtract(18,'y').format('YYYY');
    let curYear = moment().format('YYYY');
    let curMonth = moment().format('M');
    let curDay = moment().format('D');
    if (isEdit && initYear < yearLimit && !dobChanged && key !== "year") {
      this.setState({
        dobChanged: true,
        yearPlaceholderClass: true,
      });
      this.onChange("year", -1);
    }
    if (value && value !== '-1') {
      this.setState({
        [`${key}PlaceholderClass`]: false
      });
    } else {
      this.setState({
        [`${key}PlaceholderClass`]: true
      });
    }
    if (key === "month") {
      if ((parseInt(year) === parseInt(yearLimit) && parseInt(value) === parseInt(curMonth) && (typeof day === 'undefined' || day === "" || parseInt(day) < parseInt(curDay))) || (parseInt(year) === parseInt(curYear) && parseInt(value) === parseInt(curMonth) && (typeof day === 'undefined' || day === "" || parseInt(day) > parseInt(curDay)))) {
        this.setState({
          dayPlaceholderClass: true,
          prevSelectedDay: (typeof day !== 'undefined' && parseInt(day) > -1) ? day : prevSelectedDay ? prevSelectedDay : "",
          day: ""
        });
      }
      else if (typeof value === 'undefined') {
        this.setState({
          dayPlaceholderClass: true,
          prevSelectedDay: (typeof day !== 'undefined' && parseInt(day) > -1) ? day : prevSelectedDay ? prevSelectedDay : "",
          day: ""
        });
      }
      else {
        this.setState({
          dayPlaceholderClass: ((typeof day !== 'undefined' && parseInt(day) > -1) || prevSelectedDay) ? false : true,
          day: (typeof day !== 'undefined' && parseInt(day) > -1) ? day : prevSelectedDay
        });
      }
    }
    else if (key === "year") {
      if ((parseInt(value) === parseInt(yearLimit) && (typeof month === 'undefined' || parseInt(month) < parseInt(curMonth))) || (parseInt(value) === parseInt(curYear) && (typeof month === 'undefined' || parseInt(month) > parseInt(curMonth)))) {
        this.setState({
          monthPlaceholderClass: true,
          prevSelectedMonth: (typeof month !== 'undefined' && parseInt(month) > -1) ? month : "",
          month: ""
        });
      }
      else if ((parseInt(value) === parseInt(yearLimit) && parseInt(month) === parseInt(curMonth) && (typeof day === 'undefined' || parseInt(day) < parseInt(curDay))) || (parseInt(value) === parseInt(curYear) && parseInt(month) === parseInt(curMonth) && (typeof day === 'undefined' || parseInt(day) > parseInt(curDay)))) {
        this.setState({
          dayPlaceholderClass: true,
          prevSelectedDay: (typeof day !== 'undefined' && parseInt(day) > -1) ? day : "",
          day: ""
        });
      }
      else {
        this.setState({
          monthPlaceholderClass: (typeof month !== 'undefined' && parseInt(month) > -1) ? false : true,
          month: (typeof month !== 'undefined' && parseInt(month) > -1) ? month : prevSelectedMonth,
          day: (typeof day !== 'undefined' && parseInt(day) > -1) ? day : prevSelectedDay
        });
      }
    }
    this.setState(
      {
        [key]: value
      },
      () => {
        const { year, month, day } = this.state;
        let dob = null;
        if (parseInt(year) > -1 && parseInt(month) > -1 && parseInt(day) > -1 && value) {
          dob = moment()
            .set("year", year)
            .set("month", month - 1)
            .set("date", day)
            .set("hour", 0)
            .set("minute", 0)
            .set("second", 0)
            .set("millisecond", 0)
            .valueOf();
        }
        this.props.onDobChange(dob, year, month, day);
      }
    );
  };

  render() {
    const {
      year,
      selectedDate,
      yearPlaceholderClass,
      monthPlaceholderClass,
      dayPlaceholderClass,
      dobChanged,
      initYear
    } = this.state;
    const { isEdit } = this.props;
    let yearLimit = moment().subtract(18,'y').format('YYYY');
    return (
      <DropdownDate
        startDate={
          moment().subtract(18,'y').format('YYYY-MM-DD')
        }
        endDate={
          moment(new Date()).format("YYYY-MM-DD")
        }
        selectedDate={
          selectedDate
        }
        order={
          ['year', 'month', 'day']
        }
        onMonthChange={(month) => {
          if (month) {
            month = moment().month(month).format("M");
          }
          this.onChange("month", month);
        }}
        onDayChange={(day) => {
          this.onChange("day", day);
        }}
        onYearChange={(year) => {
          this.onChange("year", year);
        }}
        ids={
          {
            year: 'select-year',
            month: 'select-month',
            day: 'select-day'
          }
        }
        classes={
          {
            dateContainer: styles.birthDateWrapper,
            yearContainer: styles.dateInnerWrapper,
            monthContainer: styles.dateInnerWrapper,
            dayContainer: styles.dateInnerWrapper,
            year: yearPlaceholderClass ? "date_dropdown year placeholder" : "date_dropdown year",
            month: monthPlaceholderClass ? "date_dropdown month placeholder" : "date_dropdown month",
            day: dayPlaceholderClass ? "date_dropdown day placeholder" : "date_dropdown day",
            yearOptions: 'date_option',
            monthOptions: 'date_option',
            dayOptions: 'date_option'
          }
        }
        defaultValues={
          {
            year: isEdit && !dobChanged && initYear < yearLimit ? year : 'Year',
            month: 'Month',
            day: 'Day'
          }
        }
        options={
          {
            yearReverse: true,
            monthShort: true
          }
        }
      />
    );
  }
}
