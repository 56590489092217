import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import get from "lodash/get";
import Cookies from "js-cookie";
import { PATHS } from "constants/url";
import { RESOURCE_NOT_FOUND_ERROR } from "constants/index";

import { selectEntity, selectEntityExist, selectItemTypes, selectCountryCode } from "selectors/dashboard";
import { selectIsApiRequestSyncing, selectBaseUrl } from "selectors";
import { selectSelectedChildId } from "selectors/children";

import Loading from "../Loading";
import fetchManifestData from "../FetchManifestData";
import styles from "./_styles.module.scss";
import FullScreenModalTemplate from "../Templates/FullScreenModalTemplate";
import {
  webAppEvent,
  resourceProgressions,
  authenticateUrl
} from "actions/common";
import { titleCompleted } from "../../helper.js";
import { selectUser, selectIsCleverResource } from "selectors/profile";
import Error from "../Error";
import c from "classnames";
import LockedPopup from "components/Dashboard/LockedPopup";
import { cookieParams } from "utils";

let startTime = null;
let endTime = null;
let apiErrorMessageText = "";
class Game extends Component {
  static propTypes = {
    game: PropTypes.object,
    isApiRequestSyncing: PropTypes.bool,
    gamesUrl: PropTypes.string,
    history: PropTypes.shape({
      goBack: PropTypes.func
    })
  };
  
  constructor(props) {
    super(props);

    this.state = {
      showLockedImage:false,
      loaded: false,
      timeOnTask: 0
    };
    this.timer = 0;
  }

  static defaultProps = {
    game: {},
    isApiRequestSyncing: false,
    gamesUrl: "",
    history: {
      goBack() {}
    }
  };

  componentDidMount() {
    const { game, baseUrl, authenticateUrl } = this.props;
    const subscriptionStatus = Cookies.get("subscriptionStatus");
    if (Object.keys(game).length !== 0) {
      if((subscriptionStatus === "Cancelled" || subscriptionStatus === "Free Promotion Expired" || subscriptionStatus === "Free") && !game.isPublic){
        this.setState({showLockedImage:true})
      }
      else{
        if (game.id) {
          let params = {
            id: game.id,
            web_game: 1
          };
          authenticateUrl(baseUrl, params);
          startTime = game.href && new Date().getTime();
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { game, baseUrl, authenticateUrl, gameNotExist, history, privateData, itemTypes, selectedChildId, isApiRequestSyncing, isCleverResource, gameId } = this.props;
    const { showLockedImage, loaded } = this.state;
    const subscriptionStatus = Cookies.get("subscriptionStatus");
    if (isApiRequestSyncing || !game.href || !privateData.data || gameNotExist == null || gameNotExist) {

    } else if (!loaded) {
      this.setState({loaded:true})
    }
    if (!showLockedImage) {
      if ((!isCleverResource && gameNotExist) || (isCleverResource && isCleverResource.type !== "collection" && isCleverResource.id !== gameId) || (isCleverResource && isCleverResource.type === "collection" && gameNotExist) || (!isCleverResource && game !== prevProps.game && game.type !== itemTypes.games)) {
        Cookies.set("resourceNotFound", 1, cookieParams);
        history.push(`/${selectedChildId}/dashboard`);
      }
      else {
        if (game.id !== prevProps.game.id) {
          if((subscriptionStatus === "Cancelled" || subscriptionStatus === "Free Promotion Expired" || subscriptionStatus === "Free") && !game.isPublic){
            this.setState({showLockedImage:true})
          }
          let params = {
            id: game.id,
            web_game: 1
          };
          authenticateUrl(baseUrl, params);
          startTime = game.href && new Date().getTime();
        }
      }
    }
  }

  updatePrivateDataUrl = () => {
    const { privateData, domainUrl } = this.props;
    // return `${process.env.PUBLIC_URL}/games/html_arthur_dinosaurs/index.html`;
    if (privateData && privateData.data) {
      let url = privateData.data.split("/");
      url[2] = domainUrl;
      return url.join("/");
    }
  };

  handleTitleComplete = () => {
    const { webAppEvent, profile, kafkaUrl, game, countryCode } = this.props;
    const { timeOnTask } = this.state;
    clearInterval(this.timer);
    let params = [
      {
        ...titleCompleted(profile, game, timeOnTask, 'game', countryCode)
      }
    ];
    webAppEvent(kafkaUrl, params);
  };

  countDown = () => {
    const { timeOnTask } = this.state;
    this.setState({
      timeOnTask: timeOnTask + 1
    });
  }

  iframeLoaded = () => {
    this.timer = setInterval(this.countDown, 1000);
  }

  onClose = () => {
    const { history, selectedChildId } = this.props;
    if (Cookies.get("collectionPath")) {
      history.push(Cookies.get("collectionPath"));
    }
    else {
      if(Cookies.get("prevPageforPolicy")==="Favourite"){
        history.push(`/${selectedChildId}/favourites`); 
      }
      else if(Cookies.get("prevPageforPolicy")==="Search"){
        history.push(`/${selectedChildId}/Search`);
      }
      else if(Cookies.get("prevPageforPolicy")==="Category"){
        history.push(Cookies.get("categoryPath"));
      }
      else{
        history.push(`/${selectedChildId}/dashboard`);
      }
    }
   /*  if (history.action === "PUSH") {
      history.goBack();
    } else {
      history.push(`/${selectedChildId}/dashboard`);
    } */
    this.handleTitleComplete();
    this.handleResourceProgressions();
    Cookies.remove("modal");
  };

  onCloselockedpop = () => {
    const { history, selectedChildId } = this.props;
    if (selectedChildId) {
      if (Cookies.get("collectionPath")) {
        history.push(Cookies.get("collectionPath"));
      }
      else {
        if(Cookies.get("prevPageforPolicy")==="Favourite"){
          history.push(`/${selectedChildId}/favourites`); 
        }
        else if(Cookies.get("prevPageforPolicy")==="Search"){
          history.push(`/${selectedChildId}/Search`);
        }
        else if(Cookies.get("prevPageforPolicy")==="Category"){
          history.push(Cookies.get("categoryPath"));
        }
        else {
          history.push(`/${selectedChildId}/dashboard`);
        }
      }
    } else {
      history.push(PATHS.HOME);
    }
    this.setState({showLockedImage:false})
  };

  handleResourceProgressions = () => {
    const {
      baseUrl,
      resourceProgressions,
      game = {},
      selectedChildId
    } = this.props;
    const { resourceId } = game;
    endTime = new Date().getTime();
    let params = {
      resourceId: resourceId,
      completion: Math.floor(0),
      time: (startTime && endTime) ? Math.floor(endTime - startTime) : 0
    };
    resourceProgressions(baseUrl, params, selectedChildId);
  };

  render() {
    const {
      isApiRequestSyncing,
      game,
      privateData,
      dashboardApiErrorMsg,
      gameNotExist,
      isCleverResource,
      itemTypes
    } = this.props;
    const { showLockedImage, loaded } = this.state;
    if (
      dashboardApiErrorMsg !== "" &&
      dashboardApiErrorMsg !== null &&
      dashboardApiErrorMsg !== undefined
    ) {
      apiErrorMessageText = dashboardApiErrorMsg;
    }
    return (
      <React.Fragment>
        {apiErrorMessageText !== "" && apiErrorMessageText !== undefined ? (
          <Error errorMessage={apiErrorMessageText} />
        ) : (
          <React.Fragment>
            {showLockedImage ? (
              <LockedPopup
                showLockedImage={this.state.showLockedImage}
                onClose={this.onCloselockedpop}
              />
            ) : (
              !loaded && (isApiRequestSyncing || !game.href || !privateData.data || gameNotExist == null || gameNotExist) ? (
                (isCleverResource && isCleverResource.id && ((gameNotExist != null && gameNotExist) || (game.type && game.type !== itemTypes.games))) ? (
                  <Error errorMessage={RESOURCE_NOT_FOUND_ERROR} />
                ) : (
                  <Loading />
                )
              ) : (
                <FullScreenModalTemplate
                  onClose={this.onClose}
                  page="GAMES"
                  className={styles.games_bumper_content}
                >
                  <div className={styles.iframe_wrapper}>
                    <iframe
                      title="gameIframe"
                      className={c("game_iframe", styles.game_iframe)}
                      src={this.updatePrivateDataUrl()}
                      onLoad={this.iframeLoaded}
                    />
                  </div>
                </FullScreenModalTemplate>
              )
            )}
          </React.Fragment>
        )}
        
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state, ownProps) => {
  const id = get(ownProps, "match.params.gameId");
  const type = "game";

  return {
    gameNotExist: selectEntityExist(state, { id, type }),
    game: selectEntity(state, { id }),
    isApiRequestSyncing: selectIsApiRequestSyncing(state),
    gamesUrl: get(state, "appManifest.data.app_settings.games_url"),
    profile: selectUser(state),
    kafkaUrl: get(state, "appManifest.data.app_settings.kafka_url"),
    baseUrl: selectBaseUrl(state),
    selectedChildId: selectSelectedChildId(state),
    privateData: get(state, "common.entityData"),
    dashboardApiErrorMsg: get(state, "dashboardList.errorMessage"),
    domainUrl: get(state, "appManifest.data.game_page.domain_url"),
    itemTypes: selectItemTypes(state),
    countryCode: selectCountryCode(state),
    isCleverResource: selectIsCleverResource(state),
    gameId: id
  };
};

export const mapDispatchToProps = dispatch => ({
  webAppEvent(url, params) {
    dispatch(webAppEvent(url, params));
  },
  resourceProgressions(baseUrl, params, selectedChildId) {
    dispatch(resourceProgressions(baseUrl, params, selectedChildId));
  },
  authenticateUrl(baseUrl, params) {
    dispatch(authenticateUrl(baseUrl, params));
  }
});

export default withRouter(
  fetchManifestData(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Game)
  )
);
