import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "components/Templates/_BasicTemplate.module.scss";
import { BACKGROUNDS } from "constants/styles";
import c from "classnames";
import Image from "components/Global/Image";
import { selectSiteTheme } from "selectors";
import get from "lodash/get";

class BasicTemplate extends React.Component {
  static propTypes = {
    background: PropTypes.string,
    siteTheme: PropTypes.object
  };

  static defaultProps = {
    background: BACKGROUNDS.BG_DEFAULT,
    siteTheme: {}
  };

  renderSwitch(background) {
    switch (background) {
      case BACKGROUNDS.BG_IMAGE:
        return "background_transparent";
      case BACKGROUNDS.LOGIN_BG_IMAGE:
        switch (true) {
          case (window.innerWidth < 768 && window.orientation === 0):
            return "login_backgorund_mobile";
          case window.innerWidth < 1025:
            return "login_backgorund_ipad";
          default:
            return "login_backgorund";
        }
      case BACKGROUNDS.FAV_BG_IMAGE:
        return "favourite_background_graphics";
      default:
        return "";
    }
  }

  render() {
    const {
      children,
      background,
      siteTheme,
      bgClassName,
      autoHeight
    } = this.props;
    const bg_color = get(siteTheme, "default_bg_color");
    const gradient_base_color = get(siteTheme, "gradient_base_color");

    return (
      <React.Fragment>
        {background === BACKGROUNDS.BG_IMAGE ||
        background === BACKGROUNDS.LOGIN_BG_IMAGE ||
        background === BACKGROUNDS.FAV_BG_IMAGE ? (
          <div
            className={c("bg_image", bgClassName ? styles[bgClassName] : "")}
            style={{
              background: bg_color,
              background: `-moz-linear-gradient(top, ${bg_color} 0%, ${gradient_base_color} 100%)`,
              background: `-webkit-linear-gradient(top, ${bg_color} 0%,${gradient_base_color} 100%)`,
              background: `linear-gradient(to bottom, ${bg_color} 0%,${gradient_base_color} 100%)`,
              filter: `progid:DXImageTransform.Microsoft.gradient( startColorstr=${bg_color}, endColorstr=${gradient_base_color},GradientType=0 )`,
            }}
          >
            <Image
              className={styles.arrow}
              folderName="background"
              fileName={this.renderSwitch(background)}
              alt="Background"
            />
          </div>
        ) : (
          ""
        )}
        <div
          className={c(
            styles[background],
            bgClassName ? styles[bgClassName] : "",
            autoHeight ? styles.autoHeight : ""
          )}
        >
          {children}
        </div>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = state => ({
  siteTheme: selectSiteTheme(state)
});

export default connect(
  mapStateToProps,
  null
)(BasicTemplate);
