import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import Cookies from "js-cookie";
import c from "classnames";
import { Link } from "react-router-dom";
import { PATHS } from "constants/url";
import Image from "components/Global/Image";
import styles from "components/Header/_Header.module.scss";
import classes from "components/Search/_styles.module.scss";
import CustomButton from "components/Global/Button";
import { setScreenOrientation, cookieParams } from "utils";
import { PAGE_MODE } from "constants/screens";
import AgeCheck from "../AgeCheck";
import { launchAgeCheck, routeValuecheck } from "actions/settings";
import { isTablet } from "react-device-detect";
import AssetsImage from "../Global/AssetsImage";

class Header extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    pageTitle: PropTypes.string,
    homePageData: PropTypes.object,
    launchAgeChecked: PropTypes.func,
    routeValuecheck: PropTypes.func
  };

  state = {
    button: {
      buttonConfig: {
        type: "submit"
      }
    }
  };

  static defaultProps = {
    className: "",
    pageTitle: "",
    homePageData: {},
    launchAgeChecked() {},
    routeValuecheck() {}
  };

  OnSubscribe = () => {
    const { routeValuecheck, launchAgeChecked = {} } = this.props;
    Cookies.set("showAnimation", "FALSE", cookieParams);
    launchAgeChecked(true);
    routeValuecheck("subscribeHeader");
  };

  onClickPrivacy = () => {
    if(isTablet){
      setScreenOrientation(PAGE_MODE.TABLET);
    } else{
      setScreenOrientation(PAGE_MODE.PRIVACY_POLICY);       
    }
  };

  render() {
    const subscriptionStatus = Cookies.get("subscriptionStatus");
    let remainingdays="";
    if(Cookies.get("remainingFreeDays") !=="undefined" && Cookies.get("remainingFreeDays") !=="null" ){
       remainingdays= parseInt(Cookies.get("remainingFreeDays"))+1;
    }
    const {
      className,
      pageTitle,
      pageTitleClass,
      homePageData,
      titleThumbnail,
      isFav,
      subscribeData,
      searchHeader,
      searchPage,
      searchText,
      goBackToSearch,
      goBackToSearchWithKeyword,
      RouteValue
    } = this.props;
    return (
      <div className={className}>
        <div className={styles.privacy} >
          <Link to={PATHS.PRIVACY_POLICY} onClick={this.onClickPrivacy}>
            <Image
              folderName="static"
              fileName="info_privacy"
              alt="Privacy Icon"
            />
            {homePageData["privacy_text"]}
          </Link>
        </div>
        {searchHeader !== true ? (
          searchPage !== true && (
            <React.Fragment>
              {subscriptionStatus === "Subscriber" ||
              subscriptionStatus === "Trial" ? (
                <React.Fragment>
                  <div className={styles.logo}>
                    <Image
                      folderName="ui_and_learning_area"
                      fileName="curious_world_logo"
                      alt="CW Logo"
                    />
                  </div>
                  <div className={c(styles.page_title, styles[pageTitleClass])}>
                    {titleThumbnail ? (
                      isFav ? (
                        <Image
                          folderName="ui_and_learning_area"
                          fileName={titleThumbnail}
                          alt={pageTitle}
                        />
                      ) : (
                        <AssetsImage
                          assetsImageName={titleThumbnail}
                          alt={pageTitle}
                          categoryImage="true"
                        />
                      )
                    ) : (
                      ""
                    )}
                    {pageTitle ? pageTitle : homePageData["page_title"]}
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {RouteValue === "subscribeHeader" && <AgeCheck />}
                  <div className={styles.logo_wrapper}>
                    <div className={styles.logo}>
                      {window.innerWidth > 1024 ? (
                        <Image
                          folderName="ui_and_learning_area"
                          fileName="curious_world_logo"
                          alt="CW Logo"
                        />
                      ) : (
                        <Image
                          folderName="ui_and_learning_area"
                          fileName="curious_world_logo_double"
                          alt="CW Logo"
                        />
                      )}
                    </div>
                    <CustomButton
                      config={this.state.button.buttonConfig}
                      content={subscriptionStatus === "Free"
                        ? subscribeData["free_button_text"]
                        : subscribeData["subscribe_button_text"]}
                      onClick={this.OnSubscribe}
                      className={styles.subscribe_button}
                    />
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )
        ) : (
          <div className={classes.search_result_wrapper}>
            <Image
              folderName="static"
              fileName="cross_orange"
              className={classes.close_icon}
              onClick={goBackToSearch}
            />
            <span className={classes.search_result} onClick={goBackToSearchWithKeyword}>'{searchText}'</span>
          </div>
        )}
        {subscriptionStatus === "Free Promotion Expired" && searchPage !== true && (<div className={styles.promotion_Text}>
          {subscribeData["free_promotion_expired_text"]} </div>)}
          {subscriptionStatus === "Free for Promotion" && remainingdays && searchPage !== true && (<div  className={styles.promotion_Text}>
            {subscribeData["free_trial_text1"]} {remainingdays} {subscribeData["free_trial_text2"]}</div>)}
      </div>
    );
  }
}

export const mapDispatchToProps = dispatch => ({
  launchAgeChecked(params) {
    dispatch(launchAgeCheck(params));
  },
  routeValuecheck(params) {
    dispatch(routeValuecheck(params));
  }
});

const mapStateToProps = state => ({
  homePageData: get(state, "appManifest.data.home_page"),
  subscribeData: get(state, "appManifest.data.subscribe"),
  RouteValue: get(state, "settings.RouteValue")
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header);
